import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import SharedTemplate from '@/js/vuexTemplates/shared';
import ImportTemplate from '@/js/vuexTemplates/import';
import { getModuleProps, objToSnake } from '@/js/utils';
import axios from 'axios';

const questionState = {
  showDisabled: 'all',
};

const questionGetters = {
  setShowDisabled(state, payload) {
    state.showDisabled = payload;
  },
};

const mutations = {};

const actions = {
  async generateQuestions({ dispatch, rootGetters }, requestData) {
    try {
      let endpoint = '';
      if (requestData.dataSourceId != null) {
        endpoint = `${endpoints.dataSource}${requestData.dataSourceId}/generate-questions/`;
      } else {
        endpoint = `${endpoints.article}${requestData.articleId}/generate-questions/`;
      }
      const auth = rootGetters['auth/headerAuthorization'];
      await axios.post(endpoint, objToSnake(requestData), auth);
      dispatch('task/fetchTaskList', {}, { root: true });
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to generate questions',
        text: error.message,
      }, { root: true });
    }
  },
};

const templates = [
  new GeneralTemplate(endpoints.question, 'question'),
  new ImportTemplate(endpoints.article, 'article'),
  new SharedTemplate('question'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: questionState, getters: questionGetters, mutations, actions,
  }, templates),
};
