import axios from 'axios';
import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import ImportTemplate from '@/js/vuexTemplates/import';
import SharedTemplate from '@/js/vuexTemplates/shared';
import { getModuleProps } from '@/js/utils';

const articleState = {
  scrapingResult: null,
  isScrapingSingle: false,
  showDisabled: 'all',
  scrapingTestTask: null,
  isUpdatingTags: false,
};

const articleGetters = {
  scrapingResultSingle: (state) => state.scrapingResult,
};

const mutations = {
  setScrapingSingleData(state, payload) {
    state.scrapingResult = payload;
  },
  setIsScrapingSingle(state, payload) {
    state.isScrapingSingle = payload;
  },
  setShowDisabled(state, payload) {
    state.showDisabled = payload;
  },
  setScrapingTestTask(state, id) {
    state.scrapingTestTask = id;
  },
  setIsUpdatingTags(state, value) {
    state.isUpdatingTags = value;
  },
};

function readTaskPayload(log) {
  try {
    return JSON.parse(log);
  } catch (error) {
    return null;
  }
}

const actions = {
  scrapeArticles({ dispatch }, requestData) {
    const endpoint = `${endpoints.webScraper}${requestData.webScraperId}/scrape/`;
    dispatch('postTask', {
      endpoint,
      data: requestData,
      operationType: 'scrape',
    }, { root: true });
  },
  async scrapeTest({ rootGetters, dispatch, commit }, requestData) {
    commit('setIsScrapingSingle', true);
    const endpoint = `${endpoints.webScraper}${requestData.webScraperId}/scrape/`;
    const headerAuthorization = rootGetters['auth/headerAuthorization'];
    if (requestData.testCrawler !== true) {
      console.log(`Will not test scraper - bad configs: ${requestData}`);
      return;
    }
    try {
      const resp = await axios.post(endpoint, requestData, headerAuthorization);
      if (resp.data.celery_id) {
        commit('setScrapingTestTask', resp.data.celery_id);
        dispatch('updateScrapingTestStatus');
      }
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to start scraper test',
        text: error.message,
      }, { root: true });
      commit('setIsScrapingSingle', false);
      commit('setScrapingTestTask', null);
    }
  },
  async updateScrapingTestStatus({ state, commit, dispatch }) {
    try {
      if (state.scrapingTestTask == null) {
        return;
      }
      const resp = await dispatch('task/refreshSingleTaskProgress', state.scrapingTestTask, { root: true });
      if (resp.status !== 200) {
        throw new Error('The backend failed to perform scraper testing.');
      } else if (resp.data.status === 'pending' && resp.data.isBusy === true) {
        throw new Error('Server is busy and could not perform scraper testing right now');
      }
      const log = readTaskPayload(resp.data.log);
      if (log != null) {
        commit('setScrapingSingleData', log);
      }
      if (resp.data.status === 'pending') {
        setTimeout(() => {
          dispatch('updateScrapingTestStatus');
        }, 2000);
      } else if (resp.data.status === 'done') {
        commit('setIsScrapingSingle', false);
        commit('setScrapingTestTask', null);
      }
    } catch (error) {
      const isBusy = error.message.includes('busy');
      dispatch('sidebar/showWarning', {
        title: isBusy ? 'Server is busy' : 'Scraper testing failed.',
        text: error.message,
        variant: isBusy ? 'warning' : 'danger',
      }, { root: true });
      commit('setIsScrapingSingle', false);
      commit('setScrapingTestTask', null);
    }
  },
  async abortScrapingTest({ dispatch, state, commit }) {
    if (state.scrapingTestTask) {
      await dispatch('task/abortTask', state.scrapingTestTask, { root: true });
    }
    commit('setIsScrapingSingle', false);
    commit('setScrapingSingleData', null);
    commit('setScrapingTestTask', null);
  },
  async tagArticles({ rootGetters, dispatch, commit }, payload) {
    commit('setIsUpdatingTags', true);
    const endpoint = `${endpoints.article}tag-articles/`;
    const headerAuthorization = rootGetters['auth/headerAuthorization'];
    try {
      const resp = await axios.post(endpoint, payload, headerAuthorization);
      commit('setIsUpdatingTags', false);
      if (!resp.data.success) {
        dispatch('sidebar/showWarning', {
          title: 'Failed to tag articles',
          text: resp.data.error,
        }, { root: true });
        return false;
      }
      return true;
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to tag articles',
        text: error.message,
      }, { root: true });
      return false;
    }
  },
};

const templates = [
  new GeneralTemplate(endpoints.article, 'article'),
  new ImportTemplate(endpoints.article, 'article'),
  new SharedTemplate('article'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: articleState, getters: articleGetters, mutations, actions,
  }, templates),
};
