<template>
  <div v-if="keys.length">
    <b-form-group
      v-for="key in keys"
      :key="key"
      :label="key"
      :label-for="'meta-data-key-' + key"
    >
      <b-form-input
        :id="'meta-data-key-' + key"
        @input="(x) => updateMetaDataKey({ key: key, value: x })"
      />
    </b-form-group>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
} from 'vuex';

export default {
  name: 'MetaDataForm',
  data() {
    return {
      newKey: '',
    };
  },
  computed: {
    ...mapGetters('metaDataField', { metaDataFields: 'items' }),
    keys() {
      return Object.values(this.metaDataFields).filter((x) => !x.deletable).map((x) => x.key);
    },
  },
  created() {
    this.fetchMetaDataFields();
  },
  methods: {
    ...mapActions('ranker', ['updateMetaDataKey']),
    ...mapActions('metaDataField', { fetchMetaDataFields: 'fetchItems' }),
  },
};
</script>
