<template>
  <keep-alive
    v-if="chartType === 'line'"
  >
    <LineChart
      :data="chartData"
      :options="options"
    />
  </keep-alive>
  <keep-alive
    v-else-if="chartType === 'bar'"
  >
    <BarChart
      :data="chartData"
      :options="options"
    />
  </keep-alive>
  <div v-else>
    Invalid chart type.
  </div>
</template>

<script>

import { Bar as BarChart, Line as LineChart } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LineElement,
  LinearScale,
  PointElement,
);
export default {
  name: 'TypedChart',
  components: {
    BarChart,
    LineChart,
  },
  props: {
    chartType: { type: String, default: 'line' },
    chartData: { type: Object, required: true },
    options: { type: Object, required: true },
    styles: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
