<template>
  <div class="r-25 border p-2">
    <h5 class="mb-1">
      Task Status
    </h5>
    <span class="font-weight-bolder">Running tasks:
      <span class="bg-primary text-white px-2 r-25">{{ runningTasks.length }}</span>
    </span> |
    <span class="font-weight-bolder">Failed tasks:
      <span class="bg-warning text-white px-2 r-25">{{ failedTasks.length }}</span>
    </span> |
    <span class="font-weight-bolder">Pending tasks:
      <span class="bg-secondary px-2 r-25">{{ pendingTasks.length }}</span>
    </span>

    <template v-if="showDetails && runningTasks.length">
      <br><span class="font-weight-bolder">Running tasks summary: </span>
      <div style="max-height:250px; overflow-y: auto;">
        <div v-for="task in runningTasks" :key="task.celery_id" class="border-bottom mb-1">
          <b-row class="mb-1" no-gutters>
            <b-col class="mt-auto font-weight-bolder" cols="auto">
              {{ getTaskTitle(task) }}
            </b-col>
            <b-col cols="auto" class="my-auto">
              <b-button
                size="sm"
                variant="outline-warning"
                class="py-0 ml-2"
                @click="stopClick(task)"
              >
                Abort
              </b-button>
            </b-col>
          </b-row>
          <b-progress v-if="task.progress !== null" :max="100" class="my-1">
            <b-progress-bar :value="getTaskProgress(task)" :label="`${getTaskProgress(task)}%`" />
          </b-progress>
          <div v-if="getTaskAdditionalInfo(task).length">
            <span class="font-weight-bolder">Additional information: </span>
            <div
              v-for="(info, index) in getTaskAdditionalInfo(task)"
              :key="task.celery_id + index"
            >
              <span>{{ info.label }}: </span>
              <span>{{ info.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { snakeCaseToText } from 'supwiz/util/data';

export default {
  name: 'TaskStatus',
  props: {
    taskTypes: {
      type: Array,
      default: () => [],
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('task', ['getTasksOfTypes']),
    ...mapGetters('dataSource', ['itemIdToName']),
    activeTasks() {
      return this.getTasksOfTypes(this.taskTypes).activeTasks;
    },
    pendingTasks() {
      return this.getTasksOfTypes(this.taskTypes).pendingTasks;
    },
    runningTasks() {
      return this.activeTasks.filter((e) => e.status === 'pending');
    },
    failedTasks() {
      return this.activeTasks.filter((e) => e.status === 'failed');
    },
  },
  methods: {
    ...mapActions('task', ['abortTask', 'fetchTaskList']),
    getTaskTitle(task) {
      return snakeCaseToText(task.type);
    },
    getTaskProgress(task) {
      return (task.progress * 100).toFixed(1);
    },
    getTaskAdditionalInfo(task) {
      const info = [];
      Object.entries(task?.meta || {}).forEach(([k, v]) => {
        if (k === 'data_source') {
          info.push({ label: snakeCaseToText(k), value: this.itemIdToName(v) });
        } else {
          info.push({ label: snakeCaseToText(k), value: v });
        }
      });
      return info;
    },
    async stopClick(task) {
      const modalText = 'Are you sure that you want to stop the task?';
      const modalOptions = { okTitle: 'Stop task', okVariant: 'warning' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        await this.abortTask(task.celery_id);
        this.fetchTaskList();
      }
    },
  },
};
</script>
<style>

</style>
