<template>
  <b-modal
    id="upload-tickets-modal"
    ref="modal"
    title="Upload tickets"
    :size="showFormat ? 'xl' : 'lg'"
    ok-variant="primary"
    ok-title="Upload"
    :ok-disabled="!ticketsFile"
    @ok="localAdd"
  >
    <template v-if="showFormat">
      <b-button
        variant="primary"
        @click="toggleShowFormat"
      >
        Back
      </b-button>
      <h2 class="mt-4">
        File types
      </h2>
      <span>
        The uploaded file should be a <code>json</code> array.
      </span>
      <h2 class="mt-4">
        Ticket fields
      </h2>
      <div>
        Each ticket object in the json array (or row in the csv file)  must have the following
        fields
      </div>
      <b-list-group class="mt-2">
        <b-list-group-item>
          <code>external_id</code>: The id of the ticket in the external system.
        </b-list-group-item>
        <b-list-group-item>
          <code>short_description</code>: The title of the ticket.
        </b-list-group-item>
        <b-list-group-item>
          <code>description</code>: The description of the ticket.
        </b-list-group-item>
        <b-list-group-item>
          <code>resolution</code> (optional): The resolution text of the ticket.
        </b-list-group-item>
        <b-list-group-item>
          <code>activities</code>: An array of activity objects (see below).
        </b-list-group-item>
        <b-list-group-item>
          <code>display_id</code> (optional): The id displayed to the user.
        </b-list-group-item>
        <b-list-group-item>
          <code>ticket_created</code> (optional): The time that the ticket was created in
          iso format, e.g. "2021-04-26T14:51:15Z".
        </b-list-group-item>
      </b-list-group>
      <h2 class="mt-4">
        Activity fields
      </h2>
      <div>
        Each activity object nested in a ticket should have the following fields
      </div>
      <b-list-group class="mt-2">
        <b-list-group-item>
          <code>sender</code>: The author of the activity. Must be "user", "agent", "bot",
          or "system".
        </b-list-group-item>
        <b-list-group-item>
          <code>content</code>: The content of the activity.
        </b-list-group-item>
        <b-list-group-item>
          <code>timestamp</code>: The time that the activity was executed in
          iso format, e.g. "2021-04-26T14:51:15Z".
        </b-list-group-item>
      </b-list-group>
      <h2 class="mt-3">
        Example
      </h2>
      JSON
      <b-card
        border-radius="25"
        class="p-2 mb-2 overflow-hidden w-100 border bg-light"
      >
        <vue-json-pretty
          :data="jsonExample"
          :deep="10"
        />
      </b-card>
    </template>
    <template v-else>
      <p>
        Choose a file to upload
        (<b-link @click="toggleShowFormat">
          see format
        </b-link>).
      </p>
      <b-form-file
        v-model="ticketsFile"
        placeholder="Choose file..."
        accept=".json, .csv"
      />
    </template>
  </b-modal>
</template>

<script>

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { mapActions } from 'vuex';
import { DataTypes } from '@/js/constants';

export default {
  name: 'UploadTicketsModal',
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      ticketsFile: null,
      showFormat: false,
      jsonExample: JSON.parse('[{"short_description": "Computer replacement", "description": "The employee needs her old lenovo replaced", "resolution": "A new laptop has been ordered", "external_id": 5237, "ticket_created": "2021-04-26T14:51:15Z", "activities": [{"sender": "user", "timestamp": "2021-04-26T14:51:15Z", "type": "msg", "content": "My old Lenovo laptop doesn\' have enough RAM to run the required programs. Can you get me a new one?"}, {"sender": "agent", "timestamp": "2021-04-26T15:32:22Z", "type": "msg", "content": "I can see that your laptop has insufficient performance, I will order you a new one."}, {"sender": "user", "timestamp": "2021-04-26T16:01:51Z", "type": "msg", "content": "Thanks, when will I get it?"}, {"sender": "agent", "timestamp": "2021-04-27T09:16:05Z", "type": "msg", "content": "It should be here some time tomorrow."}]}]'),
    };
  },
  methods: {
    ...mapActions('fileUpload', { uploadFiles: 'uploadFiles' }),
    toggleShowFormat() {
      this.showFormat = !this.showFormat;
    },
    async localAdd() {
      await this.uploadFiles({ files: [this.ticketsFile], dataType: DataTypes.TICKET });
    },
  },
};
</script>
