import { dataSourceTypeToEndpoint } from '@/js/constants';

export default class {
  constructor(endpoint, dataType) {
    const getImportEndpoint = (rootState, id) => {
      const baseUrl = dataSourceTypeToEndpoint[rootState.dataSource.details.type];
      return `${baseUrl}${id}/import_${dataType}s/`;
    };
    this.state = {
      isImporting: false, // this is also used for scraping acrticles
    };
    this.getters = {
      isImporting: (state) => state.isImporting,
    };
    this.mutations = {
      setIsImporting(state, val) {
        state.isImporting = val;
      },
    };
    this.actions = {
      async importItems({ dispatch, rootState }, requestData) {
        const importEndpoint = getImportEndpoint(
          rootState,
          requestData.dataSourceId,
        );
        await dispatch('postTask', {
          endpoint: importEndpoint,
          data: requestData,
          operationType: 'import',
        }, { root: true });
      },
    };
  }
}
