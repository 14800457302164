<template>
  <b-row
    v-if="isFetchingDetails"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <b-row v-else-if="objectNotFound">
    <b-col>
      <h1>Tag not found</h1>
      <p>Requested tag value could not be found.</p>
    </b-col>
  </b-row>
  <b-card
    v-else
    class="r-75"
    body-class="p-3"
  >
    <b-row>
      <b-col class="my-auto">
        <h4 class="card-title my-auto">
          Tag: {{ fieldValueDetails.displayValue || fieldValueDetails.value }}
        </h4>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="$router.go(-1)"
        >
          Return
        </b-button>
      </b-col>
    </b-row>
    <display-key-value
      class="my-3"
      key-prop="Tag ID"
      :value-prop="fieldValueDetails.id"
      :min-key-width="keyWidth"
    />
    <display-key-value
      class="my-3"
      key-prop="Tag category"
      :value-prop="fieldIdToName(fieldValueDetails.field)"
      :min-key-width="keyWidth"
    />
    <display-key-value
      class="my-3"
      key-prop="Value"
      :value-prop="fieldValueDetails.value"
      :min-key-width="keyWidth"
    />
    <display-key-value
      class="mt-3"
      key-prop="Display Value"
      :value-prop="fieldValueDetails.displayValue"
      :min-key-width="keyWidth"
    />
    <b-button
      v-if="fieldDetails.dataType === 'article'"
      v-b-modal.tag-articles
      variant="primary"
      class="mt-3"
      :style="`width: ${keyWidth}px;`"
    >
      Tag articles
    </b-button>
    <b-modal
      id="tag-articles"
      size="xl"
      :title="tagArticleModalTitle"
      hide-footer
    >
      <Articles tagging />
    </b-modal>
  </b-card>
</template>
<script>
import DisplayKeyValue from 'supwiz/components/DisplayKeyValue.vue';
import {
  mapGetters, mapState, mapActions, mapMutations,
} from 'vuex';
import Articles from '@/pages/DataSource/Articles.vue';

export default {
  name: 'FieldValue',
  components: {
    DisplayKeyValue, Articles,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchFieldValueDetails({ id: to.params.fieldValueId });
      vm.fetchFieldDetails({ id: to.params.fieldId });
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchFieldValueDetails({ id: to.params.fieldValueId });
    this.fetchFieldDetails({ id: to.params.fieldId });
    next();
  },
  computed: {
    ...mapState('fieldValue', { fieldValueDetails: 'details' }),
    ...mapState('field', { fieldDetails: 'details' }),
    ...mapState('fieldValue', ['isFetchingDetails']),
    ...mapGetters('field', ['fieldIdToName']),
    keyWidth() {
      return 140;
    },
    objectNotFound() {
      return Object.keys(this.fieldValueDetails).length === 0;
    },
    tagArticleModalTitle() {
      return `Tag articles - ${this.fieldIdToName(this.fieldValueDetails.field)} : ${this.fieldValueDetails.displayValue}`;
    },
  },
  destroyed() {
    this.setFieldValueDetails({});
  },
  methods: {
    ...mapActions('field', { fetchFieldDetails: 'fetchItemDetails' }),
    ...mapActions('fieldValue', { fetchFieldValueDetails: 'fetchItemDetails' }),
    ...mapMutations('fieldValue', { setFieldValueDetails: 'setItemDetails' }),
  },
};
</script>
