<template>
  <div>
    <b-card
      title="Chats"
      class="r-75"
      body-class="p-3"
    >
      <TaskStatus :task-types="taskTypes" />
      <b-row class="my-2">
        <b-col class="my-auto">
          <b-pagination
            v-model="currentPage"
            size="sm"
            class="my-auto"
            :total-rows="pagination.count"
            :per-page="pagination.perPage"
            aria-controls="chats-table"
          />
        </b-col>
        <b-col cols="auto" class="my-auto">
          <b-button
            v-if="dataSourceDetails.type !== dataSourceTypes.UPLOAD.value"
            v-b-modal.import-modal
            variant="primary"
          >
            Import chats
          </b-button>
        </b-col>
      </b-row>
      <b-table
        id="chats-table"
        ref="chats-table"
        :fields="fields"
        :per-page="pagination.perPage"
        class="mb-0"
        tbody-tr-class="cursor-pointer"
        :caption="tableCaption"
        :items="chatsArray"
        show-empty
        empty-text="There are currently no chats."
        hover
        @row-clicked="openChat"
      >
        <template #cell(actions)="data">
          <b-button
            block
            size="sm"
            :disabled="isDisabling"
            :variant="data.item.disabled ? 'danger' : 'primary'"
            @click.stop="handleToggleDisabled(data.item.id)"
          >
            {{ data.item.disabled ? 'Ignored' : 'Ignore' }}
          </b-button>
        </template>
      </b-table>
      <import-modal type="chats" />
    </b-card>
    <br />
    <file-upload-table v-if="isUploadType" data-type="chat" />
  </div>
</template>

<script>

import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';
import { dataSourceTypes } from '@/js/constants';
import { objToCamel } from '@/js/utils';
import ImportModal from '@/components/DataSource/ImportModal.vue';
import TaskStatus from '@/components/TaskStatus.vue';
import FileUploadTable from '../../components/DataSource/FileUploadTable.vue';

function chatSummary(activities) {
  if (activities.length) {
    const firstMsg = (activities.find((e) => e.sender === 'user') || activities[0]).content;
    if (firstMsg.length > 50) {
      return `${firstMsg.slice(0, 50)}...`;
    }
    return firstMsg;
  }
  return 'No content';
}

function orderActivities(activities) {
  return activities.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
}

export default {
  name: 'DataSourceChats',
  components: {
    ImportModal, FileUploadTable, TaskStatus,
  },
  data() {
    return {
      dataSourceTypes,
      chatsFile: null,
      fields: [
        { key: 'chatEnded', label: 'Chat Ended', formatter: (date) => new Date(date).toLocaleString('en-GB') },
        {
          key: 'activities', label: 'First message', formatter: chatSummary, tdClass: 'chats-table',
        },
        {
          key: 'actions', label: '', tdClass: 'action-col',
        },
      ],
      taskTypes: ['chat_import'],
      intervalId: null,
      lastUpdated: null,
    };
  },
  computed: {
    ...mapState('chat', { chats: 'items' }),
    ...mapState('chat', ['pagination', 'isFetching', 'isDisabling']),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    ...mapState('fileUpload', ['shouldRefresh']),
    ...mapGetters('task', ['getTasksOfTypes']),
    runningTasks() {
      return this.getTasksOfTypes(this.taskTypes).activeTasks.filter((e) => e.status === 'pending');
    },
    tableCaption() {
      return `Last updated: ${this.lastUpdated ? this.lastUpdated.toLocaleTimeString() : '-'}`;
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
    isUploadType() {
      return this.dataSourceDetails?.type === dataSourceTypes.UPLOAD.value;
    },
    shouldRefreshChats() {
      return this.shouldRefresh.chat;
    },
    chatsArray() {
      const chatsArray = Object.values(this.chats);
      return Object.values(chatsArray.map((c) => objToCamel(c)).map((c) => {
        // eslint-disable-next-line no-param-reassign
        c.activities = orderActivities(c.activities);
        return c;
      }));
    },
  },
  watch: {
    runningTasks(n) {
      if (n.length) {
        if (!this.intervalId) {
          this.intervalId = setInterval(this.refreshData, 5000);
        }
      } else if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.refreshData();
        this.fetchFields();
        this.fetchFieldValues();
      }
    },
    shouldRefreshChats(newVal) {
      if (newVal) {
        this.refreshData();
        this.updateShouldRefresh({ dataType: 'chat', payload: false });
      }
    },
    currentPage() {
      this.refreshData();
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  created() {
    this.refreshData();
  },
  methods: {
    ...mapActions('chat', {
      fetchChats: 'fetchItems',
      toggleDisabled: 'toggleDisabled',
    }),
    ...mapActions('field', { fetchFields: 'fetchItems' }),
    ...mapActions('fieldValue', { fetchFieldValues: 'fetchItems' }),
    ...mapMutations('chat', ['updatePagination']),
    ...mapMutations('fileUpload', ['updateShouldRefresh']),
    openChat(row) {
      this.$router.push({ name: 'chats-single', params: { chatId: row.id } });
    },
    async handleToggleDisabled(chatId) {
      await this.toggleDisabled(chatId);
      this.refreshData();
    },
    async refreshData() {
      await this.fetchChats({
        params: {
          data_source: this.dataSourceDetails.id,
          page: this.pagination.page,
        },
      });
      this.lastUpdated = new Date();
    },
  },
};
</script>
<style scoped>
::v-deep .action-col{
  width: 90px;
}
</style>
