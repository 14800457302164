import store from '@/store/index';

export function getDisplayFromId(key, value) {
  let display;
  switch (key) {
    case 'dataSourceId':
      display = store.state.dataSource.details ? store.state.dataSource.details.name : null;
      break;
    case 'pipelineId':
      display = store.state.pipeline.details ? store.state.pipeline.details.name : null;
      break;
    case 'rankerId':
      display = store.state.ranker.details ? store.state.ranker.details.name : null;
      break;
    case 'fieldId':
      display = store.state.field.details ? store.state.field.details.name : null;
      break;
    case 'fieldValueId':
      display = store.state.fieldValue.details ? store.state.fieldValue.details.displayValue : null;
      break;
    case 'languageModelId':
      display = store.state.languageModel.details ? store.state.languageModel.details.name : null;
      break;
    default:
      display = value;
  }

  return display;
}

export function getBreadcrumb(match, params) {
  // if the path ends with / or is empty, it means it is some route that is accessed
  // from base route, so it already has a match

  if (match.path.charAt(match.path.length - 1) === '/') {
    return null;
  }

  if (!match.path.length) {
    return null;
  }

  // get parameter key and value of match
  let paramKey;
  // route params with special formatting need to be handled separately
  if (match.path.includes('extractorId')) {
    paramKey = 'extractorId';
  } else {
    paramKey = match.path.split('/').pop().trim().substring(1);
  }
  const paramValue = params[paramKey];

  // create link and display name
  const link = { name: match.name, params: { paramKey: paramValue } };
  const display = getDisplayFromId(paramKey, paramValue);

  // handle special cases
  if (match.path.includes('urls')) {
    return [{ display, link }, { display: 'Urls', link: 'web-scrapers-urls' }];
  }

  return { display, link };
}
