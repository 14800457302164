<template>
  <div>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-row class="mb-3">
        <b-col class="my-auto">
          <h4 class="card-title my-auto">
            Article training data
          </h4>
        </b-col>

        <b-col cols="auto" class="my-auto">
          <b-pagination
            v-model="articleCurrentPage"
            size="sm"
            class="my-auto"
            :total-rows="articleRowCount"
            :per-page="perPage"
            aria-controls="article-training-data"
          />
        </b-col>
        <b-col cols="auto" class="my-auto">
          <b-button
            class="mr-4"
            size="sm"
            variant="primary"
            @click="addButtonClicked('article')"
          >
            Add articles
          </b-button>
        </b-col>
      </b-row>
      <table-data
        id="article-training-data"
        ref="articleTrainingData"
        :fields="articleFields"
        :current-page="articleCurrentPage"
        :per-page="perPage"
        :items="mappedArticleTrainingItems"
        :busy="isFetching"
        sort-by="createdTime"
        sort-desc
        show-empty
        @delete="actionBtnClicked"
      >
        <template #cell(dataSource)="row">
          {{ dataSourceIdToName(row.item.dataSource) }}
        </template>
        <template #cell(type)="row">
          {{ typeValue2Text(row.item.type) }}
        </template>
        <template #cell(details)="row">
          <b-button size="sm" class="mr-2" @click="row.toggleDetails">
            Configure
          </b-button>
        </template>
        <template #row-details="row">
          <training-data-config
            :pipeline-data="row.item"
            @updateTrainingData="v=>updateTrainingData(v)"
          />
        </template>
      </table-data>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-row class="mb-3">
        <b-col class="my-auto">
          <h4 class="card-title my-auto">
            Source training data
          </h4>
        </b-col>
        <b-col cols="auto" class="my-auto">
          <b-pagination
            v-model="otherCurrentPage"
            size="sm"
            :total-rows="otherRowCount"
            :per-page="perPage"
            class="my-auto"
            aria-controls="other-training-data"
          />
        </b-col>
        <b-col cols="auto" class="my-auto">
          <b-button
            class="px-3 mr-4"
            size="sm"
            variant="primary"
            @click="addButtonClicked('other')"
          >
            Add data
          </b-button>
        </b-col>
      </b-row>
      <b-table
        id="other-training-data"
        ref="otherTrainingData"
        :fields="otherFields"
        :current-page="otherCurrentPage"
        :per-page="perPage"
        :items="mappedOtherTrainingItems"
        :busy="isFetching"
        sort-by="createdTime"
        sort-desc
        show-empty
      >
        <template #cell(delete)="row">
          <b-button
            size="sm"
            variant="outline-secondary bg-transparent"
            class="mb-1 action-button p-0"
            :disabled="row.item.type === 'rankerdatasource'"
            @click="actionBtnClicked(row.item)"
          >
            <font-awesome-icon
              v-if="row.item.type === 'articleQuestions'"
              :icon="row.item.includeQuestions ? 'check' : 'times'"
              style="transform:scale(1.5) translateY(1px)"
            />
            <font-awesome-icon
              v-else
              icon="trash-alt"
              style="transform:scale(1.5) translateY(1px)"
            />
          </b-button>
        </template>
        <template #cell(dataSource)="row">
          <span v-if="row.item.type === 'articleQuestions'">
            Questions from data source:
          </span>
          {{ dataSourceIdToName(row.item.dataSource) }}
        </template>
        <template #cell(details)="row">
          <b-button
            size="sm"
            class="mr-2"
            :disabled="row.item.type === 'articleQuestions' || configureDisabled(row.item)"
            @click="row.toggleDetails"
          >
            Configure
          </b-button>
        </template>
        <template #cell(type)="row">
          {{ typeValue2Text(row.item.type) }}
        </template>
        <template #row-details="row">
          <training-data-config
            :pipeline-data="row.item"
            @updateTrainingData="v=>updateTrainingData(v)"
          />
        </template>
      </b-table>
      <b-modal
        id="training-data-form-modal"
        :title="`Add ${trainingDataType === 'article' ? 'article' : ''} training data`"
        hide-footer
        size="lg"
        @hide="trainingDataType = null"
      >
        <training-data-form
          :type="trainingDataType"
        />
      </b-modal>
    </b-card>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import TrainingDataForm from '@/components/Ranker/TrainingDataForm.vue';
import { pipelineSourceTypes, rankerOptions, dataSourceTypes } from '@/js/constants';
import TrainingDataConfig from '@/components/Ranker/TrainingDataConfig.vue';

export default {
  name: 'TrainingData',
  components: {
    TableData,
    TrainingDataForm,
    TrainingDataConfig,
  },
  data() {
    return {
      rankerOptions,
      articleCurrentPage: 1,
      otherCurrentPage: 1,
      perPage: 10,
      trainingDataType: null,
      combinedOpenItems: [],
    };
  },
  computed: {
    ...mapState('pipelineSource', ['isFetching']),
    ...mapGetters('pipelineSource', ['articleTrainingItems', 'otherTrainingItems']),
    ...mapGetters('dataSource', { dataSourceIdToName: 'itemIdToName' }),
    ...mapState('ranker', { rankerDetails: 'details' }),
    ...mapState('dataSource', { dataSources: 'items', dataSourceDetails: 'details' }),
    articleFields() {
      return [
        { key: 'delete', label: '', tdClass: 'delete-column' },
        // { key: 'id', label: 'ID', tdClass: 'table-nobreak' },
        { key: 'dataSource' },
        { key: 'details', label: '', tdClass: 'text-right' },
      ];
    },
    otherFields() {
      const otherFields = [...this.articleFields];
      otherFields.splice(2, 0, 'type');
      return otherFields;
    },
    mappedArticleTrainingItems() {
      return this.articleTrainingItems
        .map((e) => ({ ...e, _showDetails: this.combinedOpenItems.includes(e.id) }));
    },
    mappedOtherTrainingItems() {
      return this.otherTrainingItems
        .map((e) => ({ ...e, _showDetails: this.combinedOpenItems.includes(e.id) }))
        .concat(this.mappedArticleTrainingItems.map((e) => ({ ...e, _showDetails: false, type: 'articleQuestions' })));
    },
    articleRowCount() {
      return this.articleTrainingItems.length;
    },
    otherRowCount() {
      return this.otherTrainingItems.length;
    },
  },
  methods: {
    ...mapActions('pipelineSource', { deletePipelineSource: 'deleteItem' }),
    ...mapActions('ranker', { fetchRankerDetails: 'fetchItemDetails' }),
    ...mapActions('pipelineSource', { updatePipelineSource: 'patchItem' }),
    ...mapActions('pipelineSource', { fetchPipelineSources: 'fetchItems' }),
    configureDisabled(pipelineData) {
      return this.dataSources[pipelineData.dataSource]?.type === dataSourceTypes.RANKER.value;
    },
    typeValue2Text(value) {
      if (value === 'articleQuestions') {
        return 'Article questions';
      }
      return Object.values(pipelineSourceTypes).find((e) => e.value === value)?.text;
    },
    async actionBtnClicked(item) {
      if (item.type === 'articleQuestions') {
        this.toggleIncludeQuestions(item);
      } else {
        const modalText = 'Are you sure that you want to delete?';
        const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
        if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
          await this.deletePipelineSource(
            { item, fetchParams: { pipeline: this.rankerDetails.pipeline.id } },
          );
          this.fetchRankerDetails({ id: this.rankerDetails.id });
        }
      }
    },
    toggleIncludeQuestions(item) {
      this.updateTrainingData({
        ...item,
        type: pipelineSourceTypes.ARTICLE.value,
        includeQuestions: !item.includeQuestions,
      });
    },
    addButtonClicked(type) {
      this.trainingDataType = type;
      this.$bvModal.show('training-data-form-modal');
    },
    async updateTrainingData(item) {
      this.combinedOpenItems = this.$refs.articleTrainingData.items
        .filter((e) => e._showDetails).map((e) => e.id);
      this.combinedOpenItems = this.combinedOpenItems.concat(this.$refs.otherTrainingData.items
        .filter((e) => e._showDetails).map((e) => e.id));
      await this.updatePipelineSource(item);
      await this.fetchPipelineSources({
        params: {
          pipeline: this.rankerDetails.pipeline.id,
        },
      });
    },
  },
};
</script>
<style scoped>
.action-button {
  border: 0;
  color: grey;
}
.action-button:hover{
  color:black;
}
</style>
