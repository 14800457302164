<template>
  <base-activity
    ref="baseActivity"
    :scraper-id="scraperId"
    :command-id="commandId"
    :indent="indent"
    bs-variant="secondary"
    v-on="$listeners"
  >
    <template #icon>
      <font-awesome-icon icon="code" />
    </template>
    <template #header>
      {{ command.target_var }} = {{ command.source_var }}
    </template>
    <b-row>
      <b-col cols="6">
        <label for="target-var">
          Variable name:
        </label>
        <b-form-input
          id="target-var"
          :value="command.target_var"
          type="text"
          @input="value => $root.$emit('update-scraper', { commandId, prop: 'target_var', value })"
        />
      </b-col>
      <b-col cols="6">
        <label for="source-var">
          Variable code:
        </label>
        <b-form-input
          id="source-var"
          :value="command.source_var"
          type="text"
          @input="value => $root.$emit('update-scraper', { commandId, prop: 'source_var', value })"
        />
      </b-col>
    </b-row>
    <br />
    <h6>
      This action supports botscript.
      <b-link v-b-modal.botscript-documentation-modal>
        Click here to see botscript documentation
      </b-link>
    </h6>
  </base-activity>
</template>

<script>
import BaseActivity from '@/components/WebScraper/SeleniumCommands/BaseCommand.vue';

export default {
  name: 'SetVariableCommand',
  components: { BaseActivity },
  props: {
    scraperId: {
      type: Number,
      required: true,
    },
    commandId: {
      type: String,
      required: true,
    },
    command: {
      type: Object,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
};
</script>
