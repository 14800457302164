<template>
  <b-card
    class="r-50 p-3"
    no-body
  >
    <div class="d-flex align-items-center justify-content-between mb-2 flex-wrap">
      <h4 class="card-title mb-0">
        Widget Configuration
      </h4>
      <!-- <div class="position-relative">
        <b-form-input
          placeholder="Search settings..."
        />
      </div> -->
    </div>
    <b-row>
      <b-col cols="12" lg="8">
        <p>
          Customize your search widget and begin using SupSearch on your website.
        </p>
      </b-col>
    </b-row>
    <div
      v-if="busy || !Object.keys(configClone).length"
      class="my-5 py-5 text-center"
    >
      <b-spinner
        variant="primary"
        style="height:5rem;width:5rem;"
      />
    </div>
    <b-tabs
      v-else
      v-model="currentTab"
      justified
      content-class="mt-3"
    >
      <b-tab
        v-for="{ tab, sections } in pageLayout"
        :key="tab"
        :title="tab"
        title-link-class="text-truncate"
      >
        <SettingSection
          v-for="{
            title, subtitle, fields, preview,
          } in sections"
          :key="title"
          v-bind="{ title, subtitle }"
        >
          <MiniPreviewHandler
            v-if="preview"
            v-bind="{ preview }"
          />
          <template v-for="field in fields">
            <FormGroup
              v-if="typeof field === 'string'"
              :key="field"
              :field-key="field"
            />
            <FormGroup
              v-else
              :key="field[0]"
              :field-key="field[0]"
              :cols="field[1]"
            />
          </template>
        </SettingSection>
      </b-tab>
    </b-tabs>
    <div>
      <b-button
        variant="primary"
        :disabled="isSaving"
        @click="saveChanges"
      >
        Save Changes
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { cloneDeep, get, isEqual } from 'lodash';

export default {
  name: 'WidgetConfig',
  components: {
    SettingSection: () => import('./WidgetConfig/SettingSection.vue'),
    FormGroup: () => import('./WidgetConfig/FormGroup.vue'),
    MiniPreviewHandler: () => import('./WidgetConfig/Previews/MiniPreviewHandler.vue'),
  },
  mixins: [validationMixin],
  provide() {
    return {
      getFieldValue: this.getFieldValue,
      setFieldValue: this.setFieldValue,
      getFieldDetails: this.getFieldDetails,
      $configClone: () => this.configClone,
    };
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges()) {
      const saveChangesFirst = await this.promptSave();
      if (saveChangesFirst) {
        await this.saveChanges();
      }
    }
    next();
  },
  data() {
    return {
      configClone: {},
      isSaving: false,
      currentTab: 0,
    };
  },
  computed: {
    ...mapState('widgetConfig', { widgetDetails: 'details' }),
    ...mapGetters('widgetConfig', ['busy']),
    ...mapGetters('auth', ['headerAuthorization', 'genAiProvider']),
    fieldDefinitions() {
      const aiProvider = this.genAiProvider;
      return [
        {
          keyPath: 'widget.colorset',
          label: 'Colorset',
          component: 'WidgetColorset',
        },
        {
          keyPath: 'widget.font_family',
          label: 'Font Family',
          component: 'b-form-tags',
          componentAttrs: {
            placeholder: 'Enter a font name...',
            tagVariant: 'primary',
          },
        },
        {
          keyPath: 'widget.cards.style.box_shadow',
          label: 'Widget Cards Shadow',
          component: 'b-form-input',
        },
        {
          keyPath: 'widget.cards.style.padding',
          label: 'Widget Cards Padding',
          component: 'XYComponent',
          componentAttrs: {
            type: 'size',
          },
        },
        {
          keyPath: 'widget.cards.style.border_radius',
          label: 'Widget Cards Border Radius',
          component: 'InputSize',
        },
        {
          keyPath: 'widget.mode.type',
          label: 'Type',
          component: 'WidgetMode',
        },
        /* {
          keyPath: 'widget.mode.settings.backdrop',
          label: 'Backdrop Styles',
          component: 'EditorStyles',
          componentAttrs: {
            placeholder: 'Example: background: rgba(0,0,0,0.5)',
            class: 'overflow-auto',
            style: 'max-height:200px',
          },
        }, */
        {
          keyPath: 'post_search.enabled',
          label: 'Enable Post Search Options',
          component: 'b-form-checkbox',
          componentAttrs: {
            switch: true,
          },
        },
        {
          keyPath: 'post_search.content',
          label: 'Content',
          component: 'EditorHtml',
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.post_search.enabled,
          },
          componentAttrs: {
            placeholder: this.configClone.post_search.enabled ? 'Enter text or HTML' : 'Disabled',
            class: 'overflow-auto',
            style: 'max-height:400px',
          },
        },
        {
          keyPath: 'post_search.actions',
          label: 'Call To Actions',
          component: 'PostSearchOptions',
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.post_search.enabled,
          },
        },
        {
          keyPath: 'translations',
          label: 'UI Texts',
          component: 'TranslationsOverwrites',
        },
        {
          keyPath: 'css_overwrites',
          label: 'CSS',
          component: 'EditorStyles',
          componentAttrs: {
            placeholder: 'Example: .my-class { color: #fff }',
            class: 'overflow-auto',
            style: 'max-height:400px',
          },
        },
        {
          keyPath: 'search_field.style.font_size',
          label: 'Font Size',
          component: 'InputSize',
        },
        {
          keyPath: 'search_field.style.padding',
          label: 'Padding',
          component: 'XYComponent',
          componentAttrs: {
            type: 'size',
          },
        },
        {
          keyPath: 'search_field.style.border_radius',
          label: 'Border Radius',
          component: 'InputSize',
        },
        {
          keyPath: 'search_field.icon.file',
          label: 'Icon',
          component: 'InputIcon',
          componentAttrs: {
            placeholder: 'If left blank, a fallback icon will be used',
          },
        },
        {
          keyPath: 'search_field.icon.size',
          label: 'Icon Size',
          component: 'XYComponent',
          componentAttrs: {
            type: 'size',
          },
        },
        {
          keyPath: 'search_field.icon.position',
          label: 'Icon Placement',
          component: 'XYComponent',
          componentAttrs: {
            type: 'position',
            singleAxis: 'x',
          },
        },
        {
          keyPath: 'search_field.button_confirm.enabled',
          label: 'Display Confirm Button',
          component: 'b-form-checkbox',
          componentAttrs: {
            switch: true,
          },
        },
        {
          keyPath: 'search_field.button_confirm.text',
          label: 'Text',
          component: 'b-form-input',
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.search_field.button_confirm.enabled,
          },
        },
        {
          keyPath: 'search_field.button_confirm.style.font_size',
          label: 'Font Size',
          component: 'InputSize',
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.search_field.button_confirm.enabled,
          },
        },
        {
          keyPath: 'search_field.button_confirm.style.border_radius',
          label: 'Border Radius',
          component: 'InputSize',
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.search_field.button_confirm.enabled,
          },
        },
        {
          keyPath: 'search_field.button_confirm.style.padding',
          label: 'Padding',
          component: 'XYComponent',
          componentAttrs: {
            type: 'size',
          },
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.search_field.button_confirm.enabled,
          },
        },
        {
          keyPath: 'search_field.suggested_search.enabled',
          label: 'Enable Suggestions',
          component: 'b-form-checkbox',
          componentAttrs: {
            switch: true,
          },
        },
        {
          keyPath: 'search_field.suggested_search.use_qa_pairs',
          label: 'Predict FAQ Answers',
          description: 'Include questions in the search pool',
          component: 'b-form-checkbox',
          componentAttrs: {
            switch: true,
          },
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.search_field.suggested_search.enabled,
          },
        },
        {
          keyPath: 'search_field.suggested_search.match_as_prefix',
          label: 'Match only begining of title',
          component: 'b-form-checkbox',
          componentAttrs: {
            switch: true,
          },
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.search_field.suggested_search.enabled,
          },
        },
        {
          keyPath: 'search_field.suggested_search.server_side_match',
          label: 'Server side matching',
          description: 'Recommended for large article pools',
          component: 'b-form-checkbox',
          componentAttrs: {
            switch: true,
          },
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.search_field.suggested_search.enabled,
          },
        },
        {
          keyPath: 'SEARCH_FILTERS',
          label: 'Filters',
          component: 'SearchFilters',
        },
        {
          keyPath: 'search_results.layout',
          label: 'Displayed Elements',
          component: 'ResultsLayout',
        },
        {
          keyPath: 'search_results.tags',
          label: 'Displayed Tags',
          component: 'TagsDisplay',
        },
        {
          keyPath: 'search_results.title.max_length',
          label: 'Max Characters',
          component: 'b-form-input',
          componentAttrs: {
            type: 'number',
          },
        },
        {
          keyPath: 'search_results.title.style.font_size',
          label: 'Font Size',
          component: 'InputSize',
          cols: {
            cols: 12,
            md: 4,
          },
        },
        {
          keyPath: 'search_results.title.style.font_weight',
          label: 'Font Weight',
          component: 'b-form-select',
          componentAttrs: {
            options: [
              {
                value: 200,
                text: 'Light',
              },
              {
                value: 400,
                text: 'Normal',
              },
              {
                value: 700,
                text: 'Bold',
              },
            ],
          },
        },
        {
          keyPath: 'search_results.title.style.padding',
          label: 'Padding',
          component: 'XYComponent',
          componentAttrs: {
            type: 'size',
          },
        },
        {
          keyPath: 'search_results.content.max_length',
          label: 'Max Characters',
          component: 'b-form-input',
          componentAttrs: {
            type: 'number',
          },
        },
        {
          keyPath: 'search_results.content.style.font_size',
          label: 'Font Size',
          component: 'InputSize',
        },
        {
          keyPath: 'search_results.content.style.font_weight',
          label: 'Font Weight',
          component: 'b-form-select',
          componentAttrs: {
            options: [
              {
                value: 200,
                text: 'Light',
              },
              {
                value: 400,
                text: 'Normal',
              },
              {
                value: 700,
                text: 'Bold',
              },
            ],
          },
        },
        {
          keyPath: 'search_results.content.style.padding',
          label: 'Padding',
          component: 'XYComponent',
          componentAttrs: {
            type: 'size',
          },
        },
        {
          keyPath: 'search_results.pagination.per_page',
          label: 'Results Per Page',
          component: 'b-form-input',
          componentAttrs: {
            type: 'number',
            min: 1,
          },
        },
        {
          keyPath: 'search_results.pagination.location',
          label: 'Pagination Location',
          component: 'b-form-select',
          componentAttrs: {
            options: [
              { text: 'Above Results', value: 'top' },
              { text: 'Below Results', value: 'bottom' },
              { text: 'Above & Below', value: 'both' },
            ],
          },
        },
        {
          keyPath: 'banners.enabled',
          label: 'Enable Banners',
          component: 'b-form-checkbox',
          componentAttrs: {
            switch: true,
          },
        },
        {
          keyPath: 'banners.max_number',
          label: 'Maximum Number of Displayed Banners',
          component: 'b-form-input',
          componentAttrs: {
            type: 'number',
            min: 0,
            max: 5,
          },
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.banners.enabled,

          },
        },
        {
          keyPath: 'banners.items',
          label: 'Current Banners',
          component: 'BannerItems',
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.banners.enabled,
          },
        },
        {
          keyPath: 'ai.mini_chat.enabled',
          label: 'Chat Enabled',
          component: 'b-form-checkbox',
          componentAttrs: {
            switch: true,
          },
        },
        {
          keyPath: 'ai.mini_chat.max_question',
          label: 'Max follow-up Questions',
          description: 'Amount of questions the user can ask after each search. The maximum is 5.',
          component: 'b-form-input',
          componentAttrs: {
            type: 'number',
            min: 0,
            max: 5,
          },
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.ai.mini_chat.enabled,
          },
        },
        {
          keyPath: 'ai.mini_chat.topk',
          label: 'Max Articles For Context',
          description: 'Set to 0 to automatically select the value depending on the user\'s query.',
          component: 'b-form-input',
          componentAttrs: {
            type: 'number',
            min: 0,
            max: 5,
          },
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.ai.mini_chat.enabled,
          },
        },
        {
          keyPath: 'ai.mini_chat.bubbles.ai.colorset',
          label: 'AI Colors',
          component: 'InputColorset',
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.ai.mini_chat.enabled,
          },
        },
        {
          keyPath: 'ai.mini_chat.bubbles.user.colorset',
          label: 'User Colors',
          component: 'InputColorset',
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.ai.mini_chat.enabled,
          },
        },
        {
          keyPath: 'ai.mini_chat.bubbles.ai.icon',
          label: 'AI Avatar',
          component: 'InputIcon',
          componentAttrs: {
            placeholder: 'If left blank, a fallback avatar will be used',
          },
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.ai.mini_chat.enabled,
          },
        },
        {
          keyPath: 'ai.mini_chat.tone_of_voice',
          label: 'Tone Of Voice',
          component: 'b-form-select',
          componentAttrs: {
            options: [
              {
                value: 'neutral',
                text: 'Neutral',
              },
              {
                value: 'easy_to_understand',
                text: 'Easy To Understand',
              },
              {
                value: 'friendly',
                text: 'Friendly',
              },
              {
                value: 'formal',
                text: 'Formal',
              },
            ],
          },
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.ai.mini_chat.enabled,
          },
        },
        {
          keyPath: 'ai.mini_chat.company_name',
          label: 'Company Name',
          description: 'Name of the company or organization that the AI represents',
          component: 'b-form-input',
          componentAttrs: {
          },
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.ai.mini_chat.enabled,
          },
        },
        {
          keyPath: 'ai.reply_guarding_setting.preflight_check_enabled',
          label: 'Article Relevance Check',
          description: 'Guard using GenAI evaluating relevance of articles. (Note: Costs extra).',
          component: 'b-form-checkbox',
          componentAttrs: {
            switch: true,
            disabled: aiProvider !== 'SupGPT',
          },
        },
        {
          keyPath: 'ai.reply_guarding_setting.jaccard_sim_threshold',
          label: 'Word Similarity Guard',
          description: 'Guard using word overlap between response and article context.',
          component: 'b-form-input',
          componentAttrs: {
            type: 'range',
            min: 0,
            max: 1,
            step: 0.05,
          },
        },
        {
          keyPath: 'ai.reply_guarding_setting.embedding_sim_threshold',
          label: 'Semantic Similarity Guard',
          description: 'Guard using semantic similarity between response and article context.',
          component: 'b-form-input',
          componentAttrs: {
            type: 'range',
            min: 0,
            max: 1,
            step: 0.05,
          },
        },
        {
          keyPath: 'ai.reply_guarding_setting.gpt_groundedness_threshold',
          label: 'Article Groundedness Guard',
          description: 'Guard using GenAI evaluation of how much the response is grounded in the article context. (Note: Costs extra).',
          component: 'b-form-input',
          componentAttrs: {
            type: 'range',
            min: 0,
            max: 1,
            step: 0.05,
          },
        },
        {
          keyPath: 'ai.reply_guarding_setting.gpt_answer_relevance_threshold',
          label: 'Response Relevance Guard',
          description: 'Guard using GenAI evaluation of how relevant the response is to the question. (Note: Costs extra).',
          component: 'b-form-input',
          componentAttrs: {
            type: 'range',
            min: 0,
            max: 1,
            step: 0.05,
          },
        },
        {
          keyPath: 'ai.mini_chat.bubbles.style.font_size',
          label: 'Font Size',
          component: 'InputSize',
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.ai.mini_chat.enabled,
          },
        },
        {
          keyPath: 'ai.mini_chat.bubbles.style.padding',
          label: 'Padding',
          component: 'XYComponent',
          componentAttrs: {
            type: 'size',
          },
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.ai.mini_chat.enabled,
          },
        },
        {
          keyPath: 'ai.mini_chat.bubbles.style.border_radius',
          label: 'Border Radius',
          component: 'InputSize',
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.ai.mini_chat.enabled,
          },
        },
        {
          keyPath: 'ai.mini_chat.min_score_for_reply',
          label: 'Minimum Article Score',
          description: 'Only base generated responses on articles with at least this score',
          component: 'b-form-input',
          componentAttrs: {
            type: 'range',
            min: 0,
            max: 1,
            step: 0.001,
          },
          requirement: {
            requiredValue: true,
            currentValue: this.configClone.ai.mini_chat.enabled,
          },
        },
        {
          keyPath: 'WIDGET_PREVIEW',
          label: '',
          component: 'WidgetPreview',
        },
        {
          keyPath: 'WIDGET_EXPORT',
          label: '',
          component: 'WidgetExport',
        },
      ];
    },
    pageLayout() {
      return [
        {
          tab: 'General',
          sections: [
            {
              title: 'Presentation',
              subtitle: 'Align the widget visuals with your branding for a seamless experience.',
              fields: [
                'widget.colorset',
                'widget.font_family',
                'widget.cards.style.box_shadow',
                ['widget.cards.style.padding', { md: 8 }],
                ['widget.cards.style.border_radius', { md: 4 }],
              ],
            },
            {
              title: 'Widget Type',
              subtitle: 'The widget can be used in several ways. Pick one that will feel familiar to your users.',
              fields: [
                'widget.mode.type',
                // 'widget.mode.settings.backdrop',
              ],
            },
            {
              title: 'Post Search',
              subtitle: 'If the user is not able to find what they were looking for, you may want '
                + 'to direct them to a contact email or a live chat option.',
              preview: 'PostSearch',
              fields: [
                'post_search.enabled',
                'post_search.content',
                'post_search.actions',
              ],
            },
            {
              title: 'Overwrites',
              subtitle: 'In this section you can make some minor tweaks to the styling or '
                + 'wording in the widget',
              fields: [
                'css_overwrites',
                'translations',
              ],
            },
          ],
        },
        {
          tab: 'Search Field',
          sections: [
            {
              title: 'Presentation',
              subtitle: 'Design the search input to fit your brand.',
              preview: 'SearchField',
              fields: [
                ['search_field.style.font_size', { md: 3 }],
                ['search_field.style.padding', { md: 9 }],
                ['search_field.style.border_radius', { md: 3 }],
                'search_field.icon.file',
                ['search_field.icon.size', { md: 9 }],
                ['search_field.icon.position', { md: 3 }],
              ],
            },
            {
              title: 'Auto Search',
              subtitle: 'To present results more quickly to your users, you may want to '
                + 'perform searches as the user is typing.',
              fields: [
                'search_field.suggested_search.enabled',
                ['search_field.suggested_search.use_qa_pairs', { md: 3 }],
                ['search_field.suggested_search.match_as_prefix', { md: 3 }],
                ['search_field.suggested_search.server_side_match', { md: 3 }],
              ],
            },
            {
              title: 'Setup Filters',
              subtitle: 'You can enable users to filter articles using these chosen article tags. '
                + 'The specified tags will then be presented to the user within the '
                + 'widget interface.',
              fields: [
                'SEARCH_FILTERS',
              ],
            },
          ],
        },
        {
          tab: 'Banners',
          sections: [
            {
              title: 'Banners',
              subtitle: 'You can address frequent user inquiries by displaying banners.',
              fields: [
                'banners.enabled',
                'banners.max_number',
                'banners.items',
              ],
            },
          ],
        },
        {
          tab: 'Results',
          sections: [
            {
              title: 'Results Presentation',
              subtitle: 'Configure how article results should be presented to the user. '
                + 'When many results are found, you can also configure pagination.',
              preview: 'ArticleItem',
              fields: [
                'search_results.layout',
                ['search_results.pagination.per_page', { md: 3 }],
                ['search_results.pagination.location', { md: 3 }],
              ],
            },
            {
              title: 'Article Titles',
              subtitle: 'Customize how article titles are displayed.',
              fields: [
                ['search_results.title.max_length', { md: 4 }],
                ['search_results.title.style.font_size', { md: 4 }],
                ['search_results.title.style.font_weight', { md: 4 }],
                ['search_results.title.style.padding', { md: 9 }],
              ],
            },
            {
              title: 'Article Snippets',
              subtitle: 'Customize how the article content is displayed.',
              fields: [
                ['search_results.content.max_length', { md: 4 }],
                ['search_results.content.style.font_size', { md: 4 }],
                ['search_results.content.style.font_weight', { md: 4 }],
                ['search_results.content.style.padding', { md: 9 }],
              ],
            },
            {
              title: 'Article Tags',
              subtitle: 'Customize what article tags are displayed and in what order.',
              fields: [
                'search_results.tags',
              ],
            },
          ],
        },
        {
          tab: 'AI',
          sections: [
            {
              title: 'AI Chat',
              subtitle: 'Powered by generative AI, let AI generate a personalized response based '
                + 'on the user\'s query. Users can also ask follow-up questions, if enabled.',
              fields: [
                'ai.mini_chat.enabled',
                ['ai.mini_chat.max_question', { md: 6 }],
                ['ai.mini_chat.topk', { md: 6 }],
                'ai.mini_chat.min_score_for_reply',
              ],
            },
            {
              title: 'Chat Presentation',
              subtitle: 'Present the generated responses in a small mini chat',
              fields: [
                ['ai.mini_chat.bubbles.ai.colorset', { md: 6 }],
                ['ai.mini_chat.bubbles.user.colorset', { md: 6 }],
                'ai.mini_chat.bubbles.ai.icon',
                ['ai.mini_chat.tone_of_voice', { md: 6 }],
                ['ai.mini_chat.company_name', { md: 6 }],
                ['ai.mini_chat.bubbles.style.padding', { md: 6 }],
                ['ai.mini_chat.bubbles.style.font_size', { md: 3 }],
                ['ai.mini_chat.bubbles.style.border_radius', { md: 3 }],
              ],
            },
            {
              title: 'AI Guarding',
              subtitle: 'Avoid hallucination in the generated AI response by applying different '
                + 'AI guards. <strong>Setting sliders to 0 turns the guard off.</strong>',
              fields: [
                ['ai.reply_guarding_setting.jaccard_sim_threshold', { md: 6 }],
                ['ai.reply_guarding_setting.embedding_sim_threshold', { md: 6 }],
                ['ai.reply_guarding_setting.gpt_groundedness_threshold', { md: 6 }],
                ['ai.reply_guarding_setting.gpt_answer_relevance_threshold', { md: 6 }],
                ['ai.reply_guarding_setting.preflight_check_enabled', { md: 12 }],
              ],
            },
          ],
        },
        {
          tab: 'Preview & Export',
          sections: [
            {
              title: 'Preview',
              subtitle: 'Test your changes before going live.',
              fields: [
                'WIDGET_PREVIEW',
              ],
            },
            {
              title: 'Export',
              subtitle: 'If everything looks as expected, all you need to do is to copy the script to your website.',
              fields: ['WIDGET_EXPORT'],
            },
          ],
        },
      ];
    },
  },
  watch: {
    // not super nice but hopefully good enough
    busy: {
      immediate: true,
      handler(isBusy) {
        if (isBusy) return;
        const storeConfig = this.widgetDetails.config;
        this.configClone = cloneDeep(storeConfig);
      },
    },
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('widgetConfig', ['patchItem']),
    getFieldDetails(fieldId) {
      // also injected into child components
      const match = this.fieldDefinitions.find((fieldDef) => fieldDef.keyPath === fieldId);
      return match || {};
    },
    getFieldValue(keyPath) {
      // also injected into child components
      return get(this.configClone, keyPath) ?? '';
    },
    setFieldValue(keyPath, value) {
      // also injected into child components
      const path = keyPath.split('.');
      let current = this.configClone;
      for (let i = 0; i < path.length - 1; i++) {
        const thisKey = path[i];
        if (current[thisKey] === undefined) {
          this.$set(current, thisKey, {});
        }
        current = current[thisKey];
      }
      if (path.includes('reply_guarding_setting') && keyPath.includes('_threshold')) {
        this.$set(current, path[path.length - 1], parseFloat(value));
      } else {
        this.$set(current, path[path.length - 1], value);
      }
    },
    hasUnsavedChanges() {
      const localCopy = this.configClone;
      const storeCopy = this.widgetDetails.config;
      return !isEqual(localCopy, storeCopy);
    },
    promptSave() {
      return this.$bvModal
        .msgBoxConfirm(
          'Your changes have not been saved. Do you want to save them before continuing?',
          {
            title: 'Unsaved Changes',
            okTitle: 'Save Changes',
            okVariant: 'primary',
            cancelTitle: 'Do Not Save',
            autoFocusButton: 'cancel',
            cancelVariant: 'danger',
            centered: true,
          },
        );
    },
    async saveChanges() {
      if (!this.hasUnsavedChanges) return;
      const currentTab = this.currentTab;
      try {
        this.isSaving = true;
        const widgetId = this.widgetDetails.id;
        await this.patchItem({
          config: this.configClone,
          id: widgetId,
        });
      } catch (error) {
        this.showWarning({
          title: 'Failed to save.',
          text: error.message,
        });
      } finally {
        this.isSaving = false;
        this.currentTab = currentTab;
      }
    },
  },
};
</script>
