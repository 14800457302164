<template>
  <div class="flex-fill">
    <b-alert
      v-if="isDeprecated"
      class="r-75"
      fade
      show
      variant="warning"
    >
      <font-awesome-icon
        class="mr-2"
        icon="exclamation"
        size="lg"
      />
      This seach engine is deprecated and can no longer be used.
    </b-alert>
    <b-row
      v-if="isFetching"
      class="h-100 align-items-center"
    >
      <b-col class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem;"
        />
      </b-col>
    </b-row>
    <router-view v-else />
  </div>
</template>

<script>

import {
  mapMutations, mapActions, mapState, mapGetters,
} from 'vuex';

export default {
  name: 'RankerSingle',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setAndFetch(to.params.rankerId);
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.rankerId !== from.params.rankerId) {
      this.setAndFetch(to.params.rankerId);
    }
    next();
  },
  data() {
    return {
      localIsFetching: true,
    };
  },
  computed: {
    ...mapState('ranker', { rankerDetails: 'details' }),
    ...mapState('ranker', { isFetchingRankerDetails: 'isFetchingDetails' }),
    ...mapState('rankerInstance', ['pagination']),
    ...mapState('pipelineBuild', { pipelineBuildPagination: 'pagination' }),
    ...mapState('pipelineSource', { isFetchingPipelineSources: 'isFetching' }),
    ...mapGetters('pipeline', ['isArticlePrediction']),
    ...mapGetters('pipeline', { pipelines: 'items' }),
    ...mapGetters('auth', ['hasAccess']),
    isFetching() {
      return this.localIsFetching || this.isFetchingRankerDetails || this.isFetchingPipelineSources;
    },
    isDeprecated() {
      const pipelineIds = Object.values(this.pipelines).map((e) => e.id);
      return !this.isFetching && pipelineIds.includes(this.rankerDetails.pipeline.id)
      && !this.isArticlePrediction(this.rankerDetails.pipeline.type);
    },
  },
  created() {
    this.setDefaultValues();
  },
  destroyed() {
    this.setRankerDetails({});
    this.setWidgetConfig({});
    this.setDefaultValues();
    this.setStats({});
    this.setArticlesDict({});
  },
  methods: {
    ...mapActions('ranker', ['fetchDataSourceByRanker']),
    ...mapActions('rankerInstance', { fetchRankerInstances: 'fetchItems' }),
    ...mapActions('pipelineBuild', { fetchPipelineBuilds: 'fetchItems' }),
    ...mapActions('ranker', { fetchRankerDetails: 'fetchItemDetails' }),
    ...mapActions('widgetConfig', { fetchWidgetConfig: 'fetchItemDetails' }),
    ...mapActions('pipelineSource', { fetchPipelineSources: 'fetchItems' }),
    ...mapActions('ranker', ['fetchRankerArticles', 'fetchRankerArticleAttributes']),
    ...mapMutations('ranker', {
      setRankerDetails: 'setItemDetails',
      setStats: 'setStats',
      setArticlesDict: 'setArticlesDict',
    }),
    ...mapMutations('widgetConfig', {
      setWidgetConfig: 'setItemDetails',
    }),
    ...mapMutations('statisticsFiltersStore', ['setDefaultValues']),
    setAndFetch(rankerId) {
      // function to be called after other fetching
      const finalCallback = () => {
        this.localIsFetching = false;
        this.fetchDataSourceByRanker(rankerId);
        this.fetchPipelineBuilds({
          params: {
            pipeline: this.rankerDetails.pipeline?.id,
            page: this.pipelineBuildPagination.page,
          },
          refreshing: false,
        });
      };
      // fetch ranker details and instances
      this.fetchRankerDetails({ id: rankerId }).then(() => {
        if (!this.hasAccess('trainer')) {
          this.localIsFetching = false;
          return;
        }
        this.fetchPipelineSources({
          params: {
            pipeline: this.rankerDetails.pipeline.id,
          },
        });
        this.fetchRankerInstances({
          params: {
            ranker: this.rankerDetails.id,
            page: this.pagination.page,
          },
          refreshing: false,
        });
        if (this.rankerDetails.pipeline.type === 'article_prediction') {
          this.fetchRankerArticles(this.rankerDetails.id);
          this.fetchRankerArticleAttributes(this.rankerDetails.id);
        }
        this.fetchWidgetConfig({ id: this.rankerDetails.widgetConfig });
        finalCallback();
      });
    },
  },
};
</script>
