// WARNING: To include this file in a product, you must create/have the '@/js/constants' in that specific product too
import Vue from 'vue';
import { defaultFilterValues } from '@/js/constants';
import { cloneDeep } from 'lodash';

const filtersState = {
  filters: cloneDeep(defaultFilterValues),
  extraSelectedFilters: [],
};
const filtersGetters = {
  filters: (state) => state.filters,
  extraSelectedFilters: (state) => state.extraSelectedFilters,
};
const filtersMutations = {
  resetFilters(state) {
    state.filters = {};
  },
  setFilters(state, payload) {
    state.filters = payload;
  },
  setFilter(state, { prop, value }) {
    if (['startDate', 'endDate'].includes(prop)) {
      const formattedValue = new Date(value);
      Vue.set(state.filters, prop, formattedValue);
    } else {
      Vue.set(state.filters, prop, value);
    }
  },
  addExtraFilter(state, payload) {
    state.extraSelectedFilters.push(payload);
  },
  removeExtraFilter(state, { index, key }) {
    if (key === 'categories') {
      state.filters.includeCategory = defaultFilterValues.includeCategory;
      state.filters.includeAll = defaultFilterValues.includeAll;
      state.filters.excludeCategory = defaultFilterValues.excludeCategory;
      state.filters.excludeAll = defaultFilterValues.excludeAll;
    } else {
      state.filters[key] = defaultFilterValues[key];
    }
    state.extraSelectedFilters.splice(index, 1);
  },
  setExtraSelectedFilters(state, payload) {
    state.extraSelectedFilters = payload;
  },
  resetExtraFilters(state) {
    state.extraSelectedFilters = [];
  },
  setDefaultValues(state) {
    state.filters = cloneDeep(defaultFilterValues);
  }
};
const filtersActions = {};

export default {
  namespaced: true,
  state: filtersState,
  getters: filtersGetters,
  mutations: filtersMutations,
  actions: filtersActions,
};
