<template>
  <b-modal
    id="api-documentation-modal"
    ref="modal"
    title="API documentation"
    size="xl"
    ok-only
  >
    <b-tabs>
      <b-tab title="Overview">
        <b-card>
          <h4>Endpoint</h4>
          <p>
            The predictions of the ranker instance are exposed on the endpoint:
            <code>{{ endpoint }}</code>.
          </p>
          <h4>Parameters</h4>
          <p>
            To get the intended response, you must perform a POST request with a json object
            containing key, <code>text</code>, along with the associated query as value. The
            endpoint also accepts
            key <code>meta_data</code> which is an object with string keys and values.
          </p>
          <h4>Authorization</h4>
          <p>
            You must authorize your request using
            <a href="https://swagger.io/docs/specification/authentication/basic-authentication/">
              basic authentication
            </a>. You can find the details in the link, but basically this means that you must
            include an encoded version of your username and password in the header of the request.
            Most libraries used for
            making web requests knows how to handle basic authentication, so you will most likely
            just need to supply your username and password to the function making the request.
          </p>
          <h4>Example</h4>
          <b-container
            fluid
            class="p-2 mb-2 border bg-light"
          >
            <div
              style="white-space: pre-wrap;"
            >
              {{ example }}
            </div>
          </b-container>
        </b-card>
      </b-tab>
      <b-tab title="Swagger">
        <swagger-docs />
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import SwaggerDocs from '../SwaggerDocs.vue';

export default {
  name: 'APIDocumentationModal',
  components: { SwaggerDocs },
  props: {
    endpoint: {
      type: String,
      required: true,
    },
  },
  data() {
    return { };
  },
  computed: {
    example() {
      return `curl ${this.endpoint}
        --request POST
        --user username:password
        --header 'Content-Type: application/json'
        --data '{"text": "I cannot remember my login"}'`;
    },
  },
};
</script>
