import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import SharedTemplate from '@/js/vuexTemplates/shared';
import { getModuleProps } from '@/js/utils';

const widgetConfigState = {

};

const widgetConfigGetters = {

};

const mutations = {

};

const actions = {

};

const templates = [
  new GeneralTemplate(endpoints.widgetConfig, 'widgetConfig'),
  new SharedTemplate('widgetConfig'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: widgetConfigState, getters: widgetConfigGetters, mutations, actions,
  }, templates),
};
