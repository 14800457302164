<template>
  <b-list-group-item :disabled="disabled">
    <b-row
      class="cursor-pointer"
      @click="toggleVisible"
    >
      <b-col
        cols="auto"
        class="h4 my-auto"
      >
        <font-awesome-icon
          :icon="visible ? 'angle-up' : 'angle-down'"
        />
      </b-col>
      <b-col class="h5 my-auto pl-0">
        <b-spinner v-if="isLoading" small />
        {{ snakeCaseToText(title) }}
      </b-col>
      <b-col cols="auto">
        <slot name="rightSide" />
      </b-col>
    </b-row>
    <b-collapse v-model="visible">
      <slot name="content" />
    </b-collapse>
  </b-list-group-item>
</template>

<script>
import { snakeCaseToText } from 'supwiz/util/data';

export default {
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'lg',
    },
    startVisible: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      selectedChartType: 'line',
    };
  },
  created() {
    if (this.startVisible && !this.visible) this.toggleVisible();
  },
  methods: {
    snakeCaseToText,
    toggleVisible() {
      this.visible = !this.visible;
      this.$emit('visible', this.visible);
    },
  },
};
</script>

<style scoped>
.collapsible-section-btn svg {
  opacity: .25;
}
</style>
