<template>
  <b-card
    title="Tag categories"
    class="r-75"
    body-class="p-3"
  >
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rowCount"
          :per-page="perPage"
          aria-controls="fields-table"
          size="sm"
        />
      </b-col>
      <b-col
        v-if="showFilters"
        cols="auto"
        class="mt-auto"
      >
        <b-checkbox-group
          v-model="filters"
          class="mb-2"
          size="sm"
          :options="dataTypeOptionValues"
        />
      </b-col>
    </b-row>
    <table-data
      id="fields-table"
      :items="items"
      :fields="tableFields"
      :busy="isFetching"
      :current-page="currentPage"
      :per-page="perPage"
      show-empty
      hover
      @row-clicked="openField"
      @delete="deleteFieldLocal"
      @add="$bvModal.show('add-field-modal')"
    >
      <template #cell(dataSource)="data">
        {{ dataSourceIdToName(data.item.dataSource) }}
      </template>
    </table-data>
    <b-modal
      id="add-field-modal"
      title="Add tag category"
      centered
      :ok-disabled="$v.newField.$invalid"
      @hide="resetForm"
      @ok="addFieldLocal"
    >
      <b-form @submit.prevent>
        <edit-key-value
          class="mb-3"
          key-prop="Data Type"
          :value-prop="newField.dataType"
          :min-key-width="keyWidth"
          :options="dataTypeOptions"
          autofocus
          type="select"
          :state="!$v.newField.dataType.$invalid"
          @input="(x) => newField.dataType = x"
        >
          <template #feedback>
            <b-form-invalid-feedback>
              You must choose a data type.
            </b-form-invalid-feedback>
          </template>
        </edit-key-value>
        <edit-key-value
          class="mb-3"
          key-prop="Name"
          :value-prop="newField.name"
          :min-key-width="keyWidth"
          type="input"
          :state="!$v.newField.name.$invalid"
          @input="(x) => newField.name = x"
          @touch="fillDisplayName"
        >
          <template #feedback>
            <b-form-invalid-feedback v-if="!$v.newField.name.required">
              Name cannot be empty.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.newField.name.isUnique">
              This name is already taken.
            </b-form-invalid-feedback>
          </template>
        </edit-key-value>
        <edit-key-value
          class="mb-3"
          key-prop="Display Name"
          :value-prop="newField.displayName"
          :min-key-width="keyWidth"
          type="input"
          :state="!$v.newField.displayName.$invalid"
          @input="(x) => newField.displayName = x"
        >
          <template #feedback>
            <b-form-invalid-feedback>
              Display name cannot be empty.
            </b-form-invalid-feedback>
          </template>
        </edit-key-value>
      </b-form>
    </b-modal>
  </b-card>
</template>
<script>
import {
  mapState, mapGetters, mapActions,
} from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import { fieldDataTypes } from '@/js/constants';
import dataSourceSpecs from '@/js/dataSourceSpecs';

export default {
  name: 'Fields',
  components: { TableData, EditKeyValue },
  mixins: [validationMixin],
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      filters: [],
      newField: this.newFieldTemplate(),
      tableFields: ['dataType', 'name', 'displayName',
        { key: 'delete', label: '', tdClass: 'delete-column' }],
      dataSourceSpecs,
    };
  },
  computed: {
    ...mapState('field', { fields: 'items' }),
    ...mapState('field', ['isFetching']),
    ...mapGetters('dataSource', { dataSourceIdToName: 'itemIdToName' }),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    keyWidth() {
      return 160;
    },
    items() {
      const filteredFields = Object.values(this.fields)
        .filter((e) => e.dataSource === this.dataSourceDetails.id);
      return filteredFields.filter((e) => this.filters.includes(e.dataType));
    },
    rowCount() {
      return this.items.length;
    },
    dataTypeOptions() {
      const activeDataSourceSpecs = this.dataSourceSpecs[this.dataSourceDetails.type];
      const typeFilter = (typeObj) => {
        if (typeObj.value === fieldDataTypes.ACTIVITY.value) {
          return activeDataSourceSpecs.includes(fieldDataTypes.CHAT.value);
        }
        return activeDataSourceSpecs.includes(typeObj.value);
      };
      return Object.values(fieldDataTypes).filter(typeFilter);
    },
    dataTypeOptionValues() {
      return this.dataTypeOptions.map((x) => x.value);
    },
    showFilters() {
      return this.dataTypeOptionValues.length > 1;
    },
  },
  mounted() {
    this.filters = [...this.dataTypeOptionValues];
  },
  methods: {
    ...mapActions('field', { deleteField: 'deleteItem' }),
    ...mapActions('field', { addField: 'addItem' }),
    newFieldTemplate() {
      return {
        dataType: null,
        name: null,
        displayName: null,
      };
    },
    resetForm() {
      this.newField = this.newFieldTemplate();
    },
    openField(row) {
      if (this.$route.params.rankerId) {
        this.$router.push({ name: 'ranker-tags-single', params: { fieldId: row.id } });
      } else {
        this.$router.push({ name: 'tags-single', params: { fieldId: row.id } });
      }
    },
    addFieldLocal() {
      this.newField.dataSource = this.dataSourceDetails.id;
      this.addField(
        { newItem: this.newField, fetchParams: { dataSource: this.dataSourceDetails.id } },
      );
    },
    async deleteFieldLocal(data) {
      const modalText = `Are you sure that you want to delete ${data.name}?`;
      const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        this.deleteField(
          { item: data, fetchParams: { dataSource: this.dataSourceDetails.id } },
        );
      }
    },
    fillDisplayName() {
      this.newField.displayName = this.newField.name;
    },
  },
  validations() {
    return {
      newField: {
        dataType: { required },
        name: {
          required,
          isUnique(value) {
            return !Object.values(this.fields).filter((e) => e.dataType === this.newField.dataType)
              .map((e) => e.name).includes(value);
          },
        },
        displayName: { required },
      },

    };
  },
};
</script>
