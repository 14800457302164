import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import { getModuleProps } from '@/js/utils';
import { pipelineSourceTypes } from '@/js/constants';

const pipelineSourceState = {};

const pipelineSourceGetters = {
  articleTrainingItems: (state) => Object.values(state.items)
    .filter((e) => e.type === pipelineSourceTypes.ARTICLE.value),
  otherTrainingItems: (state) => Object.values(state.items)
    .filter((e) => e.type !== pipelineSourceTypes.ARTICLE.value),
};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.pipelineSource, 'pipelineSource'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: pipelineSourceState, getters: pipelineSourceGetters, mutations, actions,
  }, templates),
};
