<template>
  <b-row
    v-if="isFetchingDetails"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <b-row v-else-if="objectNotFound">
    <b-col>
      <h1>Query not found</h1>
      <p>Requested query could not be found.</p>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col sm="12" md="7" lg="5" xl="5">
      <b-row>
        <b-col>
          <DetailsCard
            v-bind="{
              dataType: 'query',
              sections: querySections,
              details: queryDetails,
            }"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card class="r-75 mt-3">
            <b-form-group
              v-if="queryDetails.filter"
              label="Filter"
            >
              <tag-display
                no-result-text="No filtering used"
                :tags="queryDetails.filter"
              />
            </b-form-group>
            <b-overlay
              :show="isFetchingFieldsOrFieldValues"
              rounded
            >
              <b-form-group label="Query tags" class="mb-0" description="Tags are saved automatically">
                <chip-list
                  :draggable="false"
                  :value="queryFieldValues"
                  :completions="fieldValueCompletions"
                  :disabled-delete="disabledQueryFieldValues"
                  @input="(x) => updateFieldValuesLocal(x)"
                  @click="openFieldValue"
                />
              </b-form-group>
            </b-overlay>
            <b-form-checkbox
              class="mt-2"
              :checked="queryDetails.disabled"
              :disabled="isDisabling"
              @input="toggleDisabled()"
            >
              Ignore query
            </b-form-checkbox>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-col class="pl-0" sm="12" md="5" lg="7" xl="7">
      <b-card
        class="r-75"
        body-class="p-3"
      >
        <b-row class="mb-3">
          <b-col cols="auto">
            <h4 class="card-title mb-0">
              <font-awesome-icon icon="ticket-simple" />
              Query details
            </h4>
          </b-col>
          <b-col />

          <b-col cols="auto">
            <b-button
              variant="primary"
              size="sm"
              @click="$router.go(-1)"
            >
              Return
            </b-button>
          </b-col>
        </b-row>
        <b-card no-body class="mt-3">
          <b-card-text style="white-space: pre-line">
            <h6 class="font-weight-bold">
              Content
            </h6>
            {{ queryDetails.text }}
          </b-card-text>
        </b-card>
        <b-card v-if="getGptReply" no-body class="mt-3">
          <b-card-text style="white-space: pre-line">
            <h6 class="font-weight-bold">
              Gpt reply
            </h6>
            {{ getGptReply }}
          </b-card-text>
        </b-card>

        <b-list-group class="mt-3">
          <b-list-group-item>
            <b-row
              align-v="center"
              align-h="between"
              class="cursor-pointer h6 mb-0"
              @click="showRelations = !showRelations"
            >
              <b-col
                cols="11"
                class="font-weight-bold"
              >
                Query relations ({{ queryDetails.relations?.length }})
              </b-col>
              <b-col
                cols="*"
                class="text-right pr-2"
              >
                <font-awesome-icon :icon="showRelations ? 'angle-up' : 'angle-down'" />
              </b-col>
            </b-row>
            <b-collapse
              v-model="showRelations"
              class="mt-3"
            >
              <table-data
                :fields="relationsFields"
                small
                hover
                :items="queryDetails.relations"
                show-empty
                empty-text="There are no relations to show"
                class="mb-0"
              />
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item
            v-if="queryDetails.prediction"
          >
            <b-row
              align-v="center"
              align-h="between"
              class="cursor-pointer h6 mb-0"
              @click="showResult = !showResult"
            >
              <b-col
                cols="11"
                class="font-weight-bold"
              >
                Search result
              </b-col>
              <b-col
                cols="*"
                class="text-right"
              >
                <font-awesome-icon :icon="showResult ? 'angle-up' : 'angle-down'" />
              </b-col>
            </b-row>

            <b-collapse
              v-model="showResult"
              class="mt-3"
            >
              <b-card
                no-body
              >
                <vue-json-pretty
                  :data="queryDetails.prediction.result"
                  :deep="10"
                />
              </b-card>
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import ChipList from 'supwiz/components/ChipList.vue';
import TableData from 'supwiz/components/TableData.vue';
import { DataTypes } from '@/js/constants';
import TagDisplay from '@/components/Ranker/TagDisplay.vue';
import DetailsCard from '@/components/DataSource/DetailsCard.vue';

export default {
  name: 'QueriesSingle',
  components: {
    VueJsonPretty,
    ChipList,
    TableData,
    DetailsCard,
    TagDisplay,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchQueryDetails({ id: to.params.queryId });
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchQueryDetails({ id: to.params.queryId });
    next();
  },
  data() {
    return {
      relationsFields: [
        'id',
        'origin',
        { key: 'article', label: 'Article ID' },
        { key: 'chat', label: 'Chat ID' },
        { key: 'ticket', label: 'Ticket ID' },
      ],
      showResult: false,
      showRelations: false,
      showFieldValues: false,
    };
  },
  computed: {
    ...mapState('query', { queryDetails: 'details' }),
    ...mapState('query', ['isFetchingDetails', 'isDisabling']),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    ...mapGetters('field', { fields: 'items' }),
    ...mapGetters('fieldValue', { fieldValues: 'items' }),
    ...mapGetters('fieldValue', ['getCompletions']),
    ...mapState('fieldValue', { fetchingFields: 'isFetching' }),
    ...mapState('field', { fetchingFieldValues: 'isFetching' }),
    getGptReply() {
      const chat = this.queryDetails?.miniChat || [];
      if (chat.length > 0) {
        return chat[0].answer;
      }
      return null;
    },
    querySections() {
      const sections = [
        {
          title: 'Information',
          fields: [
            { key: 'id', tooltip: 'Query identifier in SupSearch' },
            { key: 'externalId', tooltip: 'Query identifier in external system' },
            { key: 'timestamp', tooltip: 'Date and time when the query occurred' },
          ],
        },
      ];
      if (this.queryDetails.prediction) {
        sections[0].fields.push({ key: 'wasUseful', tooltip: 'Shows whether user finds the ranker results useful' });
      }
      return sections;
    },
    isFetchingFieldsOrFieldValues() {
      return this.fetchingFields || this.fetchingFieldValues;
    },
    objectNotFound() {
      return Object.keys(this.queryDetails).length === 0;
    },
    queryFieldValues() {
      return this.queryDetails.fieldValues.map((e) => (e.id));
    },
    disabledQueryFieldValues() {
      return this.queryDetails.fieldValues.filter((e) => e.origin !== 'manual').map((e) => (e.id));
    },
    fieldValueCompletions() {
      return this.getCompletions(this.dataSourceDetails.id, DataTypes.QUERY)
        .filter((e) => !this.queryFieldValues.includes(e.id));
    },
    metaDataTags() {
      return Object.entries(this.queryDetails.metaData)
        .map(([key, value]) => ({ key, value }));
    },
  },
  watch: {
    queryDetails(n) {
      if (Object.keys(n).length !== 0) {
        this.ensureFieldValuesLoaded();
      }
    },
  },
  destroyed() {
    this.setQueryDetails({});
  },
  methods: {
    ...mapActions('query', { fetchQueryDetails: 'fetchItemDetails' }),
    ...mapMutations('query', { setQueryDetails: 'setItemDetails' }),
    ...mapActions('query', ['updateFieldValues', 'toggleDisabled']),
    ...mapActions('field', { fetchFields: 'fetchItems' }),
    ...mapActions('fieldValue', { fetchFieldValues: 'fetchItems' }),
    ensureFieldValuesLoaded() {
      if (!this.isFetchingFieldsOrFieldValues && this.queryDetails.fieldValues.map((e) => e.id)
        .filter((e) => !(e in this.fieldValues)).length) {
        this.fetchFields();
        this.fetchFieldValues();
      }
    },
    updateFieldValuesLocal(values) {
      this.updateFieldValues({ itemId: this.queryDetails.id, values });
    },
    openFieldValue(id) {
      this.$router.push({
        name: 'tag-values-single',
        params: {
          dataSourceId: this.dataSourceDetails.id,
          fieldId: this.fieldValues[id].field,
          fieldValueId: id,
        },
      });
    },
  },
};
</script>
<style scoped>
.prepend{
  width: 150px;
}
</style>
