<template>
  <b-card
    no-body
    class="mb-1 border"
    :style="`margin-left: ${indent * 1.5}em;`"
  >
    <b-button
      block
      href="#"
      :variant="bsVariant"
      class="text-left"
      @click="toggleShow"
    >
      <slot name="icon" />
      <slot name="header" />
      <b-button
        v-b-tooltip.hover
        variant="text"
        href="#"
        class="float-right p-0"
        title="Delete this command"
        @click.stop.prevent="deleteCommandPrompt"
      >
        <font-awesome-icon
          icon="trash-alt"
          color="white"
        />
      </b-button>
      <b-button
        v-b-tooltip.hover
        href="#"
        variant="text"
        class="float-right mr-2 p-0"
        title="Duplicate command"
        @click.stop.prevent="cloneCommand"
      >
        <font-awesome-icon
          icon="clone"
          color="white"
        />
      </b-button>
    </b-button>
    <b-collapse
      :id="accordion + '-' + scraperId + '-' + commandId"
      v-model="showContents"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <b-card-text>
          <slot />
        </b-card-text>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>

export default {
  name: 'BaseActivity',
  props: {
    accordion: {
      type: String,
      default: () => 'activity-accordion',
    },
    scraperId: {
      type: Number,
      required: true,
    },
    commandId: {
      type: String,
      required: true,
    },
    bsVariant: {
      type: String,
      required: false,
      default: 'info',
    },
    indent: {
      type: Number,
      required: true,
    },
    allowExpand: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      showContents: false,
    };
  },
  computed: {
  },
  methods: {
    async deleteCommandPrompt() {
      if (await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this command?', {
        title: 'Delete command',
        okTitle: 'Delete command',
        okVariant: 'danger',
        autoFocusButton: 'cancel',
      })) {
        this.$emit('deleteCommand', this.commandId);
      }
    },
    cloneCommand() {
      this.$emit('cloneCommand', this.commandId);
    },
    toggleShow() {
      if (this.allowExpand) {
        this.showContents = !this.showContents;
        if (this.showContents) {
          this.$emit('show-content');
        }
      }
    },
  },
};
</script>
