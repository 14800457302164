<template>
  <b-button
    title="Test connection from SupSearch to external Data Source"
    :variant="buttonVariant"
    :disabled="disabled"
    @click="handleTestConnection"
  >
    <b-spinner v-if="isTesting" small />
    {{ buttonText }}
  </b-button>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TestConnectionButton',
  props: {
    requestData: {
      type: Object,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      testResult: null,
      isTesting: false,
    };
  },
  computed: {
    buttonVariant() {
      switch (this.testResult) {
        case false: return 'danger';
        case true: return 'success';
        default: return 'secondary';
      }
    },
    buttonText() {
      switch (this.testResult) {
        case false: return 'Connection failed';
        case true: return 'Connection succeeded';
        default: return 'Test connection';
      }
    },
  },
  methods: {
    ...mapActions('dataSource', ['testConnection']),
    async handleTestConnection() {
      this.isTesting = true;
      this.testResult = await this.testConnection(this.requestData);
      this.isTesting = false;
    },
  },
};
</script>
