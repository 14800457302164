import axios from 'axios';
import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import SharedTemplate from '@/js/vuexTemplates/shared';
import { getModuleProps } from '@/js/utils';

const userState = {
  groups: [],
};

const userGetters = {
  groupOptions(state) {
    return state.groups.map((x) => ({ value: x.id, text: x.name }));
  },
};

const mutations = {
  setGroups(state, payload) {
    state.groups = payload;
  },
};

const actions = {
  async fetchGroups({ commit, rootGetters }) {
    const request = { ...rootGetters['auth/headerAuthorization'] };
    const response = await axios.get(endpoints.group, request);
    commit('setGroups', response.data);
  },
};

const templates = [
  new GeneralTemplate(endpoints.user, 'user'),
  new SharedTemplate('user'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: userState, getters: userGetters, mutations, actions,
  }, templates),
};
