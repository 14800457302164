<template>
  <div class="flex-fill">
    <b-row
      v-if="isFetching"
      class="h-100 align-items-center"
    >
      <b-col class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem;"
        />
      </b-col>
    </b-row>
    <template v-else>
      <b-alert
        v-if="hasAccess('regular')"
        class="r-75"
        :show="!hasLanguageModel && !isFetchingLanguageModels"
        variant="warning"
      >
        <font-awesome-icon
          class="mr-2"
          icon="exclamation"
          size="lg"
        />
        No language model detected. You should contact your administrator.
      </b-alert>
      <router-view />
    </template>
  </div>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'RankerBase',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.hasAccess('regular')) {
        vm.fetchDataSources();
        vm.fetchPipelines();
      }
      vm.fetchRankers();
      vm.localIsFetching = false; // eslint-disable-line no-param-reassign
    });
  },
  data() {
    return {
      localIsFetching: true,
    };
  },
  computed: {
    ...mapGetters('languageModel', { hasLanguageModel: 'hasItems' }),
    ...mapGetters('auth', ['hasAccess']),
    ...mapState('ranker', { isFetchingRankers: 'isFetching' }),
    ...mapState('languageModel', { isFetchingLanguageModels: 'isFetching' }),
    ...mapState('dataSource', { isFetchingDataSources: 'isFetching' }),
    ...mapState('pipeline', { isFetchingPipelines: 'isFetching' }),
    isFetching() {
      return this.isFetchingRankers || this.isFetchingDataSources
      || this.isFetchingPipelines || this.localIsFetching;
    },
  },
  methods: {
    ...mapActions('ranker', { fetchRankers: 'fetchItems' }),
    ...mapActions('dataSource', { fetchDataSources: 'fetchItems' }),
    ...mapActions('pipeline', { fetchPipelines: 'fetchItems' }),
  },
};
</script>
