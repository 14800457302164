<template>
  <div>
    <b-card
      class="r-75"
      body-class="p-3"
    >
      <b-row class="mb-3">
        <b-col class="my-auto">
          <h4 class="card-title my-auto">
            File uploads
          </h4>
        </b-col>
        <b-col cols="auto" class="my-auto">
          <b-pagination
            v-model="currentPage"
            size="sm"
            class="my-auto"
            :total-rows="pagination.count"
            :per-page="pagination.perPage"
            aria-controls="uploaded-files-table"
          />
        </b-col>
        <b-col cols="auto">
          <b-button
            v-if="allowUpload"
            variant="primary"
            @click="uploadBtnClicked"
          >
            {{ uploadBtnText }}
          </b-button>
        </b-col>
      </b-row>

      <table-data
        id="uploaded-files-table"
        ref="uploaded-files-table"
        :fields="fields"
        :items="fileUploadsList"
        empty-text="There are currently no uploaded files."
        show-empty
        hover
        :busy="!hasFetched"
        sort-by="id"
        sort-desc
        @delete="deleteFileUploadWithConfirm"
      >
        <template #cell(split)="row">
          <b-button
            v-if="!!row.item.extractedData && dataType === 'article' && !row.item.errorMessage"
            variant="primary"
            @click="setFileToSplit(row.item.id)"
          >
            {{ convertButtonText(row.item) }}
          </b-button>
        </template>
      </table-data>
    </b-card>
    <articles-upload-modal />
    <chats-upload-modal />
    <tickets-upload-modal />
    <queries-upload-modal />
    <file-splitter-modal />
    <b-modal
      id="confirm-file-upload-delete"
      title="Delete file upload"
      ok-variant="danger"
      ok-title="Delete"
      @ok="deleteFileUpload(toDelete)"
    >
      Are you sure you want to delete this file upload?
      All associated articles will lose their associated file link.
    </b-modal>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import { DataTypes } from '@/js/constants';
import { objToCamel } from '@/js/utils';
import ArticlesUploadModal from '@/components/DataSource/ArticlesUploadModal.vue';
import ChatsUploadModal from '@/components/DataSource/ChatsUploadModal.vue';
import TicketsUploadModal from '@/components/DataSource/TicketsUploadModal.vue';
import QueriesUploadModal from '@/components/DataSource/QueriesUploadModal.vue';
import FileSplitterModal from '@/components/DataSource/FileSplitterModal.vue';

function statusFormatter(errorMessage, key, item) {
  if (item.errorMessage) {
    return `Error: ${item.errorMessage}`;
  }
  if (item.numberOfArticles > 0) {
    if (item.dataType === DataTypes.ARTICLE) {
      return 'Converted';
    }
    return 'Done';
  }
  if (item.extractedData !== null) {
    if (item.dataType === DataTypes.ARTICLE) {
      return 'Ready';
    }
    return 'Done';
  }
  if (item.task !== null) {
    return 'Processing';
  }
  if (item.content === null && item.dataType !== DataTypes.ARTICLE) {
    return 'Done';
  }
  return 'Processing';
}

export default {
  name: 'FileUploadTable',
  components: {
    TableData,
    ArticlesUploadModal,
    ChatsUploadModal,
    TicketsUploadModal,
    QueriesUploadModal,
    FileSplitterModal,
  },
  props: {
    dataType: {
      type: String,
      required: true,
    },
    allowUpload: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      grossFields: [
        {
          key: 'delete', label: '',
        },
        {
          key: 'name',
        },
        {
          key: 'errorMessage', label: 'Status', formatter: statusFormatter,
        },
        {
          key: 'numberOfArticles', label: 'Articles',
        },
        {
          key: 'url',
        },
        {
          key: 'split', label: '',
        },
      ],
      intervalId: null,
      fetched: false,
      oldKeys: '',
      toDelete: null,
    };
  },
  computed: {
    ...mapGetters('fileUpload', { fileUploads: 'items', currentFileUpload: 'details' }),
    ...mapState('fileUpload', ['shouldRefresh', 'pagination']),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
    fields() {
      if (this.dataType === 'article') {
        return this.grossFields;
      }
      return this.grossFields.filter((x) => !['split', 'url', 'numberOfArticles'].includes(x.key));
    },
    uploadBtnText() {
      return this.dataType === 'query' ? 'Upload queries' : `Upload ${this.dataType}s`;
    },
    fileUploadsList() {
      if (this.fileUploads) {
        return Object.values(this.fileUploads).filter(
          (x) => x.dataType === this.dataType,
        ).map(
          (c) => objToCamel(c),
        );
      }
      return [];
    },
    hasFetched() {
      return this.fetched;
    },
    shouldRefreshFileUpload() {
      return this.shouldRefresh.fileUpload;
    },
  },
  watch: {
    fileUploadsList(n, o) {
      const oldDoneStatuses = o.map((e) => statusFormatter(e.errorMessage, e.id, e)).filter((e) => ['Done', 'Converted'].includes(e));
      const newDoneStatuses = n.map((e) => statusFormatter(e.errorMessage, e.id, e)).filter((e) => ['Done', 'Converted'].includes(e));
      if (newDoneStatuses.length > oldDoneStatuses.length) {
        this.updateShouldRefresh({ dataType: this.dataType, payload: true });
      }
    },
    shouldRefreshFileUpload(newVal) {
      if (newVal) {
        this.refresh();
        this.updateShouldRefresh({ dataType: 'fileUpload', payload: false });
      }
    },
    currentPage() {
      this.refresh();
    },
  },
  beforeDestroy() {
    this.updatePagination({ page: 1 });
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  created() {
    this.refresh();
    this.initInterval();
  },
  methods: {
    ...mapActions('fileUpload', {
      fetchUploadedFiles: 'fetchItems', deleteItem: 'deleteItem', fetchFileUploadDetails: 'fetchItemDetails',
    }),
    ...mapMutations('fileUpload', ['updateShouldRefresh', 'updatePagination']),
    uploadBtnClicked() {
      switch (this.dataType) {
        case 'article': this.$bvModal.show('upload-articles-modal'); break;
        case 'chat': this.$bvModal.show('upload-chats-modal'); break;
        case 'ticket': this.$bvModal.show('upload-tickets-modal'); break;
        case 'query': this.$bvModal.show('upload-queries-modal'); break;
        default: break;
      }
    },
    async setFileToSplit(id) {
      await this.fetchFileUploadDetails({ id });
      this.$bvModal.show('file-split-modal');
    },
    initInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(this.refresh, 5000);
    },
    refresh() {
      if (this.dataSourceDetails.id) {
        this.fetchUploadedFiles({
          params: {
            data_source: this.dataSourceDetails.id,
            page: this.pagination.page,
          },
        }).then(() => {
          this.fetched = true;
        });
      }
    },
    async deleteFileUpload(item) {
      await this.deleteItem({ item, fetchParams: { data_source: this.dataSourceDetails.id } });
      this.toDelete = null;
    },
    async deleteFileUploadWithConfirm(item) {
      if (item.numberOfArticles > 0) {
        this.toDelete = item;
        this.$bvModal.show('confirm-file-upload-delete');
      } else {
        await this.deleteFileUpload(item);
      }
    },
    convertButtonText(item) {
      if (item.numberOfArticles > 0) {
        return this.allowUpload ? 'Reconvert / Reupload' : 'Reconvert';
      }
      return 'Convert to articles';
    },
  },
};
</script>
