<template>
  <b-row
    v-if="isFetchingDetails"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <b-row v-else-if="objectNotFound">
    <b-col>
      <h1>Chat not found</h1>
      <p>Requested chat could not be found.</p>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col sm="12" md="7" lg="5" xl="5">
      <b-row>
        <b-col>
          <DetailsCard
            v-bind="{
              dataType: 'chat',
              sections: chatSections,
              details: chatDetails,
            }"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card class="r-75 mt-3">
            <b-overlay
              :show="isFetchingFieldsOrFieldValues"
              rounded
            >
              <b-form-group label="Chat tags" class="mb-0" description="Tags are saved automatically">
                <chip-list
                  :draggable="false"
                  :value="chatFieldValues"
                  :completions="fieldValueCompletions"
                  :disabled-delete="disabledChatFieldValues"
                  @click="openFieldValue"
                  @input="(x) => updateFieldValuesLocal(x)"
                />
              </b-form-group>
            </b-overlay>
            <b-form-checkbox
              class="mt-2"
              :checked="chatDetails.disabled"
              :disabled="isDisabling"
              @input="toggleDisabled()"
            >
              Ignore chat
            </b-form-checkbox>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-col class="pl-0" sm="12" md="5" lg="7" xl="7">
      <b-card
        class="r-75"
        body-class="p-3"
      >
        <b-row class="mb-3">
          <b-col cols="auto">
            <h4 class="card-title mb-0">
              <font-awesome-icon icon="comments" />
              Chat details
            </h4>
          </b-col>
          <b-col />

          <b-col cols="auto">
            <b-button
              variant="primary"
              size="sm"
              @click="$router.go(-1)"
            >
              Return
            </b-button>
          </b-col>
        </b-row>

        <h6 class="font-weight-bold">
          Activities
        </h6>
        <data-activities
          :activities="chatDetails.activities"
        />
        <b-list-group class="mt-3">
          <b-list-group-item>
            <b-row
              align-v="center"
              align-h="between"
              class="cursor-pointer h6 mb-0"
              @click="showRelations = !showRelations"
            >
              <b-col
                cols="11"
                class="font-weight-bold"
              >
                Chat relations ({{ chatDetails.relations.length }})
              </b-col>
              <b-col
                cols="*"
                class="text-right pr-2"
              >
                <font-awesome-icon :icon="showRelations ? 'angle-up' : 'angle-down'" />
              </b-col>
            </b-row>
            <b-collapse
              v-model="showRelations"
              class="mt-3"
            >
              <table-data
                :fields="relationsFields"
                small
                hover
                :items="chatDetails.relations"
                show-empty
                empty-text="There are no relations to show"
                class="mb-0"
              />
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  mapState, mapMutations, mapGetters, mapActions,
} from 'vuex';
import ChipList from 'supwiz/components/ChipList.vue';
import TableData from 'supwiz/components/TableData.vue';
import { DataTypes } from '@/js/constants';
import DetailsCard from '@/components/DataSource/DetailsCard.vue';
import DataActivities from '@/components/DataSource/DataActivities.vue';

export default {
  name: 'DataSourceChatsSingle',
  components: {
    ChipList,
    TableData,
    DetailsCard,
    DataActivities,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchChatDetails({ id: to.params.chatId });
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchChatDetails({ id: to.params.chatId });
    next();
  },
  data: () => ({
    relationsFields: [
      'id',
      'origin',
      { key: 'article', label: 'Article ID' },
      { key: 'chat', label: 'Chat ID' },
      { key: 'ticket', label: 'Ticket ID' },
      { key: 'query', label: 'Query ID' },
    ],
    showRelations: false,
  }),
  computed: {
    ...mapState('chat', { chatDetails: 'details' }),
    ...mapState('chat', ['isFetchingDetails', 'isDisabling']),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    ...mapGetters('fieldValue', ['getCompletions']),
    ...mapGetters('field', { fields: 'items' }),
    ...mapGetters('fieldValue', { fieldValues: 'items' }),
    ...mapState('fieldValue', { fetchingFields: 'isFetching' }),
    ...mapState('field', { fetchingFieldValues: 'isFetching' }),
    chatSections() {
      return [
        {
          title: 'Information',
          fields: [
            { key: 'id', tooltip: 'Chat identifier in SupSearch' },
            { key: 'externalId', tooltip: 'Chat identifier in external system' },
            { key: 'chatEnded', tooltip: 'End date and time of the chat' },
          ],
        },
      ];
    },
    isFetchingFieldsOrFieldValues() {
      return this.fetchingFields || this.fetchingFieldValues;
    },
    objectNotFound() {
      return Object.keys(this.chatDetails).length === 0;
    },
    formattedEndDate() {
      return new Date(this.chatDetails.chatEnded).toLocaleString();
    },
    chatFieldValues() {
      return this.chatDetails.fieldValues.map((e) => (e.id));
    },
    disabledChatFieldValues() {
      return this.chatDetails.fieldValues.filter((e) => e.origin !== 'manual').map((e) => (e.id));
    },
    fieldValueCompletions() {
      return this.getCompletions(this.dataSourceDetails.id, DataTypes.CHAT)
        .filter((e) => !this.chatFieldValues.includes(e.id));
    },
  },
  watch: {
    chatDetails(n) {
      if (Object.keys(n).length !== 0) {
        this.ensureFieldValuesLoaded();
      }
    },
  },
  destroyed() {
    this.setChatDetails({});
  },
  methods: {
    ...mapActions('chat', { fetchChatDetails: 'fetchItemDetails' }),
    ...mapMutations('chat', { setChatDetails: 'setItemDetails' }),
    ...mapActions('chat', ['updateFieldValues', 'toggleDisabled']),
    ...mapActions('field', { fetchFields: 'fetchItems' }),
    ...mapActions('fieldValue', { fetchFieldValues: 'fetchItems' }),
    ensureFieldValuesLoaded() {
      if (!this.isFetchingFieldsOrFieldValues && this.chatDetails.fieldValues.map((e) => e.id)
        .filter((e) => !(e in this.fieldValues)).length) {
        this.fetchFields();
        this.fetchFieldValues();
      }
    },
    formatType(type) {
      switch (type) {
        case 'msg': return 'message';
        default: return 'unknown type';
      }
    },
    getTypeColor(type) {
      switch (type) {
        case 'msg': return 'primary';
        default: return 'secondary';
      }
    },
    getIcon(type) {
      switch (type) {
        case 'msg': return 'comment';
        default: return 'question-circle';
      }
    },
    updateFieldValuesLocal(values) {
      this.updateFieldValues({ itemId: this.chatDetails.id, values });
    },
    openActivity(item) {
      this.$router.push({ name: 'chat-activities-single', params: { activityId: item.id } });
    },
    openFieldValue(id) {
      this.$router.push({
        name: 'tag-values-single',
        params: {
          dataSourceId: this.dataSourceDetails.id,
          fieldId: this.fieldValues[id].field,
          fieldValueId: id,
        },
      });
    },
  },
};
</script>
