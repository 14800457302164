import moment from 'moment';
import buildinfo from '@/assets/buildinfo.json';
import spec from '../../package.json';

export function versionInfo() {
  return {
    name: spec.name,
    version: spec.version,
    ...buildinfo,
  };
}
export function versionInfoArray() {
  const info = versionInfo();
  const ret = [];
  for (const k of Object.keys(info)) {
    ret.push({ name: k, value: info[k] });
  }
  return ret;
}

// SNAKE-CAMEL-CONVERSION
export function strToCamel(str) {
  return str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase().replace('-', '').replace('_', ''),
  );
}

export function objToCamel(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [strToCamel(k), v]));
}
export function strToSnake(str) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}
export function camelCaseToText(str) {
  return str.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
}
export function objToSnake(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [strToSnake(k), v]));
}

// OTHER
export function getSupSearchEnvironment() {
  // Be cautious of what you allow yourself to use this value for. An enduser
  // (although a technical one) may change the value him/herself in the developer console.
  // In any case you should not rely on information based in the frontend to allow/disallow making
  // changes in the backend; the backend should always itself verify that the user is allowed to
  // perform the requested operation.
  return process.env.NODE_ENV;
}
export function isOpenAPIEnabled() {
  return window.openAPIEnabled.toLowerCase() === 'true';
}

export function reverseLookup(value, keyValues) {
  let key;
  let val;
  for ([key, val] of Object.entries(keyValues)) {
    if (val === value) {
      break;
    }
  }
  return key;
}

// vuex templates init function
export function getModuleProps(moduleSpecific, templates) {
  return {
    state: {
      ...moduleSpecific.state,
      ...Object.assign({}, ...templates.map((e) => e.state)),
    },
    getters: {
      ...moduleSpecific.getters,
      ...Object.assign({}, ...templates.map((e) => e.getters)),
    },
    mutations: {
      ...moduleSpecific.mutations,
      ...Object.assign({}, ...templates.map((e) => e.mutations)),
    },
    actions: {
      ...moduleSpecific.actions,
      ...Object.assign({}, ...templates.map((e) => e.actions)),
    },
  };
}

export function prepareTimeStamps({ startTime, endTime, interval }) {
  const timestamps = [startTime];
  let currentTimestamp = startTime;
  while (currentTimestamp <= endTime) {
    const nextTimestamp = moment(currentTimestamp).add(1, interval);
    timestamps.push(nextTimestamp);
    currentTimestamp = nextTimestamp;
  }
  return timestamps.map((timestamp) => (timestamp / 1000).toFixed(0));
}
