<template>
  <div>
    <b-tabs pills>
      <b-tab title="Articles" class="mt-2">
        <b-list-group v-if="getItemArticles.length > 0">
          <b-list-group-item
            v-for="article in getItemArticles"
            :key="article.id"
            no-body
            class="mb-1"
          >
            <h5 class="mb-1">
              {{ article.title }}
            </h5>
            <p class="mb-1">
              {{ article.external_id }}
            </p>
            <div v-if="article.tags.length >= 0" class="d-block">
              <b-badge
                v-for="tag in article.tags"
                :key="tag.id"
                variant="primary"
                class="mr-1 mb-1"
              >
                {{ `${tag.field === 'file_type'
                  ? 'Filetype' : tag.field}: ${tag.value}` }}
              </b-badge>
            </div>
            <b-row
              align-v="center"
              class="cursor-pointer mb-0 mt-1"
              @click="showText = !showText"
            >
              <b-col cols="auto" class="pr-2">
                <font-awesome-icon :icon="showText ? 'angle-down' : 'angle-right'" />
              </b-col>
              <b-col class="pl-0" cols="11">
                Text
              </b-col>
            </b-row>
            <b-collapse
              v-model="showText"
            >
              {{ article.text }}
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
        <span v-else>
          No articles found.
        </span>
      </b-tab>
      <b-tab title="Variables" class="mt-2">
        <vue-json-pretty
          v-if="Object.keys(getItemVariables).length > 0"
          :data="getItemVariables"
          :deep="1"
          :virtual="true"
        />
        <span v-else>
          No variables found.
        </span>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import 'vue-json-pretty/lib/styles.css';
import VueJsonPretty from 'vue-json-pretty';

export default {
  name: 'ScrapeItemDetails',
  components: {
    VueJsonPretty,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showText: false,
    };
  },
  computed: {
    getItemArticles() {
      // eslint-disable-next-line no-underscore-dangle
      return this.item.state?._test_articles || [];
    },
    getItemVariables() {
      const goodKeys = Object.keys(this.item.state).filter((x) => !x.startsWith('_'));
      const out = {};
      for (const key of goodKeys) {
        out[key] = this.item.state[key];
      }
      return out;
    },
  },
  methods: {
  },
};
</script>
