<template>
  <collapsible-section
    ref="collapsibleSection"
    :title="getTitle"
    :disabled="isComputingMetric || !hasData"
    :is-loading="isComputingMetric"
    @visible="(val) => isVisible = val"
  >
    <template
      v-if="isVisible && !hideKnowledgeGaps"
      #rightSide
    >
      <b-row no-gutters>
        <b-col
          class="my-auto text-right pl-4 d-flex"
          cols="auto"
        >
          <configuration-dropdown
            v-bind="{
              metric, specifity, numberOfTopics, maxTopics,
            }"
            class="mr-2"
            @set-specifity="setSpecifity"
            @set-topics-count="setNumberOfTopicsAndCompute"
          />
          <b-button-group>
            <b-button
              v-for="({ type, icon }) in chartTypes"
              :key="type"
              variant="primary"
              :pressed="type == selectedChartType"
              class="chart-type-button"
              @click.stop="selectedChartType = type"
            >
              <font-awesome-icon :icon="icon" />
            </b-button>
          </b-button-group>
          <export-statistics
            class="ml-2"
            v-bind="{
              items: topics,
              fields,
              title: metric,
            }"
          />
        </b-col>
      </b-row>
    </template>
    <template #content>
      <b-row v-if="hideKnowledgeGaps">
        <b-col class="py-4 text-center">
          Knowledge gaps cannot be displayed for less than {{ knowledgeGapThreshold }} queries
        </b-col>
      </b-row>
      <b-row v-else-if="hasData">
        <b-col v-bind="bColAttrs">
          <table-data
            v-if="selectedChartType === 'table'"
            sticky-header="400px"
            :items="topics"
            :fields="fields"
            class="w-100 pr-2"
          />
          <typed-chart
            v-else
            :key="isVisible + selectedChartType"
            :chart-data="chartData"
            :options="chartOptions"
            chart-type="bar"
            style="max-height:500px"
          />
          <span v-if="topicsObj?.sampled">
            * Due to a large amount of data, the result is based on a
            sample of size {{ topicsObj?.sample_size && `${topicsObj.sample_size}` }}
          </span>
        </b-col>
        <b-col
          v-if="showDetails"
          v-bind="bColAttrs"
        >
          <b-row
            v-if="loadingDetails"
            class="h-100 align-items-center"
          >
            <b-col>
              <b-spinner
                class="mx-auto d-block"
              />
            </b-col>
          </b-row>
          <details-section
            v-bind="{
              chartOptions,
              metric,
              topicIndex: selectedTopicIndex,
              topicsObj,
              specifity,
            }"
          />
        </b-col>
      </b-row>
    </template>
  </collapsible-section>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import CollapsibleSection from '@/components/Ranker/CollapsibleSection.vue';
import ExportStatistics from '@/components/Ranker/ExportStatistics.vue';
import TypedChart from '@/components/typedChart.vue';
import { intervalOptions, metricOptions, metricMap } from '@/js/constants';
import DetailsSection from '@/components/Ranker/Statistics/Topics/DetailsSection.vue';
import ConfigurationDropdown from '@/components/Ranker/Statistics/Topics/ConfigurationDropdown.vue';

export default {
  name: 'DataDisplayTopic',
  components: {
    CollapsibleSection,
    ConfigurationDropdown,
    DetailsSection,
    ExportStatistics,
    TypedChart,
    TableData,
  },
  props: {
    metric: {
      type: String,
      required: true,
    },
    startVisble: {
      type: Boolean,
      default: false,
    },
    overrideTopicsObj: {
      type: Object,
      default: () => ({}),
    },
    isDetails: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['set-topics-count'],
  data() {
    return {
      isVisible: false,
      intervalOptions,
      showDetails: false,
      loadingDetails: false,
      selectedChartType: 'bar',
      selectedTopicIndex: null,
      knowledgeGapThreshold: 100,
      specifity: 0.5,
    };
  },
  computed: {
    ...mapState('ranker', ['stats', 'computingStats', 'singleMetricBeingComputed']),
    getTitle() {
      return metricOptions.find((e) => e.value === this.metric).text;
    },
    chartTypes() {
      return [
        { type: 'bar', icon: 'chart-column' },
        { type: 'table', icon: 'table' }];
    },
    hasData() {
      return !!this.topics.length;
    },
    isComputingMetric() {
      return this.stats[this.metric2Field] === undefined
        || this.singleMetricBeingComputed.includes(this.metric);
    },
    metric2Field() {
      return metricMap[this.metric];
    },
    bColAttrs() {
      return {
        cols: 12,
        lg: this.showDetails ? 6 : 12,
        style: 'min-height:450px',
      };
    },
    fields() {
      switch (this.metric) {
        case 'knowledge_gaps':
        case 'top_keywords':
          return ['name', 'count'];
        default: return [];
      }
    },
    chartData() {
      const labels = this.getLabels();
      const datasets = this.getDatasets();
      return {
        labels,
        datasets,
      };
    },
    topics() {
      return this.topicsObj?.topics || [];
    },
    numberOfTopics() {
      return this.topics.length;
    },
    topicsObj() {
      const hasOverride = Object.keys(this.overrideTopicsObj).length;
      if (hasOverride) return this.overrideTopicsObj;
      switch (this.metric) {
        case 'knowledge_gaps':
          return this.stats.knowledge_gap_topics;
        case 'top_keywords':
          return this.stats.keyword_topics;
        default:
          return {};
      }
    },
    hideKnowledgeGaps() {
      return this.metric === metricMap.knowledge_gaps
        && this.stats?.count < this.knowledgeGapThreshold;
    },
    maxTopics() {
      let queries = 200;
      const topics = this.topicsObj?.topics;
      if (Array.isArray(topics)) {
        // if topics exists, then we set the sum of topic counts as queries
        queries = topics.reduce((a, b) => a + b.count, 0);
      }
      return Math.min(30, Math.max(1, Math.round(queries / 10)));
    },
    chartOptions() {
      return {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        onClick: (event, activeElements) => {
          if (activeElements.length) {
            const { index } = activeElements[0];
            this.selectedTopicIndex = index;
            this.getDetails(index);
          }
        },
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            ticks: {
              autoSkip: false,
              stepSize: 10,
              callback(value) {
                const maxLength = 25;
                // this.getLabelForValue is from chart.js
                const label = this.getLabelForValue(value);
                if (label.length < maxLength) return label;
                return `${label.slice(0, maxLength - 3)}...`;
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Click on chart to see details',
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: (tooltipItems) => `Volume: ${tooltipItems.formattedValue}`,
            },
          },
        },
        onHover: (event, chartElement) => {
          // eslint-disable-next-line no-param-reassign
          event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
      };
    },
  },
  watch: {
    topics() {
      this.showDetails = false;
    },
  },
  mounted() {
    if (this.startVisble) setTimeout(() => this.$refs.collapsibleSection.toggleVisible(), 250);
  },
  methods: {
    ...mapActions('ranker', ['computeSingleStat']),
    getLabels() {
      return this.topics.map(({ name }) => name);
    },
    getDatasets() {
      const datasets = [];
      datasets.push({
        label: 'Volume',
        backgroundColor: [],
        fill: false,
        borderColor: [],
        data: [],
      });
      this.topics.forEach((item, index) => {
        const color = this.selectedTopicIndex === index ? '#00354C' : '#005F89';
        datasets[0].data.push(item.count);
        datasets[0].backgroundColor.push(color);
        datasets[0].borderColor.push(color);
      });
      const filteredData = datasets;
      return filteredData;
    },
    getDetails() {
      this.showDetails = true;
      this.loadingDetails = false;
    },
    setSpecifity(newSpecifity) {
      this.specifity = newSpecifity;
    },
    setNumberOfTopicsAndCompute(numberOfTopics) {
      if (this.isDetails) {
        this.$emit('set-topics-count', numberOfTopics);
        return;
      }
      this.computeSingleStat({ metric: this.metric, numberOfTopics });
    },
  },
};
</script>
