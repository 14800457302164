<template>
  <collapsible-section
    :title="getTitle"
    :disabled="isComputingMetric || !hasData"
    :is-loading="isComputingMetric"
    @visible="(val) => isVisible = val"
  >
    <template
      v-if="isVisible"
      #rightSide
    >
      <b-row no-gutters>
        <b-col
          v-if="metric === 'least_useful_articles'"
          class="ml-2"
          @click.stop
        >
          <b-form-radio-group
            v-model="leastUsefulMetric"
            button-variant="outline-primary"
            buttons
          >
            <b-form-radio v-b-tooltip.hover value="least_useful_rate" title="Articles with highest not-useful percentage">
              By rate
            </b-form-radio>
            <b-form-radio v-b-tooltip.hover value="least_useful_count" title="Articles with most not-useful reportings">
              By count
            </b-form-radio>
          </b-form-radio-group>
        </b-col>
        <b-col
          v-if="rangeApplicable"
          class="my-auto"
        >
          <label class="mb-0" :for="metric + 'range'">
            Number of {{ snakeCaseToText(metric) }}: {{ displayNumber }}
          </label>
          <b-form-input
            :id="metric + 'range'"
            v-model="displayNumber"
            size="sm"
            type="range"
            min="1"
            :max="rangeLength"
            @click.stop
          />
        </b-col>
        <b-col
          class="my-auto text-right pl-4 d-flex"
          cols="auto"
        >
          <b-button-group>
            <b-button
              v-for="({ type, icon }) in chartTypes"
              :key="type"
              variant="primary"
              :pressed="type == selectedChartType"
              class="chart-type-button"
              @click.stop="selectedChartType = type"
            >
              <font-awesome-icon :icon="icon" />
            </b-button>
          </b-button-group>
          <export-statistics
            class="ml-2"
            v-bind="{
              items,
              fields: exportFields,
              title: metric,
            }"
          />
        </b-col>
      </b-row>
    </template>
    <template #content>
      <b-row v-if="hasData">
        <b-col :style="isLocation ? '' : 'min-height:450px'">
          <table-data
            v-if="selectedChartType === 'table'"
            sticky-header="400px"
            :items="items"
            :fields="fields"
            class="w-100 pr-2 mt-2"
          >
            <template #cell(show_details)="row">
              <b-button size="sm" class="mr-2" @click="row.toggleDetails">
                {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
              </b-button>
            </template>
            <template #row-details="row">
              <b-card
                no-body
                class="r-25 border"
                header="Top cities"
                header-class="py-2"
              >
                <b-list-group flush>
                  <b-list-group-item v-if="!row.item.city.length">
                    There is no data to show.
                  </b-list-group-item>
                  <b-list-group-item
                    v-for="(item, index) in row.item.city"
                    v-else
                    :key="index"
                    class="d-flex justify-content-between align-items-center"
                  >
                    {{ item.city }}
                    <h5 class="mb-0">
                      <b-badge variant="primary" pill class="h4 mb-0">
                        {{ item.count }}
                      </b-badge>
                    </h5>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </template>
          </table-data>
          <choropleth-map
            v-else-if="isLocation"
            :items="items"
            :visible="isVisible && selectedChartType === 'bar'"
          />
          <typed-chart
            v-else
            :key="isVisible + selectedChartType"
            :chart-data="chartData"
            :options="chartOptions"
            :chart-type="selectedChartType"
            class="h-100"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="metric === 'location'" :offset="selectedChartType === 'table' ? 0 : 4">
          <small>
            This product includes GeoLite2 data created by MaxMind, available from
            <a
              href="https://www.maxmind.com"
              target="_blank"
              rel="noreferrer noopener"
            >https://www.maxmind.com</a>.
          </small>
        </b-col>
      </b-row>
    </template>
  </collapsible-section>
</template>
<script>
import { mapState } from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import CollapsibleSection from '@/components/Ranker/CollapsibleSection.vue';
import ExportStatistics from '@/components/Ranker/ExportStatistics.vue';
import TypedChart from '@/components/typedChart.vue';
import { intervalOptions, metricMap, metricOptions } from '@/js/constants';
import { snakeCaseToText } from 'supwiz/util/data';
import ChoroplethMap from '@/components/Ranker/Statistics/ChoroplethMap.vue';

export default {
  name: 'DataDisplay',
  components: {
    CollapsibleSection,
    ExportStatistics,
    TypedChart,
    TableData,
    ChoroplethMap,
  },
  props: {
    metric: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      displayNumber: 0,
      selectedChartType: 'bar',
      intervalOptions,
      isUsefulCount: true,
      leastUsefulMetric: 'least_useful_count',
    };
  },
  computed: {
    ...mapState('ranker', ['stats', 'computingStats']),
    getTitle() {
      return metricOptions.find((e) => e.value === this.metric).text;
    },
    isLocation() {
      return this.metric === 'location';
    },
    chartTypes() {
      return [
        { type: 'bar', icon: 'chart-column' },
        { type: 'table', icon: 'table' }];
    },
    rangeApplicable() {
      return ['top_predictions', 'least_predicted', 'urls', 'least_useful_articles'].includes(this.metric);
    },
    rangeLength() {
      return this.stats[this.metric2Field]?.length;
    },
    isComputingMetric() {
      return this.stats[this.metric2Field] === undefined;
    },
    hasData() {
      return !!this.stats[this.metric2Field] && this.stats.count;
    },
    exportFields() {
      return this.isLocation ? ['country', 'city', 'count'] : this.fields;
    },
    fields() {
      switch (this.metric2Field) {
        case 'devices': return ['device', 'count'];
        case 'location': return ['country', 'count',
          {
            key: 'show_details', label: '', tdClass: 'text-right', thClass: 'text-right',
          }];
        case 'cities': return ['city', 'count'];
        case 'urls': return ['url', 'count'];
        case 'least_predicted':
        case 'prediction_counts': return ['id', 'display_name', 'count'];
        default: return [];
      }
    },
    items() {
      if (this.rangeApplicable) {
        return this.stats[this.metric2Field].slice(0, this.displayNumber);
      }
      return this.stats[this.metric2Field];
    },
    metric2Field() {
      if (this.metric === 'least_useful_articles') {
        return this.leastUsefulMetric;
      }
      return metricMap[this.metric];
    },
    chartData() {
      return {
        labels: this.getLabels(),
        datasets: this.getDatasets(),
      };
    },
    chartOptions() {
      return {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            ticks: {
              autoSkip: false,
              stepSize: 10,
              callback(value) {
                const maxLength = 25;
                // this.getLabelForValue is from chart.js
                const label = this.getLabelForValue(value);
                if (label.length < maxLength) return label;
                return `${label.slice(0, maxLength - 3)}...`;
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItems) => {
                if (this.metric2Field === 'least_useful_count') {
                  return `Times marked 'Not useful': ${tooltipItems.formattedValue}`;
                }
                if (this.metric2Field === 'least_useful_rate') {
                  return `Percentage of times marked 'Not useful': ${(tooltipItems.raw * 100).toFixed(1)}%`;
                }
                let percentage = tooltipItems.raw;
                if (typeof percentage === 'string') {
                  percentage = parseInt(percentage, 10);
                }
                return `Volume: ${tooltipItems.formattedValue} (${(percentage / this.stats.count * 100).toFixed(1)}%)`;
              },
            },
          },
        },
      };
    },
  },
  watch: {
    stats() {
      if (this.rangeApplicable) {
        this.displayNumber = Math.min(10, this.rangeLength);
      }
    },
  },
  mounted() {
    if (this.rangeApplicable) {
      this.displayNumber = Math.min(10, this.rangeLength);
    }
  },
  methods: {
    snakeCaseToText,
    getLabels() {
      let labels = [];
      switch (this.metric2Field) {
        case 'devices':
          labels = this.stats.devices.map((e) => e.device);
          break;
        case 'countries':
          labels = this.stats.countries.map((e) => e.country).slice(0, this.displayNumber);
          break;
        case 'cities':
          labels = this.stats.cities.map((e) => e.city).slice(0, this.displayNumber);
          break;
        case 'urls':
          labels = this.stats.urls.map((e) => e.url).slice(0, this.displayNumber);
          break;
        case 'prediction_counts': labels = this.stats.prediction_counts.slice(0, this.displayNumber).map((e) => e.display_name); break;
        case 'least_predicted': labels = this.stats.least_predicted.slice(0, this.displayNumber).map((e) => e.display_name); break;
        case 'least_useful_rate':
        case 'least_useful_count': labels = this.stats.least_useful_count.slice(0, this.displayNumber).map((e) => e.display_name); break;
        default: labels = [];
      }
      return labels;
    },
    getDatasets() {
      const datasets = [];
      datasets.push({
        label: 'Volume',
        backgroundColor: [],
        fill: false,
        borderColor: [],
        data: [],
      });
      this.stats[this.metric2Field].forEach((item) => {
        let metricKey = 'count';
        if (this.metric2Field === 'least_useful_rate') {
          metricKey = 'not_useful_rate';
        } else if (this.metric2Field === 'least_useful_count') {
          metricKey = 'not_useful_count';
        }
        datasets[0].data.push(item[metricKey]);
        const color = '#005F89';
        datasets[0].backgroundColor.push(color);
        datasets[0].borderColor.push(color);
      });
      const filteredData = datasets;
      if (this.rangeApplicable) {
        filteredData[0].data = datasets[0].data.slice(0, this.displayNumber);
      }
      return filteredData;
    },
  },
};
</script>
