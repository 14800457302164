<template>
  <b-list-group-item
    class="d-flex py-1 px-0 border-0"
    :class="{ 'flex-column': field.stack }"
    flush
  >
    <div
      v-if="!field.hideKey"
      v-b-tooltip.noninteractive
      class="pl-3 text-truncate text-muted text-capitalize pr-0"
      :class="{ 'col-5': !field.stack }"
      :title="field.tooltip"
    >
      <div
        v-if="!field.hideIcon"
        class="field-icon-wrap d-inline-block text-center"
      >
        <font-awesome-icon
          v-if="fieldIcon"
          :icon="fieldIcon"
        />
      </div>
      {{ field.keyFormatted || field.key }}
    </div>
    <div
      v-if="!field.hideKey"
      class="font-weight-normal text-break pr-3"
      :class="{
        'col pl-0': !field.stack,
        'pl-3': field.stack,
        'text-danger': field.negative,
      }"
    >
      <template v-if="field.key === 'urls'">
        <b-badge
          v-for="(item, index) in field.value"
          :key="index"
          class="mr-1 url-badge"
        >
          {{ item }}
        </b-badge>
      </template>
      <b-link
        v-else-if="field.key === 'externalUrl'"
        class="text-break"
        rel="noopener noreferrer"
        target="_blank"
        :href="field.value"
      >
        {{ field.value }}
        <font-awesome-icon
          icon="arrow-up-right-from-square"
        />
      </b-link>
      <template v-else-if="field.key === 'originFile'">
        <b-link :href="field.value.url" target="_blank" rel="noopener noreferrer">
          {{ field.value.name }}
        </b-link>
      </template>
      <template v-else-if="!field.value">
        <span class="text-muted font-italic">Empty</span>
      </template>
      <template v-else-if="typeof field.value === 'boolean'">
        {{ field.value ? 'True' : 'False' }}
      </template>
      <template v-else>
        {{ field.value }}
      </template>
    </div>
  </b-list-group-item>
</template>

<script>

const metadataIconMap = {
  fallback: 'info',
  link: 'link',
  url: 'link',
  department: 'building',
  agent: 'user-tie',
  /* name: 'user',
  mail: 'at',
  ticket: 'ticket',
  tel: 'phone',
  phone: 'phone',
  device: 'mobile-screen',
  os: 'mobile-screen',
  browser: 'globe', */
};

export default {
  name: 'MetaField',
  props: {
    field: {
      required: true,
      type: Object,
    },
  },
  computed: {
    fieldIcon() {
      return this.getMetadataIcon(this.field.key);
    },
  },
  methods: {
    getMetadataIcon(key) {
      const lKey = `${key}`.toLowerCase();
      if (metadataIconMap[lKey]) return metadataIconMap[lKey];
      const iconKeys = Object.keys(metadataIconMap);
      const matchingKey = iconKeys.find((iKey) => lKey.includes(iKey));
      return metadataIconMap[matchingKey] || metadataIconMap.fallback;
    },
  },
};
</script>

<style scoped>
.field-icon-wrap {
  width: 20px;
}
.url-badge{
  white-space: break-spaces;
}
</style>
