<template>
  <collapsible-section
    :title="getTitle"
    :disabled="isComputingMetric || !hasData"
    :is-loading="isComputingMetric"
    @visible="(val) => isVisible = val"
  >
    <template v-if="isVisible" #rightSide>
      <b-row no-gutters>
        <b-col
          v-if="!showDetails"
          class="my-auto text-right pl-4 d-flex"
          cols="auto"
        >
          <b-button-group>
            <b-button
              v-for="({ type, icon }) in chartTypes"
              :key="type"
              variant="primary"
              :pressed="type == selectedChartType"
              class="chart-type-button"
              @click.stop="selectedChartType = type"
            >
              <font-awesome-icon :icon="icon" />
            </b-button>
          </b-button-group>
          <export-statistics
            class="ml-2"
            v-bind="{
              items,
              fields,
              title: metric,
            }"
          />
        </b-col>
      </b-row>
    </template>
    <template #content>
      <b-row v-if="hasData" class="mt-2">
        <b-col
          v-if="!showDetails"
          v-bind="bColAttrs"
        >
          <table-data
            v-if="selectedChartType === 'table'"
            sticky-header="400px"
            :items="items"
            :fields="fields"
            class="w-100 pr-2"
            @row-clicked="itemClicked"
          />
          <typed-chart
            v-else
            :key="isVisible + selectedChartType"
            :chart-data="chartData"
            :options="chartOptions"
            :chart-type="'line'"
            class="h-100"
          />
        </b-col>
        <b-col
          v-else
          v-bind="bColAttrs"
        >
          <b-row
            v-if="loadingDetails"
            class="h-100 align-items-center"
          >
            <b-col>
              <b-spinner
                class="mx-auto d-block"
              />
            </b-col>
          </b-row>
          <div
            v-else
            class="d-flex"
          >
            <div
              v-if="!details?.keywordTopics && !details?.predictionDetails"
              class="w-100 d-flex flex-column align-items-center mt-5"
            >
              <h3>No details were found</h3>
              <b-button
                variant="primary"
                @click="closeDetails"
              >
                Go back to overview
              </b-button>
            </div>
            <template v-else>
              <b-button
                v-b-tooltip.noninteractive
                class="pl-0 text-dark"
                size="lg"
                title="Go back to traffic overview"
                variant="link"
                @click="closeDetails"
              >
                <font-awesome-icon icon="arrow-left" />
              </b-button>
              <div class="flex-fill">
                <DataDisplayTopics
                  v-if="details?.keywordTopics"
                  :is-details="true"
                  metric="top_keywords"
                  start-visible
                  :override-topics-obj="details.keywordTopics"
                  @set-topics-count="setTopicsCount"
                />
                <collapsible-section
                  v-if="details?.predictionDetails"
                  title="Top Predictions"
                >
                  <template #content>
                    <b-row>
                      <b-col v-bind="bColAttrs">
                        <typed-chart
                          :key="isVisible + selectedChartType"
                          :chart-data="details.predictionDetails"
                          :options="detailsChartOptions"
                          chart-type="bar"
                          class="h-100"
                        />
                      </b-col>
                    </b-row>
                  </template>
                </collapsible-section>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
    </template>
  </collapsible-section>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import moment from 'moment';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import CollapsibleSection from '@/components/Ranker/CollapsibleSection.vue';
import ExportStatistics from '@/components/Ranker/ExportStatistics.vue';
import TypedChart from '@/components/typedChart.vue';
import { metricOptions, intervalOptions, metricMap } from '@/js/constants';
import { prepareTimeStamps } from '@/js/utils';
import endpoints from '@/js/endpoints';
import DataDisplayTopics from './DataDisplayTopics.vue';

export default {
  name: 'DataDisplayTraffic',
  components: {
    CollapsibleSection,
    DataDisplayTopics,
    ExportStatistics,
    TypedChart,
    TableData,
  },
  props: {
    metric: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      displayNumber: 0,
      selectedChartType: 'line',
      intervalOptions,
      showDetails: false,
      loadingDetails: false,
      details: null,
      maxDetails: 10,
      detailsTask: null,
      currentLabel: null,
    };
  },
  computed: {
    ...mapState('ranker', ['stats']),
    ...mapState('statisticsFiltersStore', ['filters']),
    ...mapState('ranker', { rankerDetails: 'details' }),
    getTitle() {
      return metricOptions.find((e) => e.value === this.metric).text;
    },
    bColAttrs() {
      return {
        cols: 12,
        style: 'min-height:450px',
      };
    },
    chartTypes() {
      return [
        { type: 'line', icon: 'chart-line' },
        { type: 'table', icon: 'table' },
      ];
    },
    isComputingMetric() {
      return this.stats[this.metric2Field] === undefined;
    },
    hasData() {
      return !!this.stats[this.metric2Field] && this.stats.count;
    },
    fields() {
      switch (this.metric2Field) {
        case 'volume_over_time': return [{ key: 'timestamp', formatter: this.timestampFormatter, label: 'Date' }, 'volume'];
        case 'devices': return ['device', 'count'];
        case 'countries': return ['country', 'count'];
        case 'cities': return ['city', 'count'];
        case 'urls': return ['url', 'count'];
        case 'keyword_counts': return ['keyword', 'count'];
        case 'prediction_counts': return ['id', 'display_name', 'count'];
        default: return [];
      }
    },
    items() {
      return this.stats[this.metric2Field];
    },
    metric2Field() {
      return metricMap[this.metric];
    },
    chartData() {
      let result = {
        labels: [],
        datasets: [],
      };
      if (this.hasData && this.stats[this.metric2Field]?.length) {
        const labels = this.getLabels();
        const datasets = this.getDatasets();
        result = {
          labels,
          datasets,
        };
      }
      return result;
    },
    chartOptions() {
      return {
        indexAxis: this.selectedChartType === 'line' ? 'x' : 'y',
        responsive: true,
        maintainAspectRatio: false,
        onClick: (event, activeElements) => {
          if (activeElements.length) {
            const { index } = activeElements[0];
            this.getDetails(index);
          }
        },
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            ticks: {
              autoSkip: this.metric2Field === 'volume_over_time',
              stepSize: 10,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Click on chart to see details',
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: (tooltipItems) => {
                let percentage = tooltipItems.formattedValue;
                if (typeof percentage === 'string') {
                  percentage = parseInt(percentage, 10);
                }
                return `Volume: ${tooltipItems.formattedValue} (${(percentage / this.stats.count * 100).toFixed(1)}%)`;
              },
            },
          },
        },
        onHover: (event, chartElement) => {
          // eslint-disable-next-line no-param-reassign
          event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
      };
    },
    detailsChartOptions() {
      const options = cloneDeep(this.chartOptions);
      options.indexAxis = 'y';
      delete options.onClick;
      delete options.onHover;
      delete options.plugins.tooltip;
      delete options.plugins.title;
      options.scales = {
        x: {
          beginAtZero: true,
        },
        y: {
          ticks: {
            autoSkip: false,
            stepSize: 10,
            callback(value) {
              const maxLength = 25;
              // this.getLabelForValue is from chart.js
              const label = this.getLabelForValue(value);
              if (label.length < maxLength) return label;
              return `${label.slice(0, maxLength - 3)}...`;
            },
          },
        },
      };
      return options;
    },
  },
  watch: {
    stats() {
      this.closeDetails();
    },
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('task', ['refreshSingleTaskProgress']),
    closeDetails() {
      this.showDetails = false;
      this.details = null;
    },
    timestampFormatter(timestamp) {
      return new Date(timestamp * 1000).toLocaleString();
    },
    getLabels() {
      let labels = [];
      switch (this.metric2Field) {
        case 'devices':
          labels = this.stats.devices.map((e) => e.device);
          break;
        case 'countries':
          labels = this.stats.countries.map((e) => e.country).slice(0, this.displayNumber);
          break;
        case 'cities':
          labels = this.stats.cities.map((e) => e.city).slice(0, this.displayNumber);
          break;
        case 'urls':
          labels = this.stats.urls.map((e) => e.url).slice(0, this.displayNumber);
          break;
        case 'volume_over_time':
          labels = this.stats.volume_over_time
            .map((e) => this.formatTrafficLabels(e.timestamp, this.filters.interval));
          break;
        case 'keyword_counts':
          labels = this.stats.keyword_counts.slice(0, this.displayNumber).map((e) => e.keyword);
          break;
        case 'prediction_counts': labels = this.stats.prediction_counts.slice(0, this.displayNumber).map((e) => e.display_name); break;
        default: labels = [];
      }
      return labels;
    },
    getDatasets() {
      const datasets = [];
      datasets.push({
        label: 'Volume',
        backgroundColor: [],
        fill: false,
        borderColor: [],
        data: [],
      });
      this.stats[this.metric2Field].forEach((item) => {
        datasets[0].data.push(
          this.metric === 'traffic' ? item.volume : item.count,
        );
        const color = '#005F89';
        datasets[0].backgroundColor.push(color);
        datasets[0].borderColor.push(color);
      });
      const filteredData = datasets;
      return filteredData;
    },
    getDetails(index) {
      switch (this.metric) {
        case 'traffic': this.fetchTrafficDetails({ label: this.getLabels()[index] }); break;
        default: break;
      }
    },
    formatTrafficLabels(timestamp, interval) {
      switch (interval) {
        case intervalOptions.HOUR:
          return moment(new Date(timestamp * 1000)).format('DD/MM/YYYY HH:mm');
        case intervalOptions.DAY:
          return moment(new Date(timestamp * 1000)).format('DD/MM/YYYY');
        case intervalOptions.MONTH:
          return moment(new Date(timestamp * 1000)).format('MMMM');
        default:
          return moment(new Date(timestamp * 1000)).format('DD/MM/YYYY');
      }
    },
    itemClicked(row) {
      this.fetchTrafficDetails({
        label: this.formatTrafficLabels(row.timestamp, this.filters.interval),
      });
    },
    async fetchTrafficDetails({ label, numberOfTopics }) {
      this.loadingDetails = true;
      this.details = null;
      this.showDetails = true;
      this.currentLabel = label;
      const filters = cloneDeep(this.filters);
      const detailsInterval = this.detailsInterval(filters.interval);
      filters.metrics = ['top_keywords', 'top_predictions'];
      const formattedStartDate = this.getDetailsDate(label, detailsInterval, 'start');
      const formattedEndDate = this.getDetailsDate(label, detailsInterval, 'end');
      const config = {
        interval_endpoints: prepareTimeStamps({
          startTime: formattedStartDate,
          endTime: formattedEndDate,
          interval: detailsInterval.toLowerCase(),
        }),
        start_time: formattedStartDate,
        end_time: formattedEndDate,
        metrics: ['keyword_counts', 'prediction_counts'],
        number_of_topics: numberOfTopics || null,
      };
      try {
        const resp = await axios.post(
          `${endpoints.ranker}${this.rankerDetails.id}/stats/`,
          config,
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
        );
        if (resp.data.celery_id) {
          this.detailsTask = resp.data.celery_id;
          this.updateTrafficDetailsTask();
        }
      } catch (error) {
        this.showWarning({
          title: 'Failed to compute statistics details.',
          text: error.message,
        });
        this.loadingDetails = false;
        this.detailsTask = null;
      }
    },
    async updateTrafficDetailsTask() {
      try {
        const resp = await this.refreshSingleTaskProgress(this.detailsTask);
        if (resp.status !== 200 || resp.data.status === 'failed') {
          throw new Error('The backend failed to compute statistics details.');
        } else if (resp.data.status === 'pending' && resp.data.isBusy === true) {
          throw new Error('Server is busy and could not compute statistics right now');
        } else if (resp.data.status === 'pending') {
          setTimeout(() => {
            this.updateTrafficDetailsTask();
          }, 2000);
        } else if (resp.data.status === 'done') {
          const {
            prediction_counts: predictionCounts,
            keyword_topics: keywordTopics,
          } = resp.data.result;
          this.details = {};
          if (keywordTopics) {
            this.$set(
              this.details,
              'keywordTopics',
              keywordTopics,
            );
          }
          if (predictionCounts) {
            this.$set(
              this.details,
              'predictionDetails',
              {
                labels: predictionCounts?.slice(0, this.maxDetails)?.map(
                  (e) => e.display_name,
                ),
                datasets: [{
                  label: 'Predictions',
                  backgroundColor: predictionCounts?.slice(0, this.maxDetails)?.map(() => '#005F89'),
                  fill: false,
                  borderColor: '#005F89',
                  data: predictionCounts?.slice(0, this.maxDetails)?.map((e) => e.count),
                }],
              },
            );
          }
          this.loadingDetails = false;
          this.detailsTask = null;
        }
      } catch (error) {
        const isBusy = error.message.includes('busy');
        this.showWarning({
          title: isBusy ? 'Server is busy' : 'Statistics computation failed',
          text: error.message,
          variant: isBusy ? 'warning' : 'danger',
        });
        this.loadingDetails = false;
        this.detailsTask = null;
      }
    },
    getDetailsDate(label, interval, type) {
      if (interval === 'Minute') {
        const [d, m, y] = label.split(/\D/);
        const h = label.substring(11, 13);
        if (type === 'start') {
          return new Date(y, m - 1, d).setHours(h, 0, 0, 0);
        }
        return new Date(y, m - 1, d).setHours(h, 59, 59, 999);
      }
      if (interval === intervalOptions.HOUR) {
        const [d, m, y] = label.split(/\D/);
        if (type === 'start') {
          return new Date(y, m - 1, d).setHours(0, 0, 0, 0);
        }
        return new Date(y, m - 1, d).setHours(23, 59, 59, 999);
      }
      if (interval === intervalOptions.DAY) {
        const date = new Date(`1 ${label} ${new Date().getFullYear()}`);
        if (type === 'start') {
          return date.setHours(0, 0, 0, 0);
        }
        const date2 = new Date(new Date().getFullYear(), date.getMonth() + 1, 0);
        return date2.setHours(23, 59, 59, 999);
      }
      return null;
    },
    detailsInterval(current) {
      switch (current) {
        case 'Month': return 'Day';
        case 'Day': return 'Hour';
        default: return 'Minute';
      }
    },
    setTopicsCount(numberOfTopics) {
      if (!numberOfTopics) return;
      this.fetchTrafficDetails({ label: this.currentLabel, numberOfTopics });
    },
  },
};
</script>
