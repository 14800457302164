<template>
  <b-list-group-item class="p-0">
    <div v-if="isDisabled" class="custom-overlay p-3">
      <div class="text-right">
        <b-button
          :variant="isDisabled ? 'danger' : 'primary'"
          class="px-3"
          @click="toggleDisabled"
        >
          {{ isDisabled ? 'Ignored' : 'ignore' }}
        </b-button>
      </div>
    </div>
    <b-row class="p-3">
      <b-col>
        <span>
          <font-awesome-icon class="mr-1" icon="comment" />
          {{ timeDisplay }}</span><br>
        <h6 class="mb-2 font-weight-bolder">
          <!-- Insert relevant text for chats / tickets here -->
          {{ displayItem.body }}
        </h6>
        <collapsible-section
          v-if="['chat', 'ticket'].includes(dataType) && hasActivities(item)"
          title="Activities"
        >
          <template #content>
            <data-activities
              :activities="item.activities"
              :data-type="dataType"
              noninteractive
            />
          </template>
        </collapsible-section>
        <template v-if="dataType === 'query' && getGptReply(item)">
          <div
            style="background-color: #19C37D;"
            class="custom-badge text-white py-1 px-2 r-25 mb-1 font-weight-bolder cursor-pointer d-block"
            @click="showGptReply = !showGptReply"
            @keyup.enter="showGptReply = !showGptReply"
          >
            <font-awesome-icon class="mr-1" :icon="showGptReply ? 'angle-down' : 'angle-right'" />
            GPT reply
          </div>
          <b-collapse :visible="showGptReply">
            {{ getGptReply(item) }}
          </b-collapse>
        </template>
        <template v-if="showMetaData">
          <div
            v-for="(field, index) of metaDataTags"
            :key="field.key + index"
            v-b-tooltip.hover.noninteractive.viewport="`${field.key}: ${field.value}`"
            class="custom-badge mr-2 mt-1 r-25 text-white py-1 px-2 text-truncate"
          >
            {{ field.key }}: {{ field.value }}
          </div>
        </template>
      </b-col>
      <b-col cols="3">
        <b-dropdown
          class="w-100"
          lazy
          right
          toggle-class="query-dropdown w-100 text-truncate"
          menu-class="select-menu bg-white text-dark pb-0"
          @hide="keyword = ''"
          @show="showOptions = true"
        >
          <template #button-content>
            {{ getArticleTitle }}
            <b-link
              v-b-tooltip.hover.noninteractive.viewport="'Open article in a new tab'"
              class="float-right"
              @click.stop="openArticle(getArticleId)"
            >
              <font-awesome-icon icon="arrow-up-right-from-square" />
            </b-link>
          </template>
          <b-dropdown-form>
            <b-input
              v-model="keyword"
              autofocus
              debounce="250"
              placeholder="Type to search"
            />
          </b-dropdown-form>
          <b-dropdown-divider />
          <div v-if="showOptions" class="select-options w-100">
            <b-dropdown-item
              v-for="(option, optionIndex) in filteredOptions"
              :key="optionIndex"
              @click="changePrediction(option.value)"
            >
              <b-row>
                <b-col style="white-space: pre-wrap;">
                  {{ option.text }}
                </b-col>
                <b-col
                  v-if="option.pinned"
                  cols="auto"
                  class="pr-1"
                >
                  <b-badge v-b-tooltip.noniteractive.hover.viewport="'Pinned'">
                    <font-awesome-icon icon="map-pin" />
                  </b-badge>
                </b-col>
                <b-col v-if="option.score" cols="auto">
                  <b-badge v-b-tooltip.hover.noniteractive.viewport="'Prediction score'" variant="primary">
                    {{ option.score.toFixed(2) }}%
                  </b-badge>
                </b-col>
                <b-col cols="auto" class="pl-0 pr-1">
                  <b-link
                    v-b-tooltip.hover.noninteractive.viewport="'Open article in a new tab'"
                    @click.stop="openArticle(option.value)"
                  >
                    <font-awesome-icon icon="arrow-up-right-from-square" />
                  </b-link>
                </b-col>
              </b-row>
              <b-row
                v-if="hasTranslationOptions(option.value)"
              >
                <b-col style="white-space: pre;">
                  Translated titles:
                </b-col>
                <b-badge
                  v-for="(translation, index) of filteredTranslations[option.value]"
                  :key="index"
                  class="mt-1 mr-1"
                >
                  {{ translation }}
                </b-badge>
              </b-row>
            </b-dropdown-item>
          </div>
        </b-dropdown>
        <b-row>
          <b-col v-if="notUseful">
            <span
              v-b-tooltip.hover.noniteractive.viewport="'Query prediction was marked as not useful by user'"
              class="text-warning bg-light border r-25 px-1 w-100 d-block mt-2 text-center"
            >
              Not useful
            </span>
          </b-col>
          <b-col v-else-if="weakFeedback && !isLabeled && !localSelected">
            <span
              v-b-tooltip.hover.noniteractive.viewport="'User clicked on this article'"
              class=" bg-light border r-25 px-1 w-100 d-block mt-2 text-center"
            >
              Auto labeled
            </span>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="auto" class="">
        <b-button
          v-b-tooltip.hover.noninteractive.viewport
          :variant="(isLabeled || item.updated) && !dirty && !isDisabled ? 'success' : 'primary'"
          :disabled="updateDisabled"
          class="px-3 mr-1"
          :title="updating || ((isLabeled || item.updated) && !dirty) ? '' : 'Submit label'"
          @click="sendFeedback"
        >
          <font-awesome-icon icon="check" />
        </b-button>

        <b-button
          :variant="isDisabled ? 'danger' : 'primary'"
          class="px-3"
          @click="toggleDisabled"
        >
          {{ isDisabled ? 'Ignored' : 'Ignore' }}
        </b-button>
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions, mapState } from 'vuex';
import endpoints from '@/js/endpoints';
import DataActivities from '@/components/DataSource/DataActivities.vue';
import CollapsibleSection from '@/components/Ranker/CollapsibleSection.vue';

export default {
  name: 'FeedbackItem',
  components: { CollapsibleSection, DataActivities },
  props: {
    item: {
      type: Object,
      required: true,
    },
    dataType: {
      type: String,
      default: 'query',
    },
    selectedTranslations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      keyword: '',
      updating: false,
      localSelected: null,
      dirty: false,
      localDisabled: null,
      showGptReply: false,
      suggestedArticle: null,
      showOptions: false,
    };
  },
  computed: {
    ...mapGetters('field', { fields: 'items' }),
    ...mapGetters('fieldValue', { fieldValues: 'items' }),
    ...mapGetters('query', ['showMetaData']),
    ...mapGetters('ranker', ['rankerArticlesDict', 'rankerArticlesTranslations', 'articleId2DataSourceId', 'articleUrl2Id']),
    ...mapState('pipelineBuild', { pipelineBuildDetails: 'details' }),
    filteredOptions() {
      return this.getOptions
        .filter((e) => e.text.toLowerCase()
          .includes(this.keyword.toLowerCase()))
        .slice(0, 100);
    },
    getOptions() {
      if (['chat', 'ticket'].includes(this.dataType) || !this.item.prediction || !this.item.prediction.result.length) {
        return Object.values(this.rankerArticlesDict).map((e) => ({ value: e.id, text: e.title }));
      }
      const predictionIds = new Set(this.item.prediction.result.map((e) => e.id));
      const filteredRankerArticlesAsOptions = Object.values(this.rankerArticlesDict)
        .filter((e) => !predictionIds.has(e.id))
        .map((e) => ({ value: e.id, text: e.title }));
      return this.predictionResultsAsOptions.concat(filteredRankerArticlesAsOptions);
    },
    predictionResultsAsOptions() {
      return (this.item?.prediction?.result || [])
        .map((e) => ({
          value: e.id,
          text: this.rankerArticlesDict[e.id]?.title || `Unknown title (ID: ${e.id})`,
          score: (e.score * 100),
          pinned: e.pinned,
        }))
        .sort((a, b) => b.score - a.score);
    },
    getArticle() {
      const query = this.item;
      if (this.localSelected) {
        return this.rankerArticlesDict[this.localSelected];
      }
      // manual feedback has priority
      const feedbackRelations = query.relations.filter((e) => e.origin === 'feedback')
        .sort((a, b) => new Date(b.modified_time) - new Date(a.modified_time));
      if (feedbackRelations.length) {
        return this.rankerArticlesDict[feedbackRelations[0].article];
      }

      // if no manual feedback, weak feedback has priority
      const weekFeedbackRelations = query.relations.filter((e) => e.origin === 'weak_feedback')
        .sort((a, b) => new Date(b.modified_time) - new Date(a.modified_time));
      if (weekFeedbackRelations.length) {
        return this.rankerArticlesDict[weekFeedbackRelations[0].article];
      }
      if (['chat', 'ticket'].includes(this.dataType) || query.prediction === undefined) {
        return null;
      }
      // if no feedback at all, show highest prediction or none (if there is no prediction)
      if (!query.prediction?.result?.length) {
        return null;
      }
      const highestScore = query.prediction.result
        .reduce((highest, current) => (highest.score > current.score ? highest : current));
      return this.rankerArticlesDict[highestScore.id];
    },
    getArticleTitle() {
      const title = this.getArticle?.title || this.getArticleId;
      return title || this.suggestedArticle;
    },
    getArticleId() {
      return this.getArticle?.id;
    },
    isLabeled() {
      return !!this.item.relations.find((e) => e.origin === 'feedback');
    },
    filteredTranslations() {
      const ret = {};
      for (const [id, translations] of Object.entries(this.rankerArticlesTranslations)) {
        const arr = [];
        for (const [lang, trans] of Object.entries(translations)) {
          if (this.selectedTranslations.includes(lang)) {
            arr.push(trans);
          }
        }
        ret[id] = arr;
      }
      return ret;
    },
    notUseful() {
      if (['chat', 'ticket'].includes(this.dataType)) {
        return false;
      }
      return this.item?.prediction?.was_useful === false;
    },
    weakFeedback() {
      return this.item.relations.some((e) => e.origin === 'weak_feedback');
    },
    isDisabled() {
      if (this.localDisabled !== null) {
        return this.localDisabled;
      }
      return this.item?.disabled;
    },
    updateDisabled() {
      if (!this.getArticle && !this.localSelected) {
        return true;
      }
      if (this.updating) {
        return true;
      }
      if ((this.isLabeled || this.item.updated) && !this.dirty) {
        return true;
      }
      return false;
    },
    metaDataTags() {
      return (this.item.filter || []).map(
        (tagId) => this.fieldValues[tagId],
      ).filter(
        (fieldValue) => fieldValue != null,
      ).map(
        (x) => ({
          key: this.fields[x.field].displayName || this.fields[x.field].name,
          value: x.displayValue || x.value,
        }),
      ).sort((a, b) => `${a.key}:${a.value}` - `${b.key}:${b.value}`);
    },
    displayItem() {
      // returns a dict with normalized keys
      const res = {};
      switch (this.dataType) {
        case 'chat':
          res.timestamp = this.item.chatEnded;
          if (this.item.activities?.length) {
            res.body = `First Message: ${(this.item.activities.find((e) => e.sender === 'user') || this.item.activities[0]).content}`;
          }
          break;
        case 'ticket':
          res.timestamp = this.item.ticketModified || this.item.ticketCreated;
          res.body = this.item.description || this.item.shortDescription;
          if (!res.body && this.item.activities?.length) {
            res.body = `First Message: ${this.item.activities[0].content}`;
          } else {
            res.body = this.item.description || this.item.shortDescription || '';
          }
          break;
        case 'query':
        default:
          res.timestamp = this.item.timestamp;
          res.body = this.item.text;
      }
      return res;
    },
    timeDisplay() {
      return new Date(Date.parse(this.displayItem.timestamp)).toLocaleString();
    },
  },
  watch: {
    item() {
      this.localSelected = null;
      this.localDisabled = null;
      this.suggestedArticle = null;
      this.findUrlInText();
    },
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    openArticle(id) {
      const newPage = this.$router.resolve({
        name: 'articles-single',
        params: {
          dataSourceId: this.articleId2DataSourceId[id],
          articleId: id,
        },
      });
      window.open(newPage.href, '_blank');
    },
    changePrediction(value) {
      this.localSelected = value;
      this.dirty = true;
    },
    findUrlInText() {
      if (this.getArticle !== null) {
        return;
      }
      let body = '';
      switch (this.dataType) {
        case 'chat':
          body = this.item.activities.map((x) => x.content).join(' ');
          break;
        case 'ticket':
          body = `${this.item.description} ${this.item.shortDescription} ${this.item.activities.map((x) => x.content).join(' ')}`;
          break;
        case 'query':
        default:
          body = this.item.text;
          break;
      }
      if (!body) {
        return;
      }
      for (const [match] of [...body.matchAll(/\b[\S]*?[a-zA-Z]{2,}.[a-zA-Z]{2,}\/[\S]*\b/g)]) {
        if (this.articleUrl2Id[match] !== undefined) {
          const id = this.articleUrl2Id[match];
          this.suggestedArticle = this.rankerArticlesDict[id].title;
          this.localSelected = id;
        }
      }
    },
    async toggleDisabled() {
      if (this.localDisabled === null) {
        this.localDisabled = this.item?.disabled;
      }
      this.localDisabled = !this.localDisabled;
      this.updating = true;
      try {
        await axios.patch(
          `${endpoints[this.dataType]}${this.item.id}/`,
          { disabled: this.localDisabled },
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
        );
      } catch (error) {
        this.showWarning({
          title: 'Failed to mark as not correct',
          text: error.message,
        });
        throw error;
      } finally {
        this.updating = false;
      }
    },
    async sendFeedback() {
      this.updating = true;
      try {
        await axios.post(
          endpoints.internalFeedback,
          {
            data_type: this.dataType,
            instance_id: this.item.id,
            target_id: this.localSelected ?? this.getArticleId,
          },
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
        );
        this.$emit('updated');
        this.dirty = false;
      } catch (error) {
        this.showWarning({
          title: 'Failed to send feedback',
          text: error.message,
        });
        throw error;
      } finally {
        this.updating = false;
      }
    },
    getGptReply(query) {
      const chat = query?.miniChat || [];
      if (chat.length > 0) {
        return chat[0].answer;
      }
      return null;
    },
    hasActivities(item) {
      return item.activities?.length;
    },
    hasTranslationOptions(value) {
      return this.filteredTranslations[value]?.length;
    },
  },
};
</script>

<style scoped>
:deep(.select-options) {
  max-height: 180px;
  max-width: 600px;
  min-width: 500px;
  overflow-y: auto;
}
:deep(.query-dropdown) {
  background-color: white !important;
  color: #111f2d !important;
}
:deep(.query-dropdown:hover), :deep(.query-dropdown:focus), :deep(.query-dropdown:active) {
  box-shadow: inset 0 0 2px 2px #70d3ff;
}
.custom-badge{
  background-color: #44829c;
  max-width: 150px;
  width: fit-content;
  word-break: keep-all;
  display: inline-block;
}
.custom-overlay{
  position: absolute;
  width:100%;
  height: 100%;
  background-color: #eee;
  opacity: 0.7;
  z-index: 100;
}
</style>
