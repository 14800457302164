import axios from 'axios';
import endpoints from '@/js/endpoints';

export default class {
  constructor(dataType) {
    this.getters = {
      itemsOptions(state) {
        return Object.values(state.items).map((x) => ({
          value: x.id,
          text: x.name,
        }));
      },
      itemIdToName: (state) => (id) => {
        if (!(id in state.items)) {
          return `No ${dataType}`;
        }
        return state.items[id].name;
      },
      hasItems: (state) => Object.values(state.items).length,
    };

    this.mutations = { };

    this.actions = {
      async updateFieldValues({
        state, commit, rootGetters, dispatch,
      }, { itemId, values }) {
        const auth = rootGetters['auth/headerAuthorization'];
        const currentFieldValues = state.details.fieldValues.map((e) => e.id);
        // we have to figure out on which array we need to filter to get the new value
        const shorter = values.length > currentFieldValues.length ? currentFieldValues : values;
        const longer = values.length > currentFieldValues.length ? values : currentFieldValues;
        const difference = longer.find((v) => !shorter.includes(v));

        if (currentFieldValues.includes(difference)) {
          try {
            const fieldValue = state.details.fieldValues.find((e) => e.id === difference);
            await axios.delete(`${endpoints.fieldValueOrigin}${fieldValue.origin_id}/`, auth);
            const itemToUpdate = state.details;
            itemToUpdate.fieldValues.splice(itemToUpdate.fieldValues.indexOf(fieldValue), 1);
            commit('updateItem', { item: itemToUpdate });
          } catch (error) {
            dispatch('sidebar/showWarning', {
              title: 'Failed to remove field value',
              text: error.message,
            }, { root: true });
            throw error;
          }
        } else {
          try {
            const fieldValue = rootGetters['fieldValue/items'][difference];
            const data = { origin: 'manual', field_value: fieldValue.id };
            data[dataType] = itemId;
            const resp = await axios.post(endpoints.fieldValueOrigin, data, auth);
            const itemToUpdate = state.details;
            itemToUpdate.fieldValues.push(
              { ...fieldValue, origin_id: resp.data.id, origin: resp.data.origin },
            );
            commit('updateItem', { item: itemToUpdate });
          } catch (error) {
            dispatch('sidebar/showWarning', {
              title: 'Failed to add field value',
              text: error.message,
            }, { root: true });
            throw error;
          }
        }
      },
    };
  }
}
