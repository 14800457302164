<template>
  <b-modal
    ref="modal"
    v-bind="modalInfo"
    scrollable
    @hidden="resetModal"
    @shown="prepareModal"
  >
    <b-overlay variant="white" :show="busy" spinner-variant="primary">
      <b-form-group>
        <b-input-group class="r-25 border d-flex align-items-center">
          <b-form-input
            ref="inputfield"
            v-model="wordFilter"
            autofocus
            class="border-0 shadow-none"
            debounce="250"
            placeholder="Add or filter ignored words..."
            :formatter="formatter"
            @focus="$event.target.select()"
            @keyup.enter="addStopWord"
          />
          <b-button
            v-if="wordFilter"
            v-b-tooltip.hover.noninteractive
            class="py-1 px-2 mr-1"
            variant="primary"
            size="sm"
            title="Add to ignored words"
            :disabled="existingWord"
            @click="addStopWord"
          >
            <font-awesome-icon icon="plus" />
          </b-button>
        </b-input-group>
      </b-form-group>
      <b-list-group>
        <b-list-group-item v-if="!stopWordsSorted.length" class="d-flex bg-light">
          No words found
        </b-list-group-item>
        <template v-else>
          <b-list-group-item v-for="({ word, id }) in stopWordsSorted" :key="word" class="d-flex">
            {{ word }}
            <div class="ml-auto">
              <b-button
                v-b-tooltip.hover.noninteractive
                title="Remove word from the list of ignored words"
                variant="link"
                class="py-0 text-dark"
                @click="removeWord(id)"
              >
                <font-awesome-icon icon="times" />
              </b-button>
            </div>
          </b-list-group-item>
        </template>
      </b-list-group>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { sortBy } from 'lodash';

export default {
  name: 'StopWordsModal',
  data() {
    return {
      busy: false,
      wordFilter: '',
      changesMade: false,
    };
  },
  computed: {
    ...mapState('ranker', ['stopWords']),
    existingWord() {
      return this.stopWordsMapped.includes(this.wordFilter);
    },
    modalInfo() {
      return {
        centered: true,
        noCloseOnEsc: true,
        noCloseOnBackdrop: true,
        okOnly: true,
        okVariant: 'secondary',
        okTitle: 'Close',
        title: 'Ignored words',
      };
    },
    stopWordsMapped() {
      return this.stopWordsSorted.map(({ word }) => word);
    },
    stopWordsSorted() {
      const words = this.stopWords.filter(({ word }) => word.includes(this.wordFilter));
      return sortBy(words, ['word']);
    },
  },
  methods: {
    ...mapActions('ranker', ['handleStopWords']),
    async addStopWord() {
      if (this.existingWord) return;
      const word = this.formatter(this.wordFilter);
      await this.handleStopWords({ task: 'add', word });
      // blur then focus to trigger new focus event thats selects all
      this.$refs.inputfield?.blur();
      this.$refs.inputfield?.focus();
      this.changesMade = true;
    },
    displayModal() {
      this.$refs.modal.show();
    },
    formatter(value) {
      return value.toLowerCase().replaceAll(' ', '');
    },
    async prepareModal() {
      this.busy = true;
      await this.handleStopWords({ task: 'get' });
      this.busy = false;
    },
    resetModal() {
      if (this.changesMade) this.$emit('trigger-recompute');
      this.changesMade = false;
      this.wordFilter = '';
    },
    async removeWord(id) {
      this.busy = true;
      await this.handleStopWords({ task: 'delete', id });
      this.busy = false;
      this.changesMade = true;
    },
  },
};
</script>
