<template>
  <b-row
    v-if="isFetchingDetails"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <b-row v-else-if="objectNotFound">
    <b-col>
      <h1>Article not found</h1>
      <p>Requested article could not be found.</p>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col sm="12" md="7" lg="5" xl="5">
      <b-row>
        <b-col>
          <DetailsCard
            v-bind="{
              dataType: 'article',
              sections: articleSections,
              details: articleDetails,
              keywords: getArticleKeywords,
            }"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card class="r-75 mt-3" :class="unsavedChanges ? 'article-border-warning' : ''">
            <b-form-group
              v-if="translationOptions.length"
              label="Translation"
            >
              <b-form-select
                :options="translationOptions"
                :value="getActiveTranslationId"
                @input="setActiveTranslation"
              />
            </b-form-group>
            <b-form-group
              :label="`Manual title${translationOptions.length ? ' for ' + getActiveTranslationName : ''}`"
              description="Leave empty to use the original title"
            >
              <b-form-input
                :value="titleManual"
                @input="(x) => titleManual = x"
              />
            </b-form-group>
            <b-form-group
              label="Summary"
              description="Edit the automatically generated summary"
            >
              <b-form-textarea
                v-model="summaryManual"
              />
            </b-form-group>
            <b-form-group label="Manual links">
              <string-list
                class="urls-list"
                fixed-input
                placeholder="Add url..."
                :strings="localData.urlsManual"
                @change="updateManualUrls"
              />
            </b-form-group>
            <b-overlay
              :show="isFetchingFieldsOrFieldValues"
              rounded
            >
              <b-form-group label="Article tags" description="Tags are saved automatically">
                <chip-list
                  :draggable="false"
                  :value="articleFieldValues"
                  :completions="fieldValueCompletions"
                  :disabled-delete="disabledArticleFieldValues"
                  @click="openFieldValue"
                  @input="(x) => updateFieldValuesLocal(x)"
                />
              </b-form-group>
            </b-overlay>
            <b-form-group>
              <b-form-checkbox v-model="localData.disabled">
                Disable article
              </b-form-checkbox>
            </b-form-group>
            <b-row>
              <b-col>
                <b-form-group
                  class="mb-0"
                  :state="!unsavedChanges"
                >
                  <b-button
                    :disabled="!unsavedChanges"
                    variant="primary"
                    class="px-3"
                    @click="updateArticle(localData)"
                  >
                    Save
                  </b-button>
                </b-form-group>
                <b-form-invalid-feedback
                  class="text-warning"
                >
                  *Unsaved changes
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-col class="pl-0" sm="12" md="5" lg="7" xl="7">
      <b-card
        class="r-75"
        body-class="p-3"
      >
        <b-row class="mb-3">
          <b-col cols="auto">
            <h4 class="card-title mb-0">
              <b-link
                v-b-tooltip.hover.noninteractive.viewport="'Open article HTML view in a new tab'"
                :href="getArticleViewerLink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <font-awesome-icon icon="book" />
              </b-link>
              <b-link v-if="getArticleLink" :href="getArticleLink" target="_blank" rel="noopener noreferrer">
                {{ getArticleTitle }}
              </b-link>
              <span v-else>
                {{ getArticleTitle }}
              </span>
            </h4>
          </b-col>
          <b-col />
          <b-col v-if="canGoBack" cols="auto">
            <b-button
              variant="primary"
              size="sm"
              @click="$router.go(-1)"
            >
              Return
            </b-button>
          </b-col>
        </b-row>
        <b-card no-body class="mt-3">
          <b-card-text style="white-space: pre-line">
            <h6 class="font-weight-bold">
              Text
            </h6>
            {{ getArticleText }}
          </b-card-text>
        </b-card>
        <b-list-group class="mt-3">
          <b-list-group-item>
            <b-row
              align-v="center"
              align-h="between"
              class="cursor-pointer h6 mb-0"
              @click="showRelations = !showRelations"
            >
              <b-col
                cols="11"
                class="font-weight-bold"
              >
                Article relations ({{ articleDetails.relations.length }})
              </b-col>
              <b-col
                cols="*"
                class="text-right pr-2"
              >
                <font-awesome-icon :icon="showRelations ? 'angle-up' : 'angle-down'" />
              </b-col>
            </b-row>
            <b-collapse
              v-model="showRelations"
              class="mt-3"
            >
              <table-data
                :fields="relationsFields"
                small
                hover
                :items="articleDetails.relations"
                show-empty
                empty-text="There are no relations to show"
                class="mb-0"
              />
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import update from 'immutability-helper';
import { cloneDeep, isEqual } from 'lodash';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import ChipList from 'supwiz/components/ChipList.vue';
import StringList from 'supwiz/components/StringList.vue';
import TableData from 'supwiz/components/TableData.vue';
import { DataTypes } from '@/js/constants';
import DetailsCard from '@/components/DataSource/DetailsCard.vue';

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export default {
  name: 'DataSourceArticlesSingle',
  components: {
    DetailsCard,
    StringList,
    ChipList,
    TableData,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!Object.values(vm.languageObject).length) {
        vm.fetchLanguages();
      }
      if (!vm.isFetching) {
        vm.fetchArticleDetails({ id: to.params.articleId }).then(() => {
          vm.updateLocal();
        });
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchArticleDetails({ id: to.params.articleId }).then(() => {
      this.updateLocal();
    });
    next();
  },
  data: () => ({
    relationsFields: [
      'id',
      'origin',
      { key: 'article', label: 'Article ID' },
      { key: 'chat', label: 'Chat ID' },
      { key: 'ticket', label: 'Ticket ID' },
      { key: 'query', label: 'Query ID' },
    ],
    showRelations: false,
    localData: {},
    activeTranslationId: null,
  }),

  computed: {
    ...mapState('article', { articleDetails: 'details' }),
    ...mapState('article', ['isFetchingDetails']),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    ...mapGetters('fieldValue', ['getCompletions']),
    ...mapGetters('field', { fields: 'items' }),
    ...mapGetters('fieldValue', { fieldValues: 'items' }),
    ...mapGetters('language', { languageObject: 'items' }),
    ...mapState('fieldValue', { fetchingFields: 'isFetching' }),
    ...mapState('field', { fetchingFieldValues: 'isFetching' }),
    canGoBack() {
      return window.history.length > 1;
    },
    articleSections() {
      return [
        {
          title: 'Information',
          fields: [
            { key: 'id', tooltip: 'Article identifier in SupSearch' },
            { key: 'externalId', tooltip: 'Article identifier in external system' },
            { key: 'articleCreated', tooltip: 'Date and time when the article was first created' },
            { key: 'articleModified', tooltip: 'Date and time when the article was last modified' },
            { key: 'originFile', tooltip: 'Article originated from this file' },
            { key: 'keywords', tooltip: 'Keywords found in this article' },
            { key: 'urls', tooltip: 'Links related to this article' },
          ],
        },
      ];
    },
    isFetchingFieldsOrFieldValues() {
      return this.fetchingFields || this.fetchingFieldValues;
    },
    objectNotFound() {
      return Object.keys(this.articleDetails).length === 0;
    },
    unsavedChanges() {
      const localDataCopy = cloneDeep(this.localData);
      const articleDetailsCopy = cloneDeep(this.articleDetails);
      delete localDataCopy.fieldValues;
      delete articleDetailsCopy.fieldValues;
      return !isEqual(localDataCopy, articleDetailsCopy);
    },
    articleFieldValues() {
      return this.articleDetails.fieldValues.map((e) => e.id);
    },
    disabledArticleFieldValues() {
      return this.articleDetails.fieldValues.filter((e) => e.origin !== 'manual').map((e) => (e.id));
    },
    fieldValueCompletions() {
      return this.getCompletions(this.dataSourceDetails.id, DataTypes.ARTICLE)
        .filter((e) => !this.articleFieldValues.includes(e.id));
    },
    translationOptions() {
      const translationOptions = [];
      this.articleDetails.translations.forEach((element) => {
        translationOptions.push({
          value: element.language,
          text: (this.languageObject[element.language] || {}).displayName,
        });
      });
      return translationOptions;
    },
    getActiveTranslationId() {
      if (!this.activeTranslationId && this.articleDetails.translations.length) {
        this.setActiveTranslation(this.articleDetails.translations[0].language);
      }
      return this.activeTranslationId;
    },
    getActiveTranslationName() {
      return this.translationOptions.filter((x) => x.value === this.getActiveTranslationId)[0].text;
    },
    titleManual: {
      get() {
        if (this.localData.translations.length) {
          const title = this.localData.translations
            .filter((x) => x.language === this.getActiveTranslationId);
          if (title.length) {
            return title[0].title_manual;
          }
        }
        return this.localData.titleManual;
      },
      set(value) {
        if (this.localData.translations.length) {
          const title = this.localData.translations
            .filter((x) => x.language === this.getActiveTranslationId);
          if (title.length) {
            title[0].title_manual = value;
          }
        } else {
          this.localData.titleManual = value;
        }
      },
    },
    summaryManual: {
      get() {
        return this.localData.summaryManual || this.localData.summary;
      },
      set(value) {
        this.localData.summaryManual = value;
      },
    },
    getArticleTitle() {
      return this.articleDetails.translations
        .find((e) => e.language === this.activeTranslationId)?.title || this.articleDetails.title;
    },
    getArticleLink() {
      const url = this.articleDetails.currentUrl || '';
      if (isValidHttpUrl(url)) {
        return url;
      }
      return null;
    },
    getArticleViewerLink() {
      const url = `${window.location.origin}/article-view/${this.articleDetails.id}`;
      return url;
    },
    getArticleKeywords() {
      return this.articleDetails.translations
        .find((e) => e.language === this.activeTranslationId)?.keywords
        || this.articleDetails.keywords;
    },
    getArticleText() {
      return this.articleDetails.translations
        .find((e) => e.language === this.activeTranslationId)?.text || this.articleDetails.text;
    },
  },
  watch: {
    articleDetails(n) {
      if (Object.keys(n).length !== 0) {
        this.updateLocal();
        this.ensureFieldValuesLoaded();
      }
    },
  },
  destroyed() {
    this.setArticleDetails({});
  },
  methods: {
    ...mapActions('article', { patchArticle: 'patchItem' }),
    ...mapActions('article', { fetchArticleDetails: 'fetchItemDetails' }),
    ...mapMutations('article', { setArticleDetails: 'setItemDetails' }),
    ...mapActions('article', ['updateFieldValues']),
    ...mapActions('field', { fetchFields: 'fetchItems' }),
    ...mapActions('language', { fetchLanguages: 'fetchItems' }),
    ...mapActions('fieldValue', { fetchFieldValues: 'fetchItems' }),
    updateArticle(data) {
      const payload = { ...data };
      if (payload.disabled === false) {
        payload.autoDisabled = false;
      }
      this.patchArticle(payload);
    },
    ensureFieldValuesLoaded() {
      if (!this.isFetchingFieldsOrFieldValues && this.articleDetails.fieldValues.map((e) => e.id)
        .filter((e) => !(e in this.fieldValues)).length) {
        this.fetchFields();
        this.fetchFieldValues();
      }
    },
    updateManualUrls(urlsManual) {
      Object.assign(this.localData, update(this.localData, { urlsManual }));
    },
    updateFieldValuesLocal(values) {
      this.updateFieldValues({ itemId: this.articleDetails.id, values });
    },
    updateLocal() {
      if (this.articleDetails.autoDisabled === true) {
        this.articleDetails.disabled = true;
      }
      this.localData = cloneDeep(this.articleDetails);
    },
    openFieldValue(id) {
      this.$router.push({
        name: 'tag-values-single',
        params: {
          dataSourceId: this.dataSourceDetails.id,
          fieldId: this.fieldValues[id].field,
          fieldValueId: id,
        },
      });
    },
    setActiveTranslation(id) {
      this.activeTranslationId = id;
    },
  },

};
</script>
<style scoped>
.urls-list{
  max-height: 195px;
  overflow-y: auto;
}
.article-border-warning{
  border: 3px solid #E99002 !important;
}
.card-title{
  line-height: normal;
}
</style>
