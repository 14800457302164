import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import { getModuleProps } from '@/js/utils';

const metaDataState = {};

const getters = {};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.metaDataField, 'metaDataField'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: metaDataState, getters, mutations, actions,
  }, templates),
};
