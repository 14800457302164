<template>
  <base-activity
    ref="baseActivity"
    :scraper-id="scraperId"
    :command-id="commandId"
    :indent="indent"
    bs-variant="danger"
    v-on="$listeners"
  >
    <template #icon>
      <font-awesome-icon icon="code" />
    </template>
    <template #header>
      AddLink( {{ command.source_var }} )
    </template>
    <label for="source_var">
      Source Variable:
    </label>
    <b-form-input
      id="source_var"
      :value="command.source_var"
      type="text"
      @input="value => $root.$emit('update-scraper', { commandId, prop: 'source_var', value })"
    />
  </base-activity>
</template>

<script>
import BaseActivity from '@/components/WebScraper/SeleniumCommands/BaseCommand.vue';

export default {
  name: 'AddLinkCommand',
  components: { BaseActivity },
  props: {
    scraperId: {
      type: Number,
      required: true,
    },
    commandId: {
      type: String,
      required: true,
    },
    command: {
      type: Object,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
};
</script>
