import GeneralTemplate from '@/js/vuexTemplates/general';
import SharedTemplate from '@/js/vuexTemplates/shared';
import { getModuleProps } from '@/js/utils';

const ticketState = {};

const ticketGetters = {};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(null, 'ticketActivity'),
  new SharedTemplate('activity'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: ticketState, getters: ticketGetters, mutations, actions,
  }, templates),
};
