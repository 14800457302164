<template>
  <div class="w-100 h-100">
    <b-row
      v-if="!localReady"
      class="h-100 align-items-center"
    >
      <b-col class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem;"
        />
      </b-col>
    </b-row>
    <div v-else-if="!deployedRankerInstance" class="r-50 bg-white p-3">
      This search engine does not have deployed version available.
    </div>
    <template v-else>
      <sup-search v-if="localReady" ref="search" v-bind="{ rawConfig, fields }" />
      <b-row v-if="isOverlayMode" class="text-center">
        <b-col>
          <b-button class="mx-auto" variant="primary" size="lg" @click="toggleSearch()">
            Open widget
          </b-button>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
// eslint-disable-next-line import/no-relative-packages
import SupSearch from '../../../widget/src/SupSearch.vue';

export default {
  name: 'Search',
  components: {
    SupSearch,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setAndFetch(to.params.rankerId);
    });
  },
  data() {
    return {
      localReady: false,
    };
  },
  computed: {
    ...mapState('widgetConfig', { widgetDetails: 'details' }),
    ...mapState('ranker', { rankerDetails: 'details' }),
    ...mapGetters('auth', ['headerAuthorization']),
    ...mapGetters('ranker', ['deployedRankerInstance']),
    rawConfig() {
      return {
        ...this.widgetDetails.config,
        endpoint: `https://${window.location.hostname}/`,
        rankerId: this.$route.params.rankerId,
        visible: true,
        postOptions: this.headerAuthorization,
      };
    },
    fields() {
      return this.widgetDetails.fieldOptions;
    },
    isOverlayMode() {
      return this.widgetDetails.config?.widget?.mode?.type === 'overlay';
    },
  },
  methods: {
    ...mapActions('ranker', { fetchRankerDetails: 'fetchItemDetails', getStageableVersions: 'getStageableVersions' }),
    ...mapActions('widgetConfig', { fetchWidgetConfig: 'fetchItemDetails' }),
    toggleSearch() {
      this.$refs.search.toggleSearch(true);
    },
    async setAndFetch(rankerId) {
      // fetch ranker details and instances
      await this.fetchRankerDetails({ id: rankerId });
      await this.getStageableVersions(this.rankerDetails.id);
      await this.fetchWidgetConfig({ id: this.rankerDetails.widgetConfig });
      this.localReady = true;
    },
  },
};
</script>
