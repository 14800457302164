import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import ImportTemplate from '@/js/vuexTemplates/import';
import SharedTemplate from '@/js/vuexTemplates/shared';
import { getModuleProps } from '@/js/utils';

const chatState = {};

const chatGetters = {};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.chat, 'chat'),
  new ImportTemplate(endpoints.chat, 'chat'),
  new SharedTemplate('chat'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: chatState, getters: chatGetters, mutations, actions,
  }, templates),
};
