import axios from 'axios';
import Vue from 'vue';
import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import { getModuleProps } from '@/js/utils';

const fileUploadState = {
  shouldRefresh: {},
};

const fileUploadGetters = {};

const mutations = {
  updateShouldRefresh(state, { dataType, payload }) {
    Vue.set(state.shouldRefresh, dataType, payload);
  },
};

const actions = {
  async uploadFiles({
    dispatch, rootState, rootGetters, commit,
  }, { files, dataType }) {
    const auth = rootGetters['auth/headerAuthorization'];
    const uploadPromises = [];
    const tooLargeFilesNames = [];
    for (const file of files) {
      if (file.size >= (50 * 1024 * 1024)) {
        tooLargeFilesNames.push(file.name);
        continue;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', file.name);
      formData.append('data_source', rootState.dataSource.details.id);
      formData.append('data_type', dataType);
      uploadPromises.push(axios.post(endpoints.fileUpload, formData, auth));
    }
    if (tooLargeFilesNames.length > 0) {
      const msg = `Files over 50Mb are not allowed. Will not upload: ${tooLargeFilesNames.join(', ')}`;
      dispatch('sidebar/showWarning', {
        title: 'Too large file',
        text: msg,
      }, { root: true });
    }
    await Promise.all(uploadPromises);
    commit('updateShouldRefresh', { dataType: 'fileUpload', payload: true });
    await axios.post(`${endpoints.fileUpload}process/`, {}, auth);
  },
  async uploadFilesViaUrls({
    dispatch, rootState, rootGetters, commit,
  }, { urls, dataType }) {
    const auth = rootGetters['auth/headerAuthorization'];
    const uploadPromises = [];
    const invalidFileNames = [];
    for (const url of urls) {
      const parts = url.split('.');
      if (!['pdf', 'doc', 'docx'].includes(parts[parts.length - 1])) {
        invalidFileNames.push(url);
        continue;
      }
      uploadPromises.push(axios.post(`${endpoints.fileUpload}via-url/`, {
        url,
        data_source: rootState.dataSource.details.id,
        data_type: dataType,
      }, auth));
    }
    if (invalidFileNames.length > 0) {
      const msg = `Files must be .pdf, .doc or .docx files. Will not upload: ${invalidFileNames.join(', ')}`;
      dispatch('sidebar/showWarning', {
        title: 'Invalid file types',
        text: msg,
      }, { root: true });
    }
    await Promise.all(uploadPromises);
    commit('updateShouldRefresh', { dataType: 'fileUpload', payload: true });
    await axios.post(`${endpoints.fileUpload}process/`, {}, auth);
  },
};

const templates = [
  new GeneralTemplate(endpoints.fileUpload, 'fileUpload'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: fileUploadState, getters: fileUploadGetters, mutations, actions,
  }, templates),
};
