<template>
  <div>
    <div
      style="max-height: 140px; overflow-y: auto; overflow-x: hidden;"
    >
      <b-row
        v-for="question in questionsToShow"
        :key="question.id"
        no-gutters
      >
        <b-col class="my-auto">
          <li v-if="!editMode">
            {{ question.text }}
          </li>
          <b-form-input
            v-else
            v-model="question.text"
            size="sm"
          />
        </b-col>
        <b-col cols="auto" class="my-auto">
          <b-button
            v-b-tooltip.hover.noninteractive.viewport="'Delete question'"
            class="my-auto action-button"
            variant="text"
            @click="deleteQuestion(question.id)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-row v-if="editMode" class="mt-1">
      <b-col class="text-right">
        <b-button class="mr-2" size="sm" @click="cancelEdit">
          Cancel
        </b-button>
        <b-button variant="primary" size="sm" @click="saveEdit">
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-row v-else class="mt-1">
      <b-col class="text-right" style="min-width:300px;">
        <DebounceButton
          v-if="genAiEnabled"
          :disabled="generateDisabled"
          text="Generate questions"
          class="mr-2"
          size="sm"
          @click="startGenerating"
        />
        <b-button
          :disabled="numberOfQuestions === 0"
          size="sm"
          variant="primary"
          @click="toggleEdit"
        >
          Edit questions ({{ numberOfQuestions }})
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
import endpoints from '@/js/endpoints';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'QuestionBlock',
  components: { },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    articleId: {
      type: Number,
      required: true,
    },
    generateDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editMode: false,
      localCopy: JSON.parse(JSON.stringify(this.questions)),
    };
  },
  computed: {
    ...mapGetters('auth', ['headerAuthorization', 'genAiEnabled']),
    questionsToShow() {
      return this.editMode ? this.localCopy : this.questions;
    },
    numberOfQuestions() {
      return this.questions.length;
    },
  },
  watch: {
  },
  methods: {
    ...mapActions('question', ['generateQuestions']),
    ...mapActions('sidebar', ['showWarning']),
    async deleteQuestion(id) {
      try {
        await axios.delete(`${endpoints.question}${id}/`, this.headerAuthorization);
        const idx = this.localCopy.findIndex((x) => x.id === id);
        if (idx !== null) {
          this.localCopy.splice(idx, 1);
        }
        this.$emit('refresh-questions');
      } catch (error) {
        this.showWarning({
          title: 'Failed to delete question',
          text: error.message,
        });
      }
    },
    toggleEdit() {
      if (!this.editMode) {
        this.localCopy = JSON.parse(JSON.stringify(this.questions));
      }
      this.editMode = !this.editMode;
    },
    async saveEdit() {
      try {
        const payload = this.localCopy;
        await axios.patch(`${endpoints.question}bulk-update/`, payload, this.headerAuthorization);
        this.$emit('refresh-questions');
        this.editMode = false;
      } catch (error) {
        this.showWarning({
          title: 'Failed to update questions',
          text: error.message,
        });
      }
    },
    cancelEdit() {
      this.$emit('refresh-questions');
      this.editMode = false;
    },
    startGenerating() {
      this.generateQuestions({ articleId: this.articleId, questionsPerArticle: 5 });
    },
  },
};
</script>
<style scoped>
.action-button {
  border: 0;
  color: grey;
}
.action-button:hover{
  color:black;
}
</style>
