import endpoints from '@/js/endpoints';
import { pipelineTypes } from '@/js/constants';
import GeneralTemplate from '@/js/vuexTemplates/general';
import Shared from '@/js/vuexTemplates/shared';
import { getModuleProps } from '@/js/utils';

const pipelineState = {};

const pipelineGetters = {
  isArticlePrediction: () => (type) => type === pipelineTypes.ARTICLE_PREDICTION,
  pipelineIdToName: (state) => (id) => {
    if (!(id in state.items)) {
      return 'Pipeline not found';
    }
    return state.items[id].name;
  },
};

const mutations = {
  setIsBuildingPipeline(state, value) {
    state.isBuildingPipeline = value;
  },
};

const actions = {
  buildPipeline({ dispatch }, pipeline) {
    const endpoint = `${endpoints.pipeline}${pipeline.id}/build/`;
    dispatch('postTask', {
      endpoint,
      data: {},
      operationType: 'build',
    }, { root: true });
  },
};

const templates = [
  new GeneralTemplate(endpoints.pipeline, 'pipeline'),
  new Shared('pipeline'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: pipelineState, getters: pipelineGetters, mutations, actions,
  }, templates),
};
