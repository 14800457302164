import axios from 'axios';
import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import { objToCamel, getModuleProps } from '@/js/utils';

const rankerInstanceState = {
  isUploading: false,
};

const rankerInstanceGetters = {

};

const mutations = {
  setIsUploading(state, value) {
    state.isUploading = value;
  },
};

const actions = {
  async refreshRankerInstance({
    state, commit, dispatch, rootGetters,
  }) {
    try {
      const auth = rootGetters['auth/headerAuthorization'];
      const { data } = await axios.get(`${endpoints.rankerInstance}${state.details.id}/`, auth);
      commit('setItemDetails', objToCamel(data));
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to update ranker instance',
        text: error.message,
      }, { root: true });
      throw error;
    }
  },
};

const templates = [
  new GeneralTemplate(endpoints.rankerInstance, 'rankerInstance'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: rankerInstanceState, getters: rankerInstanceGetters, mutations, actions,
  }, templates),
};
