<template>
  <b-card
    title="Configuration"
    class="r-75"
    body-class="p-3"
    :class="unsavedChanges ? 'border-warning' : ''"
  >
    <b-form
      class="my-3"
      @submit.prevent
      @input="$v.localData.$touch()"
    >
      <template v-for="obj in fields">
        <edit-key-value
          v-if="(obj.key !== 'syncFrom' || localData.syncAutomatically) && (obj.key !== 'summarizeUsingGenAi' || localData.summarizeArticles)"
          :key="obj.key"
          :class="obj.key === 'botIds' && botIdsChanged ? 'mt-3' : 'my-3' "
          :key-prop="obj.label"
          :description="obj.description"
          :value-prop="localData[obj.key]"
          :options="obj.key === 'language' ? languageOptions : obj.type === 'select' ? obj.options : []"
          :state="getValidationState(obj.key)"
          :min-key-width="keyWidth"
          :disabled="obj.key === 'ranker'"
          :type="obj.type"
          @input="(x) => setNewValue(obj.key, x)"
        >
          <template #feedback>
            <b-form-invalid-feedback>
              {{ getInvalidFeedback(obj.key) }}
            </b-form-invalid-feedback>
          </template>
          <template
            v-if="obj.key === 'botIds'"
            #inputcontent
          >
            <b-list-group class="w-100 urls-list r-25-right border">
              <string-list
                class="ids-list"
                fixed-input
                :validate="false"
                placeholder=""
                :strings="localData[obj.key]"
                @change="updateBotIds"
              />
            </b-list-group>
          </template>
          <template
            v-else-if="obj.key === 'excludeDirectories'"
            #inputcontent
          >
            <b-list-group class="w-100 urls-list r-25-right border">
              <string-list
                class="exclude-directores-list"
                fixed-input
                :validate="false"
                placeholder=""
                :strings="localData[obj.key]"
                @change="updateExcludeDirectories"
              />
            </b-list-group>
          </template>
          <template
            v-else-if="obj.key === 'fieldsToSync'"
            #inputcontent
          >
            <b-overlay
              v-if="isTicketAnalyzerDataSource || isServiceNowDataSource"
              :show="isLoadingSyncFields"
              rounded="sm"
            >
              <div v-if="isTicketAnalyzerDataSource" class="mb-0 px-1 border r-25-right">
                <chip-list
                  v-if="syncFieldCompletions.length"
                  :draggable="false"
                  :value="activeSyncFieldValues"
                  :completions="syncFieldCompletions"
                  @input="(x) => activeSyncFieldValues = x"
                />
                <b-form-input
                  v-else
                  class="my-1"
                  placeholder="There are no fields to choose from"
                  disabled
                />
              </div>
              <div v-else-if="isServiceNowDataSource" class="mb-0 px-1 border r-25-right">
                <chip-list
                  v-if="syncFieldCompletions.length"
                  :draggable="false"
                  :value="activeServiceNowSyncFieldValues"
                  :completions="syncFieldCompletions"
                  @input="(x) => activeServiceNowSyncFieldValues = x"
                  @click="openServiceNowFieldModal"
                />
                <b-form-input
                  v-else
                  class="my-1"
                  placeholder="There are no fields to choose from"
                  disabled
                />
                <b-modal
                  id="serviceNowFieldConfig"
                  ref="serviceNowFieldConfigModal"
                  :title="`Extra field to sync: ${currentSyncField.key || ''}`"
                  @ok="okServiceNowFieldModal"
                >
                  <b-form-group
                    label="Tag display name"
                    label-for="fieldConfigDisplay"
                    :description="`Leave blank to use '${currentSyncField.key || ''}' as display name`"
                  >
                    <b-form-input
                      id="fieldConfigDisplay"
                      v-model="currentSyncField.display"
                      switch
                    />
                  </b-form-group>
                  <b-form-group
                    id="field-config-m2m"
                    label="Multiple value field"
                    label-for="fieldConfigM2M"
                    description="If enabled values will be split on comma"
                  >
                    <b-form-checkbox
                      id="fieldConfigM2M"
                      v-model="currentSyncField.m2m"
                      switch
                    />
                  </b-form-group>
                </b-modal>
              </div>
            </b-overlay>
          </template>
          <template
            v-else-if="obj.key === 'knowledgeBaseParams'"
            #inputcontent
          >
            <b-form-tags id="knowledgeParamTag" v-model="activeServiceNowParams" placeholder="Add parameters..." />
          </template>
        </edit-key-value>
        <small
          v-if="botIdsChanged && obj.key === 'botIds'"
          :key="obj.key + 'resync-warning'"
          class="text-warning"
          :style="`margin-left:${keyWidth}px`"
        >
          Bot IDs are only applied when syncing chats.
          To apply these changes to existing data, please sync chats again.
        </small>
      </template>
      <b-button
        v-if="isMicrosoftDataSource && localData.useDelegatedAccess"
        v-b-tooltip.hover.noninteractive="'Log in to MicroSoft to give SupSearch access to read OneNotes that the signed in user has access to.'"
        variant="secondary"
        class="mb-3"
        @click="handleDelegated"
      >
        Activate delegated access
      </b-button>
      <b-link
        v-if="isMicrosoftDataSource"
        v-b-modal.onenote-documentation
      >
        <h6>
          Click here to see connection documentation
        </h6>
      </b-link>
      <onenote-documentation />
      <WebScraperConfig
        v-if="localData.type === dataSourceTypes.WEBSCRAPER.value"
        :local-data="localData"
        :unsaved-changes="unsavedChanges"
        @update="updateFromScraper"
      />
    </b-form>

    <div>
      <b-button
        variant="primary"
        class="mr-2"
        :disabled="invalidData || !unsavedChanges"
        :style="`width: ${keyWidth}px;`"
        @click="updateDataSourceAndReset(localData)"
      >
        Update Data Source
      </b-button>
      <test-connection-button
        v-if="showTestConnection"
        class="mr-2"
      />
      <b-button
        variant="danger"
        @click="deleteDataSourceLocal"
      >
        Delete
      </b-button>
    </div>

    <span
      v-if="unsavedChanges"
      class="unsaved-text"
    >
      *Unsaved changes
    </span>
  </b-card>
</template>
<script>

import axios from 'axios';
import update from 'immutability-helper';
import { mapActions, mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { maxLength, required } from 'vuelidate/lib/validators';
import { cloneDeep, isEqual } from 'lodash';
import requiredIf from 'vuelidate/lib/validators/requiredIf';
import ChipList from 'supwiz/components/ChipList.vue';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import StringList from 'supwiz/components/StringList.vue';
import { dataSourceTypeToEndpoint, dataSourceTypes } from '@/js/constants';
import WebScraperConfig from '@/components/WebScraper/Config.vue';
import extraDataSourceFields from '@/js/extraDataSourceFields';
import OnenoteDocumentation from '@/components/DataSource/OnenoteDocumentation.vue';
import TestConnectionButton from '@/components/DataSource/TestConnectionButton.vue';
import { SITEMAP_URL_TYPES } from '@/js/selenium_constants';

export default {
  name: 'DataSourceConfig',
  components: {
    StringList,
    WebScraperConfig,
    EditKeyValue,
    ChipList,
    OnenoteDocumentation,
    TestConnectionButton,
  },
  mixins: [validationMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.languageOptions.length) {
        vm.fetchLanguages();
      }
    });
  },
  data() {
    return {
      localData: {},
      dataSourceTypes,
      commonFields: [
        {
          key: 'name',
          label: 'Name',
          type: 'input',
        },
        {
          key: 'description',
          label: 'Description',
          type: 'textarea',
        },
        {
          key: 'language',
          label: 'Language',
          type: 'select',
        },
        {
          key: 'sourceDisplay',
          label: 'Source display',
          type: 'input',
          description: 'Text from this field will be displayed in the widget as source/origin of an article (leave empty to not display anything)',
        },
        {
          key: 'summarizeArticles',
          label: 'Article Summarization',
          description: 'Should the articles be summarized',
          type: 'checkbox',
        },
        {
          key: 'summarizeUsingGenAi',
          label: 'GenAI summarization',
          description: 'Should the articles be summarized using generative AI',
          type: 'checkbox',
        },
      ],
      extraDataSourceFields,
      otherDataSourceNames: [],
      localScrapingData: null,
      isLoadingSyncFields: true,
      syncFields: [],
      currentSyncField: this.newSyncField(),
      currentSyncParam: this.newSyncParam(),
      manualUnsavedChanges: false,
    };
  },
  computed: {
    ...mapState('dataSource', { dataSources: 'items' }),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    ...mapState('ranker', { rankers: 'items' }),
    ...mapGetters('language', ['languageOptions']),
    ...mapGetters('auth', ['genAiEnabled']),
    keyWidth() {
      return 230;
    },
    showTestConnection() {
      return ![dataSourceTypes.WEBSCRAPER.value, dataSourceTypes.UPLOAD.value, '']
        .includes(this.localData.type);
    },
    fields() {
      let out = this.commonFields.concat(this.extraDataSourceFields[this.dataSourceDetails.type]);
      if (!this.$store.getters['auth/genAiEnabled']) {
        out = out.filter((f) => f.key !== 'summarizeUsingGenAi');
      }
      if (this.dataSourceDetails.type === 'servicenowdatasource' && this.localData.useAttachments === false) {
        out = out.filter((f) => f.key !== 'splitFilesAutomatically');
      }
      return out;
    },
    unsavedChanges() {
      if (this.manualUnsavedChanges) {
        return true;
      }
      const localDataCopy = cloneDeep(this.localData);
      const detailsCopy = cloneDeep(this.dataSourceDetails);
      const scrapingDataCopy = cloneDeep(this.localScrapingData);
      let scrapingChanges = false;
      if (this.localData.type === dataSourceTypes.WEBSCRAPER.value) {
        scrapingChanges = !isEqual(scrapingDataCopy, this.dataSourceDetails.definition);
      }

      let tokenChanges = false;
      if (localDataCopy.token !== undefined) {
        tokenChanges = localDataCopy.token.length > 0;
        delete localDataCopy.token;
      }
      let passwordChanges = false;
      if (localDataCopy.password !== undefined) {
        passwordChanges = localDataCopy.password.length > 0;
        delete localDataCopy.password;
      }
      let secretChanges = false;
      if (localDataCopy.clientSecret !== undefined) {
        secretChanges = localDataCopy.clientSecret.length > 0;
        delete localDataCopy.clientSecret;
      }
      return !isEqual(localDataCopy, detailsCopy) || secretChanges
       || tokenChanges || passwordChanges || scrapingChanges;
    },
    invalidData() {
      if (this.dataSourceDetails.type === dataSourceTypes.ZENDESK.value
      || this.dataSourceDetails.type === dataSourceTypes.BOMGAR.value) {
        if (!this.$v.localData.$invalid && this.botIdsChanged) {
          return false;
        }
        return this.$v.localData.$invalid;
      } if (this.dataSourceDetails.type === dataSourceTypes.UPLOAD.value
      || this.dataSourceDetails.type === dataSourceTypes.RANKER.value
      || this.dataSourceDetails.type === dataSourceTypes.WEBSCRAPER.value
      ) {
        return this.$v.localData.name.$invalid;
      }
      return this.$v.localData.$invalid;
    },
    syncFieldCompletions() {
      return Object.entries(this.syncFields).map(([key, value]) => ({ key, value }));
    },
    activeSyncFieldValues: {
      get() {
        return this.localData.fieldsToSync.map((e) => Object.keys(e)[0]);
      },
      set(values) {
        this.localData.fieldsToSync = values.map((id) => {
          const keyValuePair = {};
          keyValuePair[id] = this.syncFields[id];
          return keyValuePair;
        });
      },
    },
    activeServiceNowSyncFieldValues: {
      get() {
        return Object.keys(this.localData.fieldsToSync);
      },
      set(values) {
        const oldFieldToSync = cloneDeep(this.localData.fieldsToSync);
        this.localData.fieldsToSync = values.reduce((acc, element) => {
          // eslint-disable-next-line no-param-reassign
          acc[element] = oldFieldToSync[element] || {};
          return acc;
        }, {});
      },
    },
    activeServiceNowParams: {
      get() {
        return Object.keys(this.localData.knowledgeBaseParams).map((key) => `${key}=${this.localData.knowledgeBaseParams[key]}`);
      },
      set(values) {
        const newParams = {};
        for (const value of values) {
          const parts = value.split('=');
          if (parts.length >= 2) {
            // const key = parts.shift();
            newParams[parts.shift()] = parts.join('=');
          }
        }
        this.localData.knowledgeBaseParams = newParams;
      },
    },
    botIdsChanged() {
      return !isEqual(this.localData.botIds, this.dataSourceDetails.botIds);
    },
    isTicketAnalyzerDataSource() {
      return this.dataSourceDetails.type === dataSourceTypes.TICKETANALYZER.value;
    },
    isServiceNowDataSource() {
      return this.dataSourceDetails.type === dataSourceTypes.SERVICENOW.value;
    },
    isMicrosoftDataSource() {
      return [dataSourceTypes.ONENOTE.value, dataSourceTypes.SHAREPOINT.value]
        .includes(this.dataSourceDetails.type);
    },
  },
  created() {
    this.$root.$on('add-command', (data) => this.addCommand(data));
    this.$root.$on('clone-command', (data) => this.cloneCommand(data));
    this.$root.$on('delete-command', (data) => this.deleteCommand(data));
    this.$root.$on('update-scraper', (data) => {
      Vue.set(this.localData.definition.commands[data.commandId], data.prop, data.value);
    });
    this.fetchSyncFields();
    this.localData = { ...this.dataSourceDetails };
    if (this.localData.type === dataSourceTypes.WEBSCRAPER.value) {
      this.localScrapingData = cloneDeep(this.localData.definition);
    }
    this.otherDataSourceNames = Object.values(this.dataSources)
      .filter((d) => d.name !== this.localData.name);
  },
  methods: {
    ...mapActions('language', { fetchLanguages: 'fetchItems' }),
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('dataSource', { updateDataSource: 'patchItem' }),
    ...mapActions('dataSource', { deleteDataSource: 'deleteItem' }),
    updateDataSourceAndReset(data) {
      this.manualUnsavedChanges = false;
      this.updateDataSource(data);
    },
    newSyncField() {
      return {
        display: '',
        m2m: false,
      };
    },
    newSyncParam() {
      return {
        value: '',
      };
    },
    addCommand(data) {
      this.localData.definition.commandIds.push(data.cid);
      Vue.set(this.localData.definition.commands, data.cid, data.command);
    },
    cloneCommand(data) {
      this.localData.definition.commandIds.splice(data.index + 1, 0, data.cloned.id);
      Vue.set(this.localData.definition.commands, data.cid, data.cloned);
    },
    deleteCommand(data) {
      this.localData.definition.commandIds.splice(data.index, 1);
      Vue.delete(this.localData.definition.commands, data.deleteId);
    },
    updateFromScraper(data) {
      if (['commands', 'commandIds'].includes(data.prop)) {
        Vue.set(this.localData.definition, data.prop, data.value);
      } else {
        Vue.set(this.localData, data.prop, data.value);
      }
    },
    getValidationState(key) {
      if (key === 'description') {
        return null;
      }
      if (this.$v.localData[key]) {
        return !this.$v.localData[key].$invalid;
      } return null;
    },
    getInvalidFeedback(key) {
      if (this.$v.localData[key]) {
        if (!this.$v.localData[key].patternIsValid) {
          return 'Pattern must contain "{id}"';
        }
        if (!this.$v.localData[key].required) {
          return 'This field cannot be empty';
        }
        if (!this.$v.localData[key].maxLength) {
          return 'The name is too long';
        }
        if (!this.$v.localData[key].isUnique) {
          return 'The name is already taken';
        }
      }
      return '';
    },
    setNewValue(key, value) {
      Vue.set(this.localData, key, value);
      if (key === 'mode' && value === 'api') {
        this.setNewValue('crawlingRule', SITEMAP_URL_TYPES.SITEMAP);
      }
    },
    async deleteDataSourceLocal() {
      const modalText = `Are you sure that you want to delete ${this.dataSourceDetails.name}?`;
      const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        const ok = await this.deleteDataSource({ item: this.dataSourceDetails });
        if (ok) {
          this.$router.push({ name: 'data-source-overview' });
        }
      }
    },
    updateBotIds(changeObj) {
      Object.assign(this.localData, update(this.localData, { botIds: changeObj }));
    },
    updateExcludeDirectories(changeObj) {
      Object.assign(this.localData, update(this.localData, { excludeDirectories: changeObj }));
    },
    async handleDelegated() {
      const endpoint = dataSourceTypeToEndpoint[this.dataSourceDetails.type];
      try {
        const request = {
          ...this.$store.getters['auth/headerAuthorization'],
        };
        const resp = await axios.get(`${endpoint}${this.dataSourceDetails.id}/delegated_access/`, request);
        window.open(resp.data, '_blank');
      } catch (error) {
        this.showWarning({ title: 'Failed to delegated access', text: error.message });
      }
    },
    async fetchSyncFields() {
      let display = '';
      if (this.dataSourceDetails.type === dataSourceTypes.TICKETANALYZER.value) {
        display = dataSourceTypes.TICKETANALYZER.text;
      } else if (this.dataSourceDetails.type === dataSourceTypes.SERVICENOW.value) {
        display = dataSourceTypes.SERVICENOW.text;
      } else {
        this.isLoadingSyncFields = false;
        return;
      }
      const endpoint = dataSourceTypeToEndpoint[this.dataSourceDetails.type];
      try {
        const request = {
          ...this.$store.getters['auth/headerAuthorization'],
        };
        const resp = await axios.get(`${endpoint}${this.dataSourceDetails.id}/get_field_options/`, request);
        this.syncFields = resp.data;
        this.isLoadingSyncFields = false;
      } catch (error) {
        this.isLoadingSyncFields = false;
        const errorMessage = error.response.status === 418 ? error.response.data : error.message;
        this.showWarning({ title: `Failed to load ${display} fields.`, text: errorMessage });
      }
    },
    openServiceNowFieldModal(value) {
      this.currentSyncField = cloneDeep(this.localData.fieldsToSync)[value];
      this.currentSyncField.key = value;
      this.$bvModal.show('serviceNowFieldConfig');
    },
    okServiceNowFieldModal() {
      if (this.currentSyncField.key != null) {
        this.manualUnsavedChanges = !isEqual(
          this.localData.fieldsToSync[this.currentSyncField.key],
          cloneDeep(this.currentSyncField),
        );
        this.localData.fieldsToSync[this.currentSyncField.key] = cloneDeep(this.currentSyncField);
      }
    },
  },
  validations: {
    localData: {
      name: {
        required,
        maxLength: maxLength(120),
        isUnique(value) {
          return !this.otherDataSourceNames.map(
            (dataSource) => dataSource.name,
          ).includes(value);
        },
      },
      description: {},
      endpointUrl: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return ![
            dataSourceTypes.ONENOTE.value,
            dataSourceTypes.PUZZEL.value,
            dataSourceTypes.SHAREPOINT.value,
          ].includes(this.dataSourceDetails.type);
        }),
      },
      articleLinkPattern: {
        patternIsValid(value) {
          if (value != null && value.trim() !== '') {
            return value.includes('{id}');
          }
          return true;
        },
      },
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu{
  background-color: #fff !important;
}
.ids-list{
  max-height: 195px;
  overflow-y: auto;
}
</style>
