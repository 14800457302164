<template>
  <b-row
    v-if="isFetching"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <router-view v-else />
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  name: 'DataSourceBase',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchDataSources();
      vm.fetchRankers();
      vm.localIsFetching = false; // eslint-disable-line no-param-reassign
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'data-source-overview') {
      this.fetchDataSources();
    }
    next();
  },
  data() {
    return {
      localIsFetching: true,
    };
  },
  computed: {
    ...mapState('dataSource', { isFetchingDataSources: 'isFetching' }),
    ...mapState('ranker', { isFetchingRankers: 'isFetching' }),
    isFetching() {
      return this.isFetchingDataSources || this.isFetchingRankers || this.localIsFetching;
    },
  },
  methods: {
    ...mapActions('dataSource', { fetchDataSources: 'fetchItems' }),
    ...mapActions('ranker', { fetchRankers: 'fetchItems' }),
  },

};
</script>
