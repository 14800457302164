import axios from 'axios';
import endpoints from '@/js/endpoints';

const docState = {
  swaggerFile: null,
};

const getters = {
  swaggerFile: (state) => state.swaggerFile,
};

const mutations = {
  setSwaggerFile(state, newSwaggerFile) {
    state.swaggerFile = newSwaggerFile;
  },
};

const actions = {
  async ensureSwaggerFile({ commit, rootGetters }) {
    const { data } = await axios.get(endpoints.swaggerFile, rootGetters['auth/headerAuthorization']);
    commit('setSwaggerFile', data);
  },
};

export default {
  namespaced: true,
  state: docState,
  getters,
  mutations,
  actions,
};
