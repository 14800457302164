<template>
  <div>
    <edit-key-value
      class="mb-3"
      key-prop="Data source"
      autofocus
      description="The data source to get the data from"
      :value-prop="newPipelineSource.dataSource"
      :min-key-width="keyWidth"
      :options="filteredDataSourceOptions"
      type="select"
      :state="$v.newPipelineSource.dataSource.$invalid ? false : null"
      @input="(x) => updateDataSourceInput(x)"
    >
      <template #feedback>
        <b-form-invalid-feedback
          v-if="!$v.newPipelineSource.dataSource.required"
        >
          A data source is required.
        </b-form-invalid-feedback>
      </template>
    </edit-key-value>

    <b-collapse v-if="type !== 'article'" :visible="!!newPipelineSource.dataSource" class="my-3">
      <edit-key-value
        key-prop="Types"
        description="Choose data source with articles to rank"
        :value-prop="null"
        :min-key-width="keyWidth"
        type=""
        :state="!$v.newPipelineSource.type.$invalid"
      >
        <template #inputcontent>
          <div class="mb-0 p-1 border r-25-right">
            <chip-list
              :draggable="false"
              :value="newPipelineSource.type"
              :completions="filteredDataTypeOptions"
              @input="(x) => newPipelineSource.type = x"
            />
          </div>
        </template>
        <template #feedback>
          <b-form-invalid-feedback>
            This field cannot be empty
          </b-form-invalid-feedback>
        </template>
      </edit-key-value>
    </b-collapse>
    <b-collapse :visible="hasTicketType" class="border p-2 r-25">
      <div
        class="d-block bg-secondary text-center py-1 r-25 mb-3"
        :style="`width: ${keyWidth - 8}px;`"
      >
        Ticket fields
      </div>
      <edit-key-value
        class="mb-3"
        key-prop="Input specification"
        description="The text that the ranker should use as input for training"
        :value-prop="ticketTypeData.inputSpec"
        :min-key-width="keyWidth - 8"
        :options="Object.values(ticketInputSpecs)"
        :state="!$v.ticketTypeData.inputSpec.$invalid"
        type="select"
        @input="(x) => ticketTypeData.inputSpec = x"
      >
        <template #feedback>
          <b-form-invalid-feedback>
            Input specification is required.
          </b-form-invalid-feedback>
        </template>
      </edit-key-value>
      <edit-key-value
        v-if="ticketTypeData.inputSpec === ticketInputSpecs.CHAT"
        class="my-3"
        key-prop="Related chat data source"
        description="The chat data source containing the chats related to the tickets"
        :value-prop="ticketTypeData.relatedChatDataSource"
        :min-key-width="keyWidth - 8"
        :options="dataSourceOptions"
        type="select"
        @input="(x) => ticketTypeData.relatedChatDataSource = x"
      />
      <edit-key-value
        v-if="ticketTypeData.inputSpec === ticketInputSpecs.CHAT"
        class="my-3"
        key-prop="Chat identification"
        description="A regular expression with a single capturing group that will be used to
            search for the external id of a chat in the ticket fields"
        :value-prop="ticketTypeData.chatRegex"
        :min-key-width="keyWidth - 8"
        type="input"
        @input="(x) => ticketTypeData.chatRegex = x"
      />
      <edit-key-value
        class="mt-3"
        key-prop="Tag pointing to article"
        description="The field on the ticket that points to the external id of the article"
        :value-prop="ticketTypeData.articleField"
        :min-key-width="keyWidth - 8"
        :options="ticketFieldOptions"
        type="select"
        @input="(x) => ticketTypeData.articleField = x"
      />
    </b-collapse>
    <div class="text-right">
      <b-button
        :disabled="invalidData"
        variant="primary"
        class="px-3 mt-3"
        @click="createPipelineSource()"
      >
        Add {{ type === 'article' ? 'article' : '' }} data
      </b-button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapState, mapActions, mapGetters } from 'vuex';
import ChipList from 'supwiz/components/ChipList.vue';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import { validationMixin } from 'vuelidate';
import dataSourceSpecs from '@/js/dataSourceSpecs';
import { pipelineSourceTypes, DataTypes, ticketInputSpecs } from '@/js/constants';

export default {
  name: 'TrainingDataForm',
  components: { EditKeyValue, ChipList },
  mixins: [validationMixin],
  props: {
    type: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      ticketInputSpecs,
      pipelineSourceTypes,
      newPipelineSource: {
        type: [],
        dataSource: null,
      },
      ticketTypeData: {
        inputSpec: null,
        relatedChatDataSource: null,
        chatRegex: '',
        articleField: null,
      },
    };
  },
  computed: {
    ...mapState('dataSource', { dataSources: 'items' }),
    ...mapState('pipelineSource', { pipelineSources: 'items' }),
    ...mapGetters('dataSource', { dataSourceOptions: 'itemsOptions' }),
    ...mapGetters('fieldValue', ['getCompletions']),
    ...mapGetters('fieldValue', { fieldValues: 'items' }),
    ...mapState('ranker', { rankerDetails: 'details' }),
    ...mapGetters('field', ['fieldOptions']),
    keyWidth() {
      return 210;
    },
    hasTicketType() {
      return this.newPipelineSource.type.includes(pipelineSourceTypes.TICKET.value);
    },
    ticketFieldOptions() {
      const options = this.fieldOptions(null, 'ticket');
      return options;
    },
    filteredDataTypeOptions() {
      if (this.newPipelineSource.dataSource === null) {
        return [];
      }
      const dataSource = this.dataSources[this.newPipelineSource.dataSource];
      const dataSourceSpec = dataSourceSpecs[dataSource.type].map((e) => e.toUpperCase())
        .filter((e) => pipelineSourceTypes[e] && e !== DataTypes.ARTICLE.toUpperCase());

      return dataSourceSpec
        .map((e) => ({
          key: pipelineSourceTypes[e].value,
          value: pipelineSourceTypes[e].text,
        }));
    },
    filteredDataSourceOptions() {
      const availableOptions = this.type === DataTypes.ARTICLE ? [this.type] : ['chat', 'ticket', 'query'];
      return this.dataSourceOptions.filter((e) => {
        const dataSourceSpec = dataSourceSpecs[this.dataSources[e.value].type];
        if (this.type === DataTypes.ARTICLE) {
          if ((!dataSourceSpec.includes(this.type))) {
            return false;
          }
          return true;
        }
        return dataSourceSpec.filter((spec) => {
          const pipelineSourceType = Object.values(pipelineSourceTypes)
            .find((type) => type.text.toLowerCase() === spec);
          return pipelineSourceType;
        }).some((item) => availableOptions.includes(item));
      }).sort((a, b) => a.text.localeCompare(b.text));
    },
    invalidData() {
      return this.$v.newPipelineSource.$invalid
      || (this.hasTicketType && this.$v.ticketTypeData.$invalid);
    },
    dataToSend() {
      const data = [];
      this.newPipelineSource.type.forEach((type) => {
        let item = {
          pipeline: this.rankerDetails.pipeline.id,
          type,
          data_source: this.newPipelineSource.dataSource,
          include_filters: [],
          exclude_filters: [],
        };
        if (type === pipelineSourceTypes.ARTICLE.value) {
          item.text_filters = [];
        }
        if (type === pipelineSourceTypes.TICKET.value) {
          item = {
            ...item,
            input_spec: this.ticketTypeData.inputSpec,
            related_chat_data_source: this.ticketTypeData.relatedChatDataSource,
            article_field: this.ticketTypeData.articleField,
            chat_regex: this.ticketTypeData.chatRegex,
          };
        }
        data.push(item);
      });
      return data;
    },
    completions() {
      if (this.newPipelineSource.type) {
        const dataType = this.newPipelineSource.type.slice(0, -14);
        return this.getCompletions(this.newPipelineSource.dataSource, dataType);
      }
      return [];
    },
  },
  watch: {
    hasTicketType(n) {
      if (!n) {
        this.resetTicketFields();
      }
    },
  },
  mounted() {
    if (this.type === DataTypes.ARTICLE) {
      this.newPipelineSource.type.push(pipelineSourceTypes.ARTICLE.value);
    }
  },
  methods: {
    ...mapActions('pipelineSource', { addPipelineSource: 'addItem' }),
    ...mapActions('ranker', { fetchRankerDetails: 'fetchItemDetails' }),
    updateDataSourceInput(value) {
      this.newPipelineSource.dataSource = value;
      if (!this.newPipelineSource.type.includes(pipelineSourceTypes.ARTICLE.value)) {
        this.newPipelineSource.type = [];
      }
    },
    resetTicketFields() {
      this.ticketTypeData = {
        inputSpec: null,
        relatedChatDataSource: null,
        chatRegex: '',
        articleField: null,
      };
    },
    async createPipelineSource() {
      if (await this.addPipelineSource(
        { newItem: this.dataToSend, fetchParams: { pipeline: this.rankerDetails.pipeline.id } },
      )) {
        this.$nextTick(() => {
          this.fetchRankerDetails({ id: this.rankerDetails.id });
          this.$bvModal.hide('training-data-form-modal');
        });
      }
    },
    openFieldValue(id) {
      this.$router.push({
        name: 'tag-values-single',
        params: {
          dataSourceId: this.newPipelineSource.dataSource,
          fieldId: this.fieldValues[id].field,
          fieldValueId: id,
        },
      });
    },
  },
  validations() {
    return {
      newPipelineSource: {
        dataSource: {
          required,
        },
        type: {
          isEmpty(value) {
            return value.length !== 0;
          },
        },
      },
      ticketTypeData: {
        inputSpec: {
          required,
        },
      },
    };
  },
};
</script>
