<template>
  <div>
    <b-dropdown
      ref="dropdown"
      dropleft
      no-caret
      variant="primary"
      menu-class="bg-white"
      @show="setValuesOnShow"
    >
      <template #button-content>
        <font-awesome-icon icon="cog" />
        <span class="sr-only">Configure results</span>
      </template>
      <b-dropdown-item-button
        @click.stop="showStopWordsModal"
      >
        Show ignored words
      </b-dropdown-item-button>
      <b-dropdown-divider />
      <b-dropdown-form
        form-class="px-2 mb-0"
        @submit.stop.prevent="recomputeStats"
      >
        <b-form-group
          label="Number of topics"
          class="mb-0"
        >
          <b-form-input
            v-model.number="newTopicCount"
            v-b-tooltip.noninteractive.viewport="newTopicCount === null
              ? 'Select number of topics' : newTopicCount"
            min="1"
            step="1"
            :max="maxTopics"
            type="range"
            @click.stop
          />
        </b-form-group>
        <b-form-group
          label="Specificity"
          title="A high specificity favors selection of top keywords that are unique to the topic."
          class="mb-0"
        >
          <b-form-input
            v-model.number="newSpecificityValue"
            v-b-tooltip.noninteractive.viewport="newSpecificityValue === null
              ? 'Select specificity' : newSpecificityValue"
            min="0"
            step="0.01"
            max="1"
            type="range"
            @click.stop
          />
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
          size="sm"
          @click.stop
        >
          Update
        </b-button>
      </b-dropdown-form>
    </b-dropdown>
    <stop-words-modal
      ref="stopWordsModal"
      @trigger-recompute="recomputeStats"
    />
  </div>
</template>

<script>
import StopWordsModal from './StopWordsModal.vue';

export default {
  name: 'ConfigurationDropdown',
  components: {
    StopWordsModal,
  },
  props: {
    metric: {
      type: String,
      required: true,
    },
    specifity: {
      type: Number,
      required: true,
    },
    maxTopics: {
      type: Number,
      required: true,
    },
    numberOfTopics: {
      validator: (val) => val === null || typeof val === 'number',
      required: true,
    },
  },
  emits: ['set-topics-count', 'set-specifity'],
  data() {
    return {
      newTopicCount: null,
      newSpecificityValue: null,
    };
  },
  methods: {
    showStopWordsModal() {
      this.$refs.stopWordsModal.displayModal();
    },
    setValuesOnShow() {
      this.newTopicCount = this.numberOfTopics ?? this.maxTopics;
      this.newSpecificityValue = this.specifity;
    },
    recomputeStats() {
      if (![0, null].includes(this.newTopicCount)
        && this.newTopicCount !== this.numberOfTopics) {
        this.$emit('set-topics-count', this.newTopicCount);
      }
      if (this.newSpecificityValue !== this.specifity) {
        this.$emit('set-specifity', this.newSpecificityValue);
      }
      this.$refs.dropdown.hide();
    },
  },
};
</script>
