<template>
  <base-activity
    ref="baseActivity"
    :scraper-id="scraperId"
    :command-id="commandId"
    :indent="indent"
    bs-variant="dark"
    v-on="$listeners"
  >
    <template #icon>
      <font-awesome-icon icon="tower-broadcast" />
    </template>
    <template #header>
      {{ headerArgs }}
    </template>
    <b-row>
      <b-col cols="3">
        <b-form-group
          label="Variable name"
          description="The response from the API call is saved to this variable"
        >
          <b-form-input
            :value="command.target_var"
            type="text"
            @input="value => $root.$emit('update-scraper', { commandId, prop: 'target_var', value })"
          />
        </b-form-group>
      </b-col>
      <b-col cols="8">
        <b-form-group
          label="Url"
          description="The url to call (botscript enabled)"
        >
          <b-form-input
            :value="command.source_var"
            type="text"
            @input="value => $root.$emit('update-scraper', { commandId, prop: 'source_var', value })"
          />
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-form-group
          label="Cache"
        >
          <b-form-checkbox
            v-model="useCache"
            v-b-tooltip.hover.noninteractive.viewport="'Caching the results may save API calls'"
            switch
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6>
          This action supports botscript.
          <b-link v-b-modal.botscript-documentation-modal>
            Click here to see botscript documentation
          </b-link>
        </h6>
      </b-col>
    </b-row>
    <b-form-group
      label="Parameters"
      description="Add query parameters to the API call"
    >
      <b-table
        :fields="fields"
        :items="command.params"
        small
        show-empty
      >
        <template #cell(key)="data">
          <b-form-input
            :value="data.item.key"
            type="text"
            @input="value => updateParams(data.index, 'key', value)"
          />
        </template>
        <template #cell(value)="data">
          <b-form-input
            :value="data.item.value"
            type="text"
            @input="value => updateParams(data.index, 'value', value)"
          />
        </template>
        <template #cell(item_actions)="data">
          <b-button
            variant="danger"
            @click="deleteParam(data.index)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
      </b-table>
      <b-button
        variant="primary"
        class="mb-2"
        @click="createParam"
      >
        <font-awesome-icon icon="plus" />
      </b-button>
    </b-form-group>
  </base-activity>
</template>

<script>
import BaseActivity from '@/components/WebScraper/SeleniumCommands/BaseCommand.vue';

export default {
  name: 'CallAPICommand',
  components: { BaseActivity },
  props: {
    scraperId: {
      type: Number,
      required: true,
    },
    commandId: {
      type: String,
      required: true,
    },
    command: {
      type: Object,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      advancedVisible: false,
      fields: [
        { key: 'key', label: 'Key' },
        { key: 'value', label: 'Value (BotScript enabled)' },
        { key: 'item_actions', label: '' },
      ],
    };
  },
  computed: {
    headerArgs() {
      return `${this.command.target_var} = GET ${this.command.source_var}`;
    },
    useCache: {
      get() {
        return this.command.use_cache;
      },
      set(value) {
        this.$root.$emit('update-scraper', { commandId: this.commandId, prop: 'use_cache', value });
      },
    },
  },
  methods: {
    createParam() {
      const currentParams = this.command.params || [];
      currentParams.push({ key: '', value: '' });
      this.$root.$emit('update-scraper', { commandId: this.commandId, prop: 'params', value: currentParams });
    },
    updateParams(index, type, newVal) {
      const currentParams = this.command.params;
      currentParams[index][type] = newVal;
      this.$root.$emit('update-scraper', { commandId: this.commandId, prop: 'params', value: currentParams });
    },
    deleteParam(index) {
      const currentParams = this.command.params;
      currentParams.splice(index, 1);
      this.$root.$emit('update-scraper', { commandId: this.commandId, prop: 'params', value: currentParams });
    },
  },
};
</script>
