<template>
  <b-row v-if="!globalConfig">
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <b-card
    v-else
    class="r-75"
    body-class="p-3"
    :class="unsavedChanges ? 'border-warning' : ''"
  >
    <SingleSignOn
      :id="globalConfig.singleSignOnId || ssoConfigIds.CUSTOMER"
      :django-groups="djangoGroups"
      :methods="ssoMethods"
      @unsavedChanges="(val) => unsavedChanges = val"
    />
  </b-card>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import SingleSignOn from 'supwiz/components/SingleSignOn.vue';
import endpoints from '@/js/endpoints';
import { snakeCaseToText } from 'supwiz/util/data';
import { ssoConfigIds, createSsoMethods } from 'supwiz/util/sso';
import axios from 'axios';

export default {
  name: 'SingleSignOnWrapper',
  components: {
    SingleSignOn,
  },
  data() {
    return {
      unsavedChanges: false,
      ssoConfigIds,
    };
  },
  computed: {
    ...mapState('user', ['groups']),
    ...mapState('auth', ['globalConfig']),
    djangoGroups() {
      return this.groups.map((e) => ({ value: e.id, text: snakeCaseToText(e.name) }));
    },
    ssoMethods() {
      return createSsoMethods(axios, endpoints, this.$store.state.auth.jwt);
    },
  },
  async created() {
    await this.fetchGroups();
  },
  methods: {
    ...mapActions('user', ['fetchGroups']),
  },
};

</script>
