import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import { getModuleProps } from '@/js/utils';

const fieldValueState = {};

function fieldValueToName(fieldValue, fields) {
  const field = fields[fieldValue.field];
  const nameToDisplay = field.displayName ? field.displayName : field.name;
  const valueToDisplay = fieldValue.displayValue ? fieldValue.displayValue : fieldValue.value;
  return `${nameToDisplay} : ${valueToDisplay}`;
}

const fieldValueGetters = {
  getCompletions: (state, getters, rootState, rootGetters) => (dataSourceId, dataType) => {
    const filteredFields = rootGetters['field/filteredFields'](dataSourceId, dataType);
    const completions = Object.values(
      state.items,
    ).filter(
      (x) => x.field in filteredFields,
    ).map(
      (fieldValue) => ({
        key: fieldValue.id,
        value: fieldValueToName(fieldValue, filteredFields),
      }),
    );
    if (completions.length < 10000) {
      completions.sort((a, b) => ((a.value > b.value) ? 1 : -1));
    }
    return completions;
  },
};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.fieldValue, 'fieldValue'),
];

export default {
  namespaced: true,
  ...getModuleProps({
    state: fieldValueState, getters: fieldValueGetters, mutations, actions,
  }, templates),
};
