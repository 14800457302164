<template>
  <b-row
    v-if="isFetchingDetails"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <b-row v-else-if="objectNotFound">
    <b-col>
      <h1>Activity not found</h1>
      <p>Requested activity could not be found.</p>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col sm="12" md="7" lg="5" xl="5">
      <b-row>
        <b-col>
          <DetailsCard
            v-bind="{
              dataType: 'activity',
              sections: activitySections,
              details: activityDetails,
            }"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card class="r-75 mt-3">
            <b-form-group label="Activity tags" class="mb-0" description="Tags are saved automatically">
              <chip-list
                :draggable="false"
                :value="activityFieldValues"
                :completions="fieldValueCompletions"
                @click="openFieldValue"
                @input="(x) => updateFieldValuesLocal(x)"
              />
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-col class="pl-0" sm="12" md="5" lg="7" xl="7">
      <b-card
        class="r-75"
        body-class="p-3"
      >
        <b-row class="mb-3">
          <b-col cols="auto">
            <h4 class="card-title mb-0">
              <font-awesome-icon icon="comments" />
              Activity details
            </h4>
          </b-col>
          <b-col />
          <b-col cols="auto">
            <b-button
              variant="primary"
              size="sm"
              @click="$router.go(-1)"
            >
              Return
            </b-button>
          </b-col>
        </b-row>

        <b-card no-body class="mt-3">
          <b-card-text style="white-space: pre-line">
            <h6 class="font-weight-bold">
              Content
            </h6>
            {{ activityDetails.content }}
          </b-card-text>
        </b-card>

        <b-list-group class="mt-3">
          <b-list-group-item>
            <b-row
              align-v="center"
              align-h="between"
              class="cursor-pointer h6 mb-0"
              @click="showRelations = !showRelations"
            >
              <b-col
                cols="11"
                class="font-weight-bold"
              >
                Activity relations ({{ activityDetails.relations.length }})
              </b-col>
              <b-col
                cols="*"
                class="text-right pr-2"
              >
                <font-awesome-icon :icon="showRelations ? 'angle-up' : 'angle-down'" />
              </b-col>
            </b-row>
            <b-collapse
              v-model="showRelations"
              class="mt-3"
            >
              <table-data
                :fields="relationsFields"
                small
                hover
                :items="activityDetails.relations"
                show-empty
                empty-text="There are no relations to show"
                class="mb-0"
              />
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  mapState, mapMutations, mapGetters, mapActions,
} from 'vuex';
import ChipList from 'supwiz/components/ChipList.vue';
import TableData from 'supwiz/components/TableData.vue';
import { DataTypes } from '@/js/constants';
import DetailsCard from '@/components/DataSource/DetailsCard.vue';

export default {
  name: 'ChatActivitiesSingle',
  components: {
    ChipList,
    TableData,
    DetailsCard,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchChatDetails({ id: to.params.chatId }).then(() => {
        vm.setActivities(vm.chatDetails.activities);
        vm.setActivityDetails(vm.chatDetails.activities
          .find((e) => e.id.toString() === to.params.activityId.toString()));
      });
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchChatDetails({ id: to.params.chatId }).then(() => {
      this.setActivities(this.chatDetails.activities);
      this.setActivityDetails(this.chatDetails.activities
        .find((e) => e.id.toString() === to.params.activityId));
    });
    next();
  },
  data: () => ({
    relationsFields: [
      'id',
      'origin',
      { key: 'article', label: 'Article ID' },
      { key: 'chat', label: 'Chat ID' },
      { key: 'ticket', label: 'Ticket ID' },
      { key: 'query', label: 'Query ID' },
    ],
    showRelations: false,
  }),
  computed: {
    ...mapState('chat', { chatDetails: 'details' }),
    ...mapState('chat', ['isFetchingDetails']),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    ...mapState('chatActivity', { activityDetails: 'details' }),
    ...mapGetters('fieldValue', ['getCompletions']),
    ...mapGetters('fieldValue', { fieldValues: 'items' }),
    activitySections() {
      return [
        {
          title: 'Information',
          fields: [
            { key: 'id', tooltip: 'Identifier' },
            { key: 'chatId', tooltip: 'Identifier of the chat source for this message' },
            { key: 'timestamp', tooltip: 'Date and time when the activity occurred' },
            { key: 'sender', tooltip: 'Person that sent the message' },
            { key: 'type', tooltip: 'Type of activity' },
          ],
        },
      ];
    },
    objectNotFound() {
      return Object.keys(this.activityDetails).length === 0;
    },
    activityFieldValues() {
      return this.activityDetails.fieldValues
        ? this.activityDetails.fieldValues.map((e) => (e.id)) : [];
    },
    fieldValueCompletions() {
      return this.getCompletions(this.dataSourceDetails.id, DataTypes.ACTIVITY)
        .filter((e) => !this.activityFieldValues.includes(e.id));
    },
  },
  destroyed() {
    this.setActivities({});
    this.setActivityDetails({});
  },
  methods: {
    ...mapActions('chat', { fetchChatDetails: 'fetchItemDetails' }),
    ...mapMutations('chatActivity', { setActivityDetails: 'setItemDetails' }),
    ...mapMutations('chatActivity', { setActivities: 'setItems' }),
    ...mapActions('chatActivity', ['updateFieldValues']),
    updateFieldValuesLocal(values) {
      this.updateFieldValues({ itemId: this.activityDetails.id, values });
    },
    openFieldValue(id) {
      this.$router.push({
        name: 'tag-values-single',
        params: {
          dataSourceId: this.dataSourceDetails.id,
          fieldId: this.fieldValues[id].field,
          fieldValueId: id,
        },
      });
    },
  },

};
</script>
