<template>
  <div>
    <b-row class="mb-2">
      <b-col
        cols="auto"
        class="pr-1 mb-2"
      >
        <b-dropdown
          v-if="queryIds.length === 0"
          :text="`${formattedStartDate} - ${formattedEndDate}`"
          toggle-class="bg-white text-dark font-weight-normal"
          menu-class="bg-white text-dark date-dropdown p-0"
        >
          <b-dropdown-header>
            Select date range
          </b-dropdown-header>
          <b-row no-gutters>
            <b-col>
              <b-button-group
                vertical
                class="h-100 w-100 p-2"
              >
                <b-button
                  v-for="(item, index) in timeRangeOptions"
                  :key="`range-${index}`"
                  :style="timeRange === item.value ? 'background-color: #BEC4C7' : ''"
                  variant="text"
                  @click="setTimeRange(item.value)"
                >
                  {{ item.text }}
                </b-button>
              </b-button-group>
            </b-col>
            <b-col>
              <b-calendar
                start-weekday="1"
                :value="startDate"
                class="bg-white p-2 r-25"
                :max="endDate"
                locale="en-US"
                @selected="val=>startDate = val"
              />
            </b-col>
            <b-col>
              <b-calendar
                start-weekday="1"
                :value="endDate"
                :min="startDate"
                class="bg-white p-2 r-25"
                locale="en-US"
                @selected="val=>endDate = val"
              />
            </b-col>
          </b-row>
        </b-dropdown>
      </b-col>
      <b-col v-if="!hideDataSelection" class="px-1" cols="auto">
        <b-dropdown
          toggle-class="bg-white text-dark font-weight-normal"
          menu-class="bg-white text-dark p-0 pb-1"
        >
          <template #button-content>
            Data to label
            <span class="font-weight-bold">
              ({{ sourceName }})
            </span>
          </template>
          <b-dropdown-header>
            Select data to label
          </b-dropdown-header>
          <b-dropdown-group
            v-for="([type, sources], index) in dataOptions"
            :key="type"
            :header="dataOptionHeader[type]"
            header-classes="font-italic"
          >
            <b-dropdown-item-button
              v-for="(source, sourceIndex) in sources"
              :key="sourceIndex"
              @click="selectedSource = source.value"
            >
              {{ source.text }}
            </b-dropdown-item-button>
            <b-dropdown-divider v-if="index + 1 < dataOptions.length" />
          </b-dropdown-group>
        </b-dropdown>
      </b-col>
      <b-col v-if="translationOptions.length && !hideDataSelection" class="px-1" cols="auto">
        <b-dropdown
          toggle-class="bg-white text-dark font-weight-normal"
          menu-class="bg-white text-dark p-0"
        >
          <template #button-content>
            Translations
            <span class="font-weight-bold">
              ({{ selectedTranslations.length }})
            </span>
          </template>
          <b-dropdown-header>
            Show title translations for these languages
          </b-dropdown-header>
          <b-dropdown-form form-class="p-1">
            <b-button-group
              class="w-100 mb-1"
              size="sm"
            >
              <b-button @click="bulkSelectTranslations('all')">
                All
              </b-button>
              <b-button @click="bulkSelectTranslations('none')">
                None
              </b-button>
              <b-button @click="bulkSelectTranslations('invert')">
                Invert
              </b-button>
            </b-button-group>
            <b-form-input
              v-model="translationFilter"
              class="mb-1"
              placeholder="Search translation"
            />
            <b-form-checkbox-group
              v-model="selectedTranslations"
              class="mb-1"
              :options="translationOptions"
              stacked
            />
          </b-dropdown-form>
        </b-dropdown>
      </b-col>
      <b-col v-if="!hideDataSelection && filter === 'labeled'" cols="auto" class="px-1">
        <b-dropdown
          toggle-class="bg-white text-dark font-weight-normal"
          menu-class="bg-white text-dark p-0"
          @hide="articlesFilter = ''"
        >
          <template #button-content>
            Filter on articles
            <span class="font-weight-bold">
              ({{ selectedArticles.length }})
            </span>
          </template>
          <b-dropdown-header>
            Show queries with specific article labels
          </b-dropdown-header>
          <b-dropdown-form
            form-class="p-1 article-selection"
            @keydown.enter.prevent
          >
            <b-button-group
              class="w-100 mb-1"
              size="sm"
            >
              <b-button @click="bulkSelectArticles('all')">
                All
              </b-button>
              <b-button @click="bulkSelectArticles('none')">
                None
              </b-button>
              <b-button @click="bulkSelectArticles('invert')">
                Invert
              </b-button>
            </b-button-group>
            <b-form-input
              v-model="articlesFilter"
              class="mb-1"
              placeholder="Search articles"
            />
            <b-form-checkbox-group
              v-model="selectedArticles"
              class="mb-1"
              :options="filteredArticleOptions"
              stacked
            />
          </b-dropdown-form>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row class="pb-2">
      <b-col cols="auto">
        <b-pagination
          v-if="queryIds.length === 0"
          v-model="currentPage"
          :disabled="!localData.length"
          :total-rows="localPagination.count"
          :per-page="localPagination.perPage"
          size="sm"
          class="mb-0"
        />
      </b-col>
      <b-col class="text-right">
        <b-form-checkbox
          v-if="showSearchFilter"
          v-model="showingMetadata"
          button
          size="sm"
          button-variant="outline-primary"
        >
          Show search filter
        </b-form-checkbox>
      </b-col>
      <b-col cols="auto">
        <b-form-radio-group
          v-model="filter"
          buttons
          size="sm"
          button-variant="primary"
          :options="filterOptions"
        />
      </b-col>
    </b-row>
    <b-overlay :show="localIsFetching">
      <div v-if="!localData.length" class="text-center my-5">
        There is no data to show.
      </div>
      <b-list-group v-else>
        <feedback-item
          v-for="(item, index) in localData"
          :key="`${item.id}+${index}`"
          :data-type="selectedSource.type"
          :selected-translations="selectedTranslations"
          v-bind="{
            item,
            index,
          }"
          @updated="updateEntry(index)"
        />
      </b-list-group>
    </b-overlay>
  </div>
</template>
<script>
import {
  mapActions, mapMutations, mapState, mapGetters,
} from 'vuex';
import { objToCamel } from '@/js/utils';
import { pipelineSourceTypes, DataTypes, pipelineTypeToDataType } from '@/js/constants';
import moment from 'moment';
import FeedbackItem from './FeedbackItem.vue';

export default {
  name: 'FeedbackSection',
  components: { FeedbackItem },
  props: {
    queryIds: {
      type: Array,
      default: () => ([]),
    },
    hideDataSelection: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      queries: [],
      localData: [],
      dataOptionHeader: {
        query: 'Queries',
        chat: 'Chats',
        ticket: 'Tickets',
      },
      filter: 'unlabeled',
      selectedSource: { type: DataTypes.QUERY },
      timeRange: null,
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date(),
      selectedTranslations: [],
      translationFilter: '',
      selectedArticles: [],
      articlesFilter: '',
      timeRangeOptions: [
        { value: 'today', text: 'Today' },
        { value: 'yesterday', text: 'Yesterday' },
        { value: 'this_week', text: 'This week' },
        { value: 'last_week', text: 'Last week' },
        { value: 'this_month', text: 'This month' },
        { value: 'last_month', text: 'Last month' },
        { value: 'this_year', text: 'This year' },
        { value: 'last_year', text: 'Last year' }],
    };
  },
  computed: {
    ...mapGetters('ranker', ['rankerArticlesTranslations', 'rankerArticlesDict']),
    ...mapState('ranker', ['datasourceId2Name']),
    ...mapState('query', { queryPagination: 'pagination', queryIsFetching: 'isFetching' }),
    ...mapState('chat', { chatPagination: 'pagination', chatIsFetching: 'isFetching' }),
    ...mapState('ticket', { ticketPagination: 'pagination', ticketIsFetching: 'isFetching' }),
    ...mapState('query', ['pagination', 'showMetaData']),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    ...mapState('pipelineSource', { pipelineSources: 'items' }),
    filteredArticleOptions() {
      return this.articleOptions.filter((e) => e.text.toLowerCase()
        .includes(this.articlesFilter.toLocaleLowerCase())).slice(0, 100);
    },
    articleOptions() {
      return Object.values(this.rankerArticlesDict)
        .map((e) => ({ value: e.id, text: e.title }));
    },
    formattedStartDate() {
      return moment(this.startDate).format('DD-MMM-YYYY');
    },
    formattedEndDate() {
      return moment(this.endDate).format('DD-MMM-YYYY');
    },
    localIsFetching() {
      switch (this.selectedSource.type) {
        case DataTypes.CHAT: return this.chatIsFetching;
        case DataTypes.TICKET: return this.ticketIsFetching;
        case DataTypes.QUERY:
        default: return this.queryIsFetching;
      }
    },
    localPagination() {
      switch (this.selectedSource.type) {
        case DataTypes.CHAT: return this.chatPagination;
        case DataTypes.TICKET: return this.ticketPagination;
        case DataTypes.QUERY:
        default: return this.queryPagination;
      }
    },
    translationOptions() {
      return [...new Set(Object.values(this.rankerArticlesTranslations)
        .map((x) => Object.keys(x)).flat(1))]
        .map((x) => ({ text: x, value: x }))
        .filter((x) => x.text.toLowerCase().includes(this.translationFilter.toLowerCase()));
    },
    showSearchFilter() {
      return this.selectedSource.type === DataTypes.QUERY;
    },
    currentPage: {
      get() {
        return this.localPagination.page;
      },
      set(val) {
        switch (this.selectedSource.type) {
          case DataTypes.CHAT: this.updateChatPagination({ page: val }); break;
          case DataTypes.TICKET: this.updateTicketPagination({ page: val }); break;
          case DataTypes.QUERY:
          default: this.updateQueryPagination({ page: val }); break;
        }
      },
    },
    filterOptions() {
      const opts = [
        { text: 'Show all', value: null },
        { text: 'Only unlabeled', value: 'unlabeled' },
        { text: 'Only labeled', value: 'labeled' },
        { text: 'Only ignored', value: 'disabled' },
      ];
      if (this.selectedSource.type === DataTypes.QUERY) {
        opts.push(
          { text: 'Only not useful', value: 'not_useful' },
        );
      }
      return opts;
    },
    showingMetadata: {
      get() {
        return this.showMetaData;
      },
      set(val) {
        this.setShowMetaData(val);
      },
    },
    dataOptions() {
      const opts = {};
      for (const source of Object.values(this.pipelineSources)
        .filter((x) => x.type !== pipelineSourceTypes.ARTICLE.value)) {
        if (opts[pipelineTypeToDataType[source.type]] === undefined) {
          opts[pipelineTypeToDataType[source.type]] = [];
        }
        opts[pipelineTypeToDataType[source.type]].push({
          text: this.datasourceId2Name[source.dataSource],
          value: { ...source, type: pipelineTypeToDataType[source.type] },
        });
      }
      return Object.entries(opts);
    },
    sourceName() {
      if (this.selectedSource.dataSource === undefined) {
        // default queries
        return 'Queries: Search engine';
      }
      const dataSourceName = this.datasourceId2Name[this.selectedSource.dataSource];
      if (dataSourceName.includes('Data from search engine:')) {
        return 'Queries: Search engine';
      }
      return `${this.dataOptionHeader[this.selectedSource.type]}: ${dataSourceName}`;
    },
    currentFilters() {
      return {
        filter: this.filter,
        selectedSource: this.selectedSource,
        timeRange: this.timeRange,
        startDate: this.startDate,
        endDate: this.endDate,
        selectedArticles: this.selectedArticles,
      };
    },
  },
  watch: {
    currentPage() {
      this.fetchDataProxy();
    },
    currentFilters(n, o) {
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      } else if (JSON.stringify(n) !== JSON.stringify(o)) {
        this.fetchDataProxy();
      }
    },
    filter() {
      this.selectedArticles = [];
    },
  },
  created() {
    this.fetchDataProxy();
    this.fetchArticleUrl2Id();
    this.fetchDatasourceNames();
  },
  beforeDestroy() {
    this.updateChatPagination({ page: 1 });
    this.updateTicketPagination({ page: 1 });
    this.updateQueryPagination({ page: 1 });
  },
  methods: {
    ...mapActions('ranker', ['fetchArticleUrl2Id', 'fetchDatasourceNames']),
    ...mapActions('query', { fetchQueries: 'fetchItems' }),
    ...mapActions('chat', { fetchChats: 'fetchItems' }),
    ...mapActions('ticket', { fetchTickets: 'fetchItems' }),
    ...mapMutations('query', { updateQueryPagination: 'updatePagination' }),
    ...mapMutations('chat', { updateChatPagination: 'updatePagination' }),
    ...mapMutations('ticket', { updateTicketPagination: 'updatePagination' }),
    ...mapMutations('query', ['setShowMetaData']),
    ...mapActions('sidebar', ['showWarning']),
    async fetchDataProxy() {
      const formattedStartDate = new Date(this.startDate).setHours(0, 0, 0, 0);
      const formattedEndDate = new Date(this.endDate).setHours(23, 59, 59, 999);
      const params = {
        data_source: this.selectedSource?.dataSource || this.dataSourceDetails.id,
        page: this.localPagination.page,
        feedback: true,
        filter: this.filter,
        start_time: new Date(formattedStartDate).toISOString(),
        end_time: new Date(formattedEndDate).toISOString(),
        selected_articles: this.selectedArticles,
        order_by: 'top_score',
      };
      const specificIds = this.queryIds.length > 0;
      if (this.selectedSource.type === DataTypes.QUERY.value && specificIds) {
        params.ids = this.queryIds;
      }
      let resp = null;
      // get request wont handle more than ~180 params of this length
      // I capped it at 150 to prevent crash
      if (this.selectedArticles.length > 150 && !specificIds) {
        this.showWarning({
          title: 'Too many articles chosen',
          text: 'You cannot select more than 150 articles at a time.',
        });
      } else {
        switch (this.selectedSource.type) {
          case DataTypes.QUERY:
            if (specificIds) {
              params.ids = this.queryIds;
              params.page_size = 100;
              params.page = 1;
              params.start_time = undefined;
              params.end_time = undefined;
              params.selected_articles = [];
              params.order_by = undefined;
            }
            resp = await this.fetchQueries({ params });
            break;
          case DataTypes.TICKET:
            resp = await this.fetchTickets({ params });
            break;
          case DataTypes.CHAT:
            resp = await this.fetchChats({ params });
            break;
          default:
            return;
        }
        this.localData = Object.values(resp.map((c) => objToCamel(c)));
        if (this.selectedSource.type === DataTypes.QUERY && specificIds) {
          const sortingArray = this.queryIds;
          this.localData = this.localData.sort(
            (a, b) => sortingArray.indexOf(a.id) - sortingArray.indexOf(b.id),
          );
        }
      }
    },

    updateEntry(index) {
      this.localData[index].updated = true;
    },
    bulkSelectTranslations(value) {
      if (value === 'all') {
        this.selectedTranslations = this.translationOptions.map((b) => b.value);
      } else if (value === 'invert') {
        this.selectedTranslations = this.translationOptions
          .filter((e) => !this.selectedTranslations.includes(e.value)).map((e) => e.value);
      } else {
        this.selectedTranslations = [];
      }
    },
    bulkSelectArticles(value) {
      if (value === 'all') {
        this.selectedArticles = this.articleOptions.map((b) => b.value);
      } else if (value === 'invert') {
        this.selectedArticles = this.articleOptions
          .filter((e) => !this.selectedArticles.includes(e.value)).map((e) => e.value);
      } else {
        this.selectedArticles = [];
      }
    },
    setTimeRange(val) {
      this.timeRange = val;
      switch (val) {
        case 'today':
          this.startDate = new Date();
          this.endDate = new Date();
          break;
        case 'yesterday':
          {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            this.startDate = date;
            this.endDate = date;
          }
          break;
        case 'this_week':
          {
            const date = new Date();
            const day = date.getDay();
            const diff = date.getDate() - day + (day === 0 ? -6 : 1);
            date.setDate(diff);
            this.startDate = date;
            this.endDate = new Date();
          }
          break;
        case 'last_week':
          {
            let today = new Date();
            const firstDay = new Date(today.setDate(today.getDate() - today.getDay() - 6));
            today = new Date();
            const lastDay = new Date(today.setDate((today.getDate() - today.getDay())));
            this.startDate = firstDay;
            this.endDate = lastDay;
          }
          break;
        case 'this_month':
          {
            const date = new Date();
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            this.startDate = firstDay;
            this.endDate = date;
          }
          break;
        case 'last_month':
          {
            const date = new Date();
            const firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            const lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);
            this.startDate = firstDayPrevMonth;
            this.endDate = lastDayPrevMonth;
          }
          break;
        case 'this_year':
          {
            const date = new Date();
            const firstDayYear = new Date(new Date().getFullYear(), 0, 1);
            this.startDate = firstDayYear;
            this.endDate = date;
          }
          break;
        case 'last_year':
          {
            const date = new Date();
            const firstDayPrevYear = new Date(date.getFullYear() - 1, 0, 1);
            const lastDayPrevYear = new Date(date.getFullYear() - 1, 12, 0);
            this.startDate = firstDayPrevYear;
            this.endDate = lastDayPrevYear;
          }
          break;
        default: break;
      }
    },
  },
};
</script>
<style scoped>
::v-deep .date-dropdown{
  width:700px !important;
}
::v-deep .dropdown-header{
  padding-left:0.5rem;
  padding-right:0.5rem;
}
::v-deep .article-selection{
  max-height: 400px;
  width: 600px;
  overflow-y: auto;
}
</style>
