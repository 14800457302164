import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import ImportTemplate from '@/js/vuexTemplates/import';
import SharedTemplate from '@/js/vuexTemplates/shared';
import { getModuleProps } from '@/js/utils';

const ticketState = {};

const ticketGetters = {};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.ticket, 'ticket'),
  new ImportTemplate(endpoints.ticket, 'ticket'),
  new SharedTemplate('ticket'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: ticketState, getters: ticketGetters, mutations, actions,
  }, templates),
};
