<template>
  <b-modal
    ref="modal"
    v-bind="modalInfo"
    scrollable
    size="xl"
    body-class="feedback-modal"
    @hidden="resetModal"
  >
    <feedback-section hide-data-selection v-bind="{ queryIds }" />
  </b-modal>
</template>

<script>
import FeedbackSection from '@/components/Ranker/Feedback/FeedbackSection.vue';

export default {
  name: 'FeedbackModal',
  components: { FeedbackSection },
  data() {
    return {
      filterWord: '',
      queryIds: [],
    };
  },
  computed: {
    modalInfo() {
      return {
        centered: true,
        noCloseOnEsc: true,
        noCloseOnBackdrop: true,
        size: 'lg',
        okOnly: true,
        okVariant: 'secondary',
        okTitle: 'Close',
        title: this.filterWord
          ? `Examples including "${this.filterWord}"`
          : 'Topic examples',
      };
    },
  },
  methods: {
    resetModal() {
      this.queryIds = [];
      this.filterWord = '';
    },
    displayModal({ selectedWord, queryIds }) {
      this.filterWord = selectedWord;
      this.queryIds = queryIds;
      this.$refs.modal.show();
    },
  },
};
</script>
<style scoped>
::v-deep .feedback-modal{
  min-height: 500px;
}
</style>
