export default function checkCondition(condition, lastQuery, items = []) {
  if (condition.detect_articles) {
    const itemIds = items.map((e) => e.id);
    return condition.article_ids.some((articleId) => itemIds.includes(articleId));
  }
  let pattern = condition.text;
  let text = lastQuery;
  if (!pattern) {
    return true;
  }

  let matches;
  if (condition.is_regex) {
    try {
      const flags = condition.case_sensitive ? '' : 'i';
      const regexPattern = new RegExp(pattern, flags);
      matches = [regexPattern.test(text)];
    } catch (exc) {
      return true;
    }
  } else {
    text = condition.case_sensitive ? text : text.toLowerCase();
    pattern = condition.case_sensitive ? pattern : pattern.toLowerCase();
    const subPatterns = pattern.split('|').filter((subPattern) => subPattern !== '');
    if (condition.exact_match) {
      text = text.trim();
      matches = [subPatterns.includes(text)];
    } else {
      matches = [subPatterns.some((subPattern) => text.includes(subPattern))];
    }
  }
  let match = matches.some(Boolean);
  if (condition.negate) {
    match = !match;
  }
  return match;
}
