<template>
  <b-modal
    id="upload-queries-modal"
    ref="modal"
    title="Upload queries"
    :size="showFormat ? 'xl' : 'lg'"
    ok-variant="primary"
    ok-title="Upload"
    :ok-disabled="!queriesFile"
    @ok="localAdd"
  >
    <template v-if="showFormat">
      <b-button
        variant="primary"
        @click="toggleShowFormat"
      >
        Back
      </b-button>
      <h2 class="mt-4">
        File types
      </h2>
      <span>
        The uploaded file should be a <code>json</code> array or a <code>csv</code> file.
      </span>
      <h2 class="mt-4">
        Query fields
      </h2>
      <div>
        Each query object in the json array (or row in the csv file)  must have the following
        fields
      </div>
      <b-list-group class="mt-2">
        <b-list-group-item>
          <code>text</code>: The content of the query.
        </b-list-group-item>
        <b-list-group-item>
          <code>external_id</code>: The id of the query in the external system.
        </b-list-group-item>
        <b-list-group-item>
          <code>timestamp</code> (optional): The time that the query was made in
          iso format, e.g. "2021-04-26T14:51:15Z".
        </b-list-group-item>
      </b-list-group>
      <h2 class="mt-3">
        Example
      </h2>
      JSON
      <b-card
        border-radius="25"
        class="p-2 mb-2 overflow-hidden w-100 border bg-light"
      >
        <vue-json-pretty
          :data="jsonExample"
          :deep="10"
        />
      </b-card>
      CSV
      <b-card
        border-radius="25"
        class="p-2 mb-2 overflow-hidden w-100 border bg-light"
      >
        <p>
          text, external_id, timestamp<br>
          How do I reset my password?, 5237, 2021-04-26T14:51:15Z<br>
          ...
        </p>
      </b-card>
    </template>
    <template v-else>
      <p>
        Choose a file to upload
        (<b-link @click="toggleShowFormat">
          see format
        </b-link>).
      </p>
      <b-form-file
        v-model="queriesFile"
        placeholder="Choose file..."
        accept=".json, .csv"
      />
    </template>
  </b-modal>
</template>

<script>

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { mapActions } from 'vuex';
import { DataTypes } from '@/js/constants';

export default {
  name: 'UploadQueriesModal',
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      queriesFile: null,
      showFormat: false,
      jsonExample: JSON.parse('[{"text": "How do I reset my password?", "external_id": 5237, "query_created": "2021-04-26T14:51:15Z"}]'),
    };
  },
  methods: {
    ...mapActions('fileUpload', { uploadFiles: 'uploadFiles' }),
    toggleShowFormat() {
      this.showFormat = !this.showFormat;
    },
    async localAdd() {
      await this.uploadFiles({ files: [this.queriesFile], dataType: DataTypes.QUERY });
    },
  },
};
</script>
