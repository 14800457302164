<template>
  <b-modal
    id="upload-chats-modal"
    ref="modal"
    title="Upload chats"
    :size="showFormat ? 'xl' : 'lg'"
    ok-variant="primary"
    ok-title="Upload"
    :ok-disabled="!chatsFile"
    @ok="localAdd"
  >
    <template v-if="showFormat">
      <b-button
        variant="primary"
        @click="toggleShowFormat"
      >
        Back
      </b-button>
      <h2 class="mt-4">
        File types
      </h2>
      <span>
        The uploaded file should be a <code>json</code> array.
      </span>
      <h2 class="mt-4">
        Chat fields
      </h2>
      <div>
        Each chat object in the array must have the following fields
      </div>
      <b-list-group class="mt-2">
        <b-list-group-item>
          <code>external_id</code>: The id of the chat in the external system.
        </b-list-group-item>
        <b-list-group-item>
          <code>activities</code>: An array of activity objects (see below).
        </b-list-group-item>
        <b-list-group-item>
          <code>chat_ended</code> (optional): The time that the chat was ended in
          iso format, e.g. "2021-04-26T14:51:15Z".
        </b-list-group-item>
      </b-list-group>
      <h2 class="mt-4">
        Activity fields
      </h2>
      <div>
        Each activity object nested in a chat should have the following fields
      </div>
      <b-list-group class="mt-2">
        <b-list-group-item>
          <code>sender</code>: The author of the activity. Must be "user", "agent", "bot",
          or "system".
        </b-list-group-item>
        <b-list-group-item>
          <code>content</code>: The content of the activity.
        </b-list-group-item>
        <b-list-group-item>
          <code>timestamp</code>: The time that the activity was executed in
          iso format, e.g. "2021-04-26T14:51:15Z".
        </b-list-group-item>
      </b-list-group>
      <h2 class="mt-3">
        Example
      </h2>
      <b-card
        border-radius="25"
        class="p-2 mb-2 overflow-hidden w-100 border bg-light"
      >
        <vue-json-pretty
          :data="jsonExample"
          :deep="10"
        />
      </b-card>
    </template>
    <template v-else>
      <p>
        Choose a file to upload
        (<b-link @click="toggleShowFormat">
          see format
        </b-link>).
      </p>
      <b-form-file
        v-model="chatsFile"
        placeholder="Choose file..."
        accept=".json, .csv"
      />
    </template>
  </b-modal>
</template>

<script>

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { mapActions } from 'vuex';
import { DataTypes } from '@/js/constants';

export default {
  name: 'UploadChatsModal',
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      chatsFile: null,
      showFormat: false,
      jsonExample: JSON.parse('[{"external_id": "H3LL0-CH4T","chat_ended": "2021-04-29T11:17:48Z","activities": [{"sender": "user","timestamp": "2021-05-29T11:18:06Z","type": "msg","content": "I would like to know how to reset my router"},{"sender": "agent","timestamp": "2021-06-29T11:34:52Z","type": "msg","content": "Hi, a guide on how to do this can be found at: http://articles.com/router. Let me know if I can help you with anything else! Kind regards Alice"}]},{"external_id": "TH1S-1S-4N-3XT3N4L-1D","chat_ended": "2021-06-29T11:17:48Z","activities": [{"sender": "user","timestamp": "2021-06-29T11:18:06Z","type": "msg","content": "I cant log in to the app"},{"sender": "agent","timestamp": "2021-07-29T11:34:52Z","type": "msg","content": "Have you tried resetting your password? See how to do this at http://articles.com/login-issue"},{"sender": "agent","timestamp": "2021-07-30T11:34:52Z","type": "msg","content": "I repeat: look at http://articles.com/login-issue"}]}]'),
    };
  },
  methods: {
    ...mapActions('fileUpload', { uploadFiles: 'uploadFiles' }),
    toggleShowFormat() {
      this.showFormat = !this.showFormat;
    },
    async localAdd() {
      await this.uploadFiles({ files: [this.chatsFile], dataType: DataTypes.CHAT });
    },
  },
};
</script>
