<template>
  <main>
    <display-key-value
      class="my-3"
      key-prop="ID"
      :value-prop="details.id"
      :min-key-width="keyWidth"
    />
    <display-key-value
      class="my-3"
      key-prop="Name"
      :value-prop="details.name"
      :min-key-width="keyWidth"
    />
    <display-key-value
      class="my-3"
      key-prop="Description"
      :value-prop="details.description"
      :min-key-width="keyWidth"
    />
    <display-key-value
      class="my-3"
      key-prop="Created time"
      :value-prop="details.createdTime"
      :min-key-width="keyWidth"
    />
    <b-card
      border-radius="25"
      class="p-2 mb-2 overflow-hidden w-100 bg-light"
    >
      <vue-json-pretty
        :data="details.config"
        :deep="10"
      />
    </b-card>
  </main>
</template>

<script>

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import DisplayKeyValue from 'supwiz/components/DisplayKeyValue.vue';

export default {
  name: 'TransformerDetails',
  components: { DisplayKeyValue, VueJsonPretty },
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      keyWidth: 120,
    };
  },
};

</script>
