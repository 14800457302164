<template>
  <div>
    <b-card
      title="Search Engine Overview"
      class="r-75"
      body-class="p-3"
    >
      <table-data
        :fields="fields"
        :items="Object.values(rankers)"
        hover
        :text-length="300"
        @delete="deleteRankerLocal"
        @row-clicked="setEditing"
        @add="$root.$emit('bv::show::modal', 'create-ranker-modal')"
      >
        <template #cell(trainable)="row">
          <font-awesome-icon
            v-if="row.item.trainable"
            icon="check"
          />
          <font-awesome-icon
            v-else
            icon="times"
          />
        </template>
      </table-data>
    </b-card>
    <b-modal
      id="create-ranker-modal"
      title="Create New Search Engine"
      ok-title="Create Search Engine"
      centered
      size="lg"
      :ok-disabled="$v.newRanker.$invalid"
      @hidden="resetForm"
      @ok="createRanker"
    >
      <b-form @submit.prevent>
        <edit-key-value
          class="my-3"
          key-prop="Name"
          description="Enter a name for the search engine"
          :value-prop="newRanker.name"
          :min-key-width="keyWidth"
          autofocus
          type="input"
          :state="!$v.newRanker.name.$invalid"
          @input="(x) => newRanker.name = x"
        >
          <template #feedback>
            <b-form-invalid-feedback
              v-if="!$v.newRanker.name.required"
            >
              You must name the search engine
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.newRanker.name.maxLength"
            >
              The name is too long
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.newRanker.name.isUnique"
            >
              This name is already taken
            </b-form-invalid-feedback>
          </template>
        </edit-key-value>
        <edit-key-value
          class="my-3"
          key-prop="Description"
          description="Enter a description for the search engine"
          :value-prop="newRanker.description"
          :min-key-width="keyWidth"
          type="textarea"
          @input="(x) => newRanker.description = x"
        />
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import { dataSourceTypes } from '@/js/constants';

export default {
  name: 'RankersOverview',
  components: { TableData, EditKeyValue },
  mixins: [validationMixin],
  data() {
    return {
      newRanker: this.rankerTemplate(),
    };
  },
  computed: {
    ...mapState('ranker', { rankers: 'items' }),
    ...mapState('dataSource', { dataSources: 'items' }),
    keyWidth() {
      return 160;
    },
    fields() {
      return [
        { key: 'delete', label: '', tdClass: 'delete-column' },
        { key: 'id', label: 'Id', tdClass: 'table-nobreak' },
        {
          label: 'Name', key: 'name', class: 'w-25', tdClass: 'align-middle',
        },
        {
          label: 'Description', key: 'description', class: 'flex-grow-1', tdClass: 'align-middle', formatter: 'textFormatter',
        },
      ];
    },
    dataSourceOptions() {
      return Object.values(this.dataSources).filter((e) => ![
        dataSourceTypes.TICKETANALYZER.value,
        dataSourceTypes.SUPCHAT.value].includes(e.type))
        .map((e) => ({ value: e.name, key: e.id }));
    },
  },
  methods: {
    ...mapActions('ranker', { addRanker: 'addItem' }),
    ...mapActions('ranker', { deleteRanker: 'deleteItem' }),
    ...mapActions('dataSource', { fetchDataSources: 'fetchItems' }),
    rankerTemplate() {
      return {
        name: '',
        description: '',
        trainable: true,
        pipeline: {},
      };
    },
    resetForm() {
      this.newRanker = this.rankerTemplate();
    },
    async createRanker(modalEvent) {
      modalEvent.preventDefault();
      const newItem = await this.addRanker({ newItem: this.newRanker });
      await this.fetchDataSources();
      this.$router.push({ name: 'ranker-config', params: { rankerId: newItem.id } });
    },
    setEditing(ranker) {
      this.$router.push({ name: 'ranker-single', params: { rankerId: ranker.id } });
    },
    async deleteRankerLocal(data) {
      const modalText = `Are you sure that you want to delete ${data.name}?`;
      const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        this.deleteRanker({ item: data });
      }
    },
  },
  validations() {
    return {
      newRanker: {
        name: {
          required,
          maxLength: maxLength(120),
          isUnique(value) {
            return !Object.values(this.rankers)
              .map((ranker) => ranker.name)
              .includes(value);
          },
        },
      },
    };
  },
};
</script>

<style>

</style>
