<template>
  <b-dropdown
    :text="display"
    block
    disabled
    menu-class="w-100"
  >
    <b-dropdown-form @submit.prevent="addNewMetaField">
      <b-input-group>
        <b-form-input
          v-model="newKey"
          placeholder="Enter new field name"
          :state="$v.newKey.$invalid ? false : null"
        />
        <template #append>
          <b-button @click="addNewMetaField">
            Add
          </b-button>
        </template>
      </b-input-group>
      <b-form-invalid-feedback :state="!$v.newKey.$invalid">
        Key already exists
      </b-form-invalid-feedback>
    </b-dropdown-form>
    <b-dropdown-divider />
    <b-dropdown-item @click="selectField(null)">
      Query
    </b-dropdown-item>
    <b-dropdown-item
      v-for="option in options"
      :key="option.id"
      @click="selectField(option.id)"
    >
      {{ option.text }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {
  mapActions,
  mapGetters,
} from 'vuex';
import { validationMixin } from 'vuelidate';

export default {
  name: 'MetaDataSelector',
  mixins: [validationMixin],
  props: {
    defaultValue: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      value: this.defaultValue,
      newKey: '',
    };
  },
  computed: {
    ...mapGetters('metaDataField', ['items']),
    options() {
      return Object.values(this.items).map((x) => ({ id: x.id, text: `${x.key} (meta data)` }));
    },
    allKeys() {
      return Object.values(this.items).map((x) => x.key);
    },
    display() {
      if (this.value != null) {
        return this.items[this.value].key;
      }
      return 'Query';
    },
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('metaDataField', ['addItem', 'deleteItem', 'patchItem', 'fetchItems']),
    selectField(value) {
      if (value !== this.value) {
        this.value = value;
        this.$emit('metaDataFieldSelected', value);
      }
    },
    addNewMetaField() {
      const value = this.newKey.trim();
      if (value !== '' && !this.allKeys.includes(value)) {
        this.addItem({ newItem: { key: value } });
        this.newKey = '';
      }
    },
  },
  validations() {
    return {
      newKey: {
        isUnique(value) {
          return !Object.values(this.items).map((e) => e.key.trim()).includes(value.trim());
        },
      },
    };
  },
};
</script>
