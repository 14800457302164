<template>
  <div class="h-100 d-flex flex-column">
    <b-row class="flex-grow-1 mb-2">
      <b-col>
        <div v-if="!chartData.labels.length" class="py-5 text-center">
          There is no data to show.
        </div>
        <typed-chart
          v-else
          :chart-data="chartData"
          :options="preparedChartOptions"
          chart-type="bar"
          class="h-100"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          v-if="!localStopWords.includes(selectedWord)"
          variant="primary"
          block
          size="sm"
          :disabled="!selectedWord"
          @click="addStopWord"
        >
          Ignore "{{ selectedWord }}"
        </b-button>
        <b-button
          v-else
          variant="secondary"
          block
          size="sm"
          :disabled="!selectedWord"
          @click="deleteStopWord"
        >
          Undo
        </b-button>
      </b-col>
      <b-col>
        <b-button
          v-b-tooltip.noninteractive.viewport="`See queries in topic that contains '${selectedWord}'`"
          variant="primary"
          size="sm"
          block
          :disabled="hasNoWordExamples"
          @click="showFeedbackModal(true)"
        >
          {{ hasNoWordExamples
            ? 'No examples'
            : `See "${selectedWord}" queries` }}
        </b-button>
      </b-col>
      <b-col>
        <b-button
          v-b-tooltip.noninteractive.viewport="'Show all queries for this topic'"
          variant="primary"
          size="sm"
          block
          :disabled="hasNoTopicExamples"
          @click="showFeedbackModal(false)"
        >
          {{ hasNoTopicExamples
            ? 'No examples'
            : 'See all queries' }}
        </b-button>
      </b-col>
    </b-row>
    <FeedbackModal ref="feedbackModal" />
  </div>
</template>

<script>
import { cloneDeep, sortBy } from 'lodash';

import { mapActions, mapGetters } from 'vuex';
import TypedChart from '@/components/typedChart.vue';
import FeedbackModal from './FeedbackModal.vue';

export default {
  name: 'DetailsSection',
  components: { TypedChart, FeedbackModal },
  props: {
    chartOptions: {
      type: Object,
      required: true,
    },
    metric: {
      type: String,
      required: true,
    },
    topicsObj: {
      type: Object,
      required: true,
    },
    topicIndex: {
      type: Number,
      required: true,
    },
    specifity: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedWord: '',
      localStopWords: [],
    };
  },
  computed: {
    ...mapGetters('ranker', ['getStopWordId']),
    allWordsMap() {
      return this.topicsObj.word_names.map((name, index) => {
        const result = {
          name,
          index,
          count: this.topicsObj.word_counts[index],
          countInTopic: this.topicDetails.word_counts[index],
          frequency: this.topicDetails.word_frequency[index],
          relativeFrequency: this.topicDetails.word_relative_frequency[index],
        };
        const R = 1 - this.specifity;

        // see https://nlp.stanford.edu/events/illvi2014/papers/sievert-illvi2014.pdf
        result.wordRelevance = (R * result.frequency) + ((1 - R) * result.relativeFrequency);
        return result;
      });
    },
    chartData() {
      const datasets = [];
      // Data is sorted by wordRelevance, maybe we want to show that value instead?
      ['countInTopic', 'count'].forEach((count, index) => {
        const dataset = {
          data: this.wordMapFiltered.map((obj) => obj[count]),
        };
        if (!index) {
          dataset.backgroundColor = this.wordMapFiltered
            .map((x, i) => (i === this.selectedWordIndex ? '#00354C' : '#005F89'));
        } else {
          dataset.backgroundColor = '#ccc';
        }
        datasets.push(dataset);
      });
      return {
        datasets,
        labels: this.words,
      };
    },
    hasNoTopicExamples() {
      const examples = this.topicDetails?.examples || [];
      return !examples.length;
    },
    hasNoWordExamples() {
      const examples = this.topicsObj?.word_examples?.[this.selectedWordIndex] || [];
      return !examples.length;
    },
    preparedChartOptions() {
      const options = cloneDeep(this.chartOptions);
      options.scales = {
        y: {
          stacked: true,
        },
      };
      const wordCount = this.wordMapFiltered.length;
      options.plugins.title.text = [`Top ${wordCount} most relevant keywords in topic`];
      options.plugins.tooltip = {
        displayColors: false,
        callbacks: {
          label: (tooltipItems) => {
            const word = tooltipItems.label;
            const wordIndex = this.getWordIndex(word);
            const wordObj = this.wordMapFiltered[wordIndex];
            const overlapping = wordObj.countInTopic === wordObj.count;
            return (tooltipItems.datasetIndex === 0 || !overlapping) ? [
              // `Word relevance: ${Number(wordObj.wordRelevance).toFixed(2)}%`,
              `Count in topic: ${wordObj.countInTopic}`,
              `Total count: ${wordObj.count}`,
              // `Frequency: ${Number(wordObj.frequency).toFixed(2)}%`,
              // `Relative frequency: ${Number(wordObj.relativeFrequency).toFixed(2)}%`,
            ] : '';
          },
        },
      };
      options.onClick = this.onClick;
      return options;
    },
    selectedWordIndex() {
      return this.getWordIndex(this.selectedWord);
    },
    topicDetails() {
      return this.topicsObj.topics?.[this.topicIndex] || null;
    },
    wordMapFiltered() {
      const countOfAtLeastOne = this.allWordsMap.filter(({ countInTopic }) => countInTopic >= 1);
      return sortBy(countOfAtLeastOne, ['wordRelevance', 'countInTopic']).reverse().slice(0, 15);
    },
    words() {
      return this.wordMapFiltered.map(({ name }) => name);
    },
  },
  watch: {
    words: {
      immediate: true,
      handler(words) {
        if (words?.length) this.selectedWord = words[0];
        else this.selectedWord = '';
      },
    },
  },
  methods: {
    ...mapActions('ranker', ['handleStopWords']),
    async addStopWord() {
      const word = this.selectedWord.toLowerCase();
      await this.handleStopWords({ task: 'add', word });
      this.localStopWords.push(word);
    },
    async deleteStopWord() {
      const word = this.selectedWord.toLowerCase();
      const id = this.getStopWordId(word);
      if (id !== null) {
        await this.handleStopWords({ task: 'delete', id });
        const index = this.localStopWords.indexOf(word);
        if (index >= 0) this.localStopWords.splice(index, 1);
      }
    },
    getWordIndex(word) {
      return this.wordMapFiltered.findIndex(({ name }) => word === name);
    },
    onClick(event, activeElements) {
      if (activeElements.length) {
        const { index } = activeElements[0];
        const wordObj = this.wordMapFiltered[index];
        this.selectedWord = wordObj.name;
      }
    },
    showFeedbackModal(filterOnWord) {
      const modal = this.$refs.feedbackModal;
      let queryIds = this.topicDetails.examples;
      if (filterOnWord) {
        const wordMapObj = this.wordMapFiltered[this.selectedWordIndex];
        const wordTopicIndex = wordMapObj.index;
        const wordExamples = this.topicsObj.word_examples[wordTopicIndex];
        queryIds = queryIds.filter((example) => wordExamples.includes(example));
      }
      // there can be thousands of ids which can result in a request line that is too long
      // I chose 350 as it seemed to be within the limit
      queryIds = queryIds.slice(0, 350);
      modal.displayModal({ queryIds });
    },
  },
};
</script>
