<template>
  <b-container class="h-100">
    <b-row
      v-if="isLoading"
      class="h-100 align-items-center"
    >
      <b-col class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem;"
        />
      </b-col>
    </b-row>
    <div v-else>
      <b-row
        class="text-center justify-content-center align-items-center pt-5"
      >
        <b-col cols="4">
          <img
            style="max-width: 25%"
            src="../assets/supwiz_dark_text.svg"
            alt="SupWiz Logo"
          >
          <h3 class="pt-3">
            SupWiz SupSearch
          </h3>
          <hr class="my-1">
        </b-col>
      </b-row>
      <div
        v-if="!isArticleViewerOrNone"
        class="text-left"
      >
        <b-row
          v-if="hasAccess('regular')"
          class="justify-content-center text-left"
        >
          <b-col
            cols="8"
            md="4"
            class="px-1"
          >
            <b-btn
              v-b-tooltip.hover.noninteractive
              variant="outline-primary"
              block
              class="py-3 my-1 menu-button text-left"
              @click="goToRoute('data-source-overview')"
            >
              <font-awesome-icon
                class="mx-3"
                icon="folder-open"
                size="lg"
              />
              Data Sources
            </b-btn>
          </b-col>
        </b-row>

        <b-row
          v-if="hasAccess('observer')"
          class="justify-content-center justify-text-left"
        >
          <b-col
            cols="8"
            md="4"
            class="px-1"
          >
            <b-btn
              variant="outline-primary"
              block
              class="py-3 my-1 menu-button text-left"
              @click="goToRoute('ranker-overview')"
            >
              <font-awesome-icon
                class="mx-3"
                icon="exchange-alt"
                style="transform: rotate(90deg);"
                size="lg"
              />
              Search Engines
            </b-btn>
          </b-col>
        </b-row>
        <b-row
          v-if="hasAccess('admin')"
          class="justify-content-center justify-text-left"
        >
          <b-col
            cols="8"
            md="4"
            class="px-1"
          >
            <b-btn
              variant="outline-primary"
              block
              class="py-3 my-1 menu-button text-left"
              @click="goToRoute('global-anonymization')"
            >
              <font-awesome-icon
                class="mx-3"
                icon="cog"
                size="lg"
              />
              Configuration
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters('auth', ['isArticleViewerOrNone', 'hasAccess']),
  },
  methods: {
    goToRoute(name) {
      this.isLoading = true;
      this.$router.push({ name });
    },
  },
};
</script>
<style scoped>
.jumbotron {
  text-align: center;
}
.menu-button {
  font-size: 1.2em;
}
svg{
  width:24px !important;
}
</style>
