<template>
  <b-row v-if="!globalConfig">
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <b-card
    v-else
    class="r-75"
    body-class="p-3"
    :class="unsavedChanges ? 'border-warning' : ''"
  >
    <anonymization
      :id="globalConfig.anonymizationConfig"
      @unsavedChanges="(val)=>updateUnsavedChanges(val)"
    />
  </b-card>
</template>

<script>

import { mapState } from 'vuex';
import Anonymization from 'supwiz/components/anonymization/Anonymization.vue';

export default {
  name: 'GlobalAnonymization',
  components: { Anonymization },
  data() {
    return {
      unsavedChanges: false,
    };
  },
  computed: {
    ...mapState('auth', ['globalConfig']),
  },
  methods: {
    updateUnsavedChanges(val) {
      this.unsavedChanges = val;
    },
  },
};
</script>
