import axios from 'axios';
import jwtDecode from 'jwt-decode';
import endpoints from '@/js/endpoints';
import { objToCamel } from '@/js/utils';
import { accessLevels } from '@/js/constants';

function jwtDecodeSafe(rawToken) {
  // Encodes a token, but if a malformed token is given, null is returned instead of raising an
  // error.
  if (!rawToken) {
    return null;
  }
  try {
    return jwtDecode(rawToken);
  } catch (e) {
    return null;
  }
}

const authState = {
  expired: false,
  jwt: localStorage.getItem('token'),
  refreshToken: localStorage.getItem('refreshToken'),
  loggedIn: false,
  ongoingSession: false,
  refreshAccessTokenTask: null,
  user: null,
  globalConfig: null,
};

const getters = {
  headerAuthorization: (state) => ({
    headers: {
      Authorization: `JWT ${state.jwt}`,
    },
  }),
  displayName(state) {
    if (state.user === null) {
      return 'Missing name';
    }
    if (state.user.firstName && state.user.lastName) {
      return `${state.user.firstName} ${state.user.lastName}`;
    }
    if (state.user.firstName) {
      return state.user.firstName;
    }
    return state.user.username;
  },
  genAiEnabled: (state) => (state.globalConfig || {}).genAiEnabled,
  genAiProvider: (state) => (state.globalConfig || {}).genAiProvider,
  isArticleViewer: (state) => (state.user?.group === 'article_reader'),
  isArticleViewerOrNone(state) {
    if (state.user?.group === 'article_reader' || state.user?.group == null) {
      return true;
    }
    return false;
  },
  userRole: (state) => (state.user?.group),
  hasAccess: (state) => (requiredRole) => {
    if (state.user === null) {
      return false;
    }
    return (accessLevels[state.user.group] || 0) >= (accessLevels[requiredRole] || 5);
  },
};

const mutations = {
  removeTokens(state) {
    localStorage.removeItem('token');
    state.jwt = null;
    localStorage.removeItem('refreshToken');
    state.refreshToken = null;
  },
  updateAccessToken(state, newAccessToken) {
    localStorage.setItem('token', newAccessToken);
    state.jwt = newAccessToken;
  },
  updateRefreshToken(state, newRefreshToken) {
    localStorage.setItem('refreshToken', newRefreshToken);
    state.refreshToken = newRefreshToken;
  },
  updateExpired(state, payload) {
    state.expired = payload;
  },
  updateLoggedIn(state, payload) {
    state.loggedIn = payload;
  },
  updateOngoingSession(state, payload) {
    state.ongoingSession = payload;
  },
  setRefreshAccessTokenTask(state, newValue) {
    state.refreshAccessTokenTask = newValue;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setGlobalConfig(state, payload) {
    state.globalConfig = payload;
  },
};

const actions = {
  async login(_, { username, password }) {
    return axios.post(endpoints.obtainJWT, { username, password });
  },

  async getUser({ commit, dispatch, rootGetters }) {
    try {
      const { data } = await axios.get(`${endpoints.user}me/`, rootGetters['auth/headerAuthorization']);
      commit('setUser', objToCamel(data));
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch user info',
        text: error.message,
      }, { root: true });
    }
  },

  async getGlobalConfig({ commit, dispatch, rootGetters }) {
    try {
      const { data } = await axios.get(endpoints.globalConfig, rootGetters['auth/headerAuthorization']);
      commit('setGlobalConfig', objToCamel(data));
    } catch (error) {
      if (error?.response?.status === 403 && rootGetters['auth/isArticleViewerOrNone']) {
        //
      } else {
        dispatch('sidebar/showWarning', {
          title: 'Failed to fetch settings',
          text: error.message,
        }, { root: true });
      }
    }
  },

  logout({ commit }) {
    commit('task/setTasks', { pending: [], active: [] }, { root: true });
    commit('updateLoggedIn', false);
    commit('removeTokens');
    commit('setUser', null);
  },

  verifyToken({ state }) {
    // This promise is rejected if we receive a non 2.x.x status-code in response. This means
    // implicitly that if this request does not reject, then it is assumed the token
    // could be verified. See axios validateStatus documentation for more.
    return axios.post(endpoints.verifyJWT, {
      token: state.jwt,
    });
  },

  async refreshToken({ commit, state }) {
    const response = await axios.post(endpoints.refreshJWT, {
      refresh: state.refreshToken,
    });
    commit('updateAccessToken', response.data.access);
  },

  async inspectToken({ commit, dispatch, state }, { shouldRefresh }) {
    const rawAccessToken = state.jwt;
    const rawRefreshToken = state.refreshToken;
    const accessToken = jwtDecodeSafe(rawAccessToken);
    const refreshToken = jwtDecodeSafe(rawRefreshToken);
    if (accessToken !== null && refreshToken !== null) {
      // exp is given in EPOCH _seconds_, while Date.now() returns EPOCH _miliseconds_
      const accessTokenLeftTimeSeconds = accessToken.exp - (Date.now() / 1000);
      const refreshTokenLeftTimeSeconds = refreshToken.exp - (Date.now() / 1000);

      if (state.refreshAccessTokenTask !== null) {
        // If we're already in the process of refreshing tokens, just await completion
        await state.refreshAccessTokenTask;
        return;
      }

      const maximumTimeToRefreshAccessToken = 5;
      if (accessTokenLeftTimeSeconds >= 1200) {
        // More than 20 minutes until access-token expires: No need to do anything now
      } else if (accessTokenLeftTimeSeconds < 1200
        && refreshTokenLeftTimeSeconds > maximumTimeToRefreshAccessToken
        && shouldRefresh) {
        // Access token expires in less than 20 minutes but/and we are still able to refresh
        // it using refresh-token
        try {
          const refreshTokenTask = dispatch('refreshToken');
          commit('setRefreshAccessTokenTask', refreshTokenTask);
          await refreshTokenTask;
        } finally {
          commit('setRefreshAccessTokenTask', null);
        }
      } else if (accessTokenLeftTimeSeconds < 30) {
        // access-token expired (or we can't refresh it in time) and re-login is required
        // We insert a small lag to avoid race conditions.
        commit('updateExpired', true);
        // We remove the tokens from the store and the localStorage - otherwise we will keep
        // showing the message if the user refreshes the page.
        commit('removeTokens');
      }
    }
  },

};

export default {
  namespaced: true,
  state: authState,
  getters,
  mutations,
  actions,
};
