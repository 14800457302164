<template>
  <div>
    <b-card
      title="Data Source Overview"
      class="r-75"
      body-class="p-3"
    >
      <b-row class="mb-2">
        <b-col>
          <b-form-input
            v-model="filterKeyword"
            size="sm"
            placeholder="Search by name or type"
          />
        </b-col>
      </b-row>
      <table-data
        :items="filteredDataSources"
        :fields="fields"
        hover
        :text-length="200"
        empty-text="No data sources were found."
        @delete="deleteDataSourceLocal"
        @row-clicked="setEditing"
        @add="$root.$emit('bv::show::modal', 'create-data-source-modal')"
      >
        <template #cell(type)="row">
          {{ formatDataSourceType(row.item.type) }}
        </template>
        <template #head(usage)>
          <span v-b-tooltip.hover.noninteractive.viewport="'Data source usage in search engines'">Usage</span>
        </template>
      </table-data>
    </b-card>
    <b-modal
      id="create-data-source-modal"
      title="Create New Data Source"
      size="lg"
      centered
      no-close-on-backdrop
      @hidden="resetForm"
      @ok="createDataSource"
    >
      <b-form @submit.prevent>
        <edit-key-value
          class="my-3"
          key-prop="Name"
          description="Enter a name for the data source"
          :value-prop="newDataSource.name"
          autofocus
          :min-key-width="keyWidth"
          type="input"
          :state="!$v.newDataSource.name.$invalid"
          @input="(x) => newDataSource.name = x"
        >
          <template #feedback>
            <b-form-invalid-feedback v-if="!$v.newDataSource.name.required">
              You must name the data source
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.newDataSource.name.maxLength">
              The name is too long
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.newDataSource.name.isUnique">
              This name is already taken
            </b-form-invalid-feedback>
          </template>
        </edit-key-value>
        <edit-key-value
          class="my-3"
          key-prop="Description"
          description="Enter a description for the data source"
          :value-prop="newDataSource.description"
          :min-key-width="keyWidth"
          type="textarea"
          @input="(x) => newDataSource.description = x"
        />
        <edit-key-value
          class="my-3"
          key-prop="Language"
          description="Choose data source language"
          :min-key-width="keyWidth"
          type="select"
          :options="languageOptions"
          :value-prop="newDataSource.language"
          :state="!$v.newDataSource.language.$invalid"
          @input="(x) => newDataSource.language = x"
        >
          <template #feedback>
            <b-form-invalid-feedback>
              You must choose a language
            </b-form-invalid-feedback>
          </template>
        </edit-key-value>
        <edit-key-value
          class="my-3"
          key-prop="Type"
          description="Specify the type of data source"
          :value-prop="newDataSource.type"
          :min-key-width="keyWidth"
          :options="newDataSourceTypes"
          :state="!$v.newDataSource.type.$invalid"
          type="select"
          @input="(x) => newDataSource.type = x"
        >
          <template #feedback>
            <b-form-invalid-feedback>
              You must choose a data source type
            </b-form-invalid-feedback>
          </template>
        </edit-key-value>

        <template v-if="newDataSourceExtraKey !== null">
          <template v-for="(obj, key) in newDataSourceExtra">
            <edit-key-value
              v-if="['token', 'password', 'zendeskToken'].includes(key)"
              :key="key"
              class="my-3"
              :key-prop="obj.label"
              :description="obj.description"
              :value-prop="obj.value"
              :min-key-width="keyWidth"
              type="password"
              :state="getExtraKeyState(key)"
              @input="(x) => obj.value = x"
            >
              <template #feedback>
                <b-form-invalid-feedback>
                  This field cannot be empty
                </b-form-invalid-feedback>
              </template>
            </edit-key-value>
            <edit-key-value
              v-else-if="checkExtraKey(key)"
              :key="key"
              class="my-3"
              :key-prop="obj.label"
              :description="obj.description"
              :value-prop="obj.value"
              :min-key-width="keyWidth"
              type="input"
              :state="getExtraKeyState(key)"
              @input="(x) => obj.value = x"
            >
              <template #feedback>
                <b-form-invalid-feedback>
                  This field cannot be empty
                </b-form-invalid-feedback>
              </template>
            </edit-key-value>
          </template>
        </template>
      </b-form>
      <b-link
        v-if="['newOnenote', 'newSharepoint'].includes(newDataSourceExtraKey)"
        v-b-modal.onenote-documentation
      >
        <h6>
          Click here to see connection documentation
        </h6>
      </b-link>
      <onenote-documentation />
      <template #modal-footer="{ cancel, ok }">
        <test-connection-button
          v-if="showTestConnection"
          :disabled="disableTestConnection"
          :request-data="connectionCredentials"
        />
        <b-button
          variant="secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="isOkDisabled"
          @click="ok()"
        >
          Create Data Source
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
import { mapActions, mapState, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import TableData from 'supwiz/components/TableData.vue';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import { dataSourceTypes } from '@/js/constants';
import { DEFAULT_LINK_EXTRACTOR } from '@/js/selenium_constants';
import OnenoteDocumentation from '@/components/DataSource/OnenoteDocumentation.vue';
import TestConnectionButton from '@/components/DataSource/TestConnectionButton.vue';
import { objToSnake } from '@/js/utils';
import { textFormatter } from 'supwiz/util/formatters';

export default {
  name: 'DataSourceOverview',
  components: {
    TableData, EditKeyValue, OnenoteDocumentation, TestConnectionButton,
  },
  mixins: [validationMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.languageOptions.length) {
        vm.fetchLanguages();
      }
    });
  },
  data() {
    return {
      dataSourceTypes,
      newDataSource: this.dataSourceTemplate(),
      newZendesk: this.zendeskTemplate(),
      newSupchat: this.supchatTemplate(),
      newPuzzel: this.puzzelTemplate(),
      newTicketAnalyzer: this.ticketAnalyzerTemplate(),
      newWebScraper: this.webScraperTemplate(),
      newRightAnswers: this.rightAnswersTemplate(),
      newBomgar: this.bomgarTemplate(),
      newServiceNow: this.serviceNowTemplate(),
      newOnenote: this.onenoteTemplate(),
      newSharepoint: this.sharepointTemplate(),
      newTopdesk: this.topdeskTemplate(),
      fields: [
        { key: 'delete', label: '', tdClass: 'delete-column' },
        {
          label: 'Last modified', key: 'modifiedTime', tdClass: 'time-col', sortable: true, formatter: (value) => (value ? new Date(value).toLocaleString() : '-'),
        },
        {
          label: 'Name', key: 'name', tdClass: 'align-middle', sortable: true,
        },
        {
          label: 'Type', key: 'type', tdClass: 'align-middle', sortable: true,
        },
        {
          label: 'Description', key: 'description', class: 'flex-grow-1', tdClass: 'align-middle', formatter: (v) => textFormatter(v, 120),
        },
        {
          label: 'Articles #', key: 'articleCount', thClass: 'text-center', tdClass: 'align-middle text-center', formatter: (v) => (v || '0'), sortable: true,
        },
        {
          label: 'Chats #', key: 'chatCount', thClass: 'text-center', tdClass: 'align-middle text-center', formatter: (v) => (v || '0'), sortable: true,
        },
        {
          label: 'Tickets #', key: 'ticketCount', thClass: 'text-center', tdClass: 'align-middle text-center', formatter: (v) => (v || '0'), sortable: true,
        },
        {
          label: 'Questions #', key: 'questionCount', thClass: 'text-center', tdClass: 'align-middle text-center', formatter: (v) => (v || '0'), sortable: true,
        },
        {
          label: '', key: 'usage', class: '', thClass: 'text-center', tdClass: 'align-middle text-center', formatter: (v) => (v || '0'), sortable: true,
        },
      ],
      filterKeyword: '',
    };
  },
  computed: {
    ...mapGetters('language', ['languageOptions']),
    ...mapState('dataSource', { dataSources: 'items' }),
    filteredDataSources() {
      return Object.values(this.dataSources)
        .filter((e) => e.type !== this.dataSourceTypes.RANKER.value
        && (e.name.toLowerCase().includes(this.filterKeyword.toLowerCase())
        || Object.values(dataSourceTypes).find((t) => t.value === e.type).text.toLowerCase()
          .includes(this.filterKeyword.toLowerCase())));
    },
    keyWidth() {
      return 160;
    },
    newDataSourceExtraKey() {
      if (this.newDataSource.type === dataSourceTypes.ZENDESK.value) {
        return 'newZendesk';
      }
      if (this.newDataSource.type === dataSourceTypes.SUPCHAT.value) {
        return 'newSupchat';
      }
      if (this.newDataSource.type === dataSourceTypes.PUZZEL.value) {
        return 'newPuzzel';
      }
      if (this.newDataSource.type === dataSourceTypes.TICKETANALYZER.value) {
        return 'newTicketAnalyzer';
      }
      if (this.newDataSource.type === dataSourceTypes.RIGHTANSWERS.value) {
        return 'newRightAnswers';
      }
      if (this.newDataSource.type === dataSourceTypes.BOMGAR.value) {
        return 'newBomgar';
      }
      if (this.newDataSource.type === dataSourceTypes.SERVICENOW.value) {
        return 'newServiceNow';
      }
      if (this.newDataSource.type === dataSourceTypes.ONENOTE.value) {
        return 'newOnenote';
      }
      if (this.newDataSource.type === dataSourceTypes.SHAREPOINT.value) {
        return 'newSharepoint';
      }
      if (this.newDataSource.type === dataSourceTypes.TOPDESK.value) {
        return 'newTopdesk';
      }
      return null;
    },
    newDataSourceExtra: {
      get() {
        return this[this.newDataSourceExtraKey];
      },
      set(val) {
        this[this.newDataSourceExtraKey] = val;
      },
    },
    newDataSourceTypes() {
      return Object.values(dataSourceTypes).filter(
        (x) => (x.value !== dataSourceTypes.RANKER.value),
      );
    },
    isOkDisabled() {
      if (this.newDataSource.type === dataSourceTypes.ZENDESK.value) {
        return this.$v.newDataSource.$invalid || this.$v.newZendesk.$invalid;
      }
      if (this.newDataSource.type === dataSourceTypes.SUPCHAT.value) {
        return this.$v.newDataSource.$invalid || this.$v.newSupchat.$invalid;
      }
      if (this.newDataSource.type === dataSourceTypes.PUZZEL.value) {
        return this.$v.newDataSource.$invalid || this.$v.newPuzzel.$invalid;
      }
      if (this.newDataSource.type === dataSourceTypes.TICKETANALYZER.value) {
        return this.$v.newDataSource.$invalid || this.$v.newTicketAnalyzer.$invalid;
      }
      if (this.newDataSource.type === dataSourceTypes.RIGHTANSWERS.value) {
        return this.$v.newDataSource.$invalid || this.$v.newRightAnswers.$invalid;
      }
      if (this.newDataSource.type === dataSourceTypes.BOMGAR.value) {
        return this.$v.newDataSource.$invalid || this.$v.newBomgar.$invalid;
      }
      if (this.newDataSource.type === dataSourceTypes.SERVICENOW.value) {
        return this.$v.newDataSource.$invalid || this.$v.newServiceNow.$invalid;
      }
      if (this.newDataSource.type === dataSourceTypes.ONENOTE.value) {
        return this.$v.newDataSource.$invalid || this.$v.newOnenote.$invalid;
      }
      return this.$v.newDataSource.$invalid;
    },
    showTestConnection() {
      return ![dataSourceTypes.WEBSCRAPER.value, dataSourceTypes.UPLOAD.value, '']
        .includes(this.newDataSource.type);
    },
    disableTestConnection() {
      switch (this.newDataSource.type) {
        case dataSourceTypes.ZENDESK.value:
          return this.$v.newZendesk.$invalid;
        case dataSourceTypes.SUPCHAT.value:
          return this.$v.newSupchat.$invalid;
        case dataSourceTypes.PUZZEL.value:
          return this.$v.newPuzzel.$invalid;
        case dataSourceTypes.TICKETANALYZER.value:
          return this.$v.newTicketAnalyzer.$invalid;
        case dataSourceTypes.RIGHTANSWERS.value:
          return this.$v.newRightAnswers.$invalid;
        case dataSourceTypes.BOMGAR.value:
          return this.$v.newBomgar.$invalid;
        case dataSourceTypes.SERVICENOW.value:
          return this.$v.newServiceNow.$invalid;
        case dataSourceTypes.ONENOTE.value:
          return this.$v.newOnenote.$invalid;
        case dataSourceTypes.SHAREPOINT.value:
          return this.$v.newSharepoint.$invalid;
        case dataSourceTypes.TOPDESK.value:
          return this.$v.newTopdesk.$invalid;
        default:
          return false;
      }
    },
    connectionCredentials() {
      const requestData = { data_source_type: this.newDataSource.type, credentials: {} };
      for (const entry of Object.entries(objToSnake(this.newDataSourceExtra))) {
        requestData.credentials[entry[0]] = entry[1].value;
      }
      return requestData;
    },
  },
  methods: {
    ...mapActions('language', { fetchLanguages: 'fetchItems' }),
    ...mapActions('dataSource', { addDataSource: 'addItem' }),
    ...mapActions('dataSource', { deleteDataSource: 'deleteItem' }),
    resetForm() {
      this.newDataSource = this.dataSourceTemplate();
      this.newZendesk = this.zendeskTemplate();
      this.newSupchat = this.supchatTemplate();
      this.newPuzzel = this.puzzelTemplate();
      this.newTicketAnalyzer = this.ticketAnalyzerTemplate();
      this.newWebScraper = this.webScraperTemplate();
      this.newRightAnswers = this.rightAnswersTemplate();
      this.newBomgar = this.bomgarTemplate();
      this.newServiceNow = this.serviceNowTemplate();
      this.newOnenote = this.onenoteTemplate();
      this.newSharepoint = this.sharepointTemplate();
      this.newTopdesk = this.topdeskTemplate();
    },
    async createDataSource(modalEvent) {
      modalEvent.preventDefault();
      const data = { ...this.newDataSource };
      if (this.newDataSourceExtra) {
        for (const [k, v] of Object.entries(this.newDataSourceExtra)) {
          data[k] = v.value;
        }
      }
      // The webscraber is handled explicitly, since we do not do validation,
      // but provide default values from the frontend
      if (this.newDataSource.type === dataSourceTypes.WEBSCRAPER.value) {
        for (const [k, v] of Object.entries(this.newWebScraper)) {
          data[k] = v.value;
        }
      }
      if (await this.addDataSource({ newItem: data })) {
        this.$nextTick(() => {
          this.$bvModal.hide('create-data-source-modal');
        });
      }
    },
    async deleteDataSourceLocal(item) {
      const modalText = `Are you sure that you want to delete ${item.name}?`;
      const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        this.deleteDataSource({ item });
      }
    },
    setEditing(dataSource) {
      this.$router.push({ name: 'data-source-config', params: { dataSourceId: dataSource.id } });
    },
    formatDataSourceType(type) {
      return type ? Object.values(this.dataSourceTypes).find((e) => e.value === type).text : '';
    },
    getExtraKeyState(key) {
      if (this.newDataSource.type === dataSourceTypes.ZENDESK.value) {
        return !this.$v[this.newDataSourceExtraKey][key].$invalid;
      }
      return !this.$v[this.newDataSourceExtraKey].$each[key].value.$invalid;
    },
    checkExtraKey(key) {
      if (this.newDataSource.type === dataSourceTypes.ZENDESK.value) {
        return Boolean(this.$v[this.newDataSourceExtraKey][key]);
      }
      return Boolean(this.$v[this.newDataSourceExtraKey].$each[key]);
    },
    dataSourceTemplate() {
      return {
        name: '',
        description: '',
        type: '',
        language: null,
      };
    },
    zendeskTemplate() {
      return {
        endpointUrl: {
          label: 'Endpoint',
          value: '',
          description: 'URL to Zendesk API. Example: https://somename.zendesk.com/',
        },
        token: { label: 'Chat token', value: '', description: 'API token to the Zopim API' },
        zendeskToken: { label: 'Ticket token', value: '', description: 'API token to the Zendesk API' },
      };
    },
    supchatTemplate() {
      return {
        endpoint_url: {
          label: 'Endpoint',
          value: '',
          description: 'URL to SupChat API. Example: https://somename.supchat.supwizapp.com',
        },
        username: { label: 'Username', value: '' },
        password: { label: 'Password', value: '' },
      };
    },
    puzzelTemplate() {
      return {
        customer_key: { label: 'Customer key', value: '' },
        username: { label: 'Username', value: '' },
        password: { label: 'Password', value: '' },
      };
    },
    ticketAnalyzerTemplate() {
      return {
        endpoint_url: {
          label: 'Endpoint',
          value: '',
          description: 'URL to TicketAnalyzer API. Example: https://somename.ticketanalyzer.supwizapp.com',
        },
        username: { label: 'Username', value: '' },
        password: { label: 'Password', value: '' },
      };
    },
    webScraperTemplate() {
      return {
        definition: { value: cloneDeep(DEFAULT_LINK_EXTRACTOR) },
        start_urls: { value: [] },
      };
    },
    rightAnswersTemplate() {
      return {
        endpoint_url: {
          label: 'Endpoint',
          value: '',
          description: 'URL to RightAnswers API. Example: https://somename.rightanswers.com/portal/api/soap?wsdl',
        },
        company_code: { label: 'Company code', value: '' },
        app_interface: { label: 'App interface', value: '' },
        username: { label: 'Username', value: '' },
        password: { label: 'Password', value: '' },
      };
    },
    bomgarTemplate() {
      return {
        endpoint_url: {
          label: 'Endpoint',
          value: '',
          description: 'URL to Bomgar API. Example: https://somename.bomgarcloud.com/',
        },
        client_id: { label: 'Client ID', value: '' },
        client_secret: { label: 'Client secret', value: '' },
      };
    },
    serviceNowTemplate() {
      return {
        endpoint_url: {
          label: 'Endpoint',
          value: '',
          description: 'URL to ServiceNow API. Example: https://somename.service-now.com/api/now/table/kb_knowledge',
        },
        username: { label: 'Username', value: '' },
        password: { label: 'Password', value: '' },
      };
    },
    onenoteTemplate() {
      return {
        tenant_id: { label: 'Tenant ID', value: '' },
        client_id: { label: 'Client ID', value: '' },
        client_secret: { label: 'Client secret', value: '' },
      };
    },
    sharepointTemplate() {
      return {
        tenant_id: { label: 'Tenant ID', value: '' },
        client_id: { label: 'Client ID', value: '' },
        client_secret: { label: 'Client secret', value: '' },
      };
    },
    topdeskTemplate() {
      return {
        endpoint_url: {
          label: 'Endpoint',
          value: '',
          description: 'URL to TopDesk instance. Example: https://somename.topdesk.net',
        },
        username: { label: 'Username', value: '' },
        password: { label: 'Password', value: '' },
      };
    },
  },
  validations() {
    return {
      newDataSource: {
        name: {
          required,
          maxLength: maxLength(120),
          isUnique(value) {
            return !Object.values(this.dataSources).map(
              (dataSource) => dataSource.name,
            ).includes(value);
          },
        },
        type: {
          required,
        },
        language: {
          required,
        },
      },
      newZendesk: {
        endpointUrl: {
          value: {
            required,
          },
        },
        token: {
          value: {
            required: requiredIf(() => !this.newZendesk.zendeskToken.value),
          },
        },
        zendeskToken: {
          value: {
            required: requiredIf(() => !this.newZendesk.token.value),
          },
        },
      },
      newSupchat: {
        $each: {
          value: {
            required,
          },
        },
      },
      newPuzzel: {
        $each: {
          value: {
            required,
          },
        },
      },
      newTicketAnalyzer: {
        $each: {
          value: {
            required,
          },
        },
      },
      newRightAnswers: {
        $each: {
          value: {
            required,
          },
        },
      },
      newBomgar: {
        $each: {
          value: {
            required,
          },
        },
      },
      newServiceNow: {
        $each: {
          value: {
            required,
          },
        },
      },
      newTopdesk: {
        $each: {
          value: {
            required,
          },
        },
      },
      newOnenote: {
        $each: {
          value: {
            required,
          },
        },
      },
      newSharepoint: {
        $each: {
          value: {
            required,
          },
        },
      },
    };
  },
};
</script>
<style scoped>
::v-deep .time-col{
  word-break: normal;
  width: 100px;
}
</style>
