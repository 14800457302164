<template>
  <div>
    <b-card
      title="Questions"
      class="r-75"
      body-class="p-3"
    >
      <small v-if="genAiEnabled" class="text-muted d-block my-2">
        <font-awesome-icon icon="circle-exclamation" color="orange" />
        There is no guarantee that questions can be successfully generated for all articles.
      </small>
      <TaskStatus :task-types="taskTypes" />

      <b-row class="my-2">
        <b-col>
          <b-input-group>
            <b-input-group-prepend>
              <b-dropdown :text="`Search (${getSelectedSearchFieldsText})`" toggle-class="search-btn">
                <b-dropdown-form>
                  <b-form-checkbox-group
                    v-model="selectedSearchFields"
                    :options="searchOptions"
                    stacked
                  />
                </b-dropdown-form>
              </b-dropdown>
            </b-input-group-prepend>
            <b-form-input v-model="searchKeyword" @keyup.enter="refreshQuestions(true)" />
            <b-input-group-append>
              <b-button variant="primary" @click="refreshQuestions(true)">
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="my-2">
        <b-col class="my-auto">
          <b-pagination
            v-model="currentPage"
            size="sm"
            class="my-auto"
            :total-rows="pagination.count"
            :per-page="pagination.perPage"
            aria-controls="article-questions-table"
          />
        </b-col>
        <b-col v-if="genAiEnabled" cols="auto" class="my-auto">
          <DebounceButton
            :disabled="hasRunningDataSourceTasks"
            text="Generate"
            @click="startGenerating"
          />
        </b-col>
      </b-row>
      <b-list-group-item
        v-if="articlesWithQuestions.length === 0"
        class="my-auto text-center text-muted"
      >
        No Questions Found
      </b-list-group-item>
      <b-table
        v-else
        id="article-questions-table"
        ref="article-questions-table"
        :fields="articleFields"
        :per-page="pagination.perPage"
        :caption="tableCaption"
        :items="questionsArray"
        class="mb-0"
        show-empty
        empty-text="There are currently no articles."
      >
        <template #cell(id)="data">
          <b-row class="m-1">
            <b-link
              v-b-tooltip.hover.noninteractive.viewport="'Open article in a new tab'"
              class="float-right"
              @click.stop="openArticle(data.item)"
            >
              {{ data.item.title }}
              <font-awesome-icon icon="arrow-up-right-from-square" />
            </b-link>
          </b-row>
          <b-row class="m-1">
            {{ data.item.currentUrl }}
          </b-row>
        </template>
        <template #cell(questions)="data">
          <question-block
            v-if="data.item.id && data.item.questions"
            :questions="data.item.questions"
            :article-id="data.item.id"
            :generate-disabled="hasRunningDataSourceTasks"
            @refresh-questions="refreshQuestions"
          />
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';
import { objToCamel } from '@/js/utils';
import QuestionBlock from '@/components/DataSource/QuestionBlock.vue';
import TaskStatus from '@/components/TaskStatus.vue';

export default {
  name: 'DataSourceQuestions',
  components: { QuestionBlock, TaskStatus },
  data() {
    return {
      articleFields: [
        { key: 'id', label: 'Article' },
        'questions',
      ],
      searchKeyword: '',
      selectedSearchFields: ['title', 'text', 'current_url'],
      searchOptions: [
        { text: 'Title', value: 'title' },
        { text: 'Text', value: 'text' },
        { text: 'Current url', value: 'current_url' },
      ],
      taskTypes: ['gen_questions'],
      intervalId: null,
      lastUpdated: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['genAiEnabled']),
    ...mapGetters('article', { articlesWithQuestions: 'items' }),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    ...mapState('article', ['pagination']),
    ...mapGetters('task', ['getTasksOfTypes']),
    runningTasks() {
      return this.getTasksOfTypes(this.taskTypes).activeTasks.filter((e) => e.status === 'pending');
    },
    hasRunningDataSourceTasks() {
      return !!this.runningTasks
        .filter((e) => e.meta.data_source === this.dataSourceDetails.id).length;
    },
    tableCaption() {
      return `Last updated: ${this.lastUpdated ? this.lastUpdated.toLocaleTimeString() : '-'}`;
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
    getSelectedSearchFieldsText() {
      return this.selectedSearchFields.map((e) => this.searchOptions
        .find((o) => o.value === e).text).join(', ');
    },
    questionsArray() {
      const articlesArray = Object.values(this.articlesWithQuestions);
      return Object.values(articlesArray.map((c) => objToCamel(c)).map((c) => {
        if (c.questions) {
          c.questions.sort((a, b) => b.id - a.id);
        }
        return c;
      }));
    },
  },
  watch: {
    runningTasks(n) {
      if (n.length) {
        if (!this.intervalId) {
          this.intervalId = setInterval(this.refreshData, 5000);
        }
      } else if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.refreshData();
      }
    },
    currentPage() {
      this.refreshData();
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  created() {
    this.refreshData();
  },
  methods: {
    ...mapMutations('article', ['updatePagination']),
    ...mapActions('question', ['generateQuestions']),
    ...mapActions('article', { fetchArticles: 'fetchItems' }),
    openArticle(row) {
      const newPage = this.$router.resolve({
        name: 'articles-single',
        params: { articleId: row.id, dataSourceId: row.dataSource },
      });
      window.open(newPage.href, '_blank');
    },
    refreshQuestions(resetPagination = false) {
      if (resetPagination) {
        this.currentPage = 1;
        this.$refs['article-questions-table'].refresh();
      }
      this.refreshData();
    },
    startGenerating() {
      this.generateQuestions({ dataSourceId: this.dataSourceDetails.id, questionsPerArticle: 5 });
    },
    async refreshData() {
      const params = {
        data_source: this.dataSourceDetails.id,
        page: this.pagination.page,
        with_questions: true,
        disabled: false,
      };
      if (this.searchKeyword) {
        params.search = this.searchKeyword;
        params.search_fields = this.selectedSearchFields;
      }
      await this.fetchArticles({ params });
      this.lastUpdated = new Date();
    },
  },
};
</script>
<style scoped>
.action-button {
  border: 0;
  color: grey;
}
.action-button:hover{
  color:black;
}
::v-deep .search-btn{
  color: grey !important;
  background-color: #eee !important;
  font-weight: 400 !important;
}
</style>
