<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in data"
        :key="index"
        class="p-3"
        :header="item.title"
      >
        <h5 class="font-weight-bolder mb-0">
          <font-awesome-icon
            v-if="item.pinned"
            v-b-tooltip.hover.noninteractive.viewport="'Pinned article'"
            icon="map-pin"
            class="handle"
          />
          {{ item.title }}
        </h5>

        <b-collapse :visible="showDetails" class="mt-2">
          <b-row>
            <b-col>
              <strong>
                <strong>Score:</strong> {{ item.score.toFixed(3) }}
                <br>
                <strong>ID:</strong> {{ item.id }}
                <br>
                <strong>External ID:</strong> {{ item.external_id }}
                <br>
                <strong>Link:</strong> {{ item.link }}
              </strong>
            </b-col>
          </b-row>
          <b-row v-if="item.summary" class="mt-2">
            <b-col>
              <h6 class="font-weight-bolder">
                Summary
              </h6>
              <p class="mb-0">
                {{ item.summary }}
              </p>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <h6 class="font-weight-bolder">
                Extract
              </h6>
              <p class="mb-0">
                {{ item.extract }}
              </p>
            </b-col>
          </b-row>
        </b-collapse>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
export default {
  name: 'ArticleOutput',
  props: {
    data: {
      type: Array,
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
