<template>
  <b-row
    v-if="isFetching"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <router-view v-else />
</template>

<script>

import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  name: 'DataSourceSingle',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchDataSourceDetails({ id: to.params.dataSourceId });
      vm.localIsFetching = false; // eslint-disable-line no-param-reassign
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.dataSourceId !== from.params.dataSourceId) {
      this.fetchDataSourceDetails({ id: to.params.dataSourceId });
    }
    next();
  },
  data() {
    return {
      localIsFetching: true,
    };
  },
  computed: {
    ...mapState('dataSource', ['isFetchingDetails']),
    isFetching() {
      return this.localIsFetching || this.isFetchingDetails;
    },
  },
  destroyed() {
    this.setDataSourceDetails({});
    this.resetArticlePagination();
    this.resetChatPagination();
    this.resetQueryPagination();
    this.resetTicketPagination();
  },
  methods: {
    ...mapActions('dataSource', { fetchDataSourceDetails: 'fetchItemDetails' }),
    ...mapMutations('dataSource', { setDataSourceDetails: 'setItemDetails' }),
    ...mapMutations('article', { resetArticlePagination: 'resetPagination' }),
    ...mapMutations('chat', { resetChatPagination: 'resetPagination' }),
    ...mapMutations('query', { resetQueryPagination: 'resetPagination' }),
    ...mapMutations('ticket', { resetTicketPagination: 'resetPagination' }),
  },
};
</script>
