<template>
  <b-card
    class="r-75"
    body-class="p-3"
    title="Whitelist words"
    :class="unsavedChanges ? 'border-warning' : ''"
  >
    <div class="mb-3">
      <edit-key-value
        class="my-3"
        key-prop="GPT Whitelist"
        description="Enable to utilize the list defined below. If disabled, the list is still saved but not used."
        :value-prop="localData.config.gpt_whitelist_enabled"
        :min-key-width="180"
        type="checkbox"
        @input="(x) => localData.config.gpt_whitelist_enabled = x"
      />
      <b-form-group label="Type a new word/phrase and press enter">
        <b-form-tags
          v-model="localData.config.gpt_whitelist"
          :tag-validator="tagValidator"
          placeholder=""
        />
        <template #description>
          If a text/query gives a too low ranking score but contains any of the
          above whitelist words, then gpt generates a generic response (i.e. without
          using top k predicted articles as context).<br>Maximum input length is 127 characters.
        </template>
      </b-form-group>
    </div>
    <div>
      <b-button
        variant="primary"
        :disabled="!unsavedChanges"
        @click="updateRanker(localData)"
      >
        Update
      </b-button>
    </div>
    <span
      v-if="unsavedChanges"
      class="unsaved-text"
    >
      *Unsaved changes
    </span>
  </b-card>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { cloneDeep, isEqual } from 'lodash';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';

export default {
  name: 'GPTRules',
  components: {
    EditKeyValue,
  },
  data() {
    return {
      localData: [],
    };
  },
  computed: {
    ...mapState('ranker', { rankerDetails: 'details' }),
    unsavedChanges() {
      const localCopy = cloneDeep({
        gpt_whitelist: this.localData.config.gpt_whitelist,
        gpt_whitelist_enabled: this.localData.config.gpt_whitelist_enabled,
      });
      const originalCopy = cloneDeep({
        gpt_whitelist: this.rankerDetails.config.gpt_whitelist,
        gpt_whitelist_enabled: this.rankerDetails.config.gpt_whitelist_enabled,
      });
      return !isEqual(localCopy, originalCopy);
    },
  },
  created() {
    this.localData = { ...cloneDeep(this.rankerDetails) };
  },
  methods: {
    ...mapActions('ranker', { updateRanker: 'patchItem' }),
    tagValidator(tag) {
      return tag.length < 128;
    },
  },

};
</script>
