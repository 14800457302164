<template>
  <base-activity
    ref="baseActivity"
    :scraper-id="scraperId"
    :command-id="commandId"
    :indent="indent"
    bs-variant="dark"
    v-on="$listeners"
  >
    <template #icon>
      <font-awesome-icon icon="arrow-pointer" />
    </template>
    <template #header>
      {{ command.source_var }}.click()
    </template>
    <b-row>
      <b-col cols="10">
        <b-form-group
          label="Variable:"
        >
          <b-form-input
            id="source-var"
            :value="command.source_var"
            type="text"
            @input="value => $root.$emit('update-scraper', { commandId, prop: 'source_var', value })"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group
          label="Optional"
        >
          <b-form-checkbox
            v-b-tooltip.hover
            title="If enabled the action is skipped if the variable is not clickable"
            switch
            :checked="command.nullable"
            @input="$root.$emit('update-scraper', { commandId, prop: 'nullable', value: !command.nullable })"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </base-activity>
</template>

<script>
import BaseActivity from '@/components/WebScraper/SeleniumCommands/BaseCommand.vue';

export default {
  name: 'ClickCommand',
  components: { BaseActivity },
  props: {
    scraperId: {
      type: Number,
      required: true,
    },
    commandId: {
      type: String,
      required: true,
    },
    command: {
      type: Object,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
};
</script>
