import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'supwiz/styling/supwiz_bootstrap.scss';

import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
// Import and configure icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@/js/icons';

import { sync } from 'vuex-router-sync';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import DebounceButton from 'supwiz/components/DebounceButton.vue';

// this provides access to the route paramters
sync(store, router);
// However, I was still missing router functionality like push.
// My ad hock solution is to bind it to the store state (then we might not need the above)
store.state.$router = router;

const isProduction = process.env.NODE_ENV === 'production';

Vue.config.productionTip = false;
// Register font-awesome-icon globally, so that we don't need to register it within every component.
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('DebounceButton', DebounceButton);
Vue.use(BootstrapVue);

Vue.prototype.goTo404Page = () => {
  if (router.history.current.name !== '404') {
    router.push({ name: '404' });
  }
};
Vue.prototype.goToLoginPage = () => {
  if (router.history.current.name !== 'login') {
    router.push({ name: 'login', query: { originalPath: router.history.current.path } });
  }
};

const vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

if (!isProduction) {
  const consoleErrorOrg = console.error;
  // eslint-disable-next-line no-inner-declarations
  function consoleErrorMock() {
    // eslint-disable-next-line prefer-rest-params
    consoleErrorOrg(...arguments);
    if (vue) {
      vue.$bvToast.toast('Console has errors', {
        title: '[SupwizDEV]: An error occurred in console',
        appendToast: true,
        variant: 'danger',
      });
    }
  }
  console.error = consoleErrorMock;
}
