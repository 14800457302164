import { render, staticRenderFns } from "./TicketsSingle.vue?vue&type=template&id=129d2086&scoped=true"
import script from "./TicketsSingle.vue?vue&type=script&lang=js"
export * from "./TicketsSingle.vue?vue&type=script&lang=js"
import style0 from "./TicketsSingle.vue?vue&type=style&index=0&id=129d2086&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "129d2086",
  null
  
)

export default component.exports