<template>
  <div>
    <b-card
      title="Configuration"
      class="r-75"
      body-class="p-3"
      :class="unsavedChanges ? 'border-warning' : ''"
    >
      <edit-key-value
        class="my-3"
        key-prop="Name"
        description="Enter a name for the Search Engine"
        :value-prop="localData.name"
        :min-key-width="keyWidth"
        type="input"
        :state="!$v.localData.name.$invalid"
        @input="(x) => localData.name = x"
      >
        <template #feedback>
          <b-form-invalid-feedback
            v-if="!$v.localData.name.required"
          >
            You must name the Search Engine
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="!$v.localData.name.maxLength"
          >
            The name is too long
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="!$v.localData.name.isUnique"
          >
            This name is already taken
          </b-form-invalid-feedback>
        </template>
      </edit-key-value>
      <edit-key-value
        class="my-3"
        key-prop="Description"
        description="Enter a description for the Search Engine"
        :value-prop="localData.description"
        :min-key-width="keyWidth"
        type="textarea"
        @input="(x) => localData.description = x"
      />
      <edit-key-value
        v-if="isTrainable && allRankerOptions.length > 1"
        class="my-3"
        key-prop="Type"
        description="Choose how the search engine should be trained"
        :value-prop="localData.config.type"
        :min-key-width="keyWidth"
        :options="allRankerOptions"
        type="select"
        @input="(x) => localData.config.type = x"
      />
      <edit-key-value
        v-if="isTrainable"
        class="my-3"
        key-prop="Language"
        description="Choose language model used for training"
        :value-prop="localData.config.language_model"
        :min-key-width="keyWidth"
        :options="languageModelOptions"
        type="select"
        @input="(x) => localData.config.language_model = x"
      />
      <edit-key-value
        class="my-3"
        key-prop="Train automatically"
        description="Train automatically once a week if sufficient amount of data has been included or any article has been updated"
        :value-prop="localData.autoTrain"
        :min-key-width="keyWidth"
        type="checkbox"
        @input="(x) => localData.autoTrain = x"
      />
      <template v-if="showClassicSearch">
        <edit-key-value
          class="mt-3"
          key-prop="Include classic search engine"
          description="The output of the search engine will be weighted with the output of
                a classic search. This will make results more robust"
          :value-prop="localData.config.use_classic_search"
          :min-key-width="keyWidth"
          type="checkbox"
          @input="(x) => localData.config.use_classic_search = x"
        />
      </template>
      <template v-if="showTargetLimitChoice">
        <edit-key-value
          class="mt-3"
          key-prop="Limit number of articles"
          :description="showTargetLimit ? ''
            : 'Limit the predictions to the most commonly used articles based on supervised data'"
          :value-prop="showTargetLimit"
          :min-key-width="keyWidth"
          type="checkbox"
          @input="(x) => showTargetLimit = x"
        />
        <edit-key-value
          v-if="showTargetLimit"
          class="mb-3 mt-1"
          key-prop="Article limit"
          description="Limit the predictions to the most commonly used articles
              based on supervised data"
          :state="!$v.localData.config.n_targets_limit.$invalid"
          :value-prop="localData.config.n_targets_limit"
          :min-key-width="keyWidth"
          type="number"
          @input="(x) => localData.config.n_targets_limit = x"
        >
          <template #feedback>
            <b-form-invalid-feedback>
              Must be a positive number.
            </b-form-invalid-feedback>
          </template>
        </edit-key-value>
      </template>
      <edit-key-value
        class="my-3"
        key-prop="Include auto labels"
        description="Include auto labels in the training data, e.g. datapoints collected
            from users clicking links to articles."
        :value-prop="localData.config.use_weak_targets"
        :min-key-width="keyWidth"
        type="checkbox"
        @input="(x) => localData.config.use_weak_targets = x"
      />
      <edit-key-value
        class="my-3"
        key-prop="Mininum score"
        description="When serving the predictions filter out results with score below this threshold"
        :value-prop="localData.serveMinScore"
        :min-key-width="keyWidth"
        type="range"
        :number-constraints="{ min: 0, max: 1, step: 0.001 }"
        @input="(x) => localData.serveMinScore = x"
      />
      <edit-key-value
        class="my-3"
        key-prop="Max results"
        description="When serving the predictions this is the maximal results returned"
        :value-prop="localData.serveMaxResults"
        :min-key-width="keyWidth"
        type="number"
        :number-constraints="{ min: 1, max: 100 }"
        @input="(x) => localData.serveMaxResults = x"
      />
      <edit-key-value
        class="my-3"
        key-prop="Public Access"
        description="If enabled the search engine can be called without authentication
        and thus all articles in this search engine's range will be publicly accessible."
        :value-prop="localData.openApi"
        :min-key-width="keyWidth"
        type="checkbox"
        @input="(x) => localData.openApi = x"
      />
      <edit-key-value
        v-if="genAiEnabled"
        class="my-3"
        key-prop="User Faced"
        description="If enabled the generated answers are adjusted to fit end users. If disabled the answers are adjusted to fit agents."
        :value-prop="localData.gptUserFaced"
        :min-key-width="keyWidth"
        type="checkbox"
        @input="(x) => localData.gptUserFaced = x"
      />
      <div class="mt-3">
        <b-button
          variant="primary"
          class="mr-2"
          :disabled="$v.localData.$invalid || !unsavedChanges"
          :style="`width: ${keyWidth}px;`"
          @click="updateRankerProxy"
        >
          Update Configuration
        </b-button>
        <b-button
          variant="danger"
          @click="deleteRankerLocal"
        >
          Delete
        </b-button>
      </div>
      <span
        v-if="unsavedChanges"
        class="unsaved-text"
      >
        *Unsaved changes
      </span>
    </b-card>
    <training-data />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import requiredIf from 'vuelidate/lib/validators/requiredIf';
import { cloneDeep, isEqual } from 'lodash';
import { languageModelName2pretty, rankerOptions } from '@/js/constants';
import TrainingData from '@/components/Ranker/TrainingData.vue';

export default {
  name: 'RankerConfig',
  components: {
    EditKeyValue,
    TrainingData,
  },
  mixins: [validationMixin],
  data() {
    return {
      localData: null,
      rankerOptions,
      showTargetLimit: false,
      useLatestPipelineBuild: true,
      tabIndex: 0,
    };
  },
  computed: {
    ...mapState('auth', ['globalConfig']),
    ...mapState('ranker', { rankers: 'items' }),
    ...mapState('ranker', { rankerDetails: 'details' }),
    ...mapGetters('ranker', ['isTrainable']),
    ...mapGetters('auth', ['genAiEnabled']),
    ...mapState('languageModel', { languageModels: 'items' }),
    ...mapState('rankerInstance', { rankerInstances: 'items' }),
    ...mapState({ isFetchingInstances: (state) => state.rankerInstance.isFetching }),
    ...mapGetters('pipeline', ['isArticlePrediction']),
    ...mapState('pipelineBuild', { builds: 'items' }),
    keyWidth() {
      return 240;
    },
    allRankerOptions() {
      return [
        { value: 'auto', text: 'Automatic' },
      ].concat(this.rankerOptions.concat(
        [
          { value: 'classic', text: 'Classic Search Only' },
        ],
      ));
    },
    languageModelOptions() {
      let languageModels = Object.values(this.languageModels);
      if (this.rankerDetails.createdAfterAiengine === true) {
        // Only present language models with associated AIEngine id
        languageModels = languageModels.filter((x) => x.aiengineId != null);
      }
      return languageModels.map(
        (x) => {
          let name = x.name;
          const pos = x.name.search('/');
          if (pos > 0) {
            const lang = x.name.slice(0, pos);
            if (Object.keys(languageModelName2pretty).includes(lang)) {
              name = languageModelName2pretty[lang];
            }
          }
          return {
            text: name,
            value: x.id,
            disabled: x.aiengineId === null,
          };
        },
      );
    },
    initModelOptions() {
      const options = Object.values(this.rankerInstances).filter(
        (x) => x.accuracy !== null
          && x.config.type === 'unsupervised_1'
          && x.config.language_model === this.localData.config.language_model,
      );
      return options.map(
        (x) => ({ value: x.id, text: `ID: ${x.id} | ${x.createdTime}` }),
      );
    },
    buildOptions() {
      return Object.values(this.builds).filter((x) => !x.cleared).map((x) => ({
        text: x.createdTime,
        value: x.id,
      }));
    },
    showTargetLimitChoice() {
      return !['supervised', 'auto'].includes(this.localData.config.type);
    },
    unsavedChanges() {
      const localCopy = cloneDeep(this.localData);
      const rankerCopy = cloneDeep(this.rankerDetails);
      return !isEqual(localCopy, rankerCopy);
    },
    showClassicSearch() {
      return this.isArticlePrediction(this.rankerDetails.pipeline.type)
      && !['classic', 'auto'].includes(this.localData.config.type);
    },
  },
  watch: {
    showTargetLimit(newVal) {
      if (!newVal) {
        this.localData.config.n_targets_limit = null;
      }
    },
    useLatestPipelineBuild(newVal) {
      if (newVal) {
        this.localData.config.build = null;
      }
    },
  },
  beforeDestroy() {
  },
  created() {
    if (this.$route.hash.includes('training-data')) {
      this.tabIndex = 1;
    }
    this.localData = { ...cloneDeep(this.rankerDetails) };
    this.showTargetLimit = Boolean(this.localData.config.n_targets_limit);
    if (this.localData.config.build) {
      this.useLatestPipelineBuild = false;
    }
  },
  methods: {
    ...mapActions('ranker', { updateRanker: 'patchItem' }),
    ...mapActions('ranker', { deleteRanker: 'deleteItem' }),
    async deleteRankerLocal() {
      const modalText = `Are you sure that you want to delete ${this.rankerDetails.name}?`;
      const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        const ok = await this.deleteRanker({ item: this.rankerDetails });
        if (ok) {
          this.$router.push({ name: 'ranker-overview' });
        }
      }
    },
    async updateRankerProxy() {
      await this.updateRanker(this.localData);
    },
  },
  validations() {
    return {
      localData: {
        name: {
          required,
          maxLength: maxLength(120),
          isUnique(value) {
            return !Object.values(this.rankers)
              .map((ranker) => (ranker.id !== this.localData.id ? ranker.name : undefined))
              .includes(value);
          },
        },
        config: {
          n_targets_limit: {
            required: requiredIf(() => this.showTargetLimit),
            positiveNumber: (value) => (this.showTargetLimit ? value > 0 : true),
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.form-check *{
  cursor:pointer;
}
</style>
