<template>
  <div id="swagger_api_docs">
    <div v-if="swaggerFile != null">
      <redoc-wrapper
        :spec-or-spec-url="swaggerFile"
        :options="redocOptions"
      />
    </div>
  </div>
</template>

<script>
// 'https://petstore.swagger.io/v2/swagger.json'
import { mapActions, mapGetters } from 'vuex';
import RedocWrapper from '@hnluu8/vue-redoc-wrapper';

export default {
  name: 'SwaggerDocs',
  components: {
    RedocWrapper,
  },
  data() {
    return {
      // https://github.com/Redocly/redoc#redoc-options-object
      redocOptions: {
        hideDownloadButton: false,
      },
    };
  },
  computed: {
    ...mapGetters('docs', ['swaggerFile']),
  },
  async created() {
    await this.ensureSwaggerFile();
  },
  methods: {
    ...mapActions('docs', ['ensureSwaggerFile']),
  },
};
</script>
  <style>
  .menu-content{
    top:0px !important;
  }
  </style>
