import axios from 'axios';
import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import Shared from '@/js/vuexTemplates/shared';
import { getModuleProps } from '@/js/utils';

const dataSourceState = {};

const dataSourceGetters = {};

const mutations = {};

const actions = {
  async testConnection({ rootGetters, rootState, dispatch }, requestData) {
    let endpoint = `${endpoints.dataSource}`;
    if (requestData === undefined) {
      endpoint += `${rootState.dataSource.details.id}/`;
    }
    endpoint += 'test-connection/';
    const headerAuthorization = rootGetters['auth/headerAuthorization'];
    try {
      const { data } = await axios.post(endpoint, requestData, headerAuthorization);
      if (data.success) {
        dispatch('sidebar/showWarning', {
          title: 'Connection test succeeded',
          text: 'Endpoint and credentials verified',
          variant: 'success',
        }, { root: true });
        return true;
      }
      dispatch('sidebar/showWarning', { title: 'Connection test failed', text: data.error }, { root: true });
      return false;
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to test datasource connection',
        text: error.message,
      }, { root: true });
    }
    return false;
  },
};

const templates = [
  new GeneralTemplate(endpoints.dataSource, 'dataSource'),
  new Shared('dataSource'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: dataSourceState, getters: dataSourceGetters, mutations, actions,
  }, templates),
};
