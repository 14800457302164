<template>
  <router-view />
</template>
<script>
export default {
  mounted() {
    this.checkPath();
  },
  methods: {
    checkPath() {
      let location = '';
      if (this.$route.name.includes('article')) {
        location = 'article';
      } else if (this.$route.name.includes('chat')) {
        location = 'chat';
      } else if (this.$route.name.includes('ticket')) {
        location = 'ticket';
      }
      if (!this.$store.getters['sidebar/pages'].map((e) => e.display.toLowerCase()).find((el) => el.includes(location))) {
        this.goTo404Page();
      }
    },
  },

};
</script>
