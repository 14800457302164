<template>
  <b-card class="r-75">
    <CollapsibleSection
      v-for="{ title, fields } in detailsStructure"
      :key="title"
      :start-visible="true"
      :title="title"
    >
      <template #content>
        <b-list-group class="mt-2">
          <MetaField
            v-for="field in fields"
            :key="field.key"
            v-bind="{ field }"
          />
        </b-list-group>
      </template>
    </CollapsibleSection>
  </b-card>
</template>

<script>
import { formatNumber } from 'supwiz/util/formatters';
import { camelCaseToText } from '@/js/utils';
import CollapsibleSection from '@/components/DataSource/CollapsibleSection.vue';
import MetaField from './MetaField.vue';

export default {
  name: 'DetailsCard',
  components: { CollapsibleSection, MetaField },
  props: {
    details: {
      type: Object,
      required: true,
    },
    sections: {
      type: Array,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
    keywords: {
      type: String,
      default: '',
    },
  },
  computed: {
    detailsStructure() {
      return this.sections.map(({ title, fields }) => {
        if (fields.length < 1) {
          return {};
        }
        return {
          title,
          fields: fields.map(this.getMetaFieldObj).filter((x) => x != null),
        };
      });
    },
  },
  methods: {
    formatDate(value) {
      try {
        const parsed = new Date(value);
        return parsed.toLocaleString();
      } catch {
        return value;
      }
    },
    getMetaFieldObj({ key, tooltip }) {
      const details = this.details;
      const keyObjRaw = details[key];

      return this.prepareKvObj(key, keyObjRaw, tooltip);
    },
    prepareKvObj(key, value, tooltip) {
      /* expected output, * indicates optional
      {
        stack: Boolean,
        hideKey: Boolean,
        keyFormatted: String*,
        key: String,
        negative: Boolean*,
        value: Boolean, Number, String, Object, Array
      }
      */
      const result = { key, value };
      result.keyFormatted = camelCaseToText(key);
      result.tooltip = tooltip;
      switch (key) {
        case 'id':
          result.keyFormatted = `${this.dataType.charAt(0).toUpperCase() + this.dataType.slice(1)} ID`;
          break;
        case 'externalId':
          result.keyFormatted = 'External ID';
          break;
        case 'urls':
          result.stack = true;
          result.keyFormatted = 'Links';
          break;
        case 'articleCreated': case 'ticketCreated':
          result.keyFormatted = 'Created';
          result.value = value === null ? '' : this.formatDate(value);
          break;
        case 'articleModified': case 'ticketModified':
          result.keyFormatted = 'Modified';
          result.value = value === null ? '' : this.formatDate(value);
          break;
        case 'chatEnded':
          if (value === null) {
            result.value = '';
          }
          result.value = value === null ? '' : this.formatDate(value);
          break;
        case 'timestamp':
          result.value = value === null ? '' : this.formatDate(value);
          break;
        case 'keywords':
          result.value = this.keywords;
          break;
        case 'wasUseful':
          result.value = this.details.prediction.was_useful;
          break;
        case 'originFile':
          if (this.details.originFile?.url == null) {
            return null;
          }
          result.keyFormatted = 'File';
          break;
        default:
          if (value === null) {
            result.value = '';
          } else if (typeof value === 'object') {
            if (Array.isArray(value)) {
              if (!value.length) result.value = '';
            } else if (!Object.keys(value)?.length) {
              result.value = '';
            }
          } else if (typeof value === 'number') {
            result.value = formatNumber(value);
          }
          break;
      }
      return result;
    },
  },
};
</script>
