<template>
  <div>
    <b-card
      title="Language Model Overview"
      class="r-75"
      body-class="p-3"
    >
      <table-data
        :fields="fields"
        :items="Object.values(languageModels)"
        :text-length="300"
        hover
        @row-clicked="rowClicked"
      />
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapState } from 'vuex';
import TableData from 'supwiz/components/TableData.vue';

export default {
  name: 'Overview',
  components: { TableData },
  mixins: [validationMixin],
  data() {
    return {};
  },
  computed: {
    ...mapState('languageModel', {
      languageModels: 'items',
    }),
    fields() {
      return [
        { key: 'delete', label: '', tdClass: 'delete-column' },
        { key: 'id', label: 'Id', tdClass: 'table-nobreak' },
        {
          label: 'Name', key: 'name', class: 'w-25', tdClass: 'align-middle',
        },
        'description',
        'createdTime',
      ];
    },
  },
  methods: {
    rowClicked(row) {
      this.$router.push({ name: 'language-model-single', params: { languageModelId: row.id } });
    },
  },
};
</script>

<style scoped>

</style>
