import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import { getModuleProps } from '@/js/utils';

const datapointState = {};

const datapointGetters = {};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.datapoint, 'datapoint'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: datapointState, getters: datapointGetters, mutations, actions,
  }, templates),
};
