<template>
  <app-template
    v-bind="{
      pages,
      showSidebar,
      userDetails,
      toolDetails: {
        name: 'SupSearch',
        logo: require('./assets/supwiz-logo-flat.svg'),
        env: docFinderEnvironment,
      },
      showSpinner: pageLoading,
      loggedIn,
    }"
    :notifications-center="!searchVisible"
    :breadcrumbs="!searchVisible"
  >
    <template #header>
      <div
        v-if="loggedIn && $route.name !== 'home'"
        class="ml-auto mr-3"
      >
        <b-nav v-if="!isArticleViewerOrNone" pills>
          <b-nav-item
            v-if="hasAccess('regular')"
            v-b-tooltip.hover.noninteractive
            title="Data Sources"
            class="menu-item text-center mb-0 ease-in-out"
            :to="{ name: 'data-source-overview' }"
            :class="{ active: isRouteActive('data-source') }"
          >
            <font-awesome-icon
              icon="folder-open"
              size="lg"
            />
          </b-nav-item>
          <b-nav-item
            v-if="hasAccess('observer')"
            v-b-tooltip.hover.noninteractive
            title="Search Engines"
            class="menu-item text-center mb-0 ease-in-out"
            :to="{ name: 'ranker-overview' }"
            :class="{ active: isRouteActive('ranker') }"
          >
            <font-awesome-icon
              icon="exchange-alt"
              size="lg"
              style="transform: rotate(90deg);"
            />
          </b-nav-item>
          <b-nav-item
            v-if="hasAccess('admin')"
            v-b-tooltip.hover.noninteractive
            title="Configuration"
            class="menu-item text-center mb-0 ease-in-out"
            :to="{ name: 'global-anonymization' }"
            :class="{ active: isRouteActive('global-config') }"
          >
            <font-awesome-icon
              icon="cog"
              size="lg"
            />
          </b-nav-item>
        </b-nav>
      </div>
      <b-button
        v-if="loggedIn"
        id="info-button"
        v-b-tooltip.noninteractive.bottom
        v-b-modal.version-info-modal
        title="Show software version"
        class="d-flex justify-content-center
        align-items-center overflow-hidden
        shadow-none font-weight-bold mr-3"
      >
        <font-awesome-icon
          size="lg"
          icon="info"
        />
      </b-button>
      <b-modal
        id="version-info-modal"
        title="Version Information"
        hide-footer
      >
        <b-table
          borderless
          striped
          small
          :items="versionInfoArray"
        />
      </b-modal>
    </template>
    <template
      #page-content
    >
      <transition
        mode="out-in"
        name="fade"
      >
        <router-view />
      </transition>
    </template>
  </app-template>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AppTemplate from 'supwiz/components/template/AppTemplate.vue';
import { getSupSearchEnvironment, versionInfoArray } from '@/js/utils';

export default {
  components: { AppTemplate },
  computed: {
    ...mapState('sidebar', ['pageLoading', 'showSidebar']),
    ...mapGetters('sidebar', ['pages', 'loggedIn']),
    ...mapGetters('auth', ['displayName', 'isArticleViewerOrNone', 'hasAccess']),
    userDetails() {
      return {
        displayName: this.displayName,
      };
    },
    docFinderEnvironment() {
      return getSupSearchEnvironment();
    },
    searchVisible() {
      return this.$route.path.includes('search');
    },
  },
  methods: {
    versionInfoArray,
    isRouteActive(name) {
      return this.$route.path.includes(name);
    },
  },
};
</script>

<style scoped>
#info-button{
  background: rgba(0,0,0,0.1);
  border-color: transparent;
  color: #fff;
  min-height: 36.4px;
  max-height: 36.4px;
  min-width: 36.4px;
  max-width: 36.4px;
}
#info-button:hover {
  background-color: rgba(0,0,0,0.25)!important;
}
.nav-item {
  opacity:.5;
}
.nav-item > a{
  color:#fff;
  padding: 7px;
  border: none !important;
}
.nav-item > a:hover{
  color:white;
}
::v-deep .dropdown .nav-link {
  color:#fff;
  padding: 7px;
  border: none !important;
}
::v-deep .dropdown .nav-link:hover {
  color:#fff;
}
.nav-item.active {
  background:rgba(0, 0, 0, 0.25)!important;
  opacity:.9;
}
.nav-item.show {
  background:rgba(0, 0, 0, 0.25)!important;
  opacity:1;
  z-index: 1;
}
.menu-item {
  width:50px !important;
  height:36px !important;
  border: none!important;
}
</style>

<style>
 body, html{
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}
::-ms-reveal {
  display: none !important;
}
#app {
  background-image:
    linear-gradient(to right, rgba(2,76,115,0.97), rgba(2,76,115,0.97)),
    url('./assets/background-pattern.svg');
}
.switch-text{
  display: block;
  font-size: 1.1em;
}
.border-warning{
  border: 3px solid #E99002 !important;
  margin: -3px !important;
}
.unsaved-text{
  font-size: 80%;
  color:#E99002 !important;
  line-height: 1.5;
  font-weight: 400;
}
</style>
