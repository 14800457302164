<template>
  <b-row class="mt-2">
    <b-col cols="4" class="pr-2">
      <b-card
        no-body
        class="r-25 border"
        header-class="py-2"
      >
        <template #header>
          <b-row>
            <b-col class="my-auto">
              <h5 class="mb-0">
                {{ selectedCountry ? `Top cities from ${selectedCountry}` : 'Top countries' }}
              </h5>
            </b-col>
            <b-col v-if="selectedCountry" cols="auto">
              <b-button variant="primary" size="sm" @click="selectedCountry = null">
                Return
              </b-button>
            </b-col>
          </b-row>
        </template>
        <b-list-group flush>
          <b-list-group-item v-if="!listData.length">
            There is no data to show.
          </b-list-group-item>
          <b-list-group-item
            v-for="(item, index) in listData"
            v-else
            :key="index"
            class="d-flex justify-content-between align-items-center"
            :button="!selectedCountry"
            @click="selectedCountry ? null : countryClicked(item)"
          >
            {{ item.country || item.city }}
            <h5 class="mb-0">
              <b-badge variant="primary" pill class="h4 mb-0">
                {{ item.count }}
              </b-badge>
            </h5>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-col>
    <b-col cols="8" class="pl-2">
      <div ref="container" class="map-container border r-25" />
    </b-col>
  </b-row>
</template>

<script>
import initializeMap from '@/js/choropleth';

export default {
  name: 'ChoroplethMap',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      resizeTimeout: null,
      selectedCountry: null,
    };
  },
  computed: {
    topCountries() {
      return [...this.items].sort((a, b) => b.count - a.count).slice(0, 5);
    },
    topCities() {
      const currentCountry = this.items.find((e) => e.country === this.selectedCountry);
      return (currentCountry?.city || []).sort((a, b) => b.count - a.count).slice(0, 5);
    },
    listData() {
      return this.selectedCountry ? this.topCities : this.topCountries;
    },
  },
  watch: {
    visible(n) {
      if (n) {
        this.initializeMap(true);
      }
    },
  },
  created() {
    window.addEventListener('resize', this.debouncedResizeMap);
    if (this.visible) {
      this.$nextTick(() => {
        this.initializeMap(true);
      });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.debouncedResizeMap);
  },
  mounted() {
    this.$root.$on('resetStatisticsDetails', () => {
      this.initializeMap(true);
    });
  },
  methods: {
    debouncedResizeMap() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.resizeMap();
      }, 400);
    },
    resizeMap() {
      this.$nextTick(() => {
        this.initializeMap(true);
      });
    },
    initializeMap(clear = false) {
      initializeMap(this.$refs.container, this.items, this.countryClicked, clear);
    },
    countryClicked(d) {
      this.selectedCountry = d.country || d.properties?.name;
    },
  },
};
</script>
<style scoped>
.map-container {
  max-height: 100%;
  overflow: hidden;
}
</style>
<style>
.map-tooltip{
  position: absolute;
  background-color: white;
  border: 1px solid #00000020;
  text-align:center;
  min-width: 100px;
  border-radius: 0.25rem;
  padding: 5px 10px;
  opacity: 0;
}
</style>
