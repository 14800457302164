import endpoints from '@/js/endpoints';

export const DataTypes = {
  ARTICLE: 'article',
  CHAT: 'chat',
  ACTIVITY: 'activity',
  TICKET: 'ticket',
  QUERY: 'query',
};
export const rankerOptions = [
  { value: 'supervised', text: 'Supervised' },
  { value: 'unsupervised_2', text: 'Unsupervised' },
];
export const dataSourceTypes = {
  UPLOAD: { value: 'uploaddatasource', text: 'Upload' },
  ZENDESK: { value: 'zendeskdatasource', text: 'Zendesk' },
  SUPCHAT: { value: 'supchatdatasource', text: 'SupChat' },
  PUZZEL: { value: 'puzzeldatasource', text: 'Puzzel' },
  TICKETANALYZER: { value: 'ticketanalyzerdatasource', text: 'Ticket Analyzer' },
  WEBSCRAPER: { value: 'webscraper', text: 'Web Scraper' },
  RIGHTANSWERS: { value: 'rightanswersdatasource', text: 'Right Answers' },
  BOMGAR: { value: 'bomgardatasource', text: 'Bomgar' },
  RANKER: { value: 'rankerdatasource', text: 'Collected Data' },
  SERVICENOW: { value: 'servicenowdatasource', text: 'ServiceNow' },
  ONENOTE: { value: 'onenotedatasource', text: 'OneNote' },
  SHAREPOINT: { value: 'sharepointdatasource', text: 'Sharepoint' },
  TOPDESK: { value: 'topdeskdatasource', text: 'TopDesk' },
};

export const pipelineTypeToDataType = {
  articlepipelinesource: 'article',
  chatpipelinesource: 'chat',
  ticketpipelinesource: 'ticket',
  querypipelinesource: 'query',
};

export const dataSourceTypeToEndpoint = {
  uploaddatasource: endpoints.dataSourceUpload,
  zendeskdatasource: endpoints.dataSourceZendesk,
  supchatdatasource: endpoints.dataSourceSupchat,
  puzzeldatasource: endpoints.dataSourcePuzzel,
  ticketanalyzerdatasource: endpoints.dataSourceTicketAnalyzer,
  webscraper: endpoints.webScraper,
  rightanswersdatasource: endpoints.dataSourceRightAnswers,
  bomgardatasource: endpoints.dataSourceBomgar,
  rankerdatasource: endpoints.dataSourceRanker,
  servicenowdatasource: endpoints.dataSourceServiceNow,
  onenotedatasource: endpoints.dataSourceOnenote,
  sharepointdatasource: endpoints.dataSourceSharepoint,
  topdeskdatasource: endpoints.dataSourceTopdesk,
};

export const pipelineSourceTypes = {
  ARTICLE: { value: 'articlepipelinesource', text: 'Article' },
  CHAT: { value: 'chatpipelinesource', text: 'Chat' },
  TICKET: { value: 'ticketpipelinesource', text: 'Ticket' },
  QUERY: { value: 'querypipelinesource', text: 'Query' },
};

export const ticketInputSpecs = {
  CHAT: 'chat',
  DESCRIPTION: 'description',
};

export const pipelineTypes = {
  ARTICLE_PREDICTION: 'article_prediction',
  CHAT_FIELD_VALUE_PREDICTION: 'chat_field_value_prediction',
  ACTIVITY_FIELD_VALUE_PREDICTION: 'activity_field_value_prediction',
  TICKET_FIELD_VALUE_PREDICTION: 'ticket_field_value_prediction',
};

export const fieldDataTypes = {
  ARTICLE: { value: 'article', text: 'Article' },
  CHAT: { value: 'chat', text: 'Chat' },
  ACTIVITY: { value: 'activity', text: 'Activity' },
  TICKET: { value: 'ticket', text: 'Ticket' },
  QUERY: { value: 'query', text: 'Query' },
};

// Statistics
export const intervalOptions = Object.freeze({
  HOUR: 'Hour',
  DAY: 'Day',
  MONTH: 'Month',
});

export const statisticsColors = [
  'rgb(42, 61, 81)',
  'rgb(37, 115, 166)',
  'rgb(84, 110, 138)',
  'rgb(63, 80, 98)',
  'rgb(42, 128, 185)',
  'rgb(95, 124, 155)',
  'rgb(84, 99, 115)',
  'rgb(63, 140, 192)',
  'rgb(106, 138, 173)',
  'rgb(105, 119, 133)',
  'rgb(84, 153, 199)',
  'rgb(106, 138, 173)',
  'rgb(127, 138, 150)',
  'rgb(105, 166, 206)',
  'rgb(120, 149, 181)',
  'rgb(127, 178, 213)',
  'rgb(148, 158, 168)',
  'rgb(135, 161, 189)',
  'rgb(148, 191, 220)',
  'rgb(169, 177, 185)',
  'rgb(150, 173, 197)',
  'rgb(169, 204, 227)',
  'rgb(165, 175, 186)',
  'rgb(165, 184, 205)',
  'rgb(191, 216, 234)',
  'rgb(175, 184, 194)',
  'rgb(180, 196, 214)',
  'rgb(185, 193, 201)',
  'rgb(195, 202, 209)',
  'rgb(195, 208, 222)',
  'rgb(205, 211, 217)',
  'rgb(225, 228, 232)',

];

export const metricOptions = Object.freeze([
  { value: 'traffic', text: 'Traffic', tooltip: '' },
  { value: 'devices', text: 'Devices', tooltip: '' },
  { value: 'location', text: 'Location', tooltip: '' },
  { value: 'urls', text: 'Urls', tooltip: '' },
  { value: 'top_keywords', text: 'Top topics', tooltip: '' },
  { value: 'top_predictions', text: 'Top predicted articles', tooltip: '' },
  { value: 'least_predicted', text: 'Least predicted articles', tooltip: '' },
  { value: 'least_useful_articles', text: 'Least useful articles', tooltip: '' },
  { value: 'knowledge_gaps', text: 'Knowledge gap topics', tooltip: '' },
]);
export const metricMap = Object.freeze({
  knowledge_gaps: 'knowledge_gaps',
  top_keywords: 'keyword_counts',
  top_predictions: 'prediction_counts',
  least_predicted: 'least_predicted',
  least_useful_articles: 'least_useful_articles',
  devices: 'devices',
  location: 'location',
  urls: 'urls',
  traffic: 'volume_over_time',
});
export const clickableMetrics = Object.freeze([
  'traffic',
  'knowledge_gaps',
]);

export const defaultFilterValues = {
  interval: intervalOptions.DAY,
  startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
  endDate: new Date(),
  metrics: [
    metricOptions[0].value,
    metricOptions[1].value,
    metricOptions[2].value,
    metricOptions[3].value,
    metricOptions[4].value,
    metricOptions[5].value,
    metricOptions[6].value,
    metricOptions[7].value,
    metricOptions[8].value,
  ],
};

export const rankerType2pretty = {
  supervised: 'Supervised',
  unsupervised_2: 'Unsupervised',
  classic: 'Classic Search Only',
  auto: 'Automatic',
};

export const languageModelName2pretty = {
  eng: 'English',
  nl: 'Dutch',
  dan: 'Danish',
  tur: 'Turkish',
  tyr: 'Turkish',
  swe: 'Swedish',
  fin: 'Finnish',
  fra: 'French',
  lit: 'Lithuanian',
  deu: 'German',
  spa: 'Spanish',
  ita: 'Italian',
  nor: 'Norwegian',
};

export const taskType2Text = Object.freeze({
  searcher_training: 'Search Engine Optimization',
  ranker_training: 'Search Engine Training',
  anonymization: 'Anonymization Job',
  config_anonymization: 'Anonymization Job (Configuration)',
  ranker_stats: 'Statistics Computation',
  pipeline_build: 'Dataset Build',
  pipeline_cleanup: 'Dataset Cleanup',
  scraping: 'Scraping',
  scrape_test: 'Scraping Test',
  article_import: 'Article Import',
  chat_import: 'Chat Import',
  ticket_import: 'Ticket Import',
  process_file_uploads: 'Process File Uploads',
  gen_questions: 'Generate Questions',
});
export const taskStatus = Object.freeze([
  { value: 'done', text: 'Done' },
  { value: 'aborted', text: 'Aborted' },
  { value: 'failed', text: 'Failed' },
  { value: 'running', text: 'Running' },
]);

export const accessLevels = {
  admin: 5,
  regular: 4,
  trainer: 3,
  observer: 2,
  article_reader: 1,
};
