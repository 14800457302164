import * as d3 from 'd3';
import world from '@/components/Ranker/Statistics/world.json';

export default function initializeMap(containerRef, countries, countryClicked, clear = false) {
  // the container element
  const container = d3.select(containerRef);

  // calculate container width and set height based on aspect ratio
  const containerWidth = container.node()?.getBoundingClientRect()?.width || 0;
  const aspectRatio = 0.5;
  const containerHeight = containerWidth * aspectRatio;

  // clear old data on resize
  if (clear) {
    const containerNode = container.node();
    const svgElement = containerNode?.querySelector('svg:last-child');
    if (svgElement) {
      svgElement.remove();
    }
  }

  // initialize different elements
  const tooltip = d3.select('body')
    .append('div')
    .attr('class', 'map-tooltip')
    .style('opacity', 0);

  const projection = d3.geoMercator()
    .scale(containerWidth / 2 / Math.PI)
    .translate([containerWidth / 2, containerHeight / 2])
    .center([0, 20]);

  const svg = d3.select(containerRef).append('svg')
    .attr('width', containerWidth)
    .attr('height', containerHeight);

  const path = d3.geoPath()
    .projection(projection);

  const group = svg.append('g');

  const colorScale = d3.scaleThreshold()
    .domain([1, 10, 100, 1000, 10000, 100000])
    .range(d3.schemeBlues[7]);

  // initialize events
  // eslint-disable-next-line func-names
  const mouseOver = function (event, d) {
    tooltip.html(`${d.properties.name}<br>${d.total}`)
      .transition()
      .duration(100)
      .style('left', `${event.pageX - 70}px`)
      .style('top', `${event.pageY - 70}px`)
      .style('opacity', 1)
      .style('z-index', '100');

    d3.selectAll('.Country')
      .transition()
      .duration(200)
      .style('opacity', 0.5)
      .style('stroke', 'transparent');
    d3.select(this)
      .transition()
      .duration(200)
      .style('opacity', 1)
      .style('stroke', '#005F89')
      .style('stroke-width', '0.5px')
      .style('cursor', 'pointer');
  };

  // eslint-disable-next-line func-names
  const mouseLeave = function () {
    tooltip.transition()
      .duration(100)
      .style('opacity', 0)
      .style('z-index', '-100');

    d3.selectAll('.Country')
      .transition()
      .duration(200)
      .style('opacity', 0.8);
    d3.select(this)
      .transition()
      .duration(200)
      .style('stroke', 'transparent');
  };

  // draw map and add data
  group.selectAll('path')
    .data(world.features)
    .enter()
    .append('path')
    .attr('d', path)
    .attr('fill', (d) => {
      const current = countries.find((e) => e.country === d.properties.name);
      // eslint-disable-next-line no-param-reassign
      d.total = current?.count || 0;
      return colorScale(d.total);
    })
    .on('mouseover', mouseOver)
    .on('mouseleave', mouseLeave)
    .on('click', (event, d) => {
      countryClicked(d);
    });

  // zooming
  const zoom = d3.zoom()
    .scaleExtent([1, 8])
    .on('zoom', (event) => {
      group.selectAll('path')
        .attr('transform', event.transform);
    });
  svg.call(zoom);
}
