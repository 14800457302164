import templateStore from 'supwiz/components/template/templateStore';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import anonymizationStore from 'supwiz/components/anonymization/anonymizationStore';
import statisticsFiltersStore from 'supwiz/components/statisticsFilters/statisticsFiltersStore';
import { camelCaseToText } from '@/js/utils';
import { accessLevels } from '@/js/constants';
import article from './modules/article';
import auth from './modules/auth';
import chat from './modules/chat';
import chatActivity from './modules/chatActivity';
import customRule from './modules/customRule';
import datapoint from './modules/datapoint';
import dataSource from './modules/dataSource';
import docs from './modules/docs';
import field from './modules/field';
import fieldValue from './modules/fieldValue';
import languageModel from './modules/languageModel';
import language from './modules/language';
import pipeline from './modules/pipeline';
import pipelineBuild from './modules/pipelineBuild';
import pipelineSource from './modules/pipelineSource';
import ranker from './modules/ranker';
import rankerInstance from './modules/rankerInstance';
import sidebar from './modules/sidebar';
import task from './modules/task';
import ticket from './modules/ticket';
import ticketActivity from './modules/ticketActivity';
import user from './modules/user';
import query from './modules/query';
import question from './modules/question';
import metaDataField from './modules/metaDataField';
import fileUpload from './modules/fileUpload';
import widgetConfig from './modules/widgetConfig';
import searchFilterField from './modules/searchFilterField';
import searchFilterValue from './modules/searchFilterValue';

Vue.use(Vuex);

const incrementPlugin = (store) => {
  // check if token should be refreshed
  setInterval(() => {
    store.dispatch('auth/inspectToken', { shouldRefresh: true });
  }, 600000);
  // fetch task status
  if (store.state.auth.loggedIn) {
    store.dispatch('task/fetchTaskList');
  }
  setInterval(async () => {
    if (store.state.auth.loggedIn) {
      store.dispatch('task/fetchTaskList');
    }
  }, 5000);
};

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    async authentication({
      state,
      commit,
      dispatch,
      rootState,
    }) {
      if (state.auth.jwt) {
        try {
          // If Promise does not reject, it is assumed that token was verified
          await dispatch('auth/verifyToken');
          commit('auth/updateLoggedIn', true);
          commit('auth/updateOngoingSession', true);
          if (rootState.auth.user === null) {
            await dispatch('auth/getUser');

            // return success early for < regular accounts
            // remaining data is not allowed or needed
            if ((accessLevels[rootState.auth.user.group] || 0) <= accessLevels.trainer) return 'success';

            // its put here to only load on first authentication attempt
            dispatch('field/fetchItems');
            dispatch('fieldValue/fetchItems');
            dispatch('languageModel/fetchItems', {}, { root: true });
          }
          if (rootState.auth.globalConfig === null) {
            await dispatch('auth/getGlobalConfig');
          }
          return 'success';
        } catch (e) {
          commit('auth/updateLoggedIn', false);
          commit('auth/updateOngoingSession', false);
          throw new Error(e);
        }
      }
      return 'no token';
    },
    async postTask({ rootGetters, dispatch }, {
      endpoint,
      data,
      operationType,
    }) {
      let formattedType = camelCaseToText(operationType);
      formattedType = formattedType.charAt(0).toUpperCase() + formattedType.slice(1);
      // function to be run on task fail
      const taskFailedFunc = (msg) => {
        dispatch('sidebar/showWarning', {
          title: 'Task failed',
          text: msg,
        }, { root: true });
      };
      try {
        const headerAuthorization = rootGetters['auth/headerAuthorization'];
        const resp = await axios.post(endpoint, data, headerAuthorization);
        if ('celery_id' in resp.data) {
          return resp.data.celery_id;
        }
        if (resp.status === 200 || resp.status === 201) {
          dispatch('sidebar/showWarning', {
            title: `${formattedType} finished`,
            text: `${formattedType} has finished successfully.`,
            variant: 'primary',
          }, { root: true });
        } else {
          dispatch('sidebar/showWarning', {
            title: 'Unexpected Response',
            text: `${formattedType} task got unexpected response.`,
          }, { root: true });
        }
        return false;
      } catch (error) {
        if (error.request.status === 413) {
          taskFailedFunc('The file type is not accepted. Please check the file. It may be corrupted or not matching the file type given by its extension.');
        } else if (error.request.status === 480) {
          taskFailedFunc('Could not process the file. This is most likely because the file is too large.\n Please lower the size to below 200MB and try uploading it again.');
        } else {
          taskFailedFunc(error.message);
        }
        throw error;
      }
    },
  },
  modules: {
    auth,
    sidebar,
    ranker,
    rankerInstance,
    customRule,
    dataSource,
    article,
    chat,
    chatActivity,
    docs,
    task,
    templateStore,
    languageModel,
    language,
    ticket,
    ticketActivity,
    user,
    query,
    question,
    pipeline,
    pipelineSource,
    pipelineBuild,
    field,
    fieldValue,
    datapoint,
    anonymizationStore,
    statisticsFiltersStore,
    metaDataField,
    fileUpload,
    widgetConfig,
    searchFilterField,
    searchFilterValue,
  },

  plugins: [incrementPlugin],
});
