import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import SharedTemplate from '@/js/vuexTemplates/shared';
import { getModuleProps } from '@/js/utils';

const queryState = {
  showMetaData: true,
};

const queryGetters = {
  showMetaData: (state) => state.showMetaData,
};

const mutations = {
  setShowMetaData(state, payload) {
    state.showMetaData = payload;
  },
};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.query, 'query'),
  new SharedTemplate('query'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: queryState, getters: queryGetters, mutations, actions,
  }, templates),
};
