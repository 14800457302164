<template>
  <base-activity
    ref="baseActivity"
    :scraper-id="scraperId"
    :command-id="commandId"
    :indent="indent"
    bs-variant="info"
    v-on="$listeners"
  >
    <template #icon>
      <font-awesome-icon icon="code" />
    </template>
    <template #header>
      {{ command.target_var }} = {{ command.source_var }}.GetAttribute({{ command.param }})
    </template>
    <b-row>
      <b-col cols="10">
        <label for="target-var">
          Target Variable:
        </label>
        <b-form-input
          id="target-var"
          :value="command.target_var"
          type="text"
          @input="value => $root.$emit('update-scraper', { commandId, prop: 'target_var', value })"
        />
      </b-col>
      <b-col cols="2">
        <b-form-group
          label="Optional"
        >
          <b-form-checkbox
            v-b-tooltip.hover
            title="If the attribute is not found, then the page is skipped unless it is optional"
            switch
            :checked="command.nullable"
            @input="$root.$emit('update-scraper', { commandId, prop: 'nullable', value: !command.nullable })"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <label for="source-var">
      Source Variable:
    </label>
    <b-form-input
      id="source-var"
      :value="command.source_var"
      type="text"
      @input="value => $root.$emit('update-scraper', { commandId, prop: 'source_var', value })"
    />
    <label for="param">
      Attribute parameter:
    </label>
    <b-form-input
      id="param"
      :value="command.param"
      type="text"
      placeholder="Type FindElement parameter here"
      class="mb-2 mr-sm-2 mb-sm-0 text-monospace"
      @input="value => $root.$emit('update-scraper', { commandId, prop: 'param', value })"
    />
  </base-activity>
</template>

<script>
import BaseActivity from '@/components/WebScraper/SeleniumCommands/BaseCommand.vue';

export default {
  name: 'GetAttributeCommand',
  components: { BaseActivity },
  props: {
    scraperId: {
      type: Number,
      required: true,
    },
    commandId: {
      type: String,
      required: true,
    },
    command: {
      type: Object,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
};
</script>
