<template>
  <div>
    <b-modal
      id="operate"
      ref="operateModal"
      :title="modalTitle"
      no-close-on-backdrop
      :ok-disabled="$v.currentRule.$invalid || (pinAction && !anyPinnedArticles)"
      @hidden="reset"
      @ok="handleOk"
      @shown="prepareCurrentRule"
    >
      <b-form-group
        label="Name"
        label-for="name"
        invalid-feedback="This field is required"
      >
        <b-input
          id="name"
          v-model="currentRule.name"
          :state="!$v.currentRule.name.$invalid"
        />
      </b-form-group>
      <b-form-group
        label="Description"
        label-for="description"
      >
        <b-textarea
          id="description"
          v-model="currentRule.description"
          rows="2"
          max-rows="6"
        />
      </b-form-group>
      <b-form-group
        label="Stop if rule applies"
        label-for="stopIfApplies"
        description="If this rule applies, all subsequent rules are skipped."
      >
        <b-form-checkbox
          id="stopIfApplies"
          v-model="currentRule.stopIfApplies"
          size="sm"
          :disabled="stopIfAppliesDisabled"
          switch
        />
      </b-form-group>
      <hr>

      <span
        v-b-tooltip
        title="The conditions must be satisfied for a rule to apply"
      >
        Conditions
      </span>
      <text-condition
        :conditions="currentRule.conditions"
        :allow-meta-data="true"
        @editTextCondition="updateCondition"
        @addTextCondition="addCondition"
        @removeTextCondition="removeCondition"
      />
      <!-- Add new text condition modal -->
      <hr>
      <span
        v-b-tooltip
        title="Actions will only apply if AI confidence is below this threshold"
      >
        Confidence Threshold
      </span>
      <b-form-input
        v-model.number="currentRule.confidenceThreshold"
        type="range"
        min="0"
        max="1.0"
        step="0.05"
      />
      Skip rule if AI confidence is at or above
      {{ Math.round(currentRule.confidenceThreshold * 100) }}%
      <hr>
      <span
        v-b-tooltip
        title="What will happen if the conditions above apply"
      >
        {{ pinAction ? 'Pinned Articles' : 'Actions' }}
      </span>
      <div
        v-if="!pinAction"
      >
        <b-list-group class="mb-2 bg-light r-25 actions">
          <b-list-group-item
            v-for="(action, index) in currentRule.actions"
            :key="`${action.id}+${index}`"
            class="actions__action d-flex align-items-center m-1 r-25"
          >
            <span
              v-b-tooltip.viewport.noninteractive
              title="Click to edit"
              class="w-100"
            >
              <div
                class="flex-grow-1 pr-1 cursor-pointer"
                tabindex="0"
                @click="editAction(action)"
                @keyup.enter="editAction(action)"
              >
                <span class="action__key">{{ action.action }}</span>
                <span v-if="action.action === 'restrict'"> to</span>
                <span v-if="action.action !== 'dictate' && action.manual_multi_targets">
                  {{ action.multi_targets.length }} articles
                </span>
                <span v-if="action.action !== 'dictate' && !action.manual_multi_targets">
                  {{ action.field_values.length }} article attributes
                </span>
                <span v-if="action.action === 'redirect'"> to</span>
                <span v-if="action.action === 'boost'" class="action__key"> {{ Math.round((action.boosting_scalar - 1) * 100) }}%</span>
                <span v-if="['redirect', 'dictate'].includes(action.action)">
                  article with id <span class="action__key">{{ action.single_target }}</span>
                </span>
              </div>
            </span>
            <b-button
              v-b-tooltip.viewport.noninteractive
              title="Remove"
              type="button"
              class="btn-invis action__btn ease-in-out"
              @click="removeAction(index)"
            >
              <font-awesome-icon
                icon="times"
                style="transform: scale(1.25)"
              />
            </b-button>
          </b-list-group-item>
          <span
            v-if="currentRule.actions.length === 0"
            class="ml-1 p-1 d-block"
          >
            <em>No Actions - Nothing will happen!</em>
          </span>
        </b-list-group>
        <p />
        <b-button
          v-b-modal.createAction
          block
          variant="primary"
        >
          Add Action
        </b-button>
      </div>
      <div v-else-if="currentRule.actions.length">
        <draggable
          v-model="currentRule.actions[0].multi_targets"
          group="pinnedArticles"
          tag="b-list-group"
          handle=".handle"
          :class="{ ['cursor-grabbing']: dragging === true }"
          @start="dragging = true"
          @end="dragging = false"
        >
          <b-list-group-item
            v-if="!currentRule.actions[0].multi_targets.length"
          >
            There are no pinned articles
          </b-list-group-item>
          <b-list-group-item
            v-for="(article, index) in currentRule.actions[0].multi_targets"
            v-else
            :key="`${article}+${index}`"
            class="handle"
          >
            {{ article }}
            <b-button
              variant="outline-danger"
              class="d-inline float-right"
              title="Delete"
              size="sm"
              @click="currentRule.actions[0].multi_targets.splice(index, 1)"
            >
              <font-awesome-icon
                icon="times"
                style="transform: scale(1.5)"
              />
            </b-button>
          </b-list-group-item>
        </draggable>
        <b-button
          v-b-modal="'pinned-article-modal'"
          variant="primary"
          block
          class="mt-1"
        >
          Add Articles
        </b-button>
        <b-modal
          id="pinned-article-modal"
          title="Pin articles"
          @ok="addPinnedArticles"
          @hidden="resetAction"
        >
          <multi-select
            v-model="newPinnedArticles"
            :options="pinnedArticleOptions"
            multiple
            feedback
            selected-on-top
            typename="article"
            class="w-100"
          />
        </b-modal>
      </div>
    </b-modal>
    <b-modal
      id="createAction"
      ref="createActionModal"
      title="Action"
      :ok-disabled="$v.currentAction.$invalid"
      @ok="addAction"
      @hide="resetAction"
    >
      <b-row>
        <b-col>
          <b-form-group
            label="Operation"
            label-for="actionOp"
          >
            <b-form-select
              id="actionOp"
              v-model="currentAction.action"
              :options="actionOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >
                  No operation selected
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="displaySingleTarget">
        <b-col>
          <!-- Single Target -->
          <b-form-group label-for="singleTarget" :label="singleTargetLabel">
            <b-dropdown
              id="singleTarget"
              :text="getSingleTargetText"
              toggle-class="w-100 dropdown-button"
              menu-class="dropdown-menu"
              class="w-100"
            >
              <b-dropdown-form>
                <b-form-input v-model="singleTargetKeyword" size="sm" placeholder="Type to search" />
              </b-dropdown-form>
              <b-dropdown-item
                v-for="(item, index) of singleTargetOptions"
                :key="`${item.value}+${index}`"
                link-class="dropdown-item"
                @click="currentAction.single_target = `${item.value}`"
              >
                {{ item.text }}
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="displayMultiTarget">
        <b-col>
          <!-- Multi Target -->
          <b-form-group
            :label="multiTargetLabel"
            label-for="multiTargets"
          >
            <b-form-radio-group
              id="multiTargetType"
              v-model="currentAction.manual_multi_targets"
              class="mb-2"
              :options="[{ text: 'manually', value: true }, { text: 'via attributes', value: false }]"
              button-variant="outline-primary"
              buttons
            />
            <multi-select
              v-if="currentAction.manual_multi_targets"
              id="multiTargets"
              v-model="currentAction.multi_targets"
              :options="articleMultiselectOptions"
              multiple
              selected-on-top
              feedback
              typename="article"
              class="w-100"
            />
            <multi-select
              v-else
              id="fieldValueTargets"
              v-model="currentAction.field_values"
              :options="articleFieldValueOptions"
              multiple
              selected-on-top
              feedback
              typename="attribute"
              class="w-100"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="currentAction.action === 'boost'">
        <b-col>
          <b-form-input
            v-model.number="currentAction.boosting_scalar"
            type="range"
            min="0.01"
            max="6"
            step="0.01"
          />
          Matching articles will be boosted
          {{ Math.round((currentAction.boosting_scalar - 1) * 100) }}%
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import MultiSelect from 'supwiz/components/MultiSelect.vue';
import { cloneDeep } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minValue, integer, requiredIf,
} from 'vuelidate/lib/validators';
import Draggable from 'vuedraggable';
import TextCondition from '@/components/Ranker/TextCondition.vue';

export default {
  name: 'CustomRuleModal',
  components: {
    Draggable,
    MultiSelect,
    TextCondition,
  },
  mixins: [validationMixin],
  props: {
    rule: { type: Object, default: null },
    ruleCount: { type: Number, default: 0 },
    pinAction: { type: Boolean, default: false },
  },
  data() {
    return {
      currentRule: this.newRule(),
      conditionIndex: -1,
      conditionApplies: null,
      currentAction: this.newAction(),
      actionIndex: -1,
      // pinned articles
      dragging: false,
      newPinnedArticles: [],
      singleTargetKeyword: '',
    };
  },
  computed: {
    ...mapState('ranker', { rankerDetails: 'details' }),
    ...mapGetters('ranker', ['rankerArticleOptions', 'rankerArticleAttributeOptions']),
    ...mapState('widgetConfig', { widgetDetails: 'details' }),
    singleTargetOptions() {
      return this.rankerArticleOptions.filter((e) => e.text.toLowerCase()
        .includes(this.singleTargetKeyword.toLowerCase()));
    },
    getSingleTargetText() {
      return this.currentAction.single_target
        ? this.rankerArticleOptions.find((e) => parseInt(e.value, 10)
        === parseInt(this.currentAction.single_target, 10)).text
        : 'No value selected';
    },
    editing() {
      return this.currentRule.id !== undefined;
    },
    modalTitle() {
      return `${this.editing ? 'Edit' : 'Create'} Custom Rule`;
    },
    stopIfAppliesDisabled() {
      return this.currentRule.actions.find((x) => x.action === 'dictate') !== undefined;
    },
    actionOptions() {
      return [
        { value: 'dictate', text: 'Dictate' },
        { value: 'restrict', text: 'Restrict' },
        { value: 'ignore', text: 'Ignore' },
        { value: 'redirect', text: 'Redirect' },
        { value: 'boost', text: 'Boost' },
      ];
    },
    articleMultiselectOptions() {
      if (this.currentAction.action === 'redirect') {
        return this.rankerArticleOptions
          .filter((x) => x.value !== this.currentAction.single_target);
      }
      return this.rankerArticleOptions;
    },
    articleFieldValueOptions() {
      return this.rankerArticleAttributeOptions;
    },
    pinnedArticleOptions() {
      return this.articleMultiselectOptions
        .filter((x) => this.currentRule.actions[0].multi_targets.indexOf(x.value) < 0);
    },
    displaySingleTarget() {
      return ['dictate', 'redirect'].includes(this.currentAction.action);
    },
    singleTargetLabel() {
      switch (this.currentAction.action) {
        case 'dictate':
          return 'Dictate article';
        case 'redirect':
          return 'Redirect to article';
        default:
          return '';
      }
    },
    displayMultiTarget() {
      return ['restrict', 'ignore', 'redirect', 'boost'].includes(this.currentAction.action);
    },
    multiTargetLabel() {
      switch (this.currentAction.action) {
        case 'restrict':
          return 'Restrict to articles';
        case 'ignore':
          return 'Ignore articles';
        case 'redirect':
          return 'Redirect from articles';
        case 'boost':
          return 'Boost articles';
        default:
          return '';
      }
    },
    anyPinnedArticles() {
      return this.currentRule.actions[0].multi_targets.length > 0;
    },
  },
  methods: {
    ...mapActions('customRule', { addCustomRule: 'addItem', updateCustomRule: 'patchItem' }),
    ...mapActions('customRule', ['testCondition']),
    ...mapActions('metaDataField', { fetchMetaDataFields: 'fetchItems' }),
    prepareCurrentRule() {
      this.singleTargetKeyword = '';
      if (!this.rule) {
        this.currentRule = this.newRule();
        if (this.pinAction) {
          this.currentRule.actions.push({ action: 'pin', multi_targets: [] });
          this.currentRule.isPinRule = true;
        }
      } else {
        this.currentRule = cloneDeep(this.rule);
      }
    },
    newRule() {
      return {
        name: '',
        priority: this.ruleCount + 1,
        description: '',
        conditions: [],
        actions: [],
        stopIfApplies: false,
        isPinRule: false,
        confidenceThreshold: this.pinAction ? 1.0 : 0.5,
      };
    },
    newAction() {
      return {
        action: null,
        single_target: null,
        multi_targets: [],
        manual_multi_targets: true,
        field_values: [],
        boosting_scalar: 0.0,
      };
    },
    reset() {
      this.currentRule = this.newRule();
      this.resetAction();
    },
    resetAction() {
      this.currentAction = this.newAction();
      this.actionIndex = -1;
      this.newPinnedArticles = [];
    },
    handleOk() {
      if (this.editing) {
        this.updateCustomRule(this.currentRule).then(this.fetchMetaDataFields());
      } else {
        this.currentRule.ranker = this.rankerDetails.id;
        this.addCustomRule({ newItem: this.currentRule }).then(this.fetchMetaDataFields());
      }
    },
    addAction() {
      if (this.currentAction.action === 'dictate') {
        this.currentRule.stopIfApplies = true;
      }
      if (this.currentAction.action === 'boost') {
        this.currentAction.boosting_scalar += 0;
      }
      if (this.actionIndex !== -1) {
        this.currentRule.actions[this.actionIndex] = this.currentAction;
      } else {
        this.currentRule.actions.push(this.currentAction);
      }
    },
    editAction(action) {
      this.currentAction = cloneDeep(action);
      this.actionIndex = this.currentRule.actions.indexOf(action);
      this.$bvModal.show('createAction');
    },
    removeAction(index) {
      this.currentRule.actions.splice(index, 1);
    },
    addCondition(condition) {
      this.currentRule.conditions.push(condition);
    },
    updateCondition({ index, condition }) {
      this.currentRule.conditions.splice(index, 1, condition);
    },
    removeCondition(index) {
      this.currentRule.conditions.splice(index, 1);
    },
    addPinnedArticles() {
      this.currentRule.actions[0].multi_targets.push(...this.newPinnedArticles);
    },
  },
  validations() {
    return {
      currentRule: {
        name: { required },
        priority: { required, integer, minValue: minValue(0) },
      },
      currentAction: {
        action: { required },
        single_target: { required: requiredIf((currentAction) => ['dictate', 'redirect'].includes(currentAction.action)) },
        multi_targets: { required: requiredIf((currentAction) => (['restrict', 'ignore', 'redirect', 'boost'].includes(currentAction.action) && currentAction.manual_multi_targets) || currentAction.action === 'pin') },
        field_values: { required: requiredIf((currentAction) => ['restrict', 'ignore', 'redirect', 'boost'].includes(currentAction.action) && !currentAction.manual_multi_targets) },
      },
    };
  },
};
</script>
<style scoped>
.actions__action .action__key,
.actions__action .action__val {
  background-color: #f2f2f2;
  padding: 0.125rem .25rem;
  border-radius: .25rem;
  position: relative;
  overflow-wrap: anywhere;
  word-break: break-all;
  word-wrap: break-word;
}
.actions .actions__action .action__btn {
    height: 20px;
    min-width: 20px;
    width: 20px;
    max-width: 20px;
    line-height: 100%;
    padding: 0;
    margin: 0;
    color: rgba(0,0,0,0.25);
    transition-property: color;
}
.actions .actions__action:hover .action__btn {
    color: rgba(0,0,0,0.5)
}
.actions {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
}
.actions .actions__action {
  border-radius: .25rem;
  line-height: 175%;
}
::v-deep .dropdown-menu{
  max-width: 600px !important;
  max-height: 40vh;
  overflow-y: auto;
}
::v-deep .dropdown-button{
  word-wrap: break-word;
  white-space: normal;
}
::v-deep .dropdown-item{
  word-wrap: break-word;
  white-space: normal;
}
</style>
