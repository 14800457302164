export default {
  uploaddatasource: ['article', 'chat', 'ticket', 'anonymization', 'question'],
  supchatdatasource: ['chat', 'anonymization'],
  puzzeldatasource: ['article', 'chat', 'anonymization'],
  zendeskdatasource: ['article', 'chat', 'anonymization', 'ticket'],
  ticketanalyzerdatasource: ['ticket', 'anonymization'],
  webscraper: ['article'],
  rightanswersdatasource: ['article'],
  bomgardatasource: ['chat', 'anonymization'],
  rankerdatasource: ['query', 'anonymization'],
  servicenowdatasource: ['article'],
  onenotedatasource: ['article'],
  sharepointdatasource: ['article'],
  topdeskdatasource: ['article'],
};
