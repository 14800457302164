<template>
  <b-row
    v-if="isFetchingRankerDataSource"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <router-view v-else />
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'RankerDataSource',
  data() {
    return { };
  },
  computed: {
    ...mapState('ranker', ['isFetchingRankerDataSource']),
  },
  methods: { },
};
</script>
