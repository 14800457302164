<template>
  <b-list-group
    v-if="activities.length"
  >
    <b-list-group-item
      v-for="(item, index) in activities"
      :key="index"
      :button="!noninteractive"
      class="p-3"
      @click="!noninteractive && openActivity(item)"
    >
      <b-row class="mb-2">
        <b-col class="my-auto">
          <h5 class="my-auto">
            <strong>{{ item.sender }}</strong>
          </h5>
        </b-col>
        <b-col
          class="text-right"
          cols="auto"
        >
          <small class="mr-3">{{ new Date(item.timestamp).toLocaleString() }}</small>
          <span
            v-b-tooltip.hover.noninteractive.viewport
            :title="formatType(item.type)"
          >
            <b-badge
              :variant="getTypeColor(item.type)"
              class="p-2"
            >
              <font-awesome-icon
                class="h6 mb-0"
                :icon="getIcon(item.type)"
              />
            </b-badge>
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="h6 mb-0">
            {{ item.content }}
          </p>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
  <div v-else>
    No activities to show
  </div>
</template>

<script>

export default {
  name: 'DataActivities',
  props: {
    activities: {
      type: Array,
      required: true,
    },
    dataType: {
      type: String,
      default: 'chat',
    },
    noninteractive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {

  },
  methods: {
    formatType(type) {
      switch (type) {
        case 'msg': return 'message';
        default: return 'unknown type';
      }
    },
    getTypeColor(type) {
      switch (type) {
        case 'msg': return 'primary';
        default: return 'secondary';
      }
    },
    getIcon(type) {
      switch (type) {
        case 'msg': return 'comment';
        default: return 'question-circle';
      }
    },
    openActivity(item) {
      this.$router.push({ name: `${this.dataType}-activities-single`, params: { activityId: item.id } });
    },
  },
};
</script>
