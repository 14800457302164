import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import Shared from '@/js/vuexTemplates/shared';
import { getModuleProps } from '@/js/utils';

const languageModelState = {
};
const getters = {};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.languageModel, 'languageModel'),
  new Shared('languageModel'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: languageModelState, getters, mutations, actions,
  }, templates),
};
