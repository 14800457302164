<template>
  <b-card
    title="Feedback"
    class="r-75"
    body-class="p-3"
  >
    <p>
      Here you can manually label or ignore query predictions
      <br>
      <font-awesome-icon icon="info-circle" />
      Feedback items are sorted by their prediction score.
    </p>
    <feedback-section />
  </b-card>
</template>
<script>
import FeedbackSection from '@/components/Ranker/Feedback/FeedbackSection.vue';

export default {
  name: 'Feedback',
  components: { FeedbackSection },
};
</script>
