var render = function render(){var _vm=this,_c=_vm._self._c;return _c('collapsible-section',{ref:"collapsibleSection",attrs:{"title":_vm.getTitle,"disabled":_vm.isComputingMetric || !_vm.hasData,"is-loading":_vm.isComputingMetric},on:{"visible":(val) => _vm.isVisible = val},scopedSlots:_vm._u([(_vm.isVisible && !_vm.hideKnowledgeGaps)?{key:"rightSide",fn:function(){return [_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"my-auto text-right pl-4 d-flex",attrs:{"cols":"auto"}},[_c('configuration-dropdown',_vm._b({staticClass:"mr-2",on:{"set-specifity":_vm.setSpecifity,"set-topics-count":_vm.setNumberOfTopicsAndCompute}},'configuration-dropdown',{
            metric: _vm.metric, specifity: _vm.specifity, numberOfTopics: _vm.numberOfTopics, maxTopics: _vm.maxTopics,
          },false)),_c('b-button-group',_vm._l((_vm.chartTypes),function({ type, icon }){return _c('b-button',{key:type,staticClass:"chart-type-button",attrs:{"variant":"primary","pressed":type == _vm.selectedChartType},on:{"click":function($event){$event.stopPropagation();_vm.selectedChartType = type}}},[_c('font-awesome-icon',{attrs:{"icon":icon}})],1)}),1),_c('export-statistics',_vm._b({staticClass:"ml-2"},'export-statistics',{
            items: _vm.topics,
            fields: _vm.fields,
            title: _vm.metric,
          },false))],1)],1)]},proxy:true}:null,{key:"content",fn:function(){return [(_vm.hideKnowledgeGaps)?_c('b-row',[_c('b-col',{staticClass:"py-4 text-center"},[_vm._v(" Knowledge gaps cannot be displayed for less than "+_vm._s(_vm.knowledgeGapThreshold)+" queries ")])],1):(_vm.hasData)?_c('b-row',[_c('b-col',_vm._b({},'b-col',_vm.bColAttrs,false),[(_vm.selectedChartType === 'table')?_c('table-data',{staticClass:"w-100 pr-2",attrs:{"sticky-header":"400px","items":_vm.topics,"fields":_vm.fields}}):_c('typed-chart',{key:_vm.isVisible + _vm.selectedChartType,staticStyle:{"max-height":"500px"},attrs:{"chart-data":_vm.chartData,"options":_vm.chartOptions,"chart-type":"bar"}}),(_vm.topicsObj?.sampled)?_c('span',[_vm._v(" * Due to a large amount of data, the result is based on a sample of size "+_vm._s(_vm.topicsObj?.sample_size && `${_vm.topicsObj.sample_size}`)+" ")]):_vm._e()],1),(_vm.showDetails)?_c('b-col',_vm._b({},'b-col',_vm.bColAttrs,false),[(_vm.loadingDetails)?_c('b-row',{staticClass:"h-100 align-items-center"},[_c('b-col',[_c('b-spinner',{staticClass:"mx-auto d-block"})],1)],1):_vm._e(),_c('details-section',_vm._b({},'details-section',{
            chartOptions: _vm.chartOptions,
            metric: _vm.metric,
            topicIndex: _vm.selectedTopicIndex,
            topicsObj: _vm.topicsObj,
            specifity: _vm.specifity,
          },false))],1):_vm._e()],1):_vm._e()]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }