// General components
import Cookies from 'js-cookie';
import Vue from 'vue';
import VueRouter from 'vue-router';
import PassThrough from '@/components/PassThrough.vue';
import { getBreadcrumb } from '@/js/breadcrumbs';
// Data Sources
import DataSourceBase from '@/pages/DataSource/Base.vue';
import DataSourceArticles from '@/pages/DataSource/Articles.vue';
import DataSourceArticlesSingle from '@/pages/DataSource/ArticlesSingle.vue';
import DataSourceChats from '@/pages/DataSource/Chats.vue';
import DataSourceChatsSingle from '@/pages/DataSource/ChatsSingle.vue';
import DataSourceChatActivitiesSingle from '@/pages/DataSource/ChatActivitiesSingle.vue';
import DataSourceConfig from '@/pages/DataSource/Config.vue';
import DataSourceOverview from '@/pages/DataSource/Overview.vue';
import DataSourceSingle from '@/pages/DataSource/Single.vue';
import DataSourceTickets from '@/pages/DataSource/Tickets.vue';
import DataSourceTicketsSingle from '@/pages/DataSource/TicketsSingle.vue';
import DataSourceTicketsActivitiesSingle from '@/pages/DataSource/TicketsActivitiesSingle.vue';
import DataSourceQueries from '@/pages/DataSource/Queries.vue';
import DataSourceQueriesSingle from '@/pages/DataSource/QueriesSingle.vue';
import DataSourceQuestions from '@/pages/DataSource/Questions.vue';
import DataSourceFields from '@/pages/DataSource/Fields.vue';
import DataSourceFieldsSingle from '@/pages/DataSource/FieldsSingle.vue';
import DataSourceFieldValue from '@/pages/DataSource/FieldValue.vue';
// Base pages
import HomePage from '@/pages/Home.vue';
import LoginPage from '@/pages/Login.vue';
import LogoutPage from '@/pages/Logout.vue';
import PageNotFoundPage from '@/pages/PageNotFound.vue';
// Global Configuration
import GlobalAnonymization from '@/pages/Configuration/Anonymization.vue';
import TaskOverview from '@/pages/Configuration/TaskOverview.vue';
import UserManagement from '@/pages/Configuration/UserManagement.vue';
import SingleSignOnWrapper from '@/pages/Configuration/SingleSignOnWrapper.vue';
import WidgetConfig from '@/pages/Ranker/WidgetConfig.vue';
// Rankers
import CustomRules from '@/pages/Ranker/CustomRule.vue';
import RankerBase from '@/pages/Ranker/Base.vue';
import RankerConfig from '@/pages/Ranker/Config.vue';
import RankerVersionsBase from '@/pages/Ranker/Versions/Base.vue';
import RankerVersionsOverview from '@/pages/Ranker/Versions/Overview.vue';
import RankerVersionsSingle from '@/pages/Ranker/Versions/Single.vue';
import RankerOverview from '@/pages/Ranker/Overview.vue';
import RankerTesting from '@/pages/Ranker/Testing.vue';
import RankerStatistics from '@/pages/Ranker/Statistics.vue';
import RankerSingle from '@/pages/Ranker/Single.vue';
import RankerDataSource from '@/pages/Ranker/RankerDataSource.vue';
import RankerFeedback from '@/pages/Ranker/Feedback.vue';
// Language Models
import LanguageModelBase from '@/pages/LanguageModel/Base.vue';
import LanguageModelOverview from '@/pages/LanguageModel/Overview.vue';
import LanguageModelSingle from '@/pages/LanguageModel/Single.vue';
// Other pages
import Search from '@/pages/Search.vue';
// Other modules
import { isOpenAPIEnabled } from '@/js/utils';
import store from '@/store/index';
import PipelineBuildSingle from '@/pages/Ranker/PipelineBuild/Single.vue';
import endpoints from '@/js/endpoints';

Vue.use(VueRouter);

async function checkAuth(to, from, next) {
  try {
    const authStatus = await store.dispatch('authentication');
    if (authStatus === 'success') {
      // handle articleViewer only accounts
      if (store.getters['auth/isArticleViewerOrNone']) {
        if (to.name !== 'article-view') next({ name: '403' });
        else next();
      } else next();
    } else if (authStatus === 'no token') {
      next({ name: 'login', query: { originalPath: to.path } });
    } else {
      console.log(`unexpected status ${authStatus}`);
      next({ name: 'login', query: { originalPath: to.path } });
    }
  } catch (error) {
    console.log(error);
    next({ name: 'login', query: { originalPath: to.path } });
  }
}

const routes = [
  {
    path: '/',
    component: HomePage,
    beforeEnter: checkAuth,
    name: 'home',
    meta: {
      breadcrumb: getBreadcrumb,
    },
  },
  {
    path: '/login',
    component: LoginPage,
    name: 'login',
    meta: {
      breadcrumb: { display: ' ', link: null },
    },
  },
  {
    path: '/login-supwiz',
    name: 'login-supwiz',
    beforeEnter: () => window.location.replace(endpoints.ssoLoginSupwiz),
  },
  {
    path: '/logout',
    component: LogoutPage,
    name: 'logout',
    meta: {
      breadcrumb: { display: ' ', link: null },
    },
  },
  {
    path: '/sso-auth',
    name: 'sso-auth',
    beforeEnter: (to, from, next) => {
      const { originalPath } = to.query;
      if (to.query.success === 'true') {
        const refreshToken = Cookies.get('jwt_refresh_token');
        const accessToken = Cookies.get('jwt_access_token');
        Cookies.remove('jwt_refresh_token');
        Cookies.remove('jwt_access_token');
        if (refreshToken && accessToken) {
          store.commit('auth/updateAccessToken', accessToken);
          store.commit('auth/updateRefreshToken', refreshToken);
          if (originalPath) {
            next(originalPath);
          } else {
            next({ name: 'home' });
          }
        } else {
          next({ name: 'login', query: { sso_error: 'unexpected_error', originalPath } });
        }
      } else {
        next({ name: 'login', query: { sso_error: to.query.sso_error, originalPath } });
      }
    },
  },
  {
    path: '/search/:rankerId',
    component: Search,
    beforeEnter: isOpenAPIEnabled() ? null : checkAuth,
    name: 'search',
    meta: {
      breadcrumb: { display: ' ', link: null },
    },
  },
  {
    path: '/data-source',
    component: DataSourceBase,
    beforeEnter: checkAuth,
    meta: {
      breadcrumb: {
        display: 'Data Sources', link: { name: 'data-source-overview' },
      },
    },
    children: [
      {
        path: '',
        name: 'data-source-overview',
        component: DataSourceOverview,
        meta: {
          breadcrumb: getBreadcrumb,
        },
      },
      {
        path: ':dataSourceId',
        name: 'data-source-single',
        component: DataSourceSingle,
        redirect: { name: 'data-source-config' },
        meta: {
          breadcrumb: getBreadcrumb,
        },
        children: [
          {
            path: 'config',
            name: 'data-source-config',
            component: DataSourceConfig,
            meta: {
              breadcrumb: {
                display: 'Configuration', link: { name: 'data-source-config' },
              },
            },
          },
          {
            path: 'article',
            name: 'article',
            component: PassThrough,
            redirect: { name: 'articles-overview' },
            meta: {
              breadcrumb: {
                display: 'Articles', link: { name: 'article' },
              },
            },
            children: [
              {
                path: '',
                name: 'articles-overview',
                component: DataSourceArticles,
                meta: {
                  breadcrumb: { display: ' ', link: null },
                },
              },
              {
                path: ':articleId',
                name: 'articles-single',
                component: DataSourceArticlesSingle,
                meta: {
                  breadcrumb: {
                    display: 'Single', link: { name: 'articles-single' },
                  },
                },
              },
            ],
          },
          {
            path: 'chat',
            name: 'chat',
            component: PassThrough,
            redirect: { name: 'chats-overview' },
            meta: {
              breadcrumb: {
                display: 'Chats', link: { name: 'chat' },
              },
            },
            children: [
              {
                path: '',
                name: 'chats-overview',
                component: DataSourceChats,
                meta: {
                  breadcrumb: {
                    display: ' ', link: null,
                  },
                },
              },
              {
                path: ':chatId',
                name: 'chats-single',
                component: PassThrough,
                redirect: { name: 'chats-single-details' },
                meta: {
                  breadcrumb: {
                    display: 'Single', link: { name: 'chats-single' },
                  },
                },
                children: [
                  {
                    path: '',
                    name: 'chats-single-details',
                    component: DataSourceChatsSingle,
                    meta: {
                      breadcrumb: {
                        display: ' ', link: null,
                      },
                    },
                  },
                  {
                    path: ':activityId',
                    name: 'chat-activities-single',
                    component: DataSourceChatActivitiesSingle,
                    meta: {
                      breadcrumb: {
                        display: 'Activity', link: { name: 'chat-activities-single' },
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'ticket',
            name: 'ticket',
            component: PassThrough,
            redirect: { name: 'tickets-overview' },
            meta: {
              breadcrumb: {
                display: 'Tickets', link: { name: 'ticket' },
              },
            },
            children: [
              {
                path: '',
                name: 'tickets-overview',
                component: DataSourceTickets,
                meta: {
                  breadcrumb: {
                    display: ' ', link: null,
                  },
                },
              },
              {
                path: ':ticketId',
                name: 'tickets-single',
                component: PassThrough,
                redirect: { name: 'tickets-single-details' },
                meta: {
                  breadcrumb: {
                    display: 'Single', link: { name: 'tickets-single' },
                  },
                },
                children: [
                  {
                    path: '',
                    name: 'tickets-single-details',
                    component: DataSourceTicketsSingle,
                    meta: {
                      breadcrumb: {
                        display: ' ', link: null,
                      },
                    },
                  },
                  {
                    path: ':activityId',
                    name: 'ticket-activities-single',
                    component: DataSourceTicketsActivitiesSingle,
                    meta: {
                      breadcrumb: {
                        display: 'Activity', link: { name: 'ticket-activities-single' },
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'question',
            name: 'question',
            component: PassThrough,
            redirect: { name: 'questions-overview' },
            meta: {
              breadcrumb: {
                display: 'Questions', link: { name: 'question' },
              },
            },
            children: [
              {
                path: '',
                name: 'questions-overview',
                component: DataSourceQuestions,
                meta: {
                  breadcrumb: {
                    display: ' ', link: null,
                  },
                },
              },

            ],
          },
          {
            path: 'tags',
            name: 'tags',
            component: PassThrough,
            redirect: { name: 'tags-overview' },
            meta: {
              breadcrumb: {
                display: 'Tags', link: { name: 'tags' },
              },
            },
            children: [
              {
                path: '',
                name: 'tags-overview',
                component: DataSourceFields,
                meta: {
                  breadcrumb: {
                    display: ' ', link: null,
                  },
                },
              },
              {
                path: ':fieldId',
                name: 'tags-single',
                component: PassThrough,
                redirect: { name: 'tags-single-details' },
                meta: {
                  breadcrumb: getBreadcrumb,
                },
                children: [
                  {
                    path: '',
                    name: 'tags-single-details',
                    component: DataSourceFieldsSingle,
                    meta: {
                      breadcrumb: {
                        display: ' ', link: null,
                      },
                    },
                  },
                  {
                    path: ':fieldValueId',
                    name: 'tag-values-single',
                    component: DataSourceFieldValue,
                    meta: {
                      breadcrumb: getBreadcrumb,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    beforeEnter: checkAuth,
    path: '/ranker',
    component: RankerBase,
    meta: {
      breadcrumb: {
        display: 'Search Engines', link: { name: 'ranker-overview' },
      },
    },
    children: [
      {
        path: '',
        name: 'ranker-overview',
        component: RankerOverview,
        meta: {
          breadcrumb: getBreadcrumb,
        },
      },
      {
        path: ':rankerId',
        name: 'ranker-single',
        component: RankerSingle,
        redirect: { name: 'ranker-statistics' },
        meta: {
          breadcrumb: getBreadcrumb,
        },
        children: [
          {
            path: 'statistics',
            name: 'ranker-statistics',
            component: RankerStatistics,
            meta: {
              breadcrumb: { display: 'Statistics', link: { name: 'ranker-statistics' } },
            },
          },
          {
            path: 'widget',
            name: 'widget',
            component: WidgetConfig,
            meta: {
              breadcrumb: { display: 'Widget', link: { name: 'widget' } },
            },
          },
          {
            path: 'ranker-collected-data',
            name: 'ranker-collected-data',
            component: RankerDataSource,
            redirect: { name: 'ranker-query' },
            meta: {
              breadcrumb: { display: 'Data Source', link: { name: 'ranker-collected-data' } },
            },
            children: [
              {
                path: 'query',
                name: 'ranker-query',
                component: PassThrough,
                redirect: { name: 'ranker-queries-overview' },
                meta: {
                  breadcrumb: {
                    display: 'Queries', link: { name: 'ranker-query' },
                  },
                },
                children: [
                  {
                    path: '',
                    name: 'ranker-queries-overview',
                    component: DataSourceQueries,
                    meta: {
                      breadcrumb: {
                        display: ' ', link: null,
                      },
                    },
                  },
                  {
                    path: ':queryId',
                    name: 'ranker-queries-single',
                    component: DataSourceQueriesSingle,
                    meta: {
                      breadcrumb: {
                        display: 'Single', link: { name: 'ranker-queries-single' },
                      },
                    },
                  },
                ],
              },
              {
                path: 'feedback',
                name: 'feedback',
                component: RankerFeedback,
                meta: {
                  breadcrumb: {
                    display: 'Feedback', link: { name: 'feedback' },
                  },
                },
              },
            ],
          },
          {
            path: 'rules',
            name: 'custom-rule',
            component: CustomRules,
            meta: {
              breadcrumb: { display: 'Custom Rules', link: { name: 'custom-rule' } },
            },
          },
          {
            path: 'config',
            name: 'ranker-config',
            component: RankerConfig,
            meta: {
              breadcrumb: {
                display: 'Configuration', link: { name: 'ranker-config' },
              },
            },
          },
          {
            path: 'versions',
            component: RankerVersionsBase,
            meta: {
              breadcrumb: { display: 'Versions', link: { name: 'ranker-versions-overview' } },
            },
            children: [
              {
                path: '',
                name: 'ranker-versions-overview',
                component: RankerVersionsOverview,
                meta: {
                  breadcrumb: getBreadcrumb,
                },
              },
              {
                path: ':rankerInstanceId',
                name: 'ranker-versions-single',
                component: RankerVersionsSingle,
                meta: {
                  breadcrumb: getBreadcrumb,
                },
              },
              {
                path: 'datasets/:pipelineBuildId',
                name: 'pipeline-build-single',
                component: PassThrough,
                redirect: { name: 'pipeline-build-details' },
                meta: {
                  breadcrumb: getBreadcrumb,
                },
                children: [
                  {
                    path: '',
                    component: PipelineBuildSingle,
                    name: 'pipeline-build-details',
                    meta: {
                      breadcrumb: { display: ' ', link: null },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'testing',
            name: 'ranker-testing',
            component: RankerTesting,
            meta: {
              breadcrumb: { display: 'Test & Stage', link: { name: 'ranker-testing' } },
            },
          },
        ],
      },
    ],
  },
  {
    beforeEnter: checkAuth,
    path: '/global-config',
    name: 'global-configuration',
    redirect: { name: 'global-anonymization' },
    component: PassThrough,
    meta: {
      breadcrumb: {
        display: 'Configuration', link: { name: 'global-configuration' },
      },
    },
    children: [
      {
        path: 'anonymization',
        name: 'global-anonymization',
        component: GlobalAnonymization,
        meta: {
          breadcrumb: { display: 'Anonymization', link: { name: 'global-anonymization' } },
        },
      },
      {
        path: 'tasks',
        name: 'task-overview',
        component: TaskOverview,
        meta: {
          breadcrumb: { display: 'Task Overview', link: { name: 'task-overview' } },
        },
      },
      {
        path: 'user-management',
        name: 'user-management',
        component: UserManagement,
        meta: {
          breadcrumb: { display: 'User Management', link: { name: 'user-management' } },
        },
      },
      {
        path: 'single-sign-on',
        name: 'single-sign-on',
        component: SingleSignOnWrapper,
        meta: {
          breadcrumb: { display: 'Single Sign-on', link: { name: 'single-sign-on' } },
        },
      },
    ],
  },
  {
    beforeEnter: checkAuth,
    path: '/language-model',
    component: LanguageModelBase,
    meta: {
      breadcrumb: {
        display: 'Language Models', link: { name: 'language-model-overview' },
      },
    },
    children: [
      {
        path: '',
        name: 'language-model-overview',
        component: LanguageModelOverview,
        meta: {
          breadcrumb: { display: ' ', link: null },
        },
      },
      {
        path: ':languageModelId',
        name: 'language-model-single',
        component: LanguageModelSingle,
        meta: {
          breadcrumb: getBreadcrumb,
        },
      },
    ],
  },
  {
    beforeEnter: checkAuth,
    path: '/article-view/:article',
    component: () => import('@/pages/ArticleLightView.vue'),
    name: 'article-view',
    props: true,
  },
  {
    beforeEnter: checkAuth,
    path: '/article-view/:article/:language',
    component: () => import('@/pages/ArticleLightView.vue'),
    name: 'article-view',
    props: true,
  },
  {
    path: '/forbidden',
    component: () => import('@/pages/PageInsufficientPermission.vue'),
    name: '403',
  },
  // At the end we have a catch all. Since routes are matched in order this will only match if
  // no other route matches.
  {
    path: '*',
    component: PageNotFoundPage,
    name: '404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.params.dataSourceId !== undefined || to.params.pipelineId !== undefined
    || (to.params.rankerId !== undefined && to.name !== 'search')
    || (to.fullPath.startsWith('/global-config'))) {
    store.commit('sidebar/setShowSidebar', true);
  } else {
    store.commit('sidebar/setShowSidebar', false);
  }
  store.commit('sidebar/setLoading', true);
  next();
});
router.afterEach(() => {
  store.commit('sidebar/setLoading', false);
});

export default router;
