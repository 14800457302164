<template>
  <div v-if="showComponent">
    <b-button
      v-b-tooltip.noninteractive.hover
      variant="primary"
      title="Export Data"
      @click.stop="displayModal"
    >
      <span class="sr-only">Export Data</span>
      <font-awesome-icon icon="download" />
    </b-button>
    <b-modal
      ref="dataExportModal"
      v-bind="modalInfo"
      @hidden="onHidden"
      @ok="exportFile"
    >
      <b-overlay
        :show.sync="busy"
        variant="white"
        spinner-variant="primary"
      >
        <b-form-group
          label="File Type"
          description="Select the file type of the exported sheet."
        >
          <b-form-select
            v-model="fileType"
            :options="$options.exportSheetFormats"
          />
        </b-form-group>
        <b-form-group
          label="Columns"
          description="Drag to arrange the order of the columns.
            You can also toggle which columns should be included in the exported sheet."
        >
          <draggable
            v-model="columnsOrder"
            class="list-group"
          >
            <b-list-group-item
              v-for="(column, index) in columnsOrder"
              :key="column"
              button
              :class="columnsEnabled[column] ? '' : 'bg-light'"
              class="p-2 font-weight-normal d-flex align-items-center"
            >
              <span>{{ `${index + 1}. ${getLabel(column)}` }}</span>
              <b-button
                v-b-tooltip.noninteractive.hover
                title="Toggle column"
                size="sm"
                class="ml-auto text-muted cursor-grabbing py-0"
                variant="link"
                @click="columnsEnabled[column] = !columnsEnabled[column]"
              >
                <font-awesome-icon
                  :icon="columnsEnabled[column] ? 'eye' : 'eye-slash'"
                />
              </b-button>
            </b-list-group-item>
          </draggable>
        </b-form-group>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { utils, writeFile } from 'xlsx';

import Draggable from 'vuedraggable';
import { mapActions } from 'vuex';

export default {
  name: 'ExportStatistics',
  exportSheetFormats: [
    { value: 'xlsx', text: '.xlsx' },
    { value: 'csv', text: '.csv' },
  ],
  components: { Draggable },
  props: {
    items: {
      required: true,
      type: [Array, Function],
    },
    fields: {
      required: true,
      type: Array,
    },
    title: {
      type: String,
      default: 'Data',
    },
  },
  data() {
    return {
      fileType: 'xlsx',
      busy: false,
      columnsOrder: [],
      columnsEnabled: {},
    };
  },
  computed: {
    showComponent() {
      if (!this.fields.length) return false;
      return this.items?.length || typeof this.items === 'function';
    },
    modalInfo() {
      return {
        okTitle: 'Export',
        title: 'Export Data',
        okDisabled: this.busy || !this.enabledOrderedColumns.length,
        centered: true,
      };
    },
    enabledOrderedColumns() {
      return this.columnsOrder.filter((column) => this.columnsEnabled[column]);
    },
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    displayModal() {
      this.fields.forEach((field) => {
        const fieldName = field?.key || field;
        this.columnsOrder.push(fieldName);
        this.$set(this.columnsEnabled, fieldName, true);
      });
      this.$refs.dataExportModal.show();
    },
    onHidden() {
      this.columnsOrder = [];
      this.columnsEnabled = {};
    },
    getLabel(keyName) {
      const fieldMatch = this.fields.find((field) => field === keyName || field?.key === keyName);
      if (typeof fieldMatch === 'string') {
        return fieldMatch.replace('_', ' ');
      }
      return fieldMatch?.label || keyName.replace('_', ' ');
    },
    formatValue(keyName, value) {
      const fieldMatch = this.fields.find((field) => field === keyName || field?.key === keyName);
      if (typeof fieldMatch === 'string') return value;
      return fieldMatch?.formatter?.(value) || value;
    },
    async exportFile(bvEvent) {
      bvEvent.preventDefault();
      try {
        this.busy = true;
        let preparedItems = this.items;
        if (typeof preparedItems === 'function') {
          preparedItems = await this.items();
        }
        if (this.title === 'location') {
          preparedItems = this.formatLocationItems(this.items);
        }
        preparedItems = preparedItems.map((item) => {
          const result = {};
          this.enabledOrderedColumns.forEach((column) => {
            result[this.getLabel(column)] = this.formatValue(column, item[column]);
          });
          return result;
        });

        const ws = utils.json_to_sheet(preparedItems);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, this.title);
        writeFile(wb, `${this.title}DataExport.${this.fileType}`);
        this.$refs.dataExportModal.hide();
      } catch (error) {
        this.showWarning({
          title: 'Failed to generate file.',
          text: error?.message || error,
        });
      } finally {
        this.busy = false;
      }
    },
    formatLocationItems(items) {
      const formatted = [];
      items.forEach((item) => {
        item.city.forEach((city) => {
          formatted.push({ country: item.country, city: city.city, count: city.count });
        });
      });
      return formatted;
    },
  },
};
</script>
