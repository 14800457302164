<template>
  <base-activity
    ref="baseActivity"
    :scraper-id="scraperId"
    :command-id="commandId"
    :indent="indent"
    bs-variant="primary"
    v-on="$listeners"
  >
    <template #icon>
      <font-awesome-icon icon="magnifying-glass" />
    </template>
    <template #header>
      {{ headerContent }}
    </template>
    <b-row>
      <b-col cols="9">
        <b-form-group
          label="Target variable:"
        >
          <b-form-input
            id="target-var"
            :value="command.target_var"
            type="text"
            @input="value => $root.$emit('update-scraper', { commandId, prop: 'target_var', value })"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Find all">
          <b-form-checkbox
            v-model="isMulti"
            switch
          />
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-form-group
          v-if="!isMulti"
          label="Optional"
        >
          <b-form-checkbox
            v-b-tooltip.hover
            title="If the element is not found, then the page is skipped unless it is optional"
            switch
            :checked="command.nullable"
            @input="$root.$emit('update-scraper', { commandId, prop: 'nullable', value: !command.nullable })"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group
      label="Source Variable:"
    >
      <b-form-input
        id="source-var"
        :value="command.source_var"
        type="text"
        @input="value => $root.$emit('update-scraper', { commandId, prop: 'source_var', value })"
      />
    </b-form-group>
    <b-row>
      <b-col cols="3">
        <b-form-group label="Find type:">
          <b-form-select
            id="param-type"
            :value="command.param_type"
            :options="findElementTypes"
            @input="value => $root.$emit('update-scraper', { commandId, prop: 'param_type', value })"
          />
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group label="Find parameter:">
          <b-form-input
            id="param"
            :value="command.param"
            type="text"
            placeholder="Type FindElement parameter here"
            class="mb-2 mr-sm-2 mb-sm-0 text-monospace"
            @input="value => $root.$emit('update-scraper', { commandId, prop: 'param', value })"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </base-activity>
</template>

<script>
import { FIND_ELEMENT_TYPES } from '@/js/selenium_constants';

import BaseActivity from '@/components/WebScraper/SeleniumCommands/BaseCommand.vue';

export default {
  name: 'FindElementCommand',
  components: { BaseActivity },
  props: {
    scraperId: {
      type: Number,
      required: true,
    },
    commandId: {
      type: String,
      required: true,
    },
    command: {
      type: Object,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  computed: {
    findElementTypes() {
      return Object.values(FIND_ELEMENT_TYPES);
    },
    isMulti: {
      get() {
        return this.command.multi;
      },
      set(value) {
        this.$root.$emit('update-scraper', { commandId: this.commandId, prop: 'multi', value });
      },
    },
    headerContent() {
      let out = `${this.command.target_var} = ${this.command.source_var}.FindElement`;
      if (this.isMulti) {
        out += 's';
      }
      return `${out}(${this.command.param_type}, ${this.command.param})`;
    },
  },
};
</script>
