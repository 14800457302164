import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import { getModuleProps } from '@/js/utils';

const fieldState = {};

const fieldGetters = {
  filteredFieldsList: (state) => (dataSource, dataType) => Object.values(state.items).filter(
    (x) => {
      if (Boolean(dataSource) && (x.dataSource !== dataSource)) {
        return false;
      }
      if (Boolean(dataType) && (x.dataType !== dataType)) {
        return false;
      }
      return true;
    },
  ),
  filteredFields: (state, getters) => (dataSource, dataType) => {
    const filteredFields = {};
    for (const obj of getters.filteredFieldsList(dataSource, dataType)) {
      filteredFields[obj.id] = obj;
    }
    return filteredFields;
  },
  fieldOptions: (state, getters) => (dataSource, dataType) => {
    const options = getters.filteredFieldsList(dataSource, dataType).map((x) => ({
      value: x.id,
      text: x.displayName || x.name,
    }));
    options.sort((a, b) => ((a.text > b.text) ? 1 : -1));
    return options;
  },
  fieldIdToName: (state, getters, rootState, rootGetters) => (fieldId, verbose = false) => {
    if (!(fieldId in state.items)) {
      return 'Tag not found';
    }
    const field = state.items[fieldId];
    const name = field.displayName || field.name;
    if (verbose) {
      const dataSourceName = rootGetters['dataSource/itemIdToName'](field.dataSource);
      return `${dataSourceName} : ${name}`;
    }
    return name;
  },
};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.field, 'field'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: fieldState, getters: fieldGetters, mutations, actions,
  }, templates),
};
