import axios from 'axios';
import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import { getModuleProps } from '@/js/utils';

const customRuleState = {};

const getters = {
  rankerIdToRules: (state) => (rankerId) => Object.values(state.items)
    .filter((x) => x.ranker === rankerId),
};

const mutations = {};

const actions = {
  async testCondition({ dispatch, rootGetters }, condition) {
    const auth = rootGetters['auth/headerAuthorization'];
    const data = { condition };
    try {
      return await axios.post(`${endpoints.customRule}test-condition/`, data, auth);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to test condition',
        text: error.message,
      }, { root: true });
      throw error;
    }
  },
};

const templates = [
  new GeneralTemplate(endpoints.customRule, 'customRule'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: customRuleState, getters, mutations, actions,
  }, templates),
};
