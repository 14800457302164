<template>
  <b-row
    v-if="isFetchingDetails"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
      <h3 class="mt-3">
        Fetching build details
      </h3>
    </b-col>
  </b-row>
  <b-row v-else-if="objectNotFound">
    <b-col>
      <h1>Pipeline build not found</h1>
      <p>Requested pipeline build could not be found.</p>
    </b-col>
  </b-row>
  <div v-else>
    <b-card
      title="Build Details"
      class="r-75"
      body-class="p-3"
    >
      <b-spinner
        v-if="!ready"
        class="m-4"
        style="width: 5rem; height: 5rem;"
      />
      <template v-else>
        <display-key-value
          v-if="!hasBuildId"
          class="my-3"
          key-prop="Build status"
          :value-prop="pipelineBuildDetails.task ? pipelineBuildDetails.task.status : 'Unknown status'"
          :min-key-width="keyWidth"
        />
        <template v-if="taskDone">
          <display-key-value
            class="my-3"
            key-prop="Datapoints"
            :value-prop="pipelineBuildDetails.datapointCount"
            :min-key-width="keyWidth"
          />
          <display-key-value
            class="mt-3"
            key-prop="Datapoints with articles"
            :value-prop="pipelineBuildDetails.datapointWithTargetCount"
            :min-key-width="keyWidth"
          />
        </template>
        <b-row no-gutters class="cursor-pointer mt-3" @click="showArticleStats = !showArticleStats">
          <b-col cols="auto" class="pr-2 my-auto h5 pt-1">
            <font-awesome-icon :icon="showArticleStats ? 'angle-down' : 'angle-right'" />
          </b-col>
          <b-col class="my-auto h4" cols="auto">
            Article usage in data
          </b-col>
          <b-col class="pl-2 my-auto h5">
            <b-badge pill>
              {{ targetsWithDataRowCount }} / {{ targetsRowCount }}
            </b-badge>
          </b-col>
        </b-row>
        <b-collapse :visible="showArticleStats">
          <b-row class="my-3">
            <b-col>
              <b-pagination
                v-model="targetsPage"
                size="sm"
                :total-rows="targetsRowCount"
                :per-page="targetsPerPage"
                class="my-auto"
                aria-controls="targets-table"
              />
            </b-col>
            <b-col cols="auto">
              <b-form-radio-group
                v-model="articleFilter"
                class="my-auto"
                buttons
                size="sm"
                button-variant="primary"
                :options="articleFilterOptions"
              />
            </b-col>
          </b-row>

          <table-data
            id="targets-table"
            :fields="targetFields"
            :items="targets"
            :current-page="targetsPage"
            :per-page="targetsPerPage"
            :busy="isFetchingDetails"
            sort-by="count"
            sort-desc
            show-empty
            hover
            empty-text="No articles references found."
            @row-clicked="targetClicked"
          />
        </b-collapse>
        <b-row no-gutters class="cursor-pointer mt-3" @click="showUnmatchedUrls = !showUnmatchedUrls">
          <b-col cols="auto" class="pr-2 my-auto h5 pt-1">
            <font-awesome-icon :icon="showUnmatchedUrls ? 'angle-down' : 'angle-right'" />
          </b-col>
          <b-col class="my-auto h4" cols="auto">
            Unmatched urls
          </b-col>
          <b-col class="pl-2 my-auto h5">
            <b-badge pill>
              {{ filteredUnmatchedUrls.length }}
            </b-badge>
          </b-col>
        </b-row>
        <b-collapse :visible="showUnmatchedUrls">
          <b-row>
            <b-col>
              <b-pagination
                v-model="unmatchedUrlsPage"
                size="sm"
                class="my-3"
                :total-rows="filteredUnmatchedUrls.length"
                :per-page="unmatchedUrlsPerPage"
                aria-controls="unmatched-urls-table"
              />
            </b-col>
            <b-col>
              <b-form-input
                id="unmatched-url-search"
                v-model="unmatchedUrlQuery"
                class="mt-3"
                placeholder="Search urls (e.g., 'company inc. co.uk visitor') ..."
              />
            </b-col>
          </b-row>
          <table-data
            id="unmatched-urls-table"
            ref="unmatched-urls-table"
            :fields="unmatchedUrlFields"
            :items="filteredUnmatchedUrls"
            :current-page="unmatchedUrlsPage"
            :per-page="unmatchedUrlsPerPage"
            show-empty
            :empty-text="unmatchedUrlQuery.length ? `No urls matches '${unmatchedUrlQuery}'` : 'There are no records to show'"
            sort-by="count"
            sort-desc
          />
        </b-collapse>
        <b-row no-gutters class="cursor-pointer mt-3" @click="showDatapoints = !showDatapoints">
          <b-col cols="auto" class="pr-2 my-auto h5 pt-1">
            <font-awesome-icon :icon="showDatapoints ? 'angle-down' : 'angle-right'" />
          </b-col>
          <b-col class="my-auto h4" cols="auto">
            Data points
          </b-col>
          <b-col class="pl-2 my-auto h5">
            <b-badge pill>
              {{ datapointPagination.count }}
            </b-badge>
          </b-col>
        </b-row>
        <b-collapse :visible="showDatapoints">
          <template v-if="pipelineBuildDetails && pipelineBuildDetails.cleared">
            <span class="text-muted mt-3">The build has been cleared of datapoints.</span>
          </template>
          <template v-else>
            <b-pagination
              v-model="datapointPage"
              size="sm"
              class="my-3"
              :total-rows="datapointPagination.count"
              :per-page="datapointPagination.perPage"
              aria-controls="datapoints-table"
            />
            <table-data
              id="datapoints-table"
              ref="datapoints-table"
              :fields="datapointFields"
              :current-page="datapointPage"
              :per-page="datapointPagination.perPage"
              :items="datapointsProvider"
              :busy="datapointsTableBusy"
              show-empty
              details-td-class="datapoint-details pt-0"
              empty-text="There are currently no datapoints."
              hover
              @row-clicked="datapointClicked"
            >
              <template #head(isFake)>
                <span
                  v-b-tooltip.hover.noninteractive="'Shows whether datapoint was created from generated question'"
                  class="text-secondary"
                >
                  Generated
                </span>
              </template>
              <template #cell(text)="row">
                {{ row.detailsShowing ? row.item.text : textFormatter(row.item.text, 70) }}
              </template>
              <template #cell(context)="row">
                {{ row.detailsShowing ? row.item.context : textFormatter(row.item.context, 70) }}
              </template>
              <template #cell(target)="row">
                {{ row.item.target }}
                <b-link v-if="row.item.target" @click="openDatapointTarget(row.item)">
                  <font-awesome-icon icon="arrow-up-right-from-square" />
                </b-link>
              </template>
              <template #cell(isFake)="row">
                <font-awesome-icon
                  :color="row.item.isFake ? 'green' : 'grey'"
                  :icon="row.item.isFake ? 'circle-check' : 'circle-xmark'"
                />
              </template>
              <template #row-details="row">
                <span class="font-weight-bolder">
                  From:
                  <b-link
                    v-if="textClickable(row.item)"
                    @click="openDatapointInput(row.item.textOrigin, row.item.dataSourceOrigin)"
                  >
                    {{ generatedFromText(row.item) }}
                  </b-link>
                  <span v-else class="font-weight-normal">
                    {{ generatedFromText(row.item) }}
                  </span>
                </span>
              </template>
            </table-data>
          </template>
        </b-collapse>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  mapState, mapMutations, mapGetters, mapActions,
} from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import DisplayKeyValue from 'supwiz/components/DisplayKeyValue.vue';
import { objToCamel } from '@/js/utils';
import { textFormatter } from 'supwiz/util/formatters';
import { dataSourceTypes } from '@/js/constants';

export default {
  name: 'PipelineBuildSingle',
  components: {
    TableData, DisplayKeyValue,
  },
  beforeRouteLeave(to, from, next) {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.setBuildDetails({});
    next();
  },
  props: {
    buildId: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      targetsPerPage: 10,
      targetsPage: 1,
      targetFields: [
        { key: 'target', formatter: this.targetFormatter, label: 'Article' },
        'count',
      ],
      datapointFields: [
        { key: 'text', formatter: this.lengthFormatter, tdClass: 'w-35' },
        { key: 'context', formatter: this.lengthFormatter, tdClass: 'w-35' },
        { key: 'target', label: 'Target' },
        {
          key: 'isFake', label: 'Generated', tdClass: 'text-center', thClass: 'text-center',
        },
      ],
      unmatchedUrlFields: [
        'url',
        'count',
      ],
      intervalId: null,
      unmatchedUrlsPerPage: 10,
      unmatchedUrlsPage: 1,
      unmatchedUrlQuery: '',
      showArticleStats: false,
      showUnmatchedUrls: false,
      showDatapoints: false,
      articleFilterOptions: [
        { text: 'Show all', value: null },
        { text: 'Only used', value: 'used' },
        { text: 'Only unused', value: 'unsused' },
      ],
      articleFilter: null,
    };
  },
  computed: {
    ...mapState('dataSource', { dataSources: 'items' }),
    ...mapGetters('pipeline', ['isArticlePrediction']),
    ...mapState('pipelineBuild', ['isFetchingDetails']),
    ...mapState('pipelineBuild', { pipelineBuildDetails: 'details' }),
    ...mapState('article', { articles: 'items' }),
    ...mapState('datapoint', {
      isFetchingDatapoints: 'isFetching',
      datapointPagination: 'pagination',
    }),
    ...mapState('fieldValue', { fieldValues: 'items' }),
    ...mapState('field', { fields: 'items' }),
    ...mapState('ranker', { rankerDetails: 'details' }),
    ...mapGetters('ranker', ['articleId2DataSourceId']),
    hasBuildId() {
      // has buildId => ranker instance single page
      // no build id => pipeline build single page
      return this.buildId !== null;
    },
    keyWidth() {
      return 200;
    },
    objectNotFound() {
      return Object.keys(this.pipelineBuildDetails).length === 0;
    },
    ready() {
      return Object.keys(this.pipelineBuildDetails).length !== 0;
    },
    targets() {
      if (!('targets' in this.pipelineBuildDetails)) {
        return [];
      }
      return this.pipelineBuildDetails.targets.filter((e) => {
        if (this.articleFilter === null) return true;
        if (this.articleFilter === 'used') return e.count > 0;
        return e.count === 0;
      });
    },
    unmatchedUrls() {
      return Object.entries(this.pipelineBuildDetails.unmatchedUrls)
        .map((x) => ({ url: x[0], count: x[1], lowerUrl: x[0].toLowerCase() }));
    },
    isUnmatchedUrlsQueryEmpty() {
      return this.unmatchedUrlQuery.trim().length === 0;
    },
    filteredUnmatchedUrls() {
      if (this.isUnmatchedUrlsQueryEmpty) {
        return this.unmatchedUrls;
      }
      let out = this.unmatchedUrls;
      for (const part of this.unmatchedUrlQuery.split(/(\s+)/).filter((e) => e.trim().length > 0)) {
        out = out.filter((x) => x.lowerUrl.includes(part.toLowerCase()));
      }
      return out;
    },
    targetDisplayNames() {
      const dict = {};
      for (const obj of this.targets) {
        dict[obj.target] = obj.display_name;
      }
      return dict;
    },
    targetsRowCount() {
      return this.targets.length;
    },
    targetsWithDataRowCount() {
      return this.targets.filter((x) => x.count > 0).length;
    },
    taskDone() {
      return this.pipelineBuildDetails && this.pipelineBuildDetails.task?.status === 'done';
    },
    datapointPage: {
      get() {
        return this.datapointPagination.page;
      },
      set(val) {
        this.updateDatapointPagination({ page: val });
      },
    },
    datapointsTableBusy() {
      return this.isFetchingDatapoints || this.isFetchingDetails;
    },
  },
  watch: {
    unmatchedUrlQuery(query) {
      if (query.trim().length > 0) {
        this.unmatchedUrlsPage = 1;
      }
    },
  },
  created() {
    this.fetchBuildDetails({
      id: this.buildId ? this.buildId : this.$route.params.pipelineBuildId,
      refreshing: false,
    }).then(() => {
      this.updateDatapointPagination({ count: null, page: 1 });
      if (this.$refs['datapoints-table']) {
        this.$refs['datapoints-table'].$refs.table.refresh();
      }
      if (this.$route.params.pipelineBuildId) {
        this.setInterval(this.$route.params.pipelineBuildId);
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    textFormatter,
    ...mapActions('pipelineBuild', { fetchBuildDetails: 'fetchItemDetails' }),
    ...mapActions('datapoint', { fetchDatapoints: 'fetchItems' }),
    ...mapMutations('datapoint', { updateDatapointPagination: 'updatePagination' }),
    ...mapMutations('pipelineBuild', { setBuildDetails: 'setItemDetails' }),
    targetClicked(item) {
      if (this.isArticlePrediction(this.rankerDetails.pipeline.type)) {
        this.$router.push({
          name: 'articles-single',
          params: {
            articleId: item.target,
            dataSourceId: this.articleId2DataSourceId[item.target],
          },
        });
      } else {
        const fieldValue = this.fieldValues[item.target];
        const field = this.fields[fieldValue.field];
        this.$router.push({
          name: 'tag-values-single',
          params: {
            dataSourceId: field.dataSource,
            fieldId: field.id,
            fieldValueId: fieldValue.id,
          },
        });
      }
    },
    datapointClicked(row) {
      // eslint-disable-next-line no-param-reassign
      row._showDetails = !row._showDetails;
    },
    setInterval(pipelineBuildId) {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => this.update(pipelineBuildId), 3000);
    },
    update(pipelineBuildId) {
      this.fetchBuildDetails({ id: pipelineBuildId, refreshing: true });
    },
    async datapointsProvider() {
      const datapoints = await this.fetchDatapoints({
        params: {
          build: this.pipelineBuildDetails.id,
          page: this.datapointPagination.page,
        },
      });
      return datapoints.map((c) => ({
        ...objToCamel(c),
        target: this.targetFormatter(c.target),
        targetId: c.target,
        _showDetails: false,
      }));
    },
    targetFormatter(targetId) {
      if (targetId === null) {
        return '';
      }
      const text = this.targetDisplayNames[targetId];
      if (text?.length > 100) {
        return `${text.substring(0, 100)}...`;
      }
      return text;
    },
    lengthFormatter(value) {
      return value.length > 300 ? `${value.substring(0, 300)}...` : value;
    },
    textClickable(item) {
      return !!(this.isArticlePrediction(this.rankerDetails.pipeline.type)
                    && item.dataSourceOrigin
                    && (item.textOrigin?.chat
                    || item.textOrigin?.ticket
                    || item.textOrigin?.query));
    },
    openDatapointInput(origin, dataSourceOrigin) {
      let page;
      if (origin.chat) {
        page = this.$router.resolve({
          name: 'chats-single',
          params: {
            chatId: origin.chat,
            dataSourceId: dataSourceOrigin,
          },
        });
      } else if (origin.ticket) {
        page = this.$router.resolve({
          name: 'tickets-single',
          params: {
            ticketId: origin.ticket,
            dataSourceId: dataSourceOrigin,
          },
        });
      } else if (origin.query) {
        page = this.$router.resolve({
          name: 'queries-single',
          params: {
            queryId: origin.query,
            dataSourceId: dataSourceOrigin,
          },
        });
      }
      window.open(page.href, '_blank');
    },
    openDatapointTarget(item) {
      let page;
      if (this.isArticlePrediction(this.rankerDetails.pipeline.type)) {
        page = this.$router.resolve({
          name: 'articles-single',
          params: {
            articleId: item.targetId,
            dataSourceId: this.articleId2DataSourceId[item.targetId],
          },
        });
      } else {
        const fieldValue = this.fieldValues[item.targetId];
        const field = this.fields[fieldValue.field];
        page = this.$router.resolve({
          name: 'tag-values-single',
          params: {
            dataSourceId: field.dataSource,
            fieldId: field.id,
            fieldValueId: fieldValue.id,
          },
        });
      }
      window.open(page.href, '_blank');
    },
    generatedFromText(item) {
      let str = '';
      if (item.textOrigin?.chat) {
        str = `Chat ${item.textOrigin?.chat}`;
      } else if (item.textOrigin?.ticket) {
        str = `Ticket ${item.textOrigin.ticket}`;
      } else if (item.textOrigin?.query
      || this.dataSources[item.dataSourceOrigin]?.type === dataSourceTypes.RANKER.value) {
        str = `Query ${item.textOrigin?.query || ''}`;
      } else {
        str = 'Question ';
      }
      str += ` (${this.dataSources[item.dataSourceOrigin]?.name})`;
      return str;
    },
  },
};
</script>
<style scoped>
::v-deep .datapoint-details{
  background-color: white;
  cursor:auto;
}
::v-deep .w-35{
  width:35%;
}
</style>
