<template>
  <div>
    <p v-if="!tags.length" class="mb-0">
      {{ noResultText }}
    </p>
    <template v-else>
      <b-input-group
        v-for="({
          key, value, id,
        }, index) in tagsList"
        :key="id"
        :class="{ 'mb-2': index < tagsList.length - 1 }"
        class="w-100"
      >
        <template #prepend>
          <b-input-group-text class="tag-label justify-content-center">
            <label
              class="mb-0"
              :for="id"
            >{{ key }}</label>
          </b-input-group-text>
        </template>
        <b-form-input
          :id="id"
          :value="value"
          class="bg-white"
          readonly
          @focus="$event.target.select()"
        />
      </b-input-group>
    </template>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';

export default {
  name: 'TagDisplay',
  props: {
    tags: {
      // Array of integers (id of FieldValue)
      type: Array,
      required: true,
    },
    noResultText: {
      type: String,
      default: 'Nothing to show',
    },
  },
  computed: {
    ...mapGetters('field', { fields: 'items' }),
    ...mapGetters('fieldValue', { fieldValues: 'items' }),
    tagsList() {
      return this.tags.map(
        (tagId) => this.fieldValues[tagId],
      ).filter(
        (fieldValue) => fieldValue != null,
      ).map(
        (x) => ({
          key: this.fields[x.field].displayName || this.fields[x.field].name,
          value: x.displayValue || x.value,
          id: uuidv4(),
        }),
      ).sort((a, b) => `${a.key}:${a.value}` - `${b.key}:${b.value}`);
    },
  },
};
</script>

<style scoped>
.tag-label {
  min-width: 75px;
}
</style>
