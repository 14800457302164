<template>
  <b-row
    v-if="isFetchingDetails"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <b-row v-else-if="objectNotFound">
    <b-col>
      <h1>Language model not found</h1>
      <p>Requested language model could not be found.</p>
    </b-col>
  </b-row>
  <b-card
    v-else
    class="r-75"
    body-class="p-3"
  >
    <b-row>
      <b-col class="my-auto">
        <h4 class="card-title my-auto">
          Language Model Details
        </h4>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="$router.go(-1)"
        >
          Return
        </b-button>
      </b-col>
    </b-row>
    <transformer-details :details="languageModelDetails" />
  </b-card>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex';
import TransformerDetails from '@/components/Ranker/transformerDetails.vue';

export default {
  name: 'LanguageModelSingle',
  components: { TransformerDetails },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchLanguageModelDetails({ id: to.params.languageModelId });
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.languageModelId !== from.params.languageModelId) {
      this.fetchLanguageModelDetails({ id: to.params.languageModelId });
    }
    next();
  },
  computed: {
    ...mapState('languageModel', { languageModelDetails: 'details' }),
    ...mapState('languageModel', ['isFetchingDetails']),
    objectNotFound() {
      return Object.keys(this.languageModelDetails).length === 0;
    },
  },
  destroyed() {
    this.setLanguageModelDetails({});
  },
  methods: {
    ...mapActions('languageModel', { fetchLanguageModelDetails: 'fetchItemDetails' }),
    ...mapMutations('languageModel', { setLanguageModelDetails: 'setItemDetails' }),
  },

};
</script>
