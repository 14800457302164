import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import SharedTemplate from '@/js/vuexTemplates/shared';
import { getModuleProps } from '@/js/utils';

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {

};

const templates = [
  new GeneralTemplate(endpoints.searchFilterValue, 'searchFilterValue'),
  new SharedTemplate('searchFilterValue'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state, getters, mutations, actions,
  }, templates),
};
