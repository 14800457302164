<template>
  <div>
    <b-card
      title="Task Overview"
      class="r-75"
      body-class="p-3"
    >
      <b-row>
        <b-col>
          <statistics-filters
            :extra-filters="[]"
            :base-filters="baseFilters"
            calendar-tooltip="Select date range for computing statistics"
            :debounce-time="1000"
            @fetchData="refreshTasks"
          />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="my-auto">
          <b-pagination
            v-model="currentPage"
            size="sm"
            class="my-auto"
            :total-rows="pagination.count"
            :per-page="pagination.perPage"
            aria-controls="tasks-table"
          />
        </b-col>
      </b-row>
      <b-table
        id="tasks-table"
        ref="taskTable"
        :fields="fields"
        :current-page="currentPage"
        :per-page="pagination.perPage"
        :items="itemsProvider"
        show-empty
        empty-text="No tasks found"
      >
        <template #cell(aborted)="row">
          <b-button
            v-if="row.item.status === 'pending'"
            size="sm"
            variant="warning"
            class="py-0 ml-2"
            @click="handleAbortTask(row.item)"
          >
            Abort
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex';
import { objToCamel } from '@/js/utils';
import { taskType2Text, taskStatus } from '@/js/constants';
import { snakeCaseToText } from 'supwiz/util/data';
import StatisticsFilters from 'supwiz/components/statisticsFilters/StatisticsFilters.vue';

export default {
  name: 'TaskOverview',
  components: {
    StatisticsFilters,
  },
  data() {
    return {
      fields: [
        { key: 'id', label: 'Id' },
        { key: 'finishedTime', label: 'Finished time', formatter: (date) => (date !== null ? new Date(date).toLocaleString('en-GB') : '-') },
        { key: 'type', label: 'Type', formatter: (type) => this.formatTaskType(type) },
        { key: 'progress', label: 'Progress', formatter: (progress) => this.formatTaskProgress(progress) },
        { key: 'status', label: 'Status', formatter: (status) => this.formatTaskStatus(status) },
        { key: 'aborted', label: '' },
      ],
    };
  },
  computed: {
    ...mapState('task', { tasks: 'items', pagination: 'pagination' }),
    ...mapState('statisticsFiltersStore', ['filters']),
    filteredTasks() {
      return Object.values(this.tasks);
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
    taskTypes() {
      return Object.entries(taskType2Text).map(([key, value]) => ({ value: key, text: value }))
        .concat([{ value: 'no_finished_time', text: 'Tasks without finished time' }]);
    },
    baseFilters() {
      return [
        {
          key: 'type', label: 'Task type', type: 'multiselect', options: this.taskTypes,
        },
        {
          key: 'status', label: 'Task status', type: 'multiselect', options: taskStatus,
        },
      ];
    },
  },
  created() {
    this.setFilters({
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date(),
      type: this.taskTypes.map((e) => e.value),
      status: taskStatus.map((e) => e.value),
    });
  },
  methods: {
    ...mapActions('task', ['fetchTaskList', 'abortTask']),
    ...mapActions('task', { fetchTasks: 'fetchItems' }),
    ...mapMutations('task', ['updatePagination']),
    ...mapMutations('statisticsFiltersStore', ['setFilters']),
    formatTaskType(type) {
      return taskType2Text[type] || snakeCaseToText(type);
    },
    formatTaskProgress(progress) {
      return progress === null ? '-' : `${(progress * 100).toFixed(2)}%`;
    },
    formatTaskStatus(status) {
      return status === 'pending' ? 'running' : status;
    },
    async handleAbortTask(item) {
      await this.abortTask(item.celeryId);
      this.$refs.taskTable.refresh();
    },
    refreshTasks() {
      this.$refs.taskTable.refresh();
    },
    async itemsProvider() {
      const formattedStartDate = new Date(this.filters.startDate).setHours(0, 0, 0, 0);
      const formattedEndDate = new Date(this.filters.endDate).setHours(23, 59, 59, 999);
      const tasks = await this.fetchTasks({
        params: {
          start_time: new Date(formattedStartDate).toISOString(),
          end_time: new Date(formattedEndDate).toISOString(),
          page: this.pagination.page,
          task_type: this.filters.type,
          task_status: this.filters.status,
        },
      });
      return Object.values(tasks.map((c) => objToCamel(c)));
    },
  },
};
</script>
