import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import Shared from '@/js/vuexTemplates/shared';
import { getModuleProps } from '@/js/utils';

const languageState = {
};
const getters = {
  languageOptions(state) {
    const isoCode2Option = {};
    for (const language of Object.values(state.items)) {
      const isoCode = language.isoCode;
      if (
        isoCode2Option[isoCode] == null
        || (language.displayName.length < isoCode2Option[isoCode].text)
      ) {
        isoCode2Option[isoCode] = { text: language.displayName, value: language.id };
      }
    }
    return Object.values(isoCode2Option).sort(
      (a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      },
    );
  },
};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.language, 'language'),
  new Shared('language'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: languageState, getters, mutations, actions,
  }, templates),
};
