<template>
  <b-row
    v-if="isFetching"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <router-view v-else />
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'RankerInstanceBase',
  computed: {
    ...mapState('ranker', { isFetching: 'isFetchingDetails' }),
  },
};
</script>
