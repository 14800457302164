<template>
  <div class="flex-fill">
    <b-card
      title="Datasets"
      class="r-75"
      body-class="p-3"
    >
      <TaskStatus :task-types="taskTypes" :show-details="false" />
      <b-row class="my-2">
        <b-col class="my-auto">
          <b-pagination
            v-model="currentPage"
            size="sm"
            class="my-auto"
            :total-rows="pagination.count"
            :per-page="pagination.perPage"
            aria-controls="pipelineBuildTable"
          />
        </b-col>
        <b-col cols="auto">
          <DebounceButton
            class="my-auto"
            text="Build"
            :disabled="missingTrainingData || buildRunning"
            @click="buildPipelineProxy"
          />
        </b-col>
      </b-row>
      <table-data
        id="pipelineBuildTable"
        ref="pipelineBuildTable"
        :fields="fields"
        :caption="taskTableCaption"
        :items="itemsAsArray"
        :deleting-id="deletingId"
        sort-by="createdTime"
        sort-desc
        show-empty
        hover
        @row-clicked="rowClicked"
        @delete="deletePipelineBuildLocal"
      >
        <template #cell(datapointCount)="data">
          <template v-if="data.item.task?.status === 'failed'">
            no data
          </template>
          <template v-else-if="data.value == null || data.value === ''">
            <b-spinner small />
          </template>
          <template v-else>
            {{ data.value }}
          </template>
        </template>
        <template #cell(datapointWithTargetCount)="data">
          <template v-if="data.item.task?.status === 'failed'">
            no data
          </template>
          <template v-else-if="data.value == null || data.value === ''">
            <b-spinner small />
          </template>
          <template v-else>
            {{ data.value }}
          </template>
        </template>
        <template #cell(task)="data">
          <template v-if="data.item.datapointCount !== '' && data.item.datapointCount != null">
            done
          </template>
          <template v-else>
            {{ data.value === 'Unknown status' ? 'In queue' : data.value }}
          </template>
        </template>
      </table-data>
    </b-card>
  </div>
</template>

<script>

import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import { taskFormatter } from 'supwiz/util/formatters';
import TaskStatus from '@/components/TaskStatus.vue';

export default {
  name: 'PipelineBuildOverview',
  components: { TableData, TaskStatus },
  data() {
    return {
      fields: [
        { key: 'delete', label: '', tdClass: 'delete-column' },
        { key: 'id', label: 'ID', tdClass: 'table-nobreak' },
        { key: 'createdTime', label: 'Created Time', formatter: (date) => new Date(date).toLocaleString('en-GB') },
        'datapointCount',
        'datapointWithTargetCount',
        { key: 'task', label: 'Task Status', formatter: taskFormatter },
      ],
      intervalId: null,
      lastUpdated: null,
      buildStarted: false,
      deletingId: null,
      taskTypes: ['pipeline_build'],
    };
  },
  computed: {
    ...mapState('pipelineSource', ['isFetching']),
    ...mapState('pipeline', ['isBuildingPipeline']),
    ...mapState('pipelineBuild', { builds: 'items' }),
    ...mapState('pipelineBuild', ['pagination']),
    ...mapGetters('pipelineSource', ['articleTrainingItems', 'otherTrainingItems']),
    ...mapGetters('task', ['getTasksOfType']),
    ...mapState('ranker', { rankerDetails: 'details' }),
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
    missingTrainingData() {
      return !this.hasArticles || !this.hasOtherData;
    },
    hasArticles() {
      return this.articleTrainingItems.length;
    },
    hasOtherData() {
      return this.otherTrainingItems.length;
    },
    itemsAsArray() {
      return Object.values(this.builds);
    },
    taskTableCaption() {
      return `Last updated: ${this.lastUpdated ? this.lastUpdated.toLocaleTimeString() : '-'}`
      + '. Note that the task status of the build will restart if multiple training data are registered.';
    },
    buildRunning() {
      if (this.buildStarted) {
        return true;
      }
      const tasks = this.getTasksOfType('pipeline_build');
      return (tasks.activeTasks.some((x) => x.status === 'pending' && x.meta.ranker === this.rankerDetails.id)
        || tasks.pendingTasks.some((x) => x.ranker === this.rankerDetails.id));
    },
  },
  watch: {
    currentPage() {
      this.refreshBuilds();
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.updatePagination({ page: 1 });
  },
  created() {
    this.refreshBuilds();
    this.initInterval();
  },
  methods: {
    ...mapActions('pipeline', ['buildPipeline']),
    ...mapMutations('pipelineBuild', ['updatePagination']),
    ...mapActions('pipelineBuild', {
      deletePipelineBuild: 'deleteItem',
      fetchPipelineBuilds: 'fetchItems',
    }),
    ...mapActions('task', ['fetchTaskList']),
    initInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => this.refreshBuilds(), 5000);
    },
    rowClicked(item) {
      this.$router.push({
        name: 'pipeline-build-single',
        params: { pipelineBuildId: item.id },
        refreshing: true,
      });
    },
    buildPipelineProxy() {
      this.buildStarted = true;
      this.buildPipeline(this.rankerDetails.pipeline);
      // refetch pending tasks in case created task is pending
      setTimeout(() => {
        this.fetchTaskList(true);
      }, 1000);
      setTimeout(() => {
        this.buildStarted = false;
      }, 5000);
    },
    async refreshBuilds() {
      await this.fetchPipelineBuilds({
        params: {
          pipeline: this.rankerDetails.pipeline.id,
          page: this.pagination.page,
        },
      });
      this.lastUpdated = new Date();
    },
    async deletePipelineBuildLocal(data) {
      const modalText = 'Are you sure that you want to delete?';
      const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        if (this.pagination.count - 1 <= (this.currentPage - 1) * 10) {
          this.updatePagination({ page: 1 });
        }
        this.deletingId = data.id;
        this.deletePipelineBuild(
          {
            fetchParams: {
              pipeline: this.rankerDetails.pipeline.id,
              page: this.pagination.page,
            },
            item: data,
          },
        );
      }
    },
  },
};
</script>
