<template>
  <div class="flex-fill">
    <b-alert
      v-if="showAlert"
      class="r-75"
      fade
      show
      variant="warning"
    >
      <div>
        <font-awesome-icon
          class="mr-2"
          icon="exclamation"
          size="lg"
        />
        Cannot train ranker
        <template v-if="!hasLanguageModel">
          -
          <b-link
            class="text-decoration-none"
            :to="{ name: 'ranker-config' }"
          >
            language model is not selected
          </b-link>
        </template>
      </div>
      <div v-if="!hasArticles || (!hasOtherData && !isClassicSearch)">
        <font-awesome-icon
          class="mr-2"
          icon="exclamation"
          size="lg"
        />
        Cannot build dataset -
        <b-link
          class="text-decoration-none"
          :to="{ name: 'ranker-config', hash: '#training-data' }"
        >
          ranker has no {{ !hasArticles ? 'article' : 'source' }} data
        </b-link>
      </div>
    </b-alert>
    <b-card
      title="Search Engine Versions"
      class="r-75"
      body-class="p-3"
    >
      <TaskStatus :task-types="taskTypes" :show-details="false" />
      <b-row class="my-2">
        <b-col class="my-auto">
          <b-pagination
            v-model="currentPage"
            size="sm"
            class="my-auto"
            :total-rows="pagination.count"
            :per-page="pagination.perPage"
            aria-controls="rankerInstanceTable"
          />
        </b-col>
        <b-col cols="auto">
          <DebounceButton
            v-if="isTrainable"
            class="my-auto"
            text="Train"
            :disabled="!canTrain"
            @click="trainRankerProxy"
          />
        </b-col>
      </b-row>
      <table-data
        id="rankerInstanceTable"
        ref="rankerInstanceTable"
        :fields="fields"
        :items="itemsAsArray"
        :busy="isFetching"
        :caption="taskTableCaption"
        :tbody-tr-class="rowClass"
        sort-by="createdTime"
        sort-desc
        show-empty
        empty-text="There are currently no search engine versions."
        hover
        @row-clicked="rowClicked"
        @delete="deleteRankerInstanceLocal"
      />
    </b-card>
    <Datapoints class="mt-3" />
  </div>
</template>

<script>

import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import { percentageFormatter, taskFormatter } from 'supwiz/util/formatters';
import { rankerType2pretty } from '@/js/constants';
import Datapoints from '@/pages/Ranker/PipelineBuild/Overview.vue';
import TaskStatus from '@/components/TaskStatus.vue';

export default {
  components: {
    TableData, TaskStatus, Datapoints,
  },
  data() {
    return {
      rankerFile: null,
      rankerDescription: '',
      keyWidth: 120,
      intervalId: null,
      lastUpdated: null,
      taskTypes: ['ranker_training'],
    };
  },
  computed: {
    ...mapState('pipelineSource', { pipelineSources: 'items' }),
    ...mapState('rankerInstance', ['isFetching', 'isUploading', 'pagination']),
    ...mapState('ranker', { rankerDetails: 'details' }),
    ...mapState('rankerInstance', { instances: 'items' }),
    ...mapGetters('pipelineSource', ['articleTrainingItems', 'otherTrainingItems']),
    ...mapGetters('ranker', ['isTrainable']),
    ...mapState('pipelineBuild', { builds: 'items' }),
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
    fields() {
      const fields = [
        { key: 'delete', label: '', tdClass: 'delete-column' },
        { key: 'id', label: 'ID', tdClass: 'table-nobreak' },
        { key: 'createdTime', label: 'Created Time', formatter: (date) => new Date(date).toLocaleString('en-GB') },
      ];
      let extraFields;
      if (this.isTrainable) {
        extraFields = [
          { key: 'config.type', label: 'Type', formatter: (x) => rankerType2pretty[x] || x },
          { key: 'accuracy', formatter: percentageFormatter },
          { key: 'task', label: 'Task Status', formatter: taskFormatter },
        ];
      } else {
        extraFields = [
          'name',
          'description',
        ];
      }
      return fields.concat(extraFields).concat(['active']);
    },
    showAlert() {
      if (!this.hasLanguageModel) {
        return true;
      }
      if (this.hasArticles) {
        if (this.isClassicSearch) {
          return false;
        }
        return !this.hasOtherData;
      }
      return true;
    },
    canTrain() {
      if (this.hasLanguageModel) {
        if (this.isClassicSearch || Object.values(this.builds).length) {
          return this.hasArticles;
        }
        return this.canBuild;
      }
      return false;
    },
    hasLanguageModel() {
      return !!this.rankerDetails.config.language_model;
    },
    isClassicSearch() {
      return this.rankerDetails.config.type === 'classic';
    },
    canBuild() {
      return this.hasArticles && this.hasOtherData;
    },
    hasArticles() {
      return !!this.articleTrainingItems.length;
    },
    hasOtherData() {
      return !!this.otherTrainingItems.length;
    },
    itemsAsArray() {
      return Object.values(this.instances);
    },
    taskTableCaption() {
      return `Last updated: ${this.lastUpdated ? this.lastUpdated.toLocaleTimeString() : '-'}`;
    },
  },
  watch: {
    currentPage() {
      this.refreshInstances();
    },
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.updatePagination({ page: 1 });
  },
  created() {
    this.refreshInstances();
    this.initInterval();
  },
  methods: {
    ...mapActions('ranker', ['trainRanker', 'uploadRankerInstance']),
    ...mapActions('rankerInstance', {
      deleteRankerInstance: 'deleteItem',
      fetchRankerInstances: 'fetchItems',
    }),
    ...mapMutations('rankerInstance', ['updatePagination']),
    initInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(this.refreshInstances, 5000);
    },
    trainRankerProxy() {
      this.trainRanker(this.rankerDetails);
    },
    rowClicked(item) {
      this.$router.push({
        name: 'ranker-versions-single',
        params: { rankerInstanceId: item.id },
      });
    },
    async refreshInstances() {
      await this.fetchRankerInstances({
        params: {
          ranker: this.rankerDetails.id,
          page: this.pagination.page,
        },
        refreshing: true,
      });
      this.lastUpdated = new Date();
    },
    async deleteRankerInstanceLocal(data) {
      const modalText = 'Are you sure that you want to delete?';
      const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        if (this.pagination.count - 1 <= (this.currentPage - 1) * 10) {
          this.updatePagination({ page: 1 });
        }
        this.deleteRankerInstance({
          item: data,
          fetchParams: {
            ranker: this.rankerDetails.id,
            page: this.pagination.page,
          },
        });
      }
    },
    rowClass(item, type) {
      if (item && type === 'row' && item.active) {
        return 'table-primary';
      }
      return null;
    },
  },
};
</script>
