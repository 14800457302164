<template>
  <b-tabs v-model="activeTab" pills>
    <b-tab title="Search engine rules">
      <div class="d-flex flex-column h-auto mt-3">
        <b-card class="r-75" body-class="p-3">
          <b-row class="mt-3 py-2 my-auto" no-gutters>
            <b-col>
              <h4 class="card-title">
                Custom Rules
              </h4>
            </b-col>
            <b-col cols="auto" class="text-right my-auto">
              <b-button variant="primary" @click="showRuleModal(null)">
                Add a Rule
              </b-button>
            </b-col>
          </b-row>
          <b-table-simple id="rule-list" hover bordered head-variant="light" sticky-header="500px">
            <b-thead>
              <b-th />
              <b-th>Active</b-th>
              <b-th>Name</b-th>
              <b-th>Description</b-th>
              <b-th>Usage last 7 days</b-th>
              <b-th />
            </b-thead>
            <tbody
              v-if="localRules?.length === 0"
              class="text-center"
            >
              <td colspan="5">
                No rules have been defined for this search engine
              </td>
            </tbody>
            <draggable
              v-else
              v-model="localRules"
              group="localRules"
              tag="tbody"
              handle=".handle"
              :class="{ ['cursor-grabbing']: dragging === true }"
              @start="dragging = true"
              @end="dragging = false"
            >
              <b-tr
                v-for="rule in localRules"
                :key="rule.id"
              >
                <b-td>
                  <font-awesome-icon icon="arrows-alt" class="handle" style="transform: scale(1.25)" />
                </b-td>
                <b-td>
                  <b-form-checkbox v-model="rule.active" toggle size="lg" @change="toggleActive($event, rule)" />
                </b-td>
                <b-td>{{ rule.name }}</b-td>
                <b-td>{{ rule.description }}</b-td>
                <b-td>{{ usageStats(rule.id) }}</b-td>
                <b-td>
                  <div class="text-right">
                    <b-button variant="outline-primary" title="Edit" size="sm" class="mr-2" @click="showRuleModal(rule.id)">
                      <font-awesome-icon icon="wrench" style="transform: scale(1.25)" />
                    </b-button>
                    <b-button variant="outline-danger" class="d-inline" title="Delete" size="sm" @click="handleDelete(rule)">
                      <font-awesome-icon icon="times" style="transform: scale(1.5)" />
                    </b-button>
                  </div>
                </b-td>
              </b-tr>
            </draggable>
          </b-table-simple>
          <b-row
            class="mt-3 py-2 my-auto"
            no-gutters
          >
            <b-col>
              <h4 class="card-title">
                Pin Rules
              </h4>
            </b-col>
            <b-col
              cols="auto"
              class="text-right my-auto"
            >
              <b-button
                variant="primary"
                @click="showRuleModal(null, true)"
              >
                Add new Pin Rule
              </b-button>
            </b-col>
          </b-row>
          <b-table-simple
            id="pin-list"
            hover
            bordered
            head-variant="light"
            sticky-header="500px"
          >
            <b-thead>
              <b-th />
              <b-th>Active</b-th>
              <b-th>Name</b-th>
              <b-th>Description</b-th>
              <b-th>Usage last 7 days</b-th>
              <b-th />
            </b-thead>
            <tbody
              v-if="localPins?.length === 0"
              class="text-center"
            >
              <td colspan="5">
                No articles have been pinned
              </td>
            </tbody>
            <draggable
              v-else
              v-model="localPins"
              group="localPins"
              tag="tbody"
              handle=".handle"
              :class="{ ['cursor-grabbing']: dragging === true }"
              @start="dragging = true"
              @end="dragging = false"
            >
              <b-tr
                v-for="rule in localPins"
                :key="rule.id"
              >
                <b-td>
                  <font-awesome-icon
                    icon="arrows-alt"
                    class="handle"
                    style="transform: scale(1.25)"
                  />
                </b-td>
                <b-td>
                  <b-form-checkbox
                    v-model="rule.active"
                    toggle
                    size="lg"
                    @change="toggleActive($event, rule)"
                  />
                </b-td>
                <b-td>{{ rule.name }}</b-td>
                <b-td>{{ rule.description }}</b-td>
                <b-td>{{ usageStats(rule.id) }}</b-td>
                <b-td>
                  <div class="text-right">
                    <b-button
                      variant="outline-primary"
                      title="Edit"
                      size="sm"
                      class="mr-2"
                      @click="showRuleModal(rule.id, true)"
                    >
                      <font-awesome-icon
                        icon="wrench"
                        style="transform: scale(1.25)"
                      />
                    </b-button>
                    <b-button
                      variant="outline-danger"
                      class="d-inline"
                      title="Delete"
                      size="sm"
                      @click="handleDelete(rule)"
                    >
                      <font-awesome-icon
                        icon="times"
                        style="transform: scale(1.5)"
                      />
                    </b-button>
                  </div>
                </b-td>
              </b-tr>
            </draggable>
          </b-table-simple>
        </b-card>
        <b-card class="r-75 mt-3" body-class="p-3">
          <b-row>
            <b-col class="my-auto">
              <b-pagination
                v-model="pagination.page"
                size="sm"
                class="my-auto"
                :total-rows="pagination.count"
                :per-page="pagination.perPage"
                aria-controls="articles-table"
              />
            </b-col>

            <b-col cols="auto">
              <b-button variant="danger" @click="handleUnstageRules">
                Unstage Rules
              </b-button>
            </b-col>
            <b-col cols="auto">
              <b-button v-b-modal.handleStageRules variant="primary">
                Stage Active Rules
              </b-button>
              <b-modal
                id="handleStageRules"
                title="Stage Active Rules"
                ok-title="Stage Rules"
                ok-variant="primary"
                @ok="handleStageRules"
                @show="fetchDisabledArticleRules"
              >
                Are you sure you want to stage active rules? This will go into effect immediately.
                <p class="font-weight-bold">
                  {{ result }}
                </p>
              </b-modal>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-table
                id="stage-history"
                head-variant="light"
                show-empty
                :current-page="pagination.page"
                :per-page="pagination.perPage"
                :items="historicalItems"
                empty-text="There is no staging history to show"
                hover
                bordered
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card class="r-75 mt-3" body-class="p-3" title="Testing">
          <b-form-group label="Query text" label-for="testingText">
            <b-textarea id="testingText" v-model="text" rows="5" max-rows="6" />
          </b-form-group>
          <meta-data-form />
          <b-button variant="primary" :disabled="!deployedRankerInstance" @click="handleTestRules">
            Test Rules
          </b-button>
          <small v-if="!deployedRankerInstance" class="text-warning d-block">
            No search engine version deployed. Please deploy a version to test custom rules.
          </small>
          <div v-if="response !== null">
            <hr>
            <b-row class="mt-3">
              <b-col>
                <b-row>
                  <b-col>
                    <h5>
                      <b-badge variant="primary">
                        Without Rules
                      </b-badge>
                    </h5>
                  </b-col>
                  <b-col cols="auto">
                    <b-button size="sm" @click="showWithRulesDetails = !showWithRulesDetails">
                      Toggle details
                    </b-button>
                  </b-col>
                </b-row>
                <article-output
                  :data="response.data.raw_result || []"
                  :show-details="showWithRulesDetails"
                />
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <h5>
                      <b-badge variant="primary">
                        Rules applied
                      </b-badge>
                    </h5>
                  </b-col>
                  <b-col cols="auto">
                    <b-button size="sm" @click="showWithoutRulesDetails = !showWithoutRulesDetails">
                      Toggle details
                    </b-button>
                  </b-col>
                </b-row>
                <article-output
                  :data="response.data.result"
                  :show-details="showWithoutRulesDetails"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
        <custom-rule-modal
          :rule="currentRule"
          :rule-count="localData.length"
          :pin-action="pinAction"
        />
      </div>
    </b-tab>
    <b-tab
      v-if="(globalConfig || {}).genAiEnabled"
      class="pt-3"
      title="GPT rules"
    >
      <GPTRules />
    </b-tab>
  </b-tabs>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapActions, mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import CustomRuleModal from '@/components/Ranker/CustomRuleModal.vue';
import endpoints from '@/js/endpoints';
import GPTRules from '@/components/Ranker/GPTRules.vue';
import ArticleOutput from '@/components/Ranker/ArticleOutput.vue';
import MetaDataForm from '../../components/Ranker/MetaDataForm.vue';

export default {
  name: 'CustomRule',
  components: {
    ArticleOutput,
    Draggable,
    CustomRuleModal,
    MetaDataForm,
    GPTRules,
  },
  data() {
    return {
      dragging: false,
      currentRule: null,
      pinAction: false,
      // testing
      text: '',
      context: '',
      response: null,
      targetRankerId: null,
      showWithRulesDetails: false,
      showWithoutRulesDetails: false,
      result: null,
      isFecthingDisabled: false,
      activeTab: 0,
      pagination: {
        count: null,
        page: 1,
        perPage: 10,
      },
    };
  },
  computed: {
    ...mapState('auth', ['globalConfig']),
    ...mapState('ranker', { rankerDetails: 'details' }),
    ...mapState('ranker', ['stagedRuleHistory', 'metaData', 'ruleUsageStats']),
    ...mapGetters('customRule', ['rankerIdToRules']),
    ...mapGetters('ranker', ['deployedRankerInstance']),
    localData() {
      return cloneDeep(this.rankerIdToRules(this.rankerDetails.id))
        .sort((a, b) => a.priority - b.priority);
    },
    localRules: {
      get() {
        return this.localData.filter((x) => !x.isPinRule);
      },
      set(newArr) {
        this.orderCustomRules({
          rankerId: this.rankerDetails.id,
          order: [...newArr.map((rule) => rule.id), ...this.localPins.map((rule) => rule.id)],
        });
      },
    },
    localPins: {
      get() {
        return this.localData.filter((x) => x.isPinRule);
      },
      set(newArr) {
        this.orderCustomRules({
          rankerId: this.rankerDetails.id,
          order: [...this.localRules.map((rule) => rule.id), ...newArr.map((rule) => rule.id)],
        });
      },
    },
    deviceType() {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
      }
      if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return 'mobile';
      }
      return 'desktop';
    },
    historicalItems() {
      const ret = [];
      for (const record of this.stagedRuleHistory) {
        const historyItem = {};
        historyItem.rules = record.staged_rules_json.length;
        historyItem.dateChanged = moment(new Date(record.history_date)).format('DD/MM/YYYY HH:mm');
        ret.push(historyItem);
      }
      return ret;
    },
  },
  watch: {
    activeTab(n) {
      if ((n === 0 && this.$route.hash !== '#ranker-rules') || this.$route.hash !== '#gpt-rules') {
        this.$router.replace({ hash: n === 0 ? 'ranker-rules' : 'gpt-rules' });
      }
    },
    historicalItems(n) {
      this.pagination.count = n.length;
    },
  },
  async created() {
    if (!this.$route.hash) {
      this.$router.replace({ hash: 'ranker-rules' });
    } else if (this.$route.hash === '#gpt-rules') {
      this.activeTab = 1;
    }
    await Promise.all([
      this.fetchCustomRules({
        params: { ranker_id: this.rankerDetails.id },
      }),
      this.fetchStagedRuleHistory(this.rankerDetails.id),
      this.fetchRuleUsageStats(this.rankerDetails.id),
      await this.getStageableVersions(this.rankerDetails.id),
    ]);
  },
  methods: {
    ...mapActions('customRule', {
      fetchCustomRules: 'fetchItems',
      updateCustomRule: 'patchItem',
      deleteCustomRule: 'deleteItem',
    }),
    ...mapActions('ranker', ['getStageableVersions', 'orderCustomRules', 'testRanker', 'stageRules', 'fetchStagedRuleHistory', 'fetchRuleUsageStats']),
    async fetchDisabledArticleRules() {
      try {
        this.isFecthingDisabled = true;
        const response = await axios.get(
          `${endpoints.customRule}disabled-rules/`,
          { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } },
        );
        this.result = response.data;
      } catch (error) { console.log('error here'); } finally {
        this.isFecthingDisabled = false;
      }
    },
    showRuleModal(ruleId, pinAction = false) {
      if (ruleId === null) {
        this.currentRule = null;
      } else {
        this.currentRule = cloneDeep(this.localData.find((x) => x.id === ruleId));
      }
      this.pinAction = pinAction;
      this.$bvModal.show('operate');
    },
    async handleDelete(rule) {
      if (await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this rule?', {
        title: 'Delete Custom Rule',
        okTitle: 'Delete Rule',
        okVariant: 'danger',
        autoFocusButton: 'cancel',
      })) {
        this.deleteCustomRule({ item: rule });
      }
    },
    toggleActive(newValue, rule) {
      this.updateCustomRule({ ...rule, active: newValue });
    },
    async handleTestRules() {
      this.response = await this.testRanker({
        rankerId: this.rankerDetails.id,
        text: this.text,
        context: this.context,
        metaData: this.metaData,
        device: this.deviceType,
        url: window.location.href,
        save: false,
        useUnstagedRules: true,
        testModel: false,
      });
    },
    async handleStageRules() {
      await this.stageRules({ rankerId: this.rankerDetails.id, unstage: false });
      this.fetchStagedRuleHistory(this.rankerDetails.id);
    },
    async handleUnstageRules() {
      if (await this.$bvModal.msgBoxConfirm('Are you sure you want to unstage rules? This will go into effect immediately.', {
        title: 'Unstage Custom Rules',
        okTitle: 'Unstage Rules',
        okVariant: 'danger',
        autoFocusButton: 'cancel',
      })) {
        await this.stageRules({ rankerId: this.rankerDetails.id, unstage: true });
        this.fetchStagedRuleHistory(this.rankerDetails.id);
      }
    },
    usageStats(ruleId) {
      if (!this.ruleUsageStats) {
        return null;
      }
      return this.ruleUsageStats[ruleId] || 0;
    },
  },
};
</script>
