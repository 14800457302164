<template>
  <div>
    <edit-key-value
      class="my-3"
      key-prop="Include tags"
      :description="getIncludeDescription"
      :value-prop="null"
      :min-key-width="keyWidth"
      type=""
    >
      <template #inputcontent>
        <div class="mb-0 p-1 border r-25-right">
          <chip-list
            v-if="includeCompletions.length"
            :draggable="false"
            :value="activeIncludeFilters"
            :completions="includeCompletions"
            @input="(x) => activeIncludeFilters = x"
          />
          <b-form-input
            v-else
            class="my-1"
            :placeholder="`There are no ${formattedPipelineType} tags to choose from`"
            disabled
          />
        </div>
      </template>
    </edit-key-value>
    <edit-key-value
      class="my-3"
      key-prop="Exclude tags"
      :description="getExcludeDescription"
      :value-prop="null"
      :min-key-width="keyWidth"
      type=""
    >
      <template #inputcontent>
        <div class="mb-0 p-1 border r-25-right">
          <chip-list
            v-if="excludeCompletions.length"
            :draggable="false"
            :value="activeExcludeFilters"
            :completions="excludeCompletions"
            @input="(x) => activeExcludeFilters = x"
          />
          <b-form-input
            v-else
            class="my-1"
            :placeholder="`There are no ${formattedPipelineType} tags to choose from`"
            disabled
          />
        </div>
      </template>
    </edit-key-value>
    <edit-key-value
      v-if="pipelineType === pipelineSourceTypes.ARTICLE.value"
      class="my-3"
      key-prop="Text filters"
      description="Filter articles based on text conditions.
      An article will only be included if it fulfills all the conditions."
      :value-prop="null"
      :min-key-width="keyWidth"
      type=""
    >
      <template #inputcontent>
        <div class="mb-0 p-1 border r-25-right">
          <text-condition
            :id="`${pipelineData.id}`"
            :conditions="conditions"
            query-display="article text"
            @editTextCondition="updateCondition"
            @addTextCondition="addCondition"
            @removeTextCondition="removeCondition"
          />
        </div>
      </template>
    </edit-key-value>
    <div v-if="pipelineType === pipelineSourceTypes.TICKET.value">
      <edit-key-value
        class="my-3"
        key-prop="Input specification"
        description="The text that the ranker should use as input for training"
        :value-prop="localData.inputSpec"
        :min-key-width="keyWidth"
        :options="Object.values(ticketInputSpecs)"
        :state="!$v.localData.inputSpec.$invalid"
        type="select"
        @input="(x) => localData.inputSpec = x"
      >
        <template #feedback>
          <b-form-invalid-feedback
            v-if="!$v.localData.inputSpec.required"
          >
            Input specification is required.
          </b-form-invalid-feedback>
        </template>
      </edit-key-value>
      <edit-key-value
        v-if="localData.inputSpec === ticketInputSpecs.CHAT"
        class="my-3"
        key-prop="Related chat data source"
        description="The chat data source containing the chats related to the tickets"
        :value-prop="localData.relatedChatDataSource"
        :min-key-width="keyWidth"
        :options="dataSourceOptions"
        type="select"
        @input="(x) => localData.relatedChatDataSource = x"
      />
      <edit-key-value
        v-if="localData.inputSpec === ticketInputSpecs.CHAT"
        class="my-3"
        key-prop="Chat identification"
        description="A regular expression with a single capturing group that will be used to
            search for the external id of a chat in the ticket fields"
        :value-prop="localData.chatRegex"
        :min-key-width="keyWidth"
        type="input"
        @input="(x) => localData.chatRegex = x"
      />
      <edit-key-value
        class="my-3"
        key-prop="Field pointing to article"
        description="The field on the ticket that points to the external id of the article"
        :value-prop="localData.articleField"
        :min-key-width="keyWidth"
        :options="ticketFieldOptions"
        type="select"
        @input="(x) => localData.articleField = x"
      />
    </div>

    <div class="d-block">
      <b-button
        :disabled="!unsavedChanges"
        variant="primary"
        :style="`width: ${keyWidth}px;`"
        class="mt-1"
        @click="localUpdateTrainingData()"
      >
        Update
      </b-button>
    </div>
    <span
      v-if=" unsavedChanges"
      class="unsaved-text"
    >
      *Unsaved changes
    </span>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ChipList from 'supwiz/components/ChipList.vue';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import { cloneDeep, isEqual } from 'lodash';
import {
  pipelineTypes, DataTypes, pipelineSourceTypes, ticketInputSpecs, pipelineTypeToDataType,
} from '@/js/constants';
import TextCondition from '@/components/Ranker/TextCondition.vue';

export default {
  name: 'PipelineConfig',
  components: {
    ChipList,
    EditKeyValue,
    TextCondition,
  },
  mixins: [validationMixin],
  props: {
    pipelineData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ticketInputSpecs,
      pipelineTypes,
      pipelineSourceTypes,
      localData: {},
    };
  },
  computed: {
    ...mapState('field', { fields: 'items' }),
    ...mapGetters('fieldValue', ['getCompletions']),
    ...mapGetters('dataSource', { dataSourceOptions: 'itemsOptions' }),
    ...mapGetters('dataSource', ['itemIdToName']),
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    ...mapState('ranker', { rankerDetails: 'details' }),
    ...mapGetters('field', ['fieldOptions']),
    getIncludeDescription() {
      return `Only include ${this.formattedPipelineType === pipelineTypeToDataType.querypipelinesource ? 'querie'
        : this.formattedPipelineType}s that have all of the given tags`;
    },
    getExcludeDescription() {
      return `Exclude all ${this.formattedPipelineType === pipelineTypeToDataType.querypipelinesource ? 'querie'
        : this.formattedPipelineType}s that have all of the given tags`;
    },
    unsavedChanges() {
      return !isEqual(this.localDataCopy, this.localData);
    },
    conditions() {
      return this.localData?.textFilters;
    },
    pipelineType() {
      return this.pipelineData.type;
    },
    formattedPipelineType() {
      return pipelineTypeToDataType[this.pipelineType];
    },
    keyWidth() {
      return 230;
    },
    articleFilterCompletions() {
      return this.getCompletions(this.pipelineData.targetDataSource, DataTypes.ARTICLE);
    },
    completions() {
      return this.getCompletions(
        this.pipelineData.dataSource,
        pipelineTypeToDataType[this.pipelineData.type],
      );
    },
    includeCompletions() {
      return this.completions.filter((e) => !this.activeExcludeFilters.includes(e.key));
    },
    excludeCompletions() {
      return this.completions.filter((e) => !this.activeIncludeFilters.includes(e.key));
    },
    ticketFieldOptions() {
      const options = this.fieldOptions(null, 'ticket');
      return options;
    },
    activeIncludeFilters: {
      get() {
        return this.localData.includeFilters || [];
      },
      set(value) {
        this.updatePipelineConfig({ key: 'includeFilters', value });
      },
    },
    activeExcludeFilters: {
      get() {
        return this.localData.excludeFilters || [];
      },
      set(value) {
        this.updatePipelineConfig({ key: 'excludeFilters', value });
      },
    },
  },
  watch: {
    pipelineData() {
      this.setUpLocalData();
    },
  },
  created() {
    this.setUpLocalData();
  },
  methods: {
    setUpLocalData() {
      this.localData = cloneDeep(this.pipelineData);
      this.localDataCopy = cloneDeep(this.pipelineData);
    },
    valueClicked(item) {
      if (this.pipelineData.target_field) {
        const field = this.fields[this.pipelineData.target_field];
        this.$router.push({
          name: 'tags-single',
          params: {
            dataSourceId: field.dataSource,
            fieldId: field.id,
          },
        });
      } else {
        this.$router.push({
          name: 'data-source-single',
          params: {
            dataSourceId: item,
          },
        });
      }
    },
    addCondition(condition) {
      this.localData.textFilters.push(condition);
    },
    updateCondition({ index, condition }) {
      this.localData.textFilters.splice(index, 1, condition);
    },
    removeCondition(index) {
      this.localData.textFilters.splice(index, 1);
    },
    updatePipelineConfig(payload) {
      this.$set(this.localData, payload.key, payload.value);
    },
    async localUpdateTrainingData() {
      this.$emit('updateTrainingData', {
        id: this.pipelineData.id,
        ...this.localData,
      });
    },
  },
  validations() {
    return {
      localData: {
        inputSpec: {
          required,
        },
      },
    };
  },
};
</script>

<style scoped>
</style>
