<template>
  <base-activity
    ref="baseActivity"
    :scraper-id="scraperId"
    :command-id="commandId"
    :indent="indent"
    bs-variant="dark"
    v-on="$listeners"
  >
    <template #icon>
      <font-awesome-icon icon="clock" />
    </template>
    <template #header>
      Wait {{ command.sleep_duration }} seconds
    </template>
    <label for="sleep-duration">
      Duration:
    </label>
    <b-form-input
      id="sleep-duration"
      :value="command.sleep_duration"
      type="number"
      step="0.1"
      min="0"
      max="60"
      @input="value => $root.$emit('update-scraper', { commandId, prop: 'sleep_duration', value })"
    />
  </base-activity>
</template>

<script>
import BaseActivity from '@/components/WebScraper/SeleniumCommands/BaseCommand.vue';

export default {
  name: 'WaitCommand',
  components: { BaseActivity },
  props: {
    scraperId: {
      type: Number,
      required: true,
    },
    commandId: {
      type: String,
      required: true,
    },
    command: {
      type: Object,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
};
</script>
