<template>
  <b-modal
    id="upload-articles-modal"
    ref="modal"
    title="Upload articles"
    :size="showFormat ? 'xl' : 'lg'"
    ok-variant="primary"
    ok-title="Upload"
    :ok-disabled="!readyForUpload()"
    @ok="uploadRelevantFiles()"
  >
    <template v-if="showFormat">
      <b-button
        variant="primary"
        @click="toggleShowFormat"
      >
        Back
      </b-button>
      <h2 class="mt-3">
        File types
      </h2>
      <div v-if="fileType === 'json'">
        <span>
          The uploaded file should either be a <code>json</code> array or a <code>csv</code> file.
        </span>
        <h2 class="mt-3">
          Fields
        </h2>
        <b-list-group>
          <b-list-group-item>
            <code>external_id</code>: The id of the article in the external system.
          </b-list-group-item>
          <b-list-group-item>
            <code>title</code>: The title of the article.
          </b-list-group-item>
          <b-list-group-item>
            <code>text</code>: The text content of the article.
          </b-list-group-item>
          <b-list-group-item>
            <code>current_url</code>: The current url of the article.
          </b-list-group-item>
          <b-list-group-item>
            <code>html_text</code> (optional): The raw html text content of the article.
          </b-list-group-item>
          <b-list-group-item>
            <code>urls</code> (optional): The current and historic URLs used by the article
            separated by spaces (csv) or as an array (json).
            These are used to find references to the article in the historic data.
          </b-list-group-item>
          <b-list-group-item>
            <code>article_created</code> (optional): The time that the article was created in
            iso format, e.g. "2021-04-26T14:51:15Z".
          </b-list-group-item>
          <b-list-group-item>
            <code>article_modified</code> (optional): The last time that the article was modified in
            iso format, e.g. "2021-04-26T14:51:15Z".
          </b-list-group-item>
        </b-list-group>
        <h2 class="mt-3">
          Examples
        </h2>
        JSON
        <b-card
          border-radius="25"
          class="p-2 mb-2 overflow-hidden w-100 border bg-light"
        >
          <vue-json-pretty
            :data="jsonExample"
            :deep="10"
          />
        </b-card>
        CSV
        <b-card
          border-radius="25"
          class="p-2 mb-2 overflow-hidden w-100 border bg-light"
        >
          <p>
            external_id, title, current_url, urls, text<br>
            1, I have a router issue, http://articles.com/router, http://routers.org/how-to-fix-it, You can reset the router if it does not work.<br>
            2, Login issues, http://articles.com/login-issue, http://app.com/login, In order to reset your password you can go to http://app.com/login
          </p>
        </b-card>
      </div>
      <div v-else-if="fileType === 'pdf'">
        <span>
          The uploaded files must be either <code>pdf</code> or <code>doc/docx</code> files.
          SupSearch will extract the text and title.
        </span>
      </div>
    </template>
    <template v-else>
      <b-form-group label="File type">
        <b-form-radio-group
          v-model="fileType"
          button-variant="outline-primary"
          :options="fileTypeOptions"
          buttons
          block
        />
      </b-form-group>
      <b-form-group v-if="fileType === 'pdf'" label="Upload type">
        <b-form-radio-group
          v-model="uploadType"
          :options="uploadTypeOptions"
          button-variant="outline-primary"
          bottons
          block
        />
      </b-form-group>
      <p v-if="fileType === 'json' || uploadType === 'file'">
        Choose {{ fileType === 'json' ? 'a file' : 'files' }} to upload
        (<b-link @click="toggleShowFormat">
          see format
        </b-link>).
      </p>
      <p v-else>
        Enter url of file to upload
        (<b-link @click="toggleShowFormat">
          see format
        </b-link>).
      </p>
      <b-form-file
        v-if="fileType === 'json'"
        v-model="articlesFile"
        placeholder="Choose file..."
        accept=".json, .csv"
      />
      <b-form-file
        v-else-if="fileType === 'pdf' && uploadType === 'file'"
        v-model="articlesFiles"
        multiple
        placeholder="Choose files..."
        accept=".pdf, .doc, .docx"
      />
      <b-list-group v-else class="w-100 urls-list r-25-right border">
        <b-form-input v-model="articleUrl" placeholder="Add url..." />
      </b-list-group>
    </template>
  </b-modal>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { mapActions } from 'vuex';
import { DataTypes } from '@/js/constants';

export default {
  name: 'UploadArticlesModal',
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      fileType: 'json',
      fileTypeOptions: [
        { text: 'PDF / DOCX', value: 'pdf' },
        { text: 'CSV / JSON', value: 'json' },
      ],
      uploadType: 'url',
      uploadTypeOptions: [
        { text: 'Via URL', value: 'url' },
        { text: 'Via file', value: 'file' },
      ],
      articlesFile: null,
      articlesFiles: [],
      articleUrl: '',
      showFormat: false,
      jsonExample: JSON.parse('[{"external_id": 1, "title": "I have router issues","current_url":"http://articles.com/router",\n"urls": ["http://articles.com/router"], "text": "You can reset the router if it does not work."}, {"external_id": 2, "title": "Login issues","current_url":"http://articles.com/login-issue","urls": ["http://articles.com/login-issue"], "text": "In order to reset your password, you can go to http://app.com/login"}]'),
    };
  },
  methods: {
    ...mapActions('fileUpload', { uploadFiles: 'uploadFiles', uploadFilesViaUrls: 'uploadFilesViaUrls' }),
    toggleShowFormat() {
      this.showFormat = !this.showFormat;
    },
    readyForUpload() {
      if (this.fileType === 'json') {
        return this.articlesFile != null;
      }
      if (this.uploadType === 'file') {
        return this.articlesFiles.length > 0;
      }
      return this.articleUrl.length > 0;
    },
    uploadRelevantFiles() {
      if (this.fileType === 'json') {
        this.uploadFiles({ files: [this.articlesFile], dataType: DataTypes.ARTICLE });
      } else if (this.uploadType === 'file') {
        this.uploadFiles({ files: this.articlesFiles, dataType: DataTypes.ARTICLE });
      } else {
        this.uploadFilesViaUrls({ urls: [this.articleUrl], dataType: DataTypes.ARTICLE });
      }
    },
  },
};
</script>
<style scoped>
.string-list{
  max-height: 195px;
  overflow-y: auto;
}

</style>
