<template>
  <b-modal
    id="import-modal"
    ref="modal"
    :title="`Import ${type}`"
    ok-variant="primary"
    :ok-disabled="isDisabled"
    ok-title="Import"
    @ok="importData"
  >
    <b-form @submit.prevent>
      <b-row>
        <b-col>
          <display-key-value
            class="mb-3"
            key-prop="Data Source"
            :value-prop="dataSourceDetails.name"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-form-group>
            <label for="start-date">Start date</label>
            <b-form-datepicker
              id="start-date"
              v-model="dateRange.start"
              nav-button-variant="primary"
              required
              class="mb-2"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-form-group>
            <label for="end-date">End date</label>
            <b-form-datepicker
              id="end-date"
              v-model="dateRange.end"
              nav-button-variant="primary"
              required
              class="mb-2"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import DisplayKeyValue from 'supwiz/components/DisplayKeyValue.vue';

export default {
  name: 'ImportModal',
  components: {
    DisplayKeyValue,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  computed: {
    ...mapState('dataSource', { dataSourceDetails: 'details' }),
    isDisabled() {
      return !this.dateRange.start || !this.dateRange.end
      || this.dateRange.start > this.dateRange.end;
    },
  },
  methods: {
    ...mapActions('article', { importArticles: 'importItems' }),
    ...mapActions('chat', { importChats: 'importItems' }),
    ...mapActions('ticket', { importTickets: 'importItems' }),
    importData() {
      const data = {
        dateRange: this.dateRange,
        dataSourceId: this.dataSourceDetails.id,
        type: this.dataSourceDetails.type,
      };
      if (this.type === 'articles') {
        this.importArticles(data);
      } else if (this.type === 'tickets') {
        this.importTickets(data);
      } else {
        this.importChats(data);
      }
    },
  },
};
</script>
