<template>
  <b-modal
    id="file-split-modal"
    ref="modal"
    size="xl"
    title="Convert file into articles"
    :ok-disabled="$v.url.$invalid"
    no-close-on-backdrop
    @show="prepare"
    @ok="convert"
  >
    <b-spinner v-if="!initialFetchDone" class="mx-auto my-3 d-block" />
    <template v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="File url"
            description="Articles are associated with this link when presented in search engines"
          >
            <b-input-group>
              <b-form-input v-model="url" :state="!$v.url.$invalid" />

              <b-input-group-append v-if="allowReupload">
                <b-button v-b-tooltip.hover.noninteractive.viewport="'Reupload file from provided url'" :disabled="$v.url.$invalid" @click="reupload">
                  <font-awesome-icon icon="rotate" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback :state="!$v.url.$invalid">
              Invalid url
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :disabled="configOptions.length === 0"
            label="Load configurations"
            description="Load configurations from previous uploads across data sources"
          >
            <b-form-select :options="configOptions" @change="loadConfig" />
          </b-form-group>
          <b-form-group
            label="Header font sizes"
            description="A new article starts when a header with these font sizes are found"
          >
            <b-form-checkbox-group
              v-model="config.heading_font_sizes"
              button-variant="outline-primary"
              :options="headerSizeOptions"
              buttons
            />
          </b-form-group>
          <b-form-checkbox v-model="showExcluded" class="d-inline" switch>
            Show excluded blocks
          </b-form-checkbox>
          <b-form-checkbox v-model="shortenText" class="d-inline ml-2" switch>
            Truncate texts
          </b-form-checkbox>
          <b-button variant="primary" block class="mt-2" @click="test">
            Test
          </b-button>
        </b-col>
        <b-col>
          <span
            v-b-tooltip.hover.noninteractive.viewport
            class="d-block mb-2"
            title="Text blocks are excluded from the articles if they satisfy any of the below conditions"
          >
            Text Exclusion Conditions
          </span>

          <text-condition
            id="excludeRuleConditions"
            :conditions="excludeRules"
            query-display="text"
            :scrollable="308"
            :condition-options="['regex', 'negate', 'caseSensitive', 'exactMatch', 'fontSize', 'deleteStrategy']"
            :font-size-options="fontSizeOptions"
            @editTextCondition="updateCondition"
            @addTextCondition="addCondition"
            @removeTextCondition="removeCondition"
          />
        </b-col>
      </b-row>

      <hr />

      <div v-if="!fileElements.length" class="py-3">
        There are no records to show.
      </div>
      <div v-else style="overflow-y: scroll; height:500px;">
        <b-card
          v-for="(element, index) in fileElements"
          :key="element.id"
          no-body
          class="mb-1"
        >
          <h4 v-if="element.cutAbove" class="font-weight-bolder">
            <hr v-if="index != 0">
            <font-awesome-icon
              icon="book"
              class="mr-2"
              size="lg"
            />

            Article
            ({{ element.articleNumber }} / {{ totalArticles }})
          </h4>

          <h1 v-if="element.tag === 'h1'">
            <s v-if="element.isExcluded">
              {{ element.text }}
            </s>
            <span v-else>
              {{ element.text }}
            </span>
          </h1>
          <h2 v-else-if="element.tag === 'h2'">
            <s v-if="element.isExcluded">
              {{ element.text }}
            </s>
            <span v-else>
              {{ element.text }}
            </span>
          </h2>
          <h3 v-else-if="element.tag === 'h3'">
            <s v-if="element.isExcluded">
              {{ element.text }}
            </s>
            <span v-else>
              {{ element.text }}
            </span>
          </h3>
          <h4 v-else-if="element.tag === 'h4'">
            <s v-if="element.isExcluded">
              {{ element.text }}
            </s>
            <span v-else>
              {{ element.text }}
            </span>
          </h4>
          <p v-else-if="element.tag === 'small'" class="mb-0">
            <small>
              <s v-if="element.isExcluded">
                {{ element.text }}
              </s>
              <span v-else>
                {{ element.text }}
              </span>
            </small>
          </p>
          <p v-else class="mb-0">
            <s v-if="element.isExcluded">
              {{ element.text }}
            </s>
            <span v-else>
              {{ element.text }}
            </span>
          </p>
        </b-card>
      </div>
    </template>
    <template #modal-footer="{ ok, cancel }">
      <b-row class="w-100" no-gutters>
        <b-col cols="auto">
          <DebounceButton
            text="Set as default"
            tooltip="Set above conversion config as default for this data source"
            @click="updateDefault"
          />
          <DebounceButton
            text="Apply to all"
            tooltip="Reconvert all files in this data source with the above config"
            variant="warning"
            @click="reconvertAll"
          />
        </b-col>
        <b-col class="text-right">
          <b-button class="mr-2" @click="cancel()">
            Cancel
          </b-button>

          <b-button
            variant="primary"
            :disabled="$v.url.$invalid"
            @click="ok()"
          >
            Convert
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { required, url } from 'vuelidate/lib/validators';
import axios from 'axios';
import { dataSourceTypeToEndpoint, dataSourceTypes } from '@/js/constants';
import endpoints from '@/js/endpoints';
import { mapActions, mapGetters } from 'vuex';
import TextCondition from '@/components/Ranker/TextCondition.vue';
import { cloneDeep } from 'lodash';

function shorten(text) {
  if (text.length < 120) {
    return text;
  }
  return `${text.slice(0, 100)} (...) ${text.slice(-20)}`;
}

export default {
  name: 'FileSplitterModal',
  components: { TextCondition },
  mixins: [
    validationMixin,
  ],
  props: {},
  data() {
    return {
      initialFetchDone: false,
      testResponse: null,
      config: {},
      url: '',
      showExcluded: false,
      shortenText: true,
      configOptions: [],
    };
  },
  computed: {
    ...mapGetters('fileUpload', ['details']),
    ...mapGetters('auth', ['headerAuthorization']),
    keyWidth() {
      return 180;
    },
    excludeRules() {
      return this.config?.exclude_rules || [];
    },
    allowReupload() {
      return this.details?.dataSource?.type === dataSourceTypes.UPLOAD.value;
    },
    fileElements() {
      const font2tag = this.font2tag;
      let elements = this.testResponse?.gross_elements || [];
      const excludeIds = this.testResponse?.exclude_ids || [];
      const cutAbove = this.testResponse?.cut_before_ids || [];
      const firstCut = elements.findIndex((x) => cutAbove.includes(x.id));
      let articleCounter = 0;
      elements = elements.map((x, idx) => {
        if (cutAbove.includes(x.id)) {
          articleCounter += 1;
        }
        return {
          ...x,
          tag: font2tag[x.font_size] || 'p',
          text: this.shortenText ? shorten(x.text) : x.text,
          isExcluded: excludeIds.includes(x.id) || idx < firstCut,
          cutAbove: cutAbove.includes(x.id),
          articleNumber: articleCounter,
        };
      });
      if (!this.showExcluded) {
        return elements.filter((x) => !x.isExcluded);
      }
      return elements;
    },
    totalArticles() {
      const cutAbove = this.testResponse?.cut_before_ids || [];
      const elements = this.testResponse?.gross_elements || [];
      return elements.filter((x) => cutAbove.includes(x.id)).length;
    },
    font2tag() {
      return this.testResponse?.font2tag || {};
    },
    headerSizeOptions() {
      const font2tag = this.font2tag;
      const elementSizes = (this.testResponse?.gross_elements || []).filter((x) => !['p', 'small'].includes(font2tag[x.font_size])).map(
        (x) => parseInt(x.font_size, 10),
      );
      return [...new Set(elementSizes)].sort((a, b) => a - b).reverse();
    },
    fontSizeOptions() {
      const elementSizes = (this.testResponse?.gross_elements || []).map(
        (x) => parseInt(x.font_size, 10),
      );
      return [{ value: null, text: 'Any' }].concat([...new Set(elementSizes)].sort((a, b) => a - b).reverse());
    },
  },
  methods: {
    ...mapActions('dataSource', { updateDataSource: 'patchItem' }),
    async prepare() {
      this.loadInitialConfig();
      await this.loadConfigOptions();
      await this.test();
      this.initialFetchDone = true;
    },
    async loadConfigOptions() {
      const { data } = await axios.get(endpoints.fileSplittingConfigs, this.headerAuthorization);
      const options = data.filter((x) => x.id !== this.details?.id).sort(
        (a, b) => a.data_source__name.localeCompare(b.data_source__name)
         || b.name.localeCompare(a.name),
      );
      const groupedOptions = [];
      let running = null;
      for (const option of options) {
        if (running != null && (running?.label !== option.data_source__name)) {
          groupedOptions.push(running);
          running = null;
        }
        if (running === null) {
          running = { label: option.data_source__name, options: [] };
        }
        running.options.push({
          text: option.name,
          value: option.id,
          config: option.file_splitting_config,
        });
      }
      if (running != null) {
        groupedOptions.push(running);
      }
      this.configOptions = groupedOptions;
    },
    loadInitialConfig() {
      if (this.details.fileSplittingConfig != null) {
        this.config = cloneDeep(this.details.fileSplittingConfig);
      } else if (this.details?.dataSource?.default_file_splitting_config != null) {
        this.config = cloneDeep(this.details.dataSource.default_file_splitting_config);
      }
      if (this.details.url) {
        this.url = this.details.url;
      }
    },
    loadConfig(option) {
      if (option?.config !== undefined) {
        this.config = cloneDeep(option.config);
      }
    },
    async test() {
      if (this.details.id == null) {
        return;
      }
      const resp = await axios.post(`${endpoints.fileUpload}${this.details.id}/test-file-split/`, { config: this.config, url: this.url }, this.headerAuthorization);
      this.testResponse = resp.data;
    },
    async convert() {
      if (this.details.id == null) {
        return;
      }
      // Call backend to split files and save to db + save config
      await axios.post(`${endpoints.fileUpload}${this.details.id}/split-file/`, { config: this.config, url: this.url }, this.headerAuthorization);
    },
    updateCondition({ index, condition }) {
      const copy = cloneDeep(this.config.exclude_rules || []);
      copy.splice(index, 1, condition);
      this.$set(this.config, 'exclude_rules', copy);
    },
    addCondition(condition) {
      const copy = cloneDeep(this.config.exclude_rules || []);
      copy.push(condition);
      this.$set(this.config, 'exclude_rules', copy);
    },
    removeCondition(index) {
      const copy = cloneDeep(this.config.exclude_rules || []);
      copy.splice(index, 1);
      this.$set(this.config, 'exclude_rules', copy);
    },
    async reupload() {
      // PATCH fileUpload/<id>/via-url/ to update url and clear extractedData
      await axios.post(`${endpoints.fileUpload}${this.details.id}/via-url/`, { url: this.url }, this.headerAuthorization);
      // POST fielUpload/process/ to trigger processing of file
      await axios.post(`${endpoints.fileUpload}process/`, {}, this.headerAuthorization);
      // Close modal
      this.$bvModal.hide('file-split-modal');
    },
    async updateDefault() {
      const dataSourceId = this.details?.dataSource?.id;
      const patchEndpoint = dataSourceTypeToEndpoint[this.details?.dataSource?.type];
      if (dataSourceId && patchEndpoint) {
        await axios.patch(`${patchEndpoint}${dataSourceId}/`, { default_file_splitting_config: this.config }, this.headerAuthorization);
      }
    },
    async reconvertAll() {
      const dataSourceId = this.details?.dataSource?.id;
      if (dataSourceId) {
        await axios.post(`${endpoints.fileUpload}/reconvert-all/`, { data_source: dataSourceId, file_splitting_config: this.config }, this.headerAuthorization);
      }
      this.$bvModal.hide('file-split-modal');
    },
  },
  validations() {
    return {
      url: { required, url },
    };
  },
};
</script>
