<template>
  <div class="h-100">
    <b-row
      v-if="isFetching"
      class="h-100 align-items-center"
    >
      <b-col class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem;"
        />
      </b-col>
    </b-row>
    <router-view v-else />
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  name: 'LanguageModelBase',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!Object.values(vm.items).length) {
        vm.fetchLanguageModels();
      }
    });
  },
  computed: {
    ...mapState('languageModel', ['isFetching', 'items']),
  },
  methods: {
    ...mapActions('languageModel', { fetchLanguageModels: 'fetchItems' }),
  },
};
</script>
